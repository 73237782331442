import { useState, useContext, useEffect } from 'react';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress,
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControl,
    IconButton,
    MenuItem,
    Stack,
    TextField, Typography,
} from '@material-ui/core';
import { Close, GroupAdd } from '@material-ui/icons';


// NPM IMPORTS
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
// API IMPORTS
import { newTeamApi, editTeamApi } from '../../../../../../_apis_/authApi.js/staffApi';
// ACTION REDUX IMPORTS
import { newTeam, editTeam, getAllTeams } from '../../../../../../redux/action/staffAction';
// CONTEXT IMPORTS
import { StaffContext } from '../../../../../../contexts/staffContext';
import { SettingsContext } from '../../../../../../contexts/SettingsContext';
// COMPONENT IMPORTS
import { MIconButton } from '../../../../../../components/@material-extend';
import GlobalButton from '../../../../../Common Component/GlobalButton';
import DisableWrapper from '../../../../../dashboard/DisableWrapper';



export default function TeamDialog(type) {
    // CONTEXT STATES
    const { getTeams, isTeamOpen, setTeamOpen, teamId, setTeamId, allGroup } = useContext(StaffContext);
    const { setColor } = useContext(SettingsContext);
    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // REDUX STATES
    const dispatch = useDispatch();
    // REACT STATES
    const [isActive, setActive] = useState(true);
    const [isDel, setDel] = useState(false);
    const [disBtn, setDisBtn] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState();

    // FORMIK STATES
    const formik = useFormik({
        initialValues: { Tname: '', groups: '' },
        validationSchema: Yup.object({

            Tname: Yup.string().required('*Required Field'),

            groups: Yup.string().required('*Required Field'),


        }),
        onSubmit: values => {


            async function edit() {
                setDisBtn(true)
                try {



                    const newObj = {
                        "groups": values.groups,
                        "is_active": true,
                        "is_deleted": false
                    }

                    if (values.Tname?.trim() !== selectedTeam.name.trim()) {
                        newObj.name = values.Tname?.trim()
                    }

                    const { data } = await editTeamApi(teamId, newObj);
                    // console.log(data)
                    dispatch(editTeam(data))
                    setDisBtn(false)
                    setSelectedTeam();
                    enqueueSnackbar('Updated Team Successfully', {
                        variant: 'success',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    dispatch(getAllTeams());
                    // console.log(data);
                    handleClose();


                } catch (err) {
                    if (!navigator.onLine) {
                        enqueueSnackbar('No Internet Connection Found', {
                            variant: 'error',
                            action: (key) => (

                                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </MIconButton>
                            )
                        });
                        return setDisBtn(false);
                    }
                    enqueueSnackbar(`${err.response.data.message}`, {
                        variant: 'error',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    setDisBtn(false);
                }

                // alert(teamId)


            }

            if (teamId) return edit()

            async function addNew() {
                setDisBtn(true)
                try {
                    const newObj = {
                        "name": values.Tname?.trim(),
                        "groups": values.groups,
                        "is_active": true,
                        "is_deleted": false
                    }

                    const { data } = await newTeamApi(newObj);
                    dispatch(newTeam(data))
                    setDisBtn(false)
                    enqueueSnackbar('Added Team Successfully', {
                        variant: 'success',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    dispatch(getAllTeams());
                    handleClose();

                } catch (err) {
                    if (!navigator.onLine) {
                        enqueueSnackbar('No Internet Connection Found', {
                            variant: 'error',
                            action: (key) => (

                                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </MIconButton>
                            )
                        });
                        return setDisBtn(false);
                    }
                    enqueueSnackbar(`${err.response.data.message}`, {
                        variant: 'error',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )
                    });
                    setDisBtn(false);
                }
            }

            addNew()


        }
    })

    useEffect(() => {
        if (teamId) {
            const team = getTeams?.find((obj) => {
                if (obj?.id === teamId) {
                    return obj;
                }
                return null
            })
            setSelectedTeam(team);
            formik.setFieldValue('Tname', team?.name)
            formik.setFieldValue('groups', team?.groups?.id)
        }

    }, [isTeamOpen])


    const handleClose = () => {
        setTeamId(null);
        formik.resetForm();
        setTeamOpen(false);
        setSelectedTeam();
    };

    const handleOpenForm = () => {
        setTeamOpen(true)
    }

    return (
        <div>
            <GlobalButton className="halloween-animation"
                label='Add Team'
                sx={{ px: '30px' }}
                variant="contained"
                startIcon={
                    <GroupAdd
                        sx={{
                            color: '#fff',
                            // stroke: (theme) => theme.palette.background.buttonPaper, strokeWidth: '1px'
                        }}
                    />
                }

                fn={
                    handleOpenForm
                }
            />



            <Dialog open={isTeamOpen} maxWidth="sm" fullWidth >
                <DisableWrapper exclude={['exclude-field', 'no-disable']}>

                    <form onSubmit={formik.handleSubmit}>
                        <Stack sx={{ py: 1, pr: 1, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} direction="row" alignContent="center" alignItems="center" justifyContent="space-between">
                            <DialogTitle > <h3>{teamId ? "Edit Team" : "Add New Team"}</h3></DialogTitle>
                            <IconButton className='no-disable' sx={{ mt: 1 }} onClick={() => { handleClose(); }}><Close /></IconButton>
                        </Stack>
                        {/* <Divider /> */}
                        <DialogContent style={{ pt: 3 }}>

                            <Stack spacing={3}>
                                <Box sx={{ my: 2 }}>
                                    <TextField
                                        fullWidth
                                        label={<>
                                            Team Name
                                            <Typography variant='body' sx={{ color: setColor?.main, fontWeight: "bold" }}>&nbsp;*</Typography>
                                        </>}
                                        name="Tname"
                                        {...formik.getFieldProps('Tname')}
                                        onChange={(e) => formik.setFieldValue('Tname', e?.target?.value)}
                                    />
                                    {formik.errors.Tname && formik.touched.Tname ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.Tname}</Typography> : null}
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="select a group"
                                            select
                                            label={<>
                                                Select A Group
                                                <Typography variant='body' sx={{ color: setColor?.main, fontWeight: "bold" }}>&nbsp;*</Typography>
                                            </>}
                                            // label="Select A Group"
                                            variant="outlined"
                                            sx={{ my: 2 }}
                                            name="groups"
                                            {...formik.getFieldProps('groups')}
                                        >
                                            {allGroup?.map((obj, index) => (
                                                <MenuItem key={index} sx={{ color: 'text.primary' }} value={obj.id}>{obj.name}</MenuItem>
                                            ))}

                                        </TextField>

                                    </FormControl>
                                    {formik.errors.groups && formik.touched.groups ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px' }}>{formik.errors.groups}</Typography> : null}

                                </Box>

                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button className='no-disable' sx={{ color: theme => theme.palette.text.buttonText }} onClick={() => { handleClose(); }} color="inherit">
                                Cancel
                            </Button>
                            <Button className="halloween-animation" sx={{ boxShadow: 'none', border: '1px solid #dddde7', borderRadius: '7px', color: theme => theme.palette.text.buttonText, background: theme => theme.palette.background.paper, '&:hover': { color: theme => theme.palette.text.buttonText, background: theme => theme.palette.background.paper } }} type='submit' variant="contained" disabled={disBtn}>
                                {teamId ? "Edit Team" : "Add Team"}
                                {disBtn && <CircularProgress size={14} sx={{ ml: 1 }} />}
                            </Button>
                        </DialogActions>
                    </form>
                </DisableWrapper>
            </Dialog>
        </div>
    );
}
