export const applySortFilter = (array, comparator, query) => {
    // console.log(array);
    const stabilizedThis = array?.map((el, index) => [el, index]);

    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis?.map((el) => el[0]);
}


const getSortArrayString = (arr, keyName, b) => {
    if (arr?.length === 0) {
        return ""
    }

    if (arr?.length > 1) {
        return `${arr?.length}` ?? ""
    }

    if (arr?.length === 1) {
        return arr[0][keyName] ?? ""
    }
}

function descendingComparator(a, b, orderBy, keyArr) {
    const key = keyArr?.filter(obj => obj?.columnId === orderBy)
    const fkey = key?.[0]?.keyName;

    if (Array.isArray(a[orderBy]) && Array.isArray(b[orderBy])) {
        const valueA = getSortArrayString(a[orderBy], fkey)
        const valueB = getSortArrayString(b[orderBy], fkey, "B")
        return valueB.localeCompare(valueA, undefined, { sensitivity: "base" });
    }

    if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
        // console.log(a[orderBy], b[orderBy])
        return b[orderBy].localeCompare(a[orderBy], undefined, { sensitivity: "base" });
    }
    return b[orderBy] - a[orderBy]; // Works for numbers
}

export const getComparator = (order, orderBy, keyArr) => {
    // console.log(orderBy)
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, keyArr)
        : (a, b) => -descendingComparator(a, b, orderBy, keyArr);
}
