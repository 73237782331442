import React, { createContext, useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import humps from 'humps';
import { useSnackbar } from 'notistack5';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { MIconButton } from '../components/@material-extend';
// import { API } from '../backend';
// -----------------------------------------------------------

const ReportingContext = createContext();
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
const grpArr = loggedUserData?.groups?.map((obj) => obj.id);

const handlers = {
  USERS_LEVEL: (state, action) => {
    // console.log(action.payload);
    const userLevels = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      userLevels
    };
  },
  GET_CUSTOM_DASHBOARD_DATA: (state, action) => {

    const customDashboardData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      customDashboardData
    };
  },
  GET_DRAFT_DASHBOARD_DATA: (state, action) => {

    const draftDashboardData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      draftDashboardData
    };
  },
  GET_SHARED_DASHBOARD_DATA: (state, action) => {

    const sharedDashboardData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      sharedDashboardData
    };
  },
  GET_VISULAIZATION_DATA: (state, action) => {

    const visulaizationData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      visulaizationData
    };
  },
  GET_MULTIPLE_VISULAIZATION_DATA: (state, action) => {
    const newVisulaizationData = action.payload; // New data from the process.env.REACT_APP_API_URL
    const existingVisulaizationData = state.multipleVisulaizationData || []; // Existing data

    return {
      ...state,
      isAuthenticated: true,
      multipleVisulaizationData: [...existingVisulaizationData, ...newVisulaizationData] // Append new data to existing data
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const ReportingProvider = ({ children }) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const initialState = {
    userLevels: [],
    groups: [],
    Teams: [],
    allUsers: [],
    multipleVisulaizationData: []
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showPreviewComponent, setShowPreviewComponent] = useState(false);
  const [phraseBuilderObj, setPhraseBuilderObj] = useState({
    builder: []
  });
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [exploreDialogOpen, setExploreDialogOpen] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [indDashboardData, setIndDashboardData] = useState();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [stateData, setStateData] = useState({
    data: [],
    // count:''
  })
  const [editData, setEditData] = useState()
  const [dashboardItems, setDashboardItems] = useState([]);

  // 
  const [loading, setLoading] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  // Create new dashboard
  // created_by_id
  const createNewDashboard = async (name) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard`, JSON.stringify({
      "name": name,
      'created_by': loggedUserData?.id
    }), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
  };

  const getSharedDashboardData = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true)
    const response = axios.get(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/shared_dashboard?is_draft=False`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      setShowSpinner(false)
      const data = humps.camelizeKeys(response.data);
      dispatch({
        type: 'GET_SHARED_DASHBOARD_DATA',
        payload: data
      });
    })
      .catch((error) => {
        setShowSpinner(false)
        console.error(error)

      })
  }
  // Get dashboard table data
  const getDraftDashboardData = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true)
    const response = axios.get(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      setShowSpinner(false)
      const { count, pageRange, results } = humps.camelizeKeys(response.data);
      dispatch({
        type: 'GET_DRAFT_DASHBOARD_DATA',
        payload: { count, pageRange, results }
      });
    })
      .catch((error) => {
        setShowSpinner(false)
        console.error(error)

      })
  }

  // Delete User
  const deleteDashboard = async (dashboardId) => {
    const accessToken = window.localStorage.getItem('accessToken');

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/${dashboardId}`, {
        headers: {
          Authorization: `Token ${accessToken}`
        }
      });
      setLoading(false)
      return response
    } catch (error) {
      console.error('error', error)
      setLoading(false)

    }
  };

  // Run Filters
  const runFilters = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    // Check if obj is empty
    if (!obj || Object.keys(obj).length === 0) {
      dispatch({
        type: 'GET_VISULAIZATION_DATA',
        payload: [] // Dispatch empty payload
      });
      return; // Exit the function early
    }

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/get_data`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const { data } = humps.camelizeKeys(response.data);
      // Access the first 10 objects
      const firstFiveObjects = data?.slice(0, 10);

      // console.log(firstFiveObjects);
      // console.log(firstFiveObjects);
      dispatch({
        type: 'GET_VISULAIZATION_DATA',
        payload: data
      });
    })
      .catch((error) => {
        console.error(error)
      })
  };




  // Save dashboard
  const saveExploreDashboardDialog = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/save_graph`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      // const { data } = humps.camelizeKeys(response.data);
      // Access the first 10 objects
      // const firstFiveObjects = data?.slice(0, 10);

      // console.log(firstFiveObjects);
      // console.log(firstFiveObjects);
      // dispatch({
      //   type: 'GET_VISULAIZATION_DATA',
      //   payload: data
      // });
    })
      .catch((error) => {
        console.error(error)
      })
  };

  const saveMainDashboard = async (obj) => {
    setShowSpinner(true)
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/save_dashboard_items`,
      JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((rs) => {
      setShowSpinner(false)
    }).catch((err) => {
      setShowSpinner(false)
    })
  }
  // Save dashboard
  const saveMainDashboardText = async (dashboardId, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.put(`${process.env.REACT_APP_API_URL}/reporting_dashboard/text_items/${dashboardId}`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
  };

  // Save dashboard markdown
  const saveMainDashboardMarkdown = async (dashboardId, obj) => {

    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.put(`${process.env.REACT_APP_API_URL}/reporting_dashboard/markdown/${dashboardId}`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
  };

  // Get custom dashboard data
  const getCustomDashboardData = async (dashboardId) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true)
    const data = axios.get(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/get_saved_graph?dashboard_id=${dashboardId}`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      // const { data } = humps.camelizeKeys(response);
      const { data } = response;
      dispatch({
        type: 'GET_CUSTOM_DASHBOARD_DATA',
        payload: data
      });
      setShowSpinner(false)

      setDashboardItems(data?.data)
      return data;
      // dispatch({
      //   type: 'GET_CUSTOM_DASHBOARD_DATA',
      //   payload: data
      // });
    })
      .catch((error) => {
        console.error(error)
        setShowSpinner(false)

      })

    return data;
  }

  // Run Multiple Filters
  const runMultipleFilters = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/get_data`, obj, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const { data } = humps.camelizeKeys(response.data);
      return data;
      // dispatch({
      //   type: 'GET_CUSTOM_DASHBOARD_DATA',
      //   payload: data
      // });
    })
      .catch((error) => {
        console.error(error)
      })

    return data;

    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/get_data`,
    //     obj, // No need to stringify, axios will do it
    //     {
    //       headers: {
    //         'Authorization': `Token ${accessToken}`,
    //         'Content-Type': 'application/json'
    //       }
    //     }
    //   );

    //   const { data } = humps.camelizeKeys(response.data);

    //   // dispatch({
    //   //   type: 'GET_MULTIPLE_VISULAIZATION_DATA',
    //   //   payload: data
    //   // });

    //   return data; // Return the data for potential further use
    // } catch (error) {
    //   console.error('Error in runMultipleFilters:', error);
    //   throw error; // Re-throw the error for the caller to handle
    // }


    // return data;
  };

  // Delete custom dashboard panel api
  const deletePanel = async (panelId, model) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/delete_graph?id=${panelId}&model=${model}`, {
      headers: {
        'Authorization': `Token ${accessToken}`
      }
    });
  };


  // Run Filters
  const getScDropdownValues = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');


    const data = await axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/get_data?filter=true`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const { data } = humps.camelizeKeys(response.data);

      return data;

    })
      .catch((error) => {
        console.error(error)
      })
    return data;
  };

  // Graph drilldown
  const graphDrilldown = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const data = await axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/get_data`, JSON.stringify(obj), {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const { data } = humps.camelizeKeys(response.data);

      return data;

    })
      .catch((error) => {
        console.error(error)
      })
    return data;
  };

  // 
  const getAccessPermissionData = async (id) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = axios.get(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/${id}`, {
      headers: {
        'Authorization': `Token ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const results = humps.camelizeKeys(response.data);
      setStateData({
        data: results
      })
    })
      .catch((error) => {
        console.error(error)
      })
  }
  // /reporting_dashboard/dashboard/1
  const updateAccessPermissionData = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setLoading(true)

    const response = axios.put(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/${obj?.id}`,
      {
        "groups": obj?.groups,
        "roles": obj?.roles,
        "name": obj?.name
      },
      {
        headers: {
          'Authorization': `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        setLoading(false)

        const results = humps.camelizeKeys(response.data);
        setStateData({
          data: results
        })
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)

      })
  }

  const moveToDraftAndShared = async (obj) => {
    setLoading(true)
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/shared_dashboard`,
        JSON.stringify({
          "id": obj?.id,
          "is_draft": obj?.isDraft
        }), {
        headers: {
          'Authorization': `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      setLoading(false)
      return response
    } catch (error) {
      console.error('error', error)
      setLoading(false)

    }
  }
  const moveToFavourite = async (obj) => {
    setLoading(true)
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/shared_dashboard`,
        JSON.stringify({
          "id": obj?.id,
          "is_favorite": obj?.isFavorite,
        }), {
        headers: {
          'Authorization': `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      setLoading(false)
      return response
    } catch (error) {
      console.error('error', error)
      setLoading(false)

    }
  }

  const createDuplicate = async (obj) => {
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/reporting_dashboard/dashboard/copy_reporting_dashboard`,
        JSON.stringify({
          "reporting_dashboard": obj?.id,
        }), {
        headers: {
          'Authorization': `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })
      setLoading(false)
      return response
    } catch (error) {
      console.error('error', error)
      setLoading(false)

    }
  }
  // ----------------------------------------------------------------------------------------------

  return (
    <ReportingContext.Provider
      value={{
        sharedDashboardData: state.sharedDashboardData,
        draftDashboardData: state.draftDashboardData,
        customDashboardData: state.customDashboardData,
        visulaizationData: state.visulaizationData,
        multipleVisulaizationData: state.multipleVisulaizationData,
        showPreviewComponent,
        setShowPreviewComponent,
        eventDialogOpen,
        setEventDialogOpen,
        phraseBuilderObj,
        setPhraseBuilderObj,
        createNewDashboard,
        getSharedDashboardData,
        getDraftDashboardData,
        exploreDialogOpen,
        setExploreDialogOpen,
        selectedFromDate,
        setSelectedFromDate,
        selectedToDate,
        setSelectedToDate,
        selectedDateRange,
        setSelectedDateRange,
        runFilters,
        deleteDashboard,
        runMultipleFilters,
        saveExploreDashboardDialog,
        saveMainDashboardText,
        saveMainDashboardMarkdown,
        getCustomDashboardData,
        indDashboardData,
        setIndDashboardData,
        deletePanel,
        getScDropdownValues,
        graphDrilldown,
        // access permission dialog
        getAccessPermissionData,
        setStateData,
        stateData,
        moveToDraftAndShared,
        loading, setLoading,
        showSpinner, setShowSpinner,
        createDuplicate,
        moveToFavourite,
        updateAccessPermissionData,
        selectedGroups, setSelectedGroups,
        selectedRoles, setSelectedRoles,
        saveMainDashboard,
        editData, setEditData,
        setDashboardItems,
        dashboardItems
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export default ReportingContext;
