import React from 'react';
// REDUX IMPORTS
import { useSelector } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box,
    Button,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    Typography,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    tableCellClasses,
    TableSortLabel,
    Tooltip
} from '@material-ui/core';
import { Flag, WarningAmber, Star, Score, ArrowDownward, ArrowUpward, Visible, Visibility } from '@material-ui/icons';
import GlobalChip from '../../Common Component/tab';
import { getComparator, applySortFilter } from '../../../utils/tableDataSorter';
import SortingSelectingEmpDashHeadScore from "../sortingselectingEmpDashHeadScore"


//
const TABLE_HEADERS = [
    { label: 'Scorecards', id: 'score_card__name', align: 'left' },
    { label: 'Reviewed', id: 'reviewed', align: 'center' },
    { label: '1:1', id: 'onetoone_req', align: 'center' },
    { label: <Flag />, id: 'fail_section_count', align: 'center' },
    { label: <WarningAmber />, id: 'fail_all_count', align: 'center' },
    { label: <Star />, id: 'kudos', align: 'center' },
    { label: 'Team Score', id: 'team_score_percentage', align: 'center' },
    { label: 'My Score', id: 'my_score_percentage', align: 'center' },
    { label: 'Action', id: 'action', align: 'center' },
]


export default function ScoreSummary({ theme, themeColor }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REDUX STATES
    const summary = useSelector(state => state.empSummary);
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // REACT STATES
    const [scorecard, setscorecard] = React.useState([]);
    const [showIcon, setShowIcon] = React.useState(false);
    const [tableOrder, setTableOrder] = React.useState(false);
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('')

    const handleTableOrder = () => {
        setTableOrder(!tableOrder)
        setscorecard(scorecard.reverse());
    }

    React.useEffect(() => {
        if (summary) {
            setscorecard(summary.score_scorecard);
            // console.log(summary.score_scorecard);
        }
    }, [summary])

    // GET PERCENTAGE VALUE
    const setPercentageKey = (sum, na, total) => {
        if ((sum - na) === 0) {
            return 0
        }
        return Number?.isNaN(((total / (sum - na)) * 100)?.toFixed(2)) ? 0 : ((total / (sum - na)) * 100)?.toFixed(2)

    }

    // SORTING

    // SORTING
    const handleRequestSort = (event, property) => {
        // const isAsc = orderBy === property && order === 'asc';
        // console.log(order)
        setOrder(prev => orderBy === property && prev === 'desc' ? 'asc' : 'desc');
        setOrderBy(property);
    };


    const filteredData = React.useMemo(() => applySortFilter(scorecard?.map(obj => ({ ...obj, my_score_percentage: Number(Number?.isNaN(((100 * obj?.total_score) / obj.max_score)) ? `0 %` : ((100 * obj?.total_score) / obj.max_score).toFixed(1)), team_score_percentage: Number(setPercentageKey(obj?.team_score_sum, obj?.team_na_sum, obj?.team_total_score)) })), getComparator(order, orderBy)), [scorecard, order, orderBy])



    return (
        <>
            <TableContainer >
                <Table size='small' sx={{ minWidth: 650 }} aria-label="simple table">
                    <SortingSelectingEmpDashHeadScore
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEADERS}
                        onRequestSort={handleRequestSort}



                    />
                    {/* <TableHead>
                        <TableRow sx={{
                            [`& .${tableCellClasses.root}`]: {
                                py: 1
                            },
                        }}>
                            <TableCell onMouseLeave={() => setShowIcon(false)} onMouseEnter={() => setShowIcon(true)}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>
                                        Scorecards
                                    </Typography>&nbsp;
                                    {showIcon && <Tooltip title='sort' ><IconButton onClick={() => handleTableOrder()} >{tableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}</IconButton></Tooltip>}
                                </Box>

                            </TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>Reviewed</Typography></TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>1:1</Typography></TableCell>
                            <TableCell align="center"><Flag /></TableCell>
                            <TableCell align="center"><WarningAmber /></TableCell>
                            <TableCell align="center"><Star /></TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>Team Score</Typography></TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>My Score</Typography></TableCell>
                            <TableCell align="center">
                                <Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>Action</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead> */}
                    <TableBody>
                        {filteredData?.length > 0 &&
                            filteredData?.map((obj, index) =>
                                <TableRow
                                    key={index}
                                    sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: (theme) => `1px solid ${(index !== filteredData?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                        },
                                    }}
                                >
                                    <TableCell align="left" component="th" scope="row">
                                        {obj.score_card__name}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.reviewed}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.onetoone_req}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.fail_section_count}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.fail_all_count}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.kudos}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {`${obj?.team_score_percentage}%`}

                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Chip label={`${obj?.my_score_percentage}%`} sx={{ "& .MuiChip-label": { color: '#CF9C07' }, backgroundColor: '#FFEDB8', fontSize: 'bold', color: 'white', minHeight: '30px', maxHeight: '30px', minWidth: '78px', maxWidth: '78px' }} />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {loggedUserData?.staff?.staffType === 10 && <Link to={`/employee/scores?tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <IconButton size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }} >
                                                <Visibility size='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                            </IconButton>
                                        </Link>}
                                        {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={`/employee/scores?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=False&tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <IconButton size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }} >
                                                <Visibility size='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                            </IconButton>
                                        </Link>}
                                        {!loggedUserData?.staff && <Link to={`/employee/scores?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=True&tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <IconButton size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }} >
                                                <Visibility size='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                            </IconButton>
                                        </Link>}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                {scorecard?.length === 0 && <Paper elevation={0} sx={{ minHeight: "100px", width: "100%", p: 2 }}>
                    <Typography gutterBottom align="center" variant="subtitle1" sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Score /> &nbsp;No Data Found For Data Range Selected.
                    </Typography>
                </Paper>}
            </TableContainer>

        </>
    )
}