import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Box, Alert, Stack, AlertTitle } from '@material-ui/core';
import { forwardRef, useEffect, useCallback } from 'react';

// utils
import track from '../utils/analytics';
import { NetworkHook } from '../hooks/networkHook';

// -------------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { isOnline } = NetworkHook()
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  const { pathname } = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (

    <Box ref={ref} {...other}
      sx={{
        backgroundColor: (theme) => theme.palette.mode === 'light' ? '#f5f6fa' : '#636e72',
        px: { lg: 1, xl: loggedUserData?.staff?.staffType !== 10 ? 3 : 0 },
        // minHeight: { lg: '410px', newLg: '658px', xl: '920px' }
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <>
        {
          (!isOnline && !pathname.includes("login")) && <Stack direction='row' justifyContent='center'>
            <Alert sx={{ width: '45%', py: 1 }} severity="error">
              <AlertTitle>No Network</AlertTitle>
              Network Disconnected. Please Make sure you are connected to internet.
            </Alert>
          </Stack>
        }
        {children}
      </>
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
