// Updated LoginForm to fix device ID and login issues

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Button, Box
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
// import Cookies from "js-cookie";
import { Person, Lock } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Cookies from 'js-cookie';
import { PATH_AUTH } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { MIconButton } from '../../@material-extend';
import TwoStepVerification from './TwoStepVerification';

export default function LoginForm() {
  const {
    login,
    enableTwoStep, setEnableTwoStep,
    // verifyOtp
  } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  // const [enableTwoStep, setEnableTwoStep] = useState(false);
  const [enterOtp, setEnterOtp] = useState('');
  const [email, setEmail] = useState('');
  const [deviceChecked, setDeviceChecked] = useState(false);
  const [isEnteredFullOtp, setIsEnteredFullOtp] = useState(false)

  const handleCheckedTrust = (checked) => {
    setDeviceChecked(checked);
  }
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });
  const devices = Cookies.get(`device_ids_${window?.location?.hostname}`) ? JSON.parse(Cookies.get(`device_ids_${window?.location?.hostname}`)) : {};

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const encodedEmail = btoa(values.email.toLowerCase());
        const deviceId = devices[encodedEmail] || null;
        // Check if OTP previously failed
        const otpFailed = Cookies.get(`otp_failed_${encodedEmail}`) ? JSON.parse(Cookies.get(`otp_failed_${encodedEmail}`)) : false;

        if (!enableTwoStep && deviceId && !otpFailed) {
          // await login(values.email.toLowerCase(), values.password, deviceId);

          const data = await login(values.email.toLowerCase(), values.password, deviceId, enableTwoStep, enterOtp, deviceChecked);
          enqueueSnackbar(data?.message, {
            variant: 'success', action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });

          // Clear on any OTP failure flag
          Cookies.remove(`otp_failed_${encodedEmail}`);
        } else if (enableTwoStep) {
          // await verifyOtp(values.email.toLowerCase(), enterOtp, deviceChecked ? deviceId : null);
          await login(values.email.toLowerCase(), values.password, deviceChecked ? deviceId : null, enableTwoStep, enterOtp, deviceChecked);
          enqueueSnackbar('User Logged in Successfully', {
            variant: 'success', action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          // Clear on any OTP failure flag
          Cookies.remove(`otp_failed_${encodedEmail}`);
        } else {
          // Mark OTP is required for this email
          Cookies.set(`otp_failed_${encodedEmail}`, 'true', { expires: 1 / 48 }); // Expire after 1/2 hours

          // await login(values.email.toLowerCase(), values.password, deviceId);
          await login(values.email.toLowerCase(), values.password, deviceId, enableTwoStep, enterOtp, deviceChecked);

          setEnableTwoStep(true);
          enqueueSnackbar('OTP sent to your email', {
            variant: 'info', action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });


        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error('Login error:', error);
        const errorMsg = error?.response?.data?.error || error?.response?.data?.message;
        // Handle OTP failure - Force OTP next time
        if (enableTwoStep) {
          Cookies.set(`otp_failed_${btoa(values.email.toLowerCase())}`, 'true', { expires: 1 / 48 }); // Ensure OTP next time
        }
        enqueueSnackbar(errorMsg, {
          variant: 'error', action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        setSubmitting(false);
        // resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: errorMsg });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleEmail = (e) => {
    formik.setFieldValue('email', e.target.value.toLowerCase())
    setEmail(e.target.value.toLowerCase())
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {enableTwoStep && (
          <Button onClick={() => {
            setEnableTwoStep(false);
            setDeviceChecked(false);
            formik.setErrors({ afterSubmit: '' });
            setIsEnteredFullOtp(false)
          }}
            startIcon={<ArrowBackIcon />}>Back</Button>
        )}

        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Typography
            variant="body1"
            display="inline"
            sx={{
              fontSize: { lg: '2vw', md: '2.5vw', sm: '3vw', xs: '6vw', },
              color: '#212668',
            }}>
            {enableTwoStep ? 'Two-Step Verification' : 'Login'}
          </Typography>
        </Stack>
        {enableTwoStep ? (
          <TwoStepVerification
            setEnterOtp={setEnterOtp}
            email={email || formik.values.email}
            deviceChecked={deviceChecked}
            setDeviceChecked={setDeviceChecked}
            setIsEnteredFullOtp={setIsEnteredFullOtp}
            handleCheckedTrust={handleCheckedTrust}
          />
        ) : (
          <Stack spacing={3}>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Username"
              placeholder="Username"
              onChange={(e) => handleEmail(e)}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              autoComplete="password"
              label="Password"
              placeholder="Password"

              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              sx={{
                'input::-ms-clear': {
                  display: 'none'
                },
                'input::-ms-reveal': {
                  display: 'none'
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Stack direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >

              <FormControlLabel
                control={<Checkbox {...getFieldProps('remember')} checked={formik.values.remember} />}
                label="Remember me"
              />

              <Link
                component={RouterLink}
                variant="subtitle2"
                to={PATH_AUTH.resetPassword}
                sx={{
                  textDecoration: 'none',
                  fontSize: { lg: '16px', md: '13px', sm: '15px', xs: '11px' },
                  '&MuiLink-root:hover': {
                    textDecoration: 'none'
                  }
                }}
              >Forgot / Reset password?</Link>
            </Stack>

          </Stack>
        )}

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#fb5d2e', borderRadius: '25px',
            '&:hover': { backgroundColor: '#FA774F' }, marginTop: '3%', letterSpacing: '1px'
          }}
          disabled={isSubmitting || (enableTwoStep && !isEnteredFullOtp)}
        >
          {enableTwoStep ? 'Verify OTP' : 'Let\'s Start!'}
        </Button>
      </Form>
    </FormikProvider>
  );
}
