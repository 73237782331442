import React from 'react';
// MATERIAL IMPORTS
import {
    Box, Button,
    CircularProgress,
    Dialog, DialogContent, DialogTitle, Divider,
    IconButton,
    Stack,
    TextField, Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
// NPM IMPORTS
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
// API IMPORTS
import { acceptReviewApi } from "../../_apis_/authApi.js/empapi";
// COMPONENT IMPORTS
import { MIconButton } from '../../components/@material-extend';



AcceptReview.propTypes = {
    ID: PropTypes.string,
    setID: PropTypes.func,
    fn: PropTypes.func
};




export default function AcceptReview({ ID, setID, fn }) {
    // CONTEXT STATES
    const { acceptReview, setAcceptReview } = React.useContext(EmpContext);

    // REACT STATES
    const [disBtn, setDisBtn] = React.useState(false);

    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // FORMIK STATES
    const formik = useFormik({
        initialValues: { reviewComment: "" },
        onSubmit: values => {
            (async () => {
                setDisBtn(true)
                const obj = {
                    score: ID,
                    comments: values.reviewComment
                }
                try {
                    const { data } = await acceptReviewApi(obj);
                    setDisBtn(false);
                    enqueueSnackbar('Accepted Commented Successfully', {
                        variant: 'success',
                        action: (key) => (

                            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                <Icon icon={closeFill} />
                            </MIconButton>
                        )

                    });
                    if (fn && data) {
                        fn();
                    }
                    setAcceptReview(false); formik.resetForm(); if (setID) {
                        setID();
                    }
                } catch (err) {
                    setDisBtn(false)
                }


            })()
        }
    });




    return (
        <>
            <Dialog open={acceptReview} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Stack sx={{ px: 2, py: 1 }} direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4">
                            Review
                        </Typography>
                        <IconButton onClick={() => { setAcceptReview(false); formik.resetForm(); if (setID) { setID(); } }}><Close /></IconButton>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ py: 1, px: 2 }}>
                        <Typography sx={{ my: 1 }} variant="body">Are you sure you want to mark reviewed for this score?</Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ my: 2 }}>
                                <TextField name="reviewComment" {...formik.getFieldProps('reviewComment')} fullWidth label="Add a review comment concering this score" sx={{ my: 1, width: "100%" }} multiline rows={5} />
                                {formik.errors.reviewComment && formik.touched.reviewComment && <Typography variant="body2" sx={{ color: "red" }}>{formik.errors.reviewComment}</Typography>}
                            </Box>
                            <Stack direction="row" justifyContent="flex-end" sx={{ pr: 1, mt: 3 }}>
                                <Button onClick={() => { setAcceptReview(false); formik.resetForm(); if (setID) { setID(); } }} variant="contained" sx={{ mr: 2 }}>Cancel</Button>
                                <Button variant="contained" disabled={disBtn} type='submit'>Yes, Review {disBtn && <CircularProgress sx={{ mx: 1 }} size={14} />}</Button>
                            </Stack>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}