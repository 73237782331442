// MATERIAL IMPORTS
import { useEffect, useState } from 'react';
import {
    Alert, AlertTitle,
    Button, Box,
    Paper, Typography, Stack
} from '@material-ui/core';
import { Summarize } from '@material-ui/icons';
// NPM IMPORTS
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
// HOOK IMPORTS
// COMPONENT IMPORTS
import Page from '../../components/Page';
import { ScoreBlock } from './scoreblock';
import { MainBlockV2 } from '../components-overview/MainBlockV2';
// import ScoreGroupTable from "../components-overview/material-ui/table/score/scretble";
// import { getAllGroups } from "../../redux/action/staffAction";
// import { PATH_DASHBOARD } from "../../routes/paths";
// API IMPORTS
import { deleteDraftApi } from "../../_apis_/authApi.js/empapi";
import { singleStaffApi } from "../../_apis_/authApi.js/staffApi";
import GlobalButton from '../Common Component/GlobalButton';
import ScoreWrapperFilter from './scorewrapperFilter';

// import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

ScoreWrapper.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    link: PropTypes.array,
    draftData: PropTypes.array,
    fnDraft: PropTypes.func,
    st: PropTypes.string
}

// TO NOT INVOKE DATA IN FOLLOWING PATHNAME
const excludeDirectFilterPath = ["/notification", "/score/selectDraft", "/calibration/selectDraft", "/employee/myscore"]

export default function ScoreWrapper({ children, title, link, draftData, fnDraft, style, btnType }) {
    // // const dispatch = useDispatch();
    // // useEffect(() => {
    // //     dispatch(getAllGroups(1))
    // // }, [])
    // const { themeStretch } = useSettings();
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT ROUTER STATE
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showDraft, setShowDraft] = useState(true)
    const [empTable, setEmpTable] = useState({})


    useEffect(() => { setShowDraft(true); if (draftData?.length > 0) { handleStaffInfo(draftData[0]?.staff?.id) } }, [draftData])

    const handleEventGlobalButton = () => {

        return navigate("/score/selectDraft?draftType=20")



    }

    const handleStaffInfo = async (id) => {
        try {
            const empResponse = await singleStaffApi(id);
            // setDisField(false);
            const empTable = { "staff_type": empResponse?.data?.staff_type, "teams": empResponse?.data?.user?.teams?.map(obj => ({ id: obj?.id, name: obj?.name })), "name": `${empResponse?.data?.user?.first_name}${empResponse?.data?.user?.last_name}`, "supervisor_id": empResponse?.data?.supervisor?.id }
            setEmpTable(empTable)
        } catch (err) {
            console.log("score wrapper", err)
        }
    }

    return (
        <Page sx={{ px: 1 }} title={`${title} | BeyondQA`}>

            {/* <HeaderBreadcrumbs
                sx={{ pl: 4, ml: 3 }}
                links={link}

            /> */}

            <Box
                component={Paper}
                elevation={5}
                sx={{ backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px', mb: 2, ...style }}
            >

                <MainBlockV2 title={title} MBLinks={link}
                    buttonType={
                        <Stack direction='row' alignItems='center'>
                            {btnType}
                            {(searchParams.get("draftType") === "10" || draftData?.length > 0) && <GlobalButton startIcon={<Summarize />} sx={{ px: '30px', display: { sm: 'flex', xs: 'none' } }} fn={handleEventGlobalButton} variant="contained" label='View Darft' />
                            }
                        </Stack>
                    }
                >


                    {(showDraft && draftData?.length > 0 && (!loggedUserData?.staff || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30)) && <Alert sx={{ mt: { sm: 1, xs: 1 } }} severity="warning" onClose={() => { setShowDraft(false) }}>
                        <AlertTitle sx={{ fontSize: { xs: '12px', sm: '15px', xl: '17px' } }} >Recent Draft Available!</AlertTitle>

                        <Typography sx={{ fontSize: { xs: '10px', sm: '13px', xl: '17px' } }} >
                            You have already started to evaluate {draftData[0]?.staff?.name}, on {draftData[0]?.score_card?.name} Scorecard. Would you prefer to pick up where you left off?<Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px', xl: '17px' }, textTransform: "capitalize", p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={() => { window.location.href = `/score/reviewPerformance?gpId=${draftData[0]?.groups?.id}&staff_type=${draftData[0]?.staff?.staff_type}&empId=${draftData[0]?.staff?.id}&score_card_Id=${draftData[0]?.score_card?.id}&draftId=${draftData[0]?.id}&toScore=True&empTable=${JSON.stringify(empTable)}`; }}>&nbsp;Resume</Button> your old score, or <Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px', xl: '17px' }, textTransform: 'lowercase', p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={async () => { await deleteDraftApi(draftData[0]?.id); fnDraft() }}>delete</Button> the draft.
                        </Typography>
                    </Alert>}

                    {(draftData?.length > 0 && loggedUserData?.staff?.staffType === 10) && <Alert sx={{ mt: { sm: 0, xs: 1 } }} severity="warning">
                        <AlertTitle sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Recent Draft Available!</AlertTitle>
                        <Typography sx={{ fontSize: { xs: '10px', sm: '13px' } }} >
                            You have already started to evaluate {draftData[0]?.score_card?.name} Scorecard. Would you prefer to pick up where you left off?<Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px' }, textTransform: "capitalize", p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={() => { window.location.href = `/score/reviewPerformance?gpId=${draftData[0]?.groups?.id}&staff_type=${draftData[0]?.staff?.staff_type}&empId=${draftData[0]?.staff?.id}&score_card_Id=${draftData[0]?.score_card?.id}&draftId=${draftData[0]?.id}&toScore=True&empTable=${JSON.stringify(empTable)}`; }}>&nbsp;Resume</Button> your old score, or <Button disableRipple variant='text' sx={{ fontSize: { xs: '10px', sm: '13px' }, textTransform: 'lowercase', p: 0, minWidth: "auto", maxWidth: "auto", fontWeight: "light", textDecoration: "underline", mx: 0, "&:hover": { backgroundColor: "transparent" } }} onClick={async () => { await deleteDraftApi(draftData[0]?.id); fnDraft() }}>delete</Button> the draft.
                        </Typography>
                    </Alert>}
                    {/* <Box component={Paper} elevation={5} sx={{ borderRadius: '5px', backgroundColor: theme => theme.palette.background.paper, }}> */}
                    {(!excludeDirectFilterPath?.includes(pathname) && !loggedUserData?.staff) && < ScoreWrapperFilter />}
                    {children}
                    {/* </Box> */}
                </MainBlockV2>
            </Box>
        </Page>
    )
}