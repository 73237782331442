import { useState } from 'react';
import PropTypes from 'prop-types';
// MATERIAL IMPORTS
import { Box, IconButton, TableRow, TableCell, TableHead, Tooltip, tableCellClasses, Typography, TableSortLabel } from '@material-ui/core';
import { visuallyHidden } from '@material-ui/utils';
import { ArrowUpward, ArrowDownward, SwapVert } from '@material-ui/icons';

// ----------------------------------------------------------------------

// SortingSelectingHeadScore.propTypes = {
//     orderBy: PropTypes.string,
//     headLabel: PropTypes.array.isRequired,
//     order: PropTypes.oneOf(['asc', 'desc'])
// };

export default function SortingSelectingHeadScore({
    order,
    orderBy,
    headLabel,
    onRequestSort,
}) {

    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    const [showIcon, setShowIcon] = useState(false);

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{
                [`& .${tableCellClasses.root}`]: {
                    py: { lg: 0.6, xl: 1 },
                },
            }}>
                {headLabel.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.id === "Id" ? "left" : "center"}
                        sx={{ px: 1, margin: "0 auto", minWidth: headCell.id === 'id' ? '95px' : '0px' }}
                        padding={headCell.disablePadding ? 'none' : 'normal'} >

                        {/* <Typography variant='tableHead' >
                            {headCell.label}
                        </Typography>{headCell.id === 'id' && <Tooltip title='Sort'>
                            <IconButton size='small' onClick={() => handleTableOrder()}>
                                <SwapVert fontSize='small' />
                            </IconButton>
                        </Tooltip>} */}
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={{ pl: headCell.id === 'id' ? 1 : 3 }}
                        >
                            <Typography variant='tableHead'>
                                {headCell?.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    // <TableCell

                    //   key={headCell.id}
                    //   align="center"
                    //   sx={{ width: "100%", border: "1px solid red", margin: "0 auto" }}
                    //   padding={headCell.disablePadding ? 'none' : 'normal'}
                    //   sortDirection={orderBy === headCell.id ? order : false}
                    // >
                    //   <TableSortLabel
                    //     active={orderBy === headCell.id}
                    //     direction={orderBy === headCell.id ? order : 'asc'}
                    //     onClick={createSortHandler(headCell.id)}
                    //   >
                    //     {headCell.label}
                    //     {orderBy === headCell.id ? (
                    //       <Box component="span" sx={{ ...visuallyHidden }}>
                    //         {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    //       </Box>
                    //     ) : null}
                    //   </TableSortLabel>
                    // </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
