import { API } from '../../hooks/apiHook'
// import axios from 'axios';
// // process.env.REACT_APP_API_URL
// import { URL } from "../../backend";

// // -----------------------------------------------------------

// // const API = axios.create({ baseURL: 'http://127.0.0.1:8000' })
// const API = axios.create({ baseURL: process.env.REACT_APP_API_URL })

// API.interceptors.request.use(req => {
//     // const { token } = JSON?.parse(localStorage?.getItem("user_info"));
//     const token = window.localStorage.getItem('accessToken');
//     if (token) {
//         req.headers.Authorization = `Token ${token}`;
//     }
//     return req
// })




// Staff API
export const getStaffCountryNameApi = (val) => API.get(`/users/list_country?country=${val}`);
export const getStaffCityNameApi = (val1, val2) => API.get(`/users/list_city?country=${val1}&city=${val2}`);
export const accessApi = (id) => API.get(`staff/change_status?staff=${id}`);
export const resendAccessLinkApi = (userId, staffType) => API.get(`/users/verification?user=${userId}&staff_type=${staffType}`);
export const addStaffApi = (data) => API.post('/staff', data);
export const allStaffApi = () => API.get('/staff/supervisor');
export const allEmpApi = (id) => API.get(id ? `/staff?staff_type=10&page=${id}` : `/staff?staff_type=10&page=1`);
export const getSupEmpApi = (id) => API.get(id ? `/staff?supervisor=True&page=${id}` : `/staff?supervisor=True&page=1`);
export const getGrpByEmpApi = (id, pgNo, query, order) => API.get(`score/group_employes?groups=${id}&page=${pgNo || 1}&search=${query || ''}&ordering=${order || false}`)
export const allSupApi = (id) => API.get(id ? `/staff?staff_type=20,30&page=${id}` : `/staff?staff_type=20,30&page=1`);
export const editStaffApi = (data, id) => API.put(`/staff/${id}`, data);
export const delStaffApi = (id, newId) => API.get(newId ? `/staff/delete_restore?id=${id}&supervisor=${newId}` : `/staff/delete_restore?id=${id}`);
export const restoreStaffApi = (id, pgNo, isSup) => API.get(isSup ? `/staff?staff_type=${id}&supervsor=True&deleted=true&page=${pgNo || 1}` : `/staff?staff_type=${id}&deleted=true&page=${pgNo || 1}`);
export const uploadStaffApi = (data) => API.post(`/staff/upload_csv`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
});
export const singleStaffApi = (id) => API.get(`/staff/${id}`);
//
export const reportToUserApiList = (grps, tms) => API.get(`/staff/get_report_to_user_list?group=${grps || ''}&team=${tms || ''}`)

// filter api
export const filterEmpApi = (grp, tm, pgNo, supDash) => API.get(supDash ? `/staff?&supervisor=True&group=${grp}&team=${tm}&page=${pgNo || 1}` : `/staff?staff_type=10&group=${grp}&team=${tm}&page=${pgNo || 1}`);
// export const filterEmpApi = (grp, tm, val, frmDate, toDate, pgNo) => API.get(`/staff?staff_type=10&group=${grp}&team=${tm}&value=${val}&from_date=${frmDate}&to_date=${toDate}&page=${pgNo || 1}`);
// export const filterSupApi = (grp, tm, val, frmDate, toDate) => API.get(`/staff?staff_type=20&group=${grp}&team=${tm}&value=${val}&from_date=${frmDate}&to_date=${toDate}`);
export const filterSupApi = (grp, tm, pgNo) => API.get(`/staff?staff_type=20,30&group=${grp}&team=${tm}&page=${pgNo || 1}`);

// multicheck
export const multiDelApi = (data) => API.post("/staff/multi_delete", data);
export const multiEditApi = (data) => API.post("/staff/multi_edit", data);

// score
export const setNewScoreApi = (data) => API.post('/score/scorecard_scores', data);
export const updateScoreApi = (data) => API.put('/score/update_score', data)
export const getScoreApi = (id) => API.get(`/score/${id}`);
export const deleteScoreApi = (id) => API.delete(`/score/${id}`);



// scorecard
export const scorecardPreviewApi = (scoreCardId, empId) => API.get(empId ? `/score_cards_preview?score_card_id=${scoreCardId}&staff=${empId}` : `/score_cards_preview?score_card_id=${scoreCardId}`)
export const getScorecardSubTypeApi = (scorecardId, eventName) => API.get(`/score_cards_event_type/sub_type?score_card=${scorecardId}&event_type=${eventName}`);
export const getScorecardEventApi = (id, pgNo) => API.get(pgNo ? `score_cards_event_type?score_card_id=${id}&page=${pgNo}` : `score_cards_event_type?score_card_id=${id}&page=1`);
export const getScorecardListApi = (id, pgNo, group) => API.get(pgNo ? `/score/score_scorecard?staff=${id}group=${group || ""}&page=${pgNo}` : `/score/score_scorecard?staff=${id}group=${group || ""}&page=1`)
export const getScorecardSearchApi = (id, query, pgNo, group, order) => API.get(`/score/score_scorecard?staff=${id}&group=${group || ""}&value=${query || ''}&page=${pgNo || 1}&ordering=${order || false}`)



// SEARCH API
export const SearchStaffApi = (query, pgNo) => API.get(`staff/search?staff_type=10,20&value=${query}&page=${pgNo}`);
export const SearchEmpApi = (query, pgNo, grp, tm, reportTo, isSupDashboard) => API.get(`staff/search?staff_type=10&group=${grp || ''}&team=${tm || ''}&report_to_id=${reportTo || ''}&value=${query}&page=${pgNo}&supervisor=${isSupDashboard || ''}`);
export const SearchSupApi = (query, pgNo, grp, tm, reportTo) => API.get(`staff/search?staff_type=20,30&group=${grp || ''}&team=${tm || ''}&report_to_id=${reportTo || ''}&value=${query}&page=${pgNo}`);
export const SearchTeamApi = (query, pgNo) => API.get(`team/search?value=${query}&page=${pgNo}`);
export const SearchGroupApi = (query, pgNo) => API.get(`groups/search?value=${query}&page=${pgNo}`);
export const SearchRestoreApi = (id, query, pgNo, isSupDashboard) => API.get(`staff/search?staff_type=${id}&value=${query}&page=${pgNo}&deleted=true&supervisor=${isSupDashboard || ''}`)
export const SearchEmpWithGroupTeamApi = (query, grp, tm, pgNo) => API.get(`/staff?staff_type=10&value=${query || ''}&group=${grp || ''}&team=${tm || ''}&page=${pgNo || 1}`);
export const SearchSupWithGroupTeamApi = (query, grp, tm, pgNo) => API.get(`/staff?staff_type=20,30&value=${query || ''}&group=${grp || ''}&team=${tm || ''}&page=${pgNo || 1}`);


// Group API
export const getAllGroupsApi = (id) => API.get(id ? `/groups?page=${id}` : `/groups?page=1`);
export const getAllGroupsApi2 = (id, query, order) => API.get(`/score_cards/group_list?page=${id || 1}&search=${query || ''}&ordering=${order || false}`);
export const newGroupAPI = (data) => API.post('/groups', data);
export const editGroupAPI = (id, data) => API.put(`/groups/${id}`, data);
export const delGroupAPI = (id) => API.delete(`/groups/${id}`);

// TEAM API
export const getAllTeamsApi = (id) => API.get(id ? `/team?page=${id}` : `/team?page=1`);
export const newTeamApi = (data) => API.post('/team', data);
export const editTeamApi = (id, data) => API.put(`/team/${id}`, data)
export const delTeamApi = (id) => API.delete(`/team/${id}`);


// TASK API
export const getTaskApi = (id) => API.get(`/tasks?staff=${id}`);
export const newTaskApi = (data) => API.post("/tasks", data);
export const editTaskApi = (id, data) => API.put(`/tasks/${id}`, data);

// GOAL API

export const getGoalApi = () => API.get(`/goals`);
export const getTeamGoalApi = (id) => API.get(`/goals?team=${id}`);
export const getGroupGoalApi = (id) => API.get(`/goals?group=${id}`);
export const addGoalApi = (data) => API.post('/goals', data)
export const editGoalApi = (data, id) => API.put(`/goals/${id}`, data);
export const delGoalApi = (id) => API.delete(`goals/${id}`);

// Download XLS

export const getSampleDwnldApi = (mode, manageStaff, IdentifyStaff, IdentifySup) => API.get(`${process.env.REACT_APP_API_URL}/staff/download_sample_csv?manage_staff=${manageStaff}&mode=${mode}&identify_staff=${IdentifyStaff}&identify_supervisor=${IdentifySup}&sample=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

export const DwnldCurrentcsvApi = (mode, manageStaff, IdentifyStaff, IdentifySup) => API.get(`${process.env.REACT_APP_API_URL}/staff/download_sample_csv?manage_staff=${manageStaff}&mode=${mode}&identify_staff=${IdentifyStaff}&identify_supervisor=${IdentifySup}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});
export const DwnldCurrentXLSApi = () => API.get(`${process.env.REACT_APP_API_URL}/staff/download?staff_type=10,20,30`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});
export const DwnldEmpXLSApi = (id, groups, teams, isSupDashboard) => API.get(`${process.env.REACT_APP_API_URL}/${isSupDashboard ? `staff/download?staff_type=${id}&group=${groups || ''}&team=${teams || ''}&supervisor=True` : `staff/download?staff_type=${id}&group=${groups || ''}&team=${teams || ''}`}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});
export const DwnldDeactivatedXLSApi = (id, isSupDashboard) => API.get(`${process.env.REACT_APP_API_URL}/${isSupDashboard ? `staff/download?staff_type=${id}&deactivated=true&supervisor=True` : `staff/download?staff_type=${id}&deactivated=true`}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});


// Full LIST OF Group

export const listGroupApi = () => API.get(`/groups/item_list`);

export const listTeamApi = (data) => API.get(`/team/teams_filter?groups=${data?.length === 0 ? [0] : data}`);
export const listAllTeamApi = (data) => API.get(`/team?page=1`);

export const listSupervisorApi = () => API.get(`/staff/supervisor`);

// export const supScorecardListApi = (sId, date1, date2, scrdId, grp, tm) => API.get(`/global_dashboard/get_supervisor_score_card_list?sypervisor_id=${sId}&from_date=${date1}&to_date=${date2}&score_cards=${scrdId}&group=${grp}&teams=${tm}`)
export const supScorecardListApi = (sId, date1, date2, scrdId, grp, tm, filterBy) => API.get(`/global_dashboard/get_supervisor_score_card_list?supervisor_id=${sId}&from_date=${date1}&to_date=${date2}&score_card__in=${scrdId}&score_card__groups__in=${grp}&teams__in=${tm}&filter_by=${filterBy}`)

export const filteredlistSupervisorApi = (gId, tId) => API.get(`/staff/supervisor?groups=${gId}&teams=${tId}`);

// supervisor Graph

export const pieChartApi = (sId, date1, date2, scrdId, grp, tm, filter) => API.get(`/global_dashboard/get_supervisor_chart?supervisor_id=${sId}&from_date=${date1}&to_date=${date2}&score_card__in=${scrdId}&score_card__groups__in=${grp}&teams__in=${tm}&filter_by=${filter}`);
// export const pieChartApi = (sId, date1, date2, scrdId, grp, tm, filter) => API.get(`/global_dashboard/get_supervisor_chart?supervisor_id=${sId}&from_date=${date1}&to_date=${date2}&score_card__in=${scrdId}&score_card__groups__in=${grp}&teams__in=${tm}&filter_by=${filter}`);

// supervisor FILTER DROPDOWNS

// GROUPS
export const getFilterGroupsApi = (obj) => API.get(`/reports/score_log_groups?from_date=${obj.date1}&to_date=${obj.date2}&filter_by=${obj.filterBy}&sup_groups=${obj.groups}&send_email=0`)
export const getFilterTeamsApi = (obj) => API.get(`/reports/score_log_teams?from_date=${obj.date1}&to_date=${obj.date2}&filter_by=${obj.filterBy}&sup_groups=${obj.groups}&send_email=0`)
export const getGroupCityNameApi = (val) => API.get(`/company_settings/list_location?search=${val}`);
export const getEcpValue = () => API.get(`/company_settings/get_key`);

