import { useEffect, useCallback, useReducer } from 'react'
// MATERIAL UI IMPORTS
import { TextField, IconButton, Tooltip, Box, Stack, Autocomplete, CircularProgress, Typography } from "@material-ui/core";
import { RateReviewOutlined } from '@material-ui/icons';
// OTHER NPM IMPORTS
import _ from "lodash";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
// API IMPORTS
import { getAllGroupsApi2, getGrpByEmpApi, getScorecardSearchApi } from '../../_apis_/authApi.js/staffApi';




// TO SET DIFFERENT TEXTFIELD
const AutoCompleteFields = ["Group", "Employee", "Scorecard"];

// TO NOT INVOKE DATA IN FOLLOWING PATHNAME
const excludeDirectFilterPath = ["/notification", "/score/selectDraft", "/calibration/selectDraft", "/employee/myscore"]


export default function ScoreWrapperFilter() {
    // REACT ROUTER STATE
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // REDUCER INITIAL STATE
    const initialstate = {
        groupState: {
            dropDownData: [],
            fieldValue: "",
            isLoading: false,
            hasMoreOptions: false,
            inputQuery: "",
            apiPageNum: 1
        },
        employeeState: {
            dropDownData: [],
            fieldValue: "",
            isLoading: false,
            hasMoreOptions: false,
            inputQuery: "",
            apiPageNum: 1
        },
        scorecardState: {
            dropDownData: [],
            fieldValue: "",
            isLoading: false,
            hasMoreOptions: false,
            inputQuery: "",
            apiPageNum: 1
        }
    }

    // FORMIK STATES
    const formik = useFormik({
        initialValues: { groupField: '', employeeField: "", scorecardField: "" },
        validationSchema: Yup.object({

            groupField: Yup.string().required(),
            employeeField: Yup.string().required(),
            scorecardField: Yup.string().required(),
        }),
        onSubmit: values => {
            const groupObj = state.groupState?.fieldValue
            const empObj = state.employeeState?.fieldValue
            const scorecardObj = state.scorecardState?.fieldValue

            const empTable = { "staff_type": empObj?.staff_type, "teams": empObj?.user?.teams?.map(obj => ({ id: obj?.id, name: obj?.name })), "name": `${empObj?.user?.first_name}${empObj?.user?.last_name}`, "supervisor_id": empObj?.supervisor?.id }


            navigate(`/score/reviewPerformance?gpId=${groupObj?.id}&empId=${empObj?.id}&score_card_Id=${scorecardObj?.id}&toScore=True&empTable=${JSON.stringify(empTable)}`)

        }
    })

    // REDUCER FUNCTION TO SET DATAS REGARDING FORMS
    const formReducer = (state, action) => {
        let tempState;


        switch (action?.type) {
            case 'FETCH_FIELD_DATA':
                tempState = { ...state, [`${action?.key}State`]: { ...state[`${action?.key}State`], dropDownData: action.payload.data, hasMoreOptions: action?.payload?.isMore, isLoading: false } }
                break;
            case 'OPTIONS_LOADING':
                tempState = { ...state, [`${action?.key}State`]: { ...state[`${action?.key}State`], isLoading: action.payload } }
                break;
            case 'SCROLL_NEW_OPTIONS':
                tempState = { ...state, [`${action?.key}State`]: { ...state[`${action?.key}State`], apiPageNum: action.payload } }
                break;
            case 'INPUT_FIELD_QUERY':
                tempState = { ...state, [`${action?.key}State`]: { ...state[`${action?.key}State`], apiPageNum: 1, dropDownData: [], hasMoreOptions: true, inputQuery: action.payload } }
                break;
            case 'SET_FIELD_VALUE':
                tempState = { ...state, [`${action?.key}State`]: { ...state[`${action?.key}State`], fieldValue: action.payload } }
                break;
            case 'RESET_FIELD_VALUE':
                tempState = { ...state, [`${action?.key}State`]: action.payload }
                break;


            default:
                tempState = state
                break;
        }

        return tempState


    }

    // REACT REDUCER DECLARATION
    const [state, dispatch] = useReducer(formReducer, initialstate)

    // ****** TO FETCH DATA FOR DROPDOWNS *****************
    /* to get GROUP options */
    const fetchGroupOptions = async (input, pageNum, isScroll) => {

        dispatch({ type: 'OPTIONS_LOADING', payload: true, key: "group" })
        try {
            const { data } = await getAllGroupsApi2(pageNum, input, true)
            const newOptions = data?.results

            const mergedArr = isScroll ? [...new Map([...state?.groupState?.dropDownData, ...newOptions].map(item => [item?.id, item])).values()] : newOptions

            setTimeout(() => {
                dispatch({ type: "FETCH_FIELD_DATA", payload: { data: mergedArr, isMore: data?.page_range?.length > pageNum }, key: "group" })

            }, isScroll ? 600 : 100);

        } catch (err) {
            console.log(err);
            dispatch({ type: 'OPTIONS_LOADING', payload: false, key: "group" })
        }
    }

    /* to get EMPLOYEE options */
    const fetchEmpOptions = async (groupId, input, pageNum, isScroll) => {

        dispatch({ type: 'OPTIONS_LOADING', payload: true, key: "employee" })
        try {
            const { data } = await getGrpByEmpApi(groupId, pageNum, input, true)
            const newOptions = data?.results?.map(obj => ({ ...obj, name: `${obj?.user?.first_name} ${obj?.user?.last_name}` }))

            const mergedArr = isScroll ? [...new Map([...state?.employeeState?.dropDownData, ...newOptions].map(item => [item?.id, item])).values()] : newOptions

            // console.log("testing", data?.page_range?.length > pageNum, data?.page_range, data, pageNum)
            setTimeout(() => {
                dispatch({ type: "FETCH_FIELD_DATA", payload: { data: mergedArr, isMore: data?.page_range?.length > pageNum }, key: "employee" })

            }, isScroll ? 600 : 100);

        } catch (err) {
            console.log(err);
            dispatch({ type: 'OPTIONS_LOADING', payload: false, key: "employee" })
        }
    }


    /* to get SCORECARD options */
    const fetchScorecardOptions = async (empId, grpId, input, pageNum, isScroll) => {

        dispatch({ type: 'OPTIONS_LOADING', payload: true, key: "scorecard" })
        try {
            const { data } = await getScorecardSearchApi(empId, input, pageNum, grpId, true)
            const newOptions = data?.results

            const mergedArr = isScroll ? [...new Map([...state?.scorecardState?.dropDownData, ...newOptions].map(item => [item?.id, item])).values()] : newOptions

            setTimeout(() => {
                dispatch({ type: "FETCH_FIELD_DATA", payload: { data: mergedArr, isMore: data?.page_range?.length > pageNum }, key: "scorecard" })     // **ADDING SETTIMEOUT TO SHOW LOADING MESSAGE CLEARLY WHEN OPTIONS LOADING FROM NEXT PAGE**

            }, isScroll ? 600 : 100);

        } catch (err) {
            console.log(err);
            dispatch({ type: 'OPTIONS_LOADING', payload: false, key: "scorecard" })
        }
    }

    // ****** TO FETCH DATA FOR DROPDOWNS *****************ENDS************




    // *********SCROLL TO GET MORE OPTIONS**************
    /* scroll function to get GROUP dropdown */
    const handleGroupDropDownScroll = (e) => {
        if (state?.groupState?.hasMoreOptions && !state?.groupState?.isLoading && Math.round(e?.currentTarget?.scrollTop + e?.currentTarget?.clientHeight) >= e?.currentTarget?.scrollHeight) {
            const newPage = state?.scorecardState?.apiPageNum + 1
            dispatch({ type: 'SCROLL_NEW_OPTIONS', key: "group", payload: newPage })
            fetchGroupOptions("", newPage, true);
        }
    }

    /* scroll function to get EMPLOYEE dropdown */
    const handleEmployeeDropDownScroll = (e) => {
        if (state?.employeeState?.hasMoreOptions && !state?.employeeState?.isLoading && Math.round(e?.currentTarget?.scrollTop + e?.currentTarget?.clientHeight) >= e?.currentTarget?.scrollHeight) {
            const newPage = state?.scorecardState?.apiPageNum + 1
            dispatch({ type: 'SCROLL_NEW_OPTIONS', key: "employee", payload: newPage })
            fetchEmpOptions(state?.groupState?.fieldValue?.id, "", newPage, true);
        }
    }


    /* scroll function to get SCORECARD dropdown */
    const handleScorecardDropDownScroll = (e) => {
        if (state?.scorecardState?.hasMoreOptions && !state?.scorecardState?.isLoading && Math.round(e?.currentTarget?.scrollTop + e?.currentTarget?.clientHeight) >= e?.currentTarget?.scrollHeight) {

            const newPage = state?.scorecardState?.apiPageNum + 1
            dispatch({ type: 'SCROLL_NEW_OPTIONS', key: "scorecard", payload: newPage })
            fetchScorecardOptions(state?.employeeState?.fieldValue?.id, state?.groupState?.fieldValue?.id, "", newPage, true);
        }
    }
    // *********SCROLL TO GET MORE OPTIONS**************ENDS



    // ****************** TO REGISTER NEW INPUT FOR TEXTFIELD ********************
    /* to set GROUP Input value */
    const debounceFetchGroupOptions = useCallback(_.debounce((input, pageNum) => {
        fetchGroupOptions(input, pageNum)                                                                       // FOR GROUP DEBOUNCE
    }, 500), [])

    const handleGroupInputChange = (e, value, reason) => {
        if (reason === "input") {
            dispatch({ type: "INPUT_FIELD_QUERY", payload: value, key: "group" })
            debounceFetchGroupOptions(value, 1)

        }
    }


    /* to set EMPLOYEE Input value */
    const debounceFetchEmpOptions = useCallback(_.debounce((groupId, input, pageNum) => {
        fetchEmpOptions(groupId, input, pageNum)                                                                // FOR EMPLOYEE DEBOUNCE
    }, 500), [])

    const handleEmployeeInputChange = (e, value, reason) => {
        if (reason === "input") {
            dispatch({ type: "INPUT_FIELD_QUERY", payload: value, key: "employee" })
            debounceFetchEmpOptions(state?.groupState?.fieldValue?.id, value, 1)

        }
    }


    /* to set SCORECARD Input value */
    const debounceFetchcorecardOptions = useCallback(_.debounce((empId, grpId, input, pageNum) => {
        fetchScorecardOptions(empId, grpId, input, pageNum)                                                            // FOR EMPLOYEE DEBOUNCE
    }, 500), [])

    const handleScorecardInputChange = (e, value, reason) => {
        if (reason === "input") {
            dispatch({ type: "INPUT_FIELD_QUERY", payload: value, key: "scorecard" })
            debounceFetchcorecardOptions(state?.employeeState?.fieldValue?.id, state?.groupState?.fieldValue?.id, value, 1)

        }
    }
    // ****************** TO REGISTER NEW INPUT FOR TEXTFIELD ********************ENDS


    // **************HANDLE CHANGE OPERATIONS********************************
    /* onchange for GROUP */
    const handleGroupChange = (e, value) => {
        dispatch({ type: "SET_FIELD_VALUE", payload: value, key: "group" })
        dispatch({ type: 'RESET_FIELD_VALUE', payload: initialstate?.employeeState, key: "employee" })
        dispatch({ type: 'RESET_FIELD_VALUE', payload: initialstate?.employeeState, key: "scorecard" })
        formik.setFieldValue("employeeField", "")
        formik.setFieldValue("scorecardField", "")
        if (value?.id) {
            formik.setFieldValue("groupField", value?.id)
            fetchEmpOptions(value?.id, "", 1)

        } else {
            formik.setFieldValue("groupField", "")
        }

    }

    /* onchange for EMPLOYEE */
    const handleEmployeeChange = (e, value) => {
        dispatch({ type: "SET_FIELD_VALUE", payload: value, key: "employee" })
        dispatch({ type: 'RESET_FIELD_VALUE', payload: initialstate?.scorecardState, key: "scorecard" })
        formik.setFieldValue("scorecardField", "")
        if (value?.id) {
            formik.setFieldValue("employeeField", value?.id)
            fetchScorecardOptions(value?.id, state?.groupState?.fieldValue?.id, "", 1)

        } else {
            formik.setFieldValue("employeeField", "")
        }

    }

    /* onchange for SCORECARD */
    const handleScorecardChange = (e, value) => {
        formik.setFieldValue("scorecardField", value?.id)
        dispatch({ type: "SET_FIELD_VALUE", payload: value, key: "scorecard" })
    }
    // **************HANDLE CHANGE OPERATIONS********************************ENDS



    // USEEFFECT TO CHECK AND LOAD GROUP OPTIONS ONLY FROM LIMITED PAGE OF EVALUATE MODULE
    useEffect(() => {
        if (!excludeDirectFilterPath?.includes(pathname)) {
            fetchGroupOptions("", 1)
        }

        return () => {
            formik.resetForm();
        }
    }, [])

    // useEffect(() => { console.log("errors", formik.errors) }, [formik.errors])


    // DYANMIC HANDLERS FOR VARIOUS FUNCTIONS TO WORK WITH AUTOCOMPLETE FORM FIELD LIST
    const dynamicFunctionHandlers = {
        handleGroupChangeFn: handleGroupChange,
        handleGroupInputChangeFn: handleGroupInputChange,
        handleGroupDropDownScrollFn: handleGroupDropDownScroll,
        handleEmployeeChangeFn: handleEmployeeChange,
        handleEmployeeInputChangeFn: handleEmployeeInputChange,
        handleEmployeeDropDownScrollFn: handleEmployeeDropDownScroll,
        handleScorecardChangeFn: handleScorecardChange,
        handleScorecardInputChangeFn: handleScorecardInputChange,
        handleScorecardDropDownScrollFn: handleScorecardDropDownScroll,
    }


    return (<>
        <Box sx={{ py: 2 }}>
            <form onSubmit={formik.handleSubmit}>
                <Stack direction='row' alignItems='center' sx={{ width: '100%', pr: 3, }}>
                    {
                        AutoCompleteFields?.map((val, fieldIndex) => <Autocomplete
                            sx={{ width: "26%", mr: 4 }}
                            name={`${val.toLowerCase()}Field`}
                            fullWidth
                            error
                            loading={state[`${val.toLowerCase()}State`]?.isLoading}
                            // loadingText="Loading More Options..."
                            loadingText={<Stack direction='row' alignItems="center">Loading Options... &nbsp;<CircularProgress size={25} /></Stack>}
                            value={state[`${val.toLowerCase()}State`]?.fieldValue}
                            options={state[`${val.toLowerCase()}State`]?.isLoading ? [] : state[`${val.toLowerCase()}State`]?.dropDownData}
                            onInputChange={dynamicFunctionHandlers[`handle${val}InputChangeFn`]} getOptionLabel={option => option?.name || ""}
                            noOptionsText={(fieldIndex > 0 && !state[`${AutoCompleteFields[fieldIndex - 1].toLowerCase()}State`]?.fieldValue) ? <Stack direction='row' justifyContent='center'><Typography align='center' variant='tableCell' sx={{ color: '#e74c3c', fontWeight: 'bold' }}>{`* Please select ${AutoCompleteFields[fieldIndex - 1]?.toLocaleLowerCase()} field for drop-down options`}</Typography></Stack> : 'No Options'}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            ListboxProps={{
                                onScroll: dynamicFunctionHandlers[`handle${val}DropDownScrollFn`]
                            }}
                            onChange={(e, value) => dynamicFunctionHandlers[`handle${val}ChangeFn`](e, value)


                            }
                            // value=
                            renderInput={params => (
                                <TextField {...params} label={`Select ${val}`}
                                    inputProps={{
                                        ...params.inputProps


                                    }}

                                    error={formik.errors[`${val.toLowerCase()}Field`] && formik.touched[`${val.toLowerCase()}Field`]}
                                    size='small'
                                />
                            )}

                        />)

                    }
                    <Tooltip sx={{ mx: 1 }} title='Open Record Performance' arrow>
                        <IconButton onClick={formik.handleSubmit} sx={{ backgroundColor: theme => theme.palette.action.selected, '&:hover': { backgroundColor: theme => theme.palette.action.selected } }} >
                            <RateReviewOutlined sx={{ color: '#ffffff', '&:hover': { backgroundColor: theme => theme.palette.action.selected } }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </form>
        </Box>



    </>)
}