import React, { useEffect, useMemo, useState } from 'react';
// REACT ROUTER IMPORTS
import { useNavigate } from "react-router-dom";
// MATERIAL IMPORTS
import {
    Avatar, Box, Button, CircularProgress,
    Collapse, IconButton, Link, List, Paper, Stack,
    Tab, Table, tableCellClasses, TableBody, TableCell, TableSortLabel,
    Card, CardHeader, Tooltip,
    TableContainer, TableHead, TableRow, Typography,
    Grid
} from '@material-ui/core';
import { visuallyHidden } from '@material-ui/utils';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import {
    FilterAltOutlined, Warning, Flag, StarRounded,
    MilitaryTech, KeyboardArrowUp, KeyboardArrowDown,
    Groups, AnnouncementRounded, Edit
} from '@material-ui/icons';
import TuneIcon from '@mui/icons-material/Tune';
import PhoneIcon from '@mui/icons-material/Phone';
// OTHER NPM IMPORTS
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';

// OTHER COMPONENT IMPORTS
import EmpDialog from "../components-overview/material-ui/dialog/admin/staff/EmpDialog";
import { MIconButton } from '../../components/@material-extend';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// API IMPORTS
import { /* getGdashboardGroupApi, */ getGdashboardDetailApi, getEmployeeDetails, downloadGdashboardDetailApi } from '../../_apis_/authApi.js/globalDashboardApi';
// CONTEXT IMPORTS
import { StaffContext } from '../../contexts/staffContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import GlobalDatePicker from "./datepicker";
import FilterDialog from './filter';

import GlobalTab from '../Common Component/tab';
import GlobalLoaders from '../Common Component/GlobalLoaders';
import { getComparator, applySortFilter } from '../../utils/tableDataSorter'


// import images ---
import xlsIcon from '../../assets/custom_icon/xlsIcon.svg'
import CustomCardImage from '../Common Component/CustomCardImage';
import GroupScoreIcon from '../../assets/Dashboard/group_score.svg'
import FailSectionIcon from '../../assets/Dashboard/fail_section.svg'
import FailAllIcon from '../../assets/Dashboard/fail_all.svg'
import KudosIcon from '../../assets/Dashboard/kudos.svg'
import PhoneCallIcon from '../../assets/Dashboard/phoneCall.svg'

// ----------------------------------------------------------------------------------------------------------------

const TABLE_HEADERS = [
    { label: 'Supervisor', id: 'supervisor', align: 'left', pl: 0 },
    { label: 'Employees', id: 'total_employe', align: 'center', pl: 2 },
    { label: 'Results', id: 'total_result', align: 'center', pl: 2 },
    { label: 'Viewed', id: 'score_viewed', align: 'center', pl: 2 },
    { label: 'Accepted', id: 'accepted', align: 'center', pl: 2 },
    { label: '1:1', id: 'onetoone_req', align: 'center', pl: 3 },
    { label: 'Reviewed', id: 'reviewed', align: 'center', pl: 2 },
    { label: 'Fail Section', id: 'fail_section_count', align: 'center', pl: 1 },
    { label: 'Fail All', id: 'fail_all_count', align: 'center', pl: 1 },
    { label: 'Kudos', id: 'kudos', align: 'center', pl: 1 },
    { label: 'Score', id: 'score_percentage', align: 'center', pl: 1 },
]

export default function GloabalDashboardBody(props) {
    const { handleGlobalDashboard } = props
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // REACT ROUTER STATES
    const navigate = useNavigate();

    // CONTEXT STATES
    const { strtDate, endDate, gId, setGpId, groupList, globalTeamId, filterby, dateObj, teamListsContext, setEmpForm, setEmployeeID } = React.useContext(StaffContext);
    const { setColor, themeMode } = React.useContext(SettingsContext);

    // REACT STATES
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [grpIds, setGrpIds] = React.useState([]);
    const [summary, setSummary] = React.useState();
    const [supervisorDetail, setSupervisorDetail] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = React.useState(true);
    const [teamTab, setTeamTab] = React.useState();
    const [groupTab, setGroupTab] = React.useState();
    const [supervisorListLoader, setSupervisorListLoader] = React.useState(false);
    const [tabLoader, setTabLoader] = React.useState(false);
    const [empList, setEmpList] = React.useState([]);
    const [empListLoader, setEmpListLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [collapseId, setCollapseId] = React.useState();
    const [value, setValue] = React.useState('1');
    const [tabData, setTabData] = React.useState([]);


    // METHODS/FUNCTIONS

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchEmpDetails = async (id) => {
        setEmpListLoader(true);
        const { data } = await getEmployeeDetails(dateObj?.date1, dateObj?.date2, groupTab, teamTab, filterby, id);
        setEmpListLoader(false);
        setEmpList(data?.data);

    }

    useEffect(() => {
        console.log("test env", process.env.REACT_APP_API_URL)

        if (groupList?.length > 0) {
            const tempArr = groupList?.map(obj => ({ label: obj?.score_card__groups__name, id: obj?.score_card__groups }))
            return setTabData(tempArr)
        }


        if (teamListsContext?.length > 0) {
            const tempArr = teamListsContext?.map(obj => ({ label: obj?.teams__name, id: obj?.teams }))
            return setTabData(tempArr)
        }


    }, [groupList, teamListsContext])

    const handleTabChange = async (tabObj) => {
        setTabLoader(true);
        if (groupList?.length > 0 && loggedUserData?.userLevel?.name !== 'Team Admin') {
            setSupervisorListLoader(true);
            setSupervisorDetail([]);
            try {
                const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, tabObj?.id, globalTeamId || "", filterby)
                setSummary(data?.data?.summery)
                setSupervisorListLoader(false);
                setSupervisorDetail(data?.data?.supervisor)
                setGroupTab(tabObj?.id);
                setTabLoader(false);
                setTeamTab(globalTeamId || "");
            } catch (err) {
                setSummary()
                setSupervisorListLoader(false);
                setSupervisorDetail([])
                setTabLoader(false);
            }
        }

        if (loggedUserData?.userLevel?.name === 'Team Admin') {
            setSupervisorListLoader(true);
            setSupervisorDetail([]);
            try {
                const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, grpIds, tabObj?.id, filterby)
                setSummary(data?.data?.summery)
                setSupervisorListLoader(false);
                setSupervisorDetail(data?.data?.supervisor)
                setGroupTab(grpIds);
                setTeamTab(tabObj?.id);
                setTabLoader(false);
            } catch (err) {
                setSummary()
                setSupervisorListLoader(false);
                setSupervisorDetail([])
                setTabLoader(false);
            }
        }

    }

    const handleDownloadXLS = async () => {
        try {

            if (groupList?.length > 0 && loggedUserData?.userLevel?.name !== 'Team Admin') {
                const { data } = await downloadGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupTab, teamTab, filterby)
                enqueueSnackbar('Downloading File...', {
                    variant: 'info',
                    autoHideDuration: 1500,
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                const outputFilename = `${loggedUserData?.userLevel?.name}_Dashboard_Details.xlsx`;
                // If you wanted to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }

            if (loggedUserData?.userLevel?.name === 'Team Admin') {

                const { data } = await downloadGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupTab, teamTab, filterby)
                enqueueSnackbar('Downloading File...', {
                    variant: 'info',
                    autoHideDuration: 1500,
                });
                const outputFilename = `${loggedUserData?.userLevel?.name}_Dashboard_Details_.xlsx`;
                // If you wanted to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
        }
    }

    const loadingTimeOut = () => {
        setTimeout(() => {
            if (isDataLoading) {
                setIsDataLoading(false);
            }
        }, 60000);

    }

    const tabButtons = (props, groupLength) => {
        console.log('props.direction', props)
        if (props.direction === 'left' && !props.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => props.onClick()}
                    icon="icon-park-solid:left-c"
                    style={{ color: '#fb5d2e', fontSize: '25px', cursor: 'pointer' }}
                />
            </Stack>)
        }
        if (props.direction === 'right' && !props.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => props.onClick()}
                        icon="icon-park-solid:right-c"
                        style={{ color: '#fb5d2e', fontSize: '25px', cursor: 'pointer' }}
                    />
                    {/* <Box component='span'>13/{groupLength}</Box> */}

                </Stack>
            )
        }
        return null
    }

    // USEEFFECT METHODS
    React.useEffect(() => {
        async function getDetail() {
            try {
                setIsDataLoading(true);
                if (loggedUserData?.userLevel?.name !== 'Team Admin' && dateObj?.date1 !== undefined && groupList?.length > 0) {
                    // if (groupList?.length > 0 && !globalTeamId) {
                    loadingTimeOut();
                    setSupervisorListLoader(true);
                    setSupervisorDetail([]);
                    const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupList[0]?.score_card__groups || groupList[0], globalTeamId || "", filterby)
                    setIsDataLoading(false);
                    setSummary(data?.data?.summery);
                    setValue('1');
                    setGroupTab(groupList[0]?.score_card__groups || groupList[0]);
                    if (globalTeamId !== null && globalTeamId !== undefined && globalTeamId !== "") {
                        setTeamTab(globalTeamId);
                    }
                    setSupervisorListLoader(false);
                    return setSupervisorDetail(data?.data?.supervisor)
                }
                if (groupList?.length === 0) {
                    setIsDataLoading(false);
                }
            } catch (err) {
                setIsDataLoading(false);
            }
        }
        if (groupList?.length > 0) { getDetail() } else { setIsDataLoading(false) }
    }, [groupList])

    React.useEffect(() => {
        async function getDetail() {
            try {
                if (loggedUserData?.userLevel?.name === 'Team Admin' && teamListsContext?.length > 0 && dateObj?.date1 !== undefined) {
                    setIsDataLoading(true);
                    setSupervisorListLoader(true);
                    setSupervisorDetail([]);
                    const groupIds = []
                    loggedUserData?.groups?.map(row => groupIds.push(row?.id));
                    setGrpIds(groupIds);
                    loadingTimeOut();
                    const { data } = await getGdashboardDetailApi(dateObj?.date1, dateObj?.date2, groupIds, teamListsContext[0]?.teams, filterby)
                    setSummary(data?.data?.summery)
                    setSupervisorListLoader(false);
                    setSupervisorDetail(data?.data?.supervisor);
                    setIsDataLoading(false);
                    setValue('1')
                    setGroupTab(groupIds);
                    setTeamTab(teamListsContext[0]?.teams);
                }
            } catch (err) {
                setIsDataLoading(false);
            }
        }
        getDetail()
    }, [teamListsContext])

    const MbLinks = [{ name: 'Home', href: '/dashboard' }, { name: 'Dashboard', href: '/dashboard' }]

    // SORTING
    const handleRequestSort = (event, property) => {
        // const isAsc = orderBy === property && order === 'asc';
        // console.log(order)
        setOrder(prev => orderBy === property && prev === 'desc' ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const createSortHandler = (property, toSort) => (event) => {

        handleRequestSort(event, property);

    };


    const filteredData = useMemo(() => applySortFilter(supervisorDetail?.map(obj => ({
        ...obj, score_percentage: Number(Number?.isNaN(((obj?.total_score / (obj?.max_score - obj?.na_sum)) * 100)) ? 0 : ((obj?.total_score / (obj?.max_score - obj?.na_sum)) * 100)?.toFixed(2))
    })), getComparator(order, orderBy)), [supervisorDetail, order, orderBy])

    return (
        <>
            <Box sx={{ px: 0, py: 0, m: 0 }}>
                <TabContext value={value}>
                    <Card
                        elevation={0}
                        sx={{
                            overflow: 'unset',
                            boxShadow: '1px solid grey',
                            position: 'unset',
                            width: '100%',
                            backgroundColor: 'white',
                            borderRadius: 1,
                            border: '1px solid transparent',
                        }}
                    >
                        <HeaderBreadcrumbs
                            sx={{ margin: "0px 0 0 25px", color: '#666666', fontWeight: 600 }}
                            links={MbLinks}
                        />
                        <CardHeader
                            sx={{
                                // mt: loggedUserData?.staff ? 3 : 0, p: 0,
                                margin: '0px',
                                padding: { lg: '0px 8px 0 0', md: '0px 8px 0 0', sm: '0px 15px 0 0', xs: '0px' },
                                color: (theme) => theme.palette.mode === 'light' ? '#666666' : '#fff',
                                flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: 'column' },
                                alignItems: 'normal'
                                // alignItems: { lg: 'unset', md: 'row', sm: 'row', xs: 'column' }
                            }}

                            title={
                                <Typography sx={{ fontWeight: 600, color: '#192a56', fontSize: { lg: '24px', xl: '32px' }, margin: '0 0 0 25px' }}>{handleGlobalDashboard()}</Typography>
                            }
                            titleTypographyProps={{ sx: { fontSize: { lg: '20px', xl: '28px' } } }}
                            action={<Box
                                sx={{ margin: { lg: '0px', md: '0px', sm: '0px', xs: '10px 25px' } }}
                            >
                                <Stack direction="row" alignItems="center" >
                                    <GlobalDatePicker />

                                    <Tooltip title='Filter'>
                                        <IconButton size='small'
                                            variant="contained"
                                            sx={{
                                                mx: 1,
                                                '&:hover': { backgroundColor: (theme) => theme.palette.background.paper },
                                                backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px',
                                                border: `1px solid #fb5d2e`
                                                // border: theme => `1px solid ${theme.palette.text.primaryBorder}`
                                            }} onClick={() => { setOpenFilter(true) }} >
                                            <TuneIcon
                                                size='small'
                                                sx={{
                                                    color: '#fb5d2e',
                                                    // color: '#636e72',
                                                    stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px',
                                                    '&:hover': {
                                                        backgroundColor: (theme) => theme.palette.background.paper,
                                                        color: '#fb5d2e'
                                                    },
                                                    backgroundColor: (theme) => theme.palette.background.paper
                                                }} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Box>}
                        />
                        <Box sx={{
                            borderBottom: (theme) => {
                                return {
                                    lg: `2px solid ${theme.palette.text.primaryBolderBorder}`,
                                    xl: `3px solid ${theme.palette.text.PrimaryBolderBorder}`
                                }
                            },
                        }}>
                            <GlobalTab

                                handleChange={handleChange}
                                tabBtns={
                                    tabData
                                }

                                fn={handleTabChange} />
                        </Box>

                        {(!tabLoader && groupList?.length !== 0) && <Grid container spacing={2}
                            sx={{
                                margin: 0,
                                width: '100%',
                            }}
                        >
                            {groupList?.length !== 0 && groupList?.map((panel, index) => (
                                <TabPanel key={index} value={String(index + 1)}
                                    sx={{
                                        padding: { lg: '0px', md: '0 16px', sm: '16px', xs: '0px' },
                                        width: '100%',
                                        margin: '15px 0'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: 'column' },
                                        }}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-around">
                                        {loggedUserData?.userLevel?.name !== 'Team Admin' &&
                                            <CustomCardImage
                                                icon={GroupScoreIcon}
                                                alt='Group Score icon'
                                                title='Group Score'
                                                scorePercentage={`${!Number.isNaN(summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) ? ((summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) * 100).toFixed(2) : 0}%`}
                                                firstCircleColour='#DBD4F01C'
                                                secondCircleColour='#DBD4F01C'
                                                iconBg='#788BD9'

                                            />

                                        }

                                        {loggedUserData?.userLevel?.name === 'Team Admin' && <>

                                            <CustomCardImage
                                                icon={GroupScoreIcon}
                                                alt='Team Score icon'
                                                title='Team Score'
                                                scorePercentage={`${!Number.isNaN(summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) ? ((summary?.group_score_sum / (summary?.scorecard_total - summary?.na_total)) * 100).toFixed(2) : 0}%`}
                                                firstCircleColour='#DBD4F01C'
                                                secondCircleColour='#DBD4F01C'
                                                iconBg='#212668'

                                            />
                                        </>

                                        }
                                        <CustomCardImage
                                            icon={FailSectionIcon}
                                            alt='Fail Section icon'
                                            title='Fail Section'
                                            scorePercentage={`${summary?.flag || 0}`}
                                            firstCircleColour='#FA808A0A'
                                            secondCircleColour='#FA808A0A'
                                            iconBg='#FA808A'

                                        />


                                        <CustomCardImage
                                            icon={FailAllIcon}
                                            alt='Fail All icon'
                                            title='Fail All'
                                            scorePercentage={`${summary?.fail_count || 0}`}
                                            firstCircleColour='#7DD1B30D'
                                            secondCircleColour='#7DD1B30D'
                                            iconBg='#7DD1B3'

                                        />

                                        <CustomCardImage
                                            icon={KudosIcon}
                                            alt='Kudos icon'
                                            title='Kudos'
                                            scorePercentage={`${summary?.Kudos || 0}`}
                                            firstCircleColour='#FFAB730D'
                                            secondCircleColour='#FFAB730D'
                                            iconBg='#FFAB73'
                                        />
                                        {
                                            loggedUserData?.isAiAccess &&
                                            <CustomCardImage
                                                icon={PhoneCallIcon}
                                                alt='Call Score icon'
                                                title="Score's Calls"
                                                scorePercentage={`${summary?.calls_count ? summary?.calls_count : 0}`}
                                                firstCircleColour='#94d0f614'
                                                secondCircleColour='#94d0f614'
                                                iconBg='#4ab5fb'

                                            />
                                        }

                                    </Box>


                                </TabPanel>

                            ))}

                        </Grid>}
                    </Card>
                    {tabLoader &&
                        <Stack direction="row" sx={{
                            width: "100%", minHeight: { sm: 'calc(100vh - 395px)', lg: 'calc(100vh - 350px)', newLg: 'calc(100vh - 348px)', xl: 'calc(100vh - 358px)' },
                            backgroundColor: (theme) => theme.palette.background.paper, borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'
                        }}>
                            <GlobalLoaders isLoading sx={{ minHeight: { sm: 'calc(100vh - 395px)', lg: 'calc(100vh - 350px)', newLg: 'calc(100vh - 348px)', xl: 'calc(100vh - 358px)' } }} />

                        </Stack>}

                    {/* Supervisor list table---------- */}
                    {(groupList?.length !== 0 && !tabLoader) &&
                        <Box sx={{ width: "100%", height: { lg: window.navigator.userAgent.includes("Chrome") ? '280px' : '310px', xl: '460px' }, py: { lg: 1, xl: 2 }, px: 0 }}>
                            <Box sx={{
                                height: '100%',
                                width: '100%',
                                borderBottomLeftRadius: '5px',
                                borderBottomRightRadius: '5px',
                                // height: { xs: '250px', xl: '400px' }
                            }}>

                                <TableContainer
                                    component={Paper}
                                    elevation={5}
                                    sx={{
                                        // height: '40px',
                                        width: '100%',
                                        borderBottomLeftRadius: '5px',
                                        borderBottomRightRadius: '5px',
                                        height: { xs: '250px', lg: 'calc(100vh - 415px)', xl: 'calc(100vh - 460px)' }
                                    }}>
                                    <Stack sx={{ backgroundColor: 'transparent', px: 1, py: 1 }} alignItems='center' justifyContent='space-between' direction='row'>
                                        <Typography sx={{ fontSize: { xs: '12px', lg: '17px', xl: '20px' }, color: '#2B2B2B', fontWeight: '700', paddingLeft: '16px' }}>Supervisor's List</Typography>
                                        <Box
                                            sx={{
                                                border: theme => `1px solid ${supervisorDetail?.length === 0 ? theme.palette.action.disabled : theme.palette.text.buttonText}`,
                                                borderRadius: '5px',
                                                display: 'flex',
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    backgroundColor: (theme) => theme.palette.background.paper,
                                                    color: (theme) => theme.palette.text.buttonText,
                                                    stroke: (theme) => theme.palette.background.paper,
                                                    strokeWidth: '1px', fontWeight: { xs: 'lighter', xl: 'bold' },
                                                    fontSize: '12px', borderRadius: '5px',
                                                    border: 'none',
                                                    padding: '8px',
                                                    '&:hover': {
                                                        backgroundColor: (theme) => theme.palette.background.paper,
                                                        border: 'none',
                                                        color: (theme) => theme.palette.text.buttonText,

                                                    },
                                                }}
                                                disabled={supervisorDetail?.length === 0}
                                                onClick={handleDownloadXLS}
                                            >
                                                Download Excel
                                            </Button>
                                            <Box sx={{
                                                backgroundColor: theme => supervisorDetail?.length === 0 ? theme.palette.action.disabled : theme.palette.text.buttonText,
                                                borderTopLeftRadius: '15px',
                                                borderBottomLeftRadius: '5px',
                                                width: '40px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <img src={xlsIcon} alt='xl-download'
                                                />
                                            </Box>

                                        </Box>
                                    </Stack>

                                    <Table size='small' sx={{ width: "100%" }} aria-label="simple table">
                                        <TableHead sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                backgroundColor: '#F1F2FF'
                                                // backgroundColor: (theme) => theme.palette.background.paper,
                                            }
                                        }} >
                                            <TableRow sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    py: { lg: 1, xl: 1.5 },
                                                    // backgroundColor: (theme) => theme.palette.background.paper,
                                                }, borderBottom: (theme) => { return { lg: `2px solid ${theme.palette.text.primaryBolderBorder}`, xl: `3px solid ${theme.palette.text.PrimaryBolderBorder}` } }
                                            }}>

                                                {TABLE_HEADERS?.map(headCell => <TableCell align={headCell?.align}>
                                                    <TableSortLabel
                                                        active={orderBy === headCell.id}
                                                        direction={orderBy === headCell.id ? order : 'asc'}
                                                        onClick={createSortHandler(headCell.id)}
                                                        sx={{ pl: headCell?.pl }}
                                                    >
                                                        <Typography variant='tableHead'>
                                                            {headCell?.label}
                                                        </Typography>
                                                        {orderBy === headCell.id ? (
                                                            <Box component="span" sx={{ ...visuallyHidden }}>
                                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </Box>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>)}

                                            </TableRow>
                                        </TableHead>

                                        <TableBody  >

                                            {filteredData?.map((row, index) => <React.Fragment key={index}>
                                                <TableRow hover onClick={() => { setOpen(!open); if (collapseId === row?.supervisor) { setEmpList([]); setCollapseId(undefined) } else { fetchEmpDetails(row?.supervisor); setCollapseId(row?.supervisor); } }} sx={{
                                                    [`& .${tableCellClasses.root}`]: {
                                                        py: 1.25,
                                                        cursor: 'pointer',
                                                        borderBottom: (theme) => `1px solid ${(index !== supervisorDetail?.length - 1 && collapseId !== row?.supervisor) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                    },
                                                }} >

                                                    <TableCell component="th" scope="row" sx={{ padding: '0 8px !important' }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "100px" }}>
                                                            <>
                                                                <IconButton onClick={() => { setOpen(!open); if (collapseId === row?.supervisor) { setEmpList([]); setCollapseId(undefined) } else { fetchEmpDetails(row?.supervisor); setCollapseId(row?.supervisor); } }}>
                                                                    {(collapseId === row?.supervisor) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                                </IconButton>
                                                                <Avatar sx={{ backgroundColor: (index % 2) === 0 ? (theme) => theme.palette.text.buttonText : (theme) => theme.palette.action.selected, color: (theme) => theme.palette.text.sidebarPrimaryText, fontSize: '14px' }}>
                                                                    {row?.first_name?.split("")[0]?.toUpperCase()}{row?.last_name?.split("")[0]?.toUpperCase()}
                                                                </Avatar>
                                                            </>

                                                            <Box sx={{ ml: 1 }}>

                                                                <Typography sx={{ fontSize: { md: '12px', xl: '14px' } }}>{row?.supervisor}</Typography>

                                                                <Link href={`/supervisor/dashboard?stff_sup_id=${row?.supervisor}&date1=${dateObj?.date1}&date2=${dateObj?.date2}&supType=${row?.supervisor_type}`} onClick={(e) => { e.preventDefault(); navigate(`/supervisor/dashboard?stff_sup_id=${row?.supervisor}&date1=${dateObj?.date1}&date2=${dateObj?.date2}&supType=${row?.supervisor_type}`, { state: { stffName: row?.staff?.user?.first_name } }) }} variant='text' sx={{ justifyContent: "flex-start", p: 0, m: 0, color: theme => theme.palette.action.selected, "&:hover": { bgcolor: "transparent" }, fontSize: { lg: '13px', xl: '15px' }, fontWeight: '600' }} underline="none">{row?.supervisor__user__email}</Link>
                                                                <Typography sx={{ marginRight: '5px', fontSize: { lg: '13px', xl: '14px' } }}>{row?.first_name}&nbsp;{row?.last_name}</Typography>

                                                            </Box >
                                                        </Box >
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.total_employe || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.total_result || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.score_viewed || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.accepted || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.onetoone_req || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.reviewed || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.fail_section_count || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.fail_all_count || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {row?.kudos || 0}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography variant='tableCell'>
                                                            {Number?.isNaN(((row?.total_score / (row?.max_score - row?.na_sum)) * 100)) ? `0%` : `${((row?.total_score / (row?.max_score - row?.na_sum)) * 100)?.toFixed(2)}%`}

                                                        </Typography>

                                                    </TableCell>

                                                </TableRow>
                                                <TableRow
                                                    sx={{
                                                        [`& .${tableCellClasses.root}`]: {
                                                            borderBottom: (theme) => `1px solid ${(collapseId === row?.supervisor) ? theme.palette.text.tableprimaryBorder : 'transparent'}`,
                                                        },

                                                    }}
                                                >
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, m: 0 }} colSpan={12}>
                                                        {!empListLoader > 0 && <Collapse in={(collapseId === row?.supervisor && !empListLoader)} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Typography sx={{ fontWeight: 600, fontSize: { lg: '14px', xl: '16px' } }} gutterBottom component="div">
                                                                    Employee Details
                                                                </Typography>
                                                                <Table size="small" aria-label="emp_details" sx={{ m: 0 }}>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Name</TableCell>
                                                                            <TableCell>Email</TableCell>
                                                                            <TableCell >Action</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {empList?.length > 0 && empList?.map((rows, index) => <>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "100px" }}>
                                                                                        <>
                                                                                            <Avatar sx={{ backgroundColor: (index % 2) === 0 ? (theme) => theme.palette.text.buttonText : (theme) => theme.palette.action.selected, color: (theme) => theme.palette.text.sidebarPrimaryText, fontSize: '14px' }}>
                                                                                                {rows?.first_name?.split("")[0]?.toUpperCase()}{rows?.last_name?.split("")[0]?.toUpperCase()}
                                                                                            </Avatar>
                                                                                        </>
                                                                                        <Box sx={{ ml: 1 }}>
                                                                                            <Typography sx={{ fontSize: '12px' }}>{rows?.id}</Typography>
                                                                                            <Typography sx={{ fontWeight: "normal", marginRight: '5px', fontSize: { lg: '12px', xl: '14px' } }}>{rows?.first_name}&nbsp;{rows?.last_name}</Typography>
                                                                                        </Box >
                                                                                    </Box >
                                                                                </TableCell>
                                                                                <TableCell>{rows?.email}</TableCell>
                                                                                <TableCell>
                                                                                    <Button size='small' sx={{ color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper, fontWeight: 'normal', border: '1px solid #dddde7', borderRadius: '5px', fontSize: { lg: '12px', xl: '13px' }, boxShadow: 'none', '&:hover': { color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper } }} startIcon={<Edit size='small' />} onClick={() => { setEmployeeID(rows?.id); setEmpForm(true) }} variant='contained' >
                                                                                        Edit
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </>)}
                                                                    </TableBody>
                                                                </Table>
                                                                {empList?.length === 0 && <Box sx={{ fontWeight: 'bold', minHeight: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    No Data Found
                                                                </Box>}
                                                            </Box>
                                                        </Collapse>}
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                            )}
                                        </TableBody>

                                    </Table>
                                    {supervisorDetail?.length === 0 && <GlobalLoaders isLoading={supervisorListLoader} sx={{ width: "100%", height: { xs: '250px', lg: 'calc(100vh - 550px)', xl: 'calc(100vh - 620px)' } }} />
                                        // <Box sx={{ width: "100%", minHeight: "250px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                        //     <List sx={{ fontSize: "50px" }} /><br />
                                        //     {!supervisorListLoader && <Typography variant="body" sx={{ my: 1, fontWeight: "bold" }}>
                                        //         No Score found for the data range selected.
                                        //     </Typography>}

                                        // </Box>
                                    }
                                </TableContainer>
                            </Box>
                        </Box>}
                    {groupList?.length === 0 && <Box sx={{ width: "100%", minHeight: { sm: 'calc(100vh - 395px)', lg: 'calc(100vh - 350px)', newLg: 'calc(100vh - 348px)', xl: 'calc(100vh - 358px)' }, backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px' }}>
                        <GlobalLoaders isLoading={isDataLoading} sx={{ height: { sm: 'calc(100vh - 395px)', lg: 'calc(100vh - 350px)', newLg: 'calc(100vh - 348px)', xl: 'calc(100vh - 358px)' } }} />



                    </Box>
                    }

                </TabContext >

            </Box >
            <EmpDialog apiSupId={groupList?.[Number(value) - 1]?.score_card__groups} handleTabChange={handleTabChange} setOpen={setOpen} setCollapseId={setCollapseId} showButton setEmpList={setEmpList} />
            {/* filter dialog */}
            <FilterDialog openFilter={openFilter} setOpenFilter={setOpenFilter} />

            {/* <Box>ok</Box> */}
        </>
    )
}
