import { useState, useContext } from 'react';
import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@material-ui/core';
// routes
import { PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
//
import FooterLogo from '../../components/FooterLogo';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { SettingsContext } from "../../contexts/SettingsContext";

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', icon: facebookFill },
  { name: 'Google', icon: googleFill },
  { name: 'Linkedin', icon: linkedinFill },
  { name: 'Twitter', icon: twitterFill }
];

const LINKS = [
  {
    headline: 'Minimal',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs }
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@minimals.cc', href: '#' },
      { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' }
    ]
  }
];


const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  // backgroundColor: '#f5f6fa',
  backgroundColor: theme.palette.background.paper,

}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
}));
// ----------------------------------------------------------------------

export default function MainFooter() {
  const [loggedUserData, setLoggedUserData] = useState(JSON.parse(window.localStorage.getItem('userData')));


  const { showSidebar } = useContext(SettingsContext);
  const { collapseClick } = useCollapseDrawer();
  const theme = useTheme();
  return (
    <RootStyle>
      <Divider />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ml: { lg: '210px', xl: '240px' },
          ...(collapseClick && {
            ml: showSidebar ? '25px' : '0px'
          })
        }}
      >


        {/* <Container maxWidth="false" sx={{ width: '100%', border: '1px solid red', px: 0, py: 1, display: 'flex', alignItems: 'center', backgroundColor: theme => theme.palette.background.paper }} > */}
        <Grid

          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          // alignItems='center'
          sx={{ py: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} md={2}>
            {/* <ScrollLink to="move_top" spy smooth> */}
            <FooterLogo sx={{ mx: { xs: 'auto', md: 'inherit', lg: 0 } }} />
            {/* </ScrollLink> */}
          </Grid>
          <Grid item xs={12} md={3} />

          <Grid item xs={12} md={7} sx={{ justifyContent: 'center', alignItems: 'center', pt: 2 }}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="flex-end" alignItems='center' >
              <Typography variant="body2" sx={{ borderRight: '0.1em solid #999', lineHeight: '12px', paddingRight: '2em', color: '#999' }}>
                Powered by BeyondQA © 2023.
              </Typography>

              {loggedUserData?.staff?.staffType !== 10 && <Link

                onMouseEnter={() => {
                  setLoggedUserData(JSON.parse(window.localStorage.getItem('userData')))
                }}
                to={(loggedUserData?.supportAccess || (loggedUserData && loggedUserData?.staff?.staffType !== 10)) ? PATH_PAGE?.support : PATH_DASHBOARD?.general?.dashboard}
                // to={PATH_DASHBOARD?.general?.dashboard}
                color="inherit"
                variant="body2"
                component={RouterLink}
              >
                <Typography
                  variant="body2"
                  sx={{ borderRight: '0.1em solid #999', lineHeight: '12px', paddingRight: '2em', paddingLeft: '2em', color: '#999' }}
                >
                  Help & Support
                </Typography>


              </Link>}


              <Link
                to='privacy-policy'
                color="inherit"
                variant="body2"
                component={RouterLink}
              >
                <Typography
                  variant="body2"
                  sx={{ lineHeight: '12px', paddingLeft: '2em', color: '#999' }}
                >
                  Privacy Policy
                </Typography>

              </Link>
            </Stack>
          </Grid>
        </Grid>

        {/* </Container> */}
      </MainStyle>
    </RootStyle>
  );
}
