import React, { useState } from 'react';
import { Outlet, useSearchParams, useLocation } from 'react-router-dom';
// material
import { Box } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// import context 
import { SettingsContext } from "../../contexts/SettingsContext"
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import MainFooter from '../main/MainFooter';
import SupervisorDashboardNavbar from './supervisor_dashboard/sup_navbar';
import EmployeeDashboardNavbar from "./employee_dashboard/emp_navbar";
import EmployeeDashboardSidebar from './employee_dashboard/emp_sidenavbar';
import HelpSupportPopUpForm from "../../pages/dashboard/help_support/help_support_pop_up_form";
import SupervisorDashboardSidebar from './supervisor_dashboard/sup_sidebar';

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;
const APP_BAR_MOBILE = 34;
const APP_BAR_DESKTOP = 60;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  // minHeight: '100%',
  // height: '90%',
  height: 'calc(100vh - 60px)',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.mode === 'light' ? '#f5f6fa' : '#636e72',


}));

const isPaddingNeededEmpDash = (val, theme) => {
  return val ? 0 : theme?.spacing(2)
}

const MainStyle = styled('div')(({ theme, userdata, isSideBar }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // height: '100% !important',
  overflowY: 'auto',
  // height: 'calc(100vh - 70px)',
  paddingTop: APP_BAR_MOBILE + 24,

  paddingBottom: '0px',
  // paddingBottom: userdata?.staff ? 15 : theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + Number(`${userdata?.staff ? 24 : 24}`),
    paddingLeft: userdata?.staff ? isPaddingNeededEmpDash((userdata?.staff && isSideBar), theme) : theme.spacing(0),
    paddingRight: userdata?.staff ? isPaddingNeededEmpDash((userdata?.staff && isSideBar), theme) : theme.spacing(0),
    paddingBottom: 0
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {


  const { showHelpSupportPopUp, setShowHelpSupportPopUp, showSidebar } = React.useContext(SettingsContext)
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  // console.log("loggedUserData", loggedUserData)
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { collapseClick, collapseHover } = useCollapseDrawer();
  // const [open, setOpen] = useState(false);
  const { pathname } = useLocation()

  const getBgValue = () => {
    if (!loggedUserData?.staff) {
      return '#f5f6fa'
    }
    return 'transparent'
  }

  // commenting for some time , while next updates
  // const pathnameIndexTwo = [
  //   'calibrate_performance', 'edit_calibrate_performance', 'view_calibrate_performance', 'reviewPerformance', 'editScore', 'call_details', 'eventBuilder'
  // ]


  return (
    <>
      <RootStyle  >
        {(loggedUserData?.staff?.staffType === 20 || (loggedUserData?.staff?.staffType === 30)) && <SupervisorDashboardNavbar />}
        {loggedUserData?.staff?.staffType !== 10 &&
          (loggedUserData?.staff?.staffType === 20 || (loggedUserData?.staff?.staffType === 30)) &&
          <SupervisorDashboardSidebar />}
        {/* {(loggedUserData?.staff?.staffType === 30 || (loggedUserData?.staff?.staffType !== 10 && searchParams.get("review"))) && <SupervisorDashboardNavbar />} */}
        {(loggedUserData?.staff?.staffType !== 10 && searchParams.get("review")) && <DashboardNavbar />}
        {loggedUserData?.staff?.staffType === 10 && <EmployeeDashboardNavbar type="score" />}
        {(!loggedUserData?.staff && !searchParams.get("review")) && <DashboardNavbar />}
        {/* {(!loggedUserData?.staff && !s earchParams.get("review") && !searchParams.get("stff_emp_id")) && <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />} */}

        {/* commnet for next uses----- DO NOT REMOVE JUST BELOW LINE CODE */}
        {/* {(!loggedUserData?.staff && !searchParams.get("review") && !searchParams.get("stff_emp_id") && !pathnameIndexTwo?.includes(pathname?.split('/')[2])) && <DashboardSidebar />} */}
        {(!loggedUserData?.staff && !searchParams.get("review") && !searchParams.get("stff_emp_id")) && <DashboardSidebar />}

        {(loggedUserData?.staff?.staffType === 10 || searchParams.get("stff_emp_id")) && <EmployeeDashboardSidebar />}
        <MainStyle
          id='mainStyle'
          userdata={loggedUserData}
          isSideBar={showSidebar}
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: showSidebar ? '60px' : '1px'
            }),
            // backgroundColor: (theme) => loggedUserData?.staff ? 'transparent' : '#f5f6fa', // changes for bg
            backgroundColor: (theme) => theme.palette.mode === 'light' ? getBgValue() : '#636e72', // changes for bg
          }}
        >
          <Outlet />

        </MainStyle>
        <HelpSupportPopUpForm />

      </RootStyle>

      <MainFooter />
    </>
  );
}