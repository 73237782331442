//  set NODE_OPTIONS=--max_old_space_size=4096 --openssl-legacy-provider
// set NODE_OPTIONS=--openssl-legacy-provider
export const countryName = [
    { name: 'Afghanistan', code: 'AF', id: '1' },
    { name: 'Åland Islands', code: 'AX', id: '2' },
    { name: 'Albania', code: 'AL', id: '3' },
    { name: 'Algeria', code: 'DZ', id: '4' },
    { name: 'American Samoa', code: 'AS', id: '5' },
    { name: 'AndorrA', code: 'AD', id: '6' },
    { name: 'Angola', code: 'AO', id: '7' },
    { name: 'Anguilla', code: 'AI', id: '8' },
    { name: 'Antarctica', code: 'AQ', id: '9' },
    { name: 'Antigua and Barbuda', code: 'AG', id: '10' },
    { name: 'Argentina', code: 'AR', id: '11' },
    { name: 'Armenia', code: 'AM', id: '12' },
    { name: 'Aruba', code: 'AW', id: '13' },
    { name: 'Australia', code: 'AU', id: '14' },
    { name: 'Austria', code: 'AT', id: '15' },
    { name: 'Azerbaijan', code: 'AZ', id: '16' },
    { name: 'Bahamas', code: 'BS', id: '17' },
    { name: 'Bahrain', code: 'BH', id: '18' },
    { name: 'Bangladesh', code: 'BD', id: '19' },
    { name: 'Barbados', code: 'BB', id: '20' },
    { name: 'Belarus', code: 'BY', id: '21' },
    { name: 'Belgium', code: 'BE', id: '22' },
    { name: 'Belize', code: 'BZ', id: '23' },
    { name: 'Benin', code: 'BJ', id: '24' },
    { name: 'Bermuda', code: 'BM', id: '25' },
    { name: 'Bhutan', code: 'BT', id: '26' },
    { name: 'Bolivia', code: 'BO', id: '27' },
    { name: 'Bosnia and Herzegovina', code: 'BA', id: '28' },
    { name: 'Botswana', code: 'BW', id: '29' },
    { name: 'Bouvet Island', code: 'BV', id: '30' },
    { name: 'Brazil', code: 'BR', id: '31' },
    { name: 'British Indian Ocean Territory', code: 'IO', id: '32' },
    { name: 'Brunei Darussalam', code: 'BN', id: '33' },
    { name: 'Bulgaria', code: 'BG', id: '34' },
    { name: 'Burkina Faso', code: 'BF', id: '35' },
    { name: 'Burundi', code: 'BI', id: '36' },
    { name: 'Cambodia', code: 'KH', id: '37' },
    { name: 'Cameroon', code: 'CM', id: '38' },
    { name: 'Canada', code: 'CA', id: '39' },
    { name: 'Cape Verde', code: 'CV', id: '40' },
    { name: 'Cayman Islands', code: 'KY', id: '41' },
    { name: 'Central African Republic', code: 'CF', id: '42' },
    { name: 'Chad', code: 'TD', id: '43' },
    { name: 'Chile', code: 'CL', id: '44' },
    { name: 'China', code: 'CN', id: '45' },
    { name: 'Christmas Island', code: 'CX', id: '46' },
    { name: 'Cocos (Keeling) Islands', code: 'CC', id: '47' },
    { name: 'Colombia', code: 'CO', id: '48' },
    { name: 'Comoros', code: 'KM', id: '49' },
    { name: 'Congo', code: 'CG', id: '50' },
    { name: 'Congo, The Democratic Republic of the', code: 'CD', id: '51' },
    { name: 'Cook Islands', code: 'CK', id: '52' },
    { name: 'Costa Rica', code: 'CR', id: '53' },
    { name: 'Cote D\'Ivoire', code: 'CI', id: '54' },
    { name: 'Croatia', code: 'HR', id: '55' },
    { name: 'Cuba', code: 'CU', id: '56' },
    { name: 'Cyprus', code: 'CY', id: '57' },
    { name: 'Czech Republic', code: 'CZ', id: '58' },
    { name: 'Denmark', code: 'DK', id: '59' },
    { name: 'Djibouti', code: 'DJ', id: '60' },
    { name: 'Dominica', code: 'DM', id: '61' },
    { name: 'Dominican Republic', code: 'DO', id: '62' },
    { name: 'Ecuador', code: 'EC', id: '63' },
    { name: 'Egypt', code: 'EG', id: '64' },
    { name: 'El Salvador', code: 'SV', id: '65' },
    { name: 'Equatorial Guinea', code: 'GQ', id: '66' },
    { name: 'Eritrea', code: 'ER', id: '67' },
    { name: 'Estonia', code: 'EE', id: '68' },
    { name: 'Ethiopia', code: 'ET', id: '69' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK', id: '70' },
    { name: 'Faroe Islands', code: 'FO', id: '71' },
    { name: 'Fiji', code: 'FJ', id: '72' },
    { name: 'Finland', code: 'FI', id: '73' },
    { name: 'France', code: 'FR', id: '74' },
    { name: 'French Guiana', code: 'GF', id: '75' },
    { name: 'French Polynesia', code: 'PF', id: '76' },
    { name: 'French Southern Territories', code: 'TF', id: '77' },
    { name: 'Gabon', code: 'GA', id: '78' },
    { name: 'Gambia', code: 'GM', id: '79' },
    { name: 'Georgia', code: 'GE', id: '80' },
    { name: 'Germany', code: 'DE', id: '81' },
    { name: 'Ghana', code: 'GH', id: '82' },
    { name: 'Gibraltar', code: 'GI', id: '83' },
    { name: 'Greece', code: 'GR', id: '84' },
    { name: 'Greenland', code: 'GL', id: '85' },
    { name: 'Grenada', code: 'GD', id: '86' },
    { name: 'Guadeloupe', code: 'GP', id: '87' },
    { name: 'Guam', code: 'GU', id: '88' },
    { name: 'Guatemala', code: 'GT', id: '89' },
    { name: 'Guernsey', code: 'GG', id: '90' },
    { name: 'Guinea', code: 'GN', id: '91' },
    { name: 'Guinea-Bissau', code: 'GW', id: '92' },
    { name: 'Guyana', code: 'GY', id: '93' },
    { name: 'Haiti', code: 'HT', id: '94' },
    { name: 'Heard Island and Mcdonald Islands', code: 'HM', id: '95' },
    { name: 'Holy See (Vatican City State)', code: 'VA', id: '96' },
    { name: 'Honduras', code: 'HN', id: '97' },
    { name: 'Hong Kong', code: 'HK', id: '98' },
    { name: 'Hungary', code: 'HU', id: '99' },
    { name: 'Iceland', code: 'IS', id: '100' },
    { name: 'India', code: 'IN', id: '101' },
    { name: 'Indonesia', code: 'ID', id: '101' },
    { name: 'Iran, Islamic Republic Of', code: 'IR', id: '103' },
    { name: 'Iraq', code: 'IQ', id: '104' },
    { name: 'Ireland', code: 'IE', id: '105' },
    { name: 'Isle of Man', code: 'IM', id: '106' },
    { name: 'Israel', code: 'IL', id: '107' },
    { name: 'Italy', code: 'IT', id: '108' },
    { name: 'Jamaica', code: 'JM', id: '109' },
    { name: 'Japan', code: 'JP', id: '110' },
    { name: 'Jersey', code: 'JE', id: '111' },
    { name: 'Jordan', code: 'JO', id: '112' },
    { name: 'Kazakhstan', code: 'KZ', id: '113' },
    { name: 'Kenya', code: 'KE', id: '114' },
    { name: 'Kiribati', code: 'KI', id: '115' },
    { name: 'Korea, Democratic People\'S Republic of', code: 'KP', id: '116' },
    { name: 'Korea, Republic of', code: 'KR', id: '117' },
    { name: 'Kuwait', code: 'KW', id: '118' },
    { name: 'Kyrgyzstan', code: 'KG', id: '119' },
    { name: 'Lao People\'S Democratic Republic', code: 'LA', id: '120' },
    { name: 'Latvia', code: 'LV', id: '121' },
    { name: 'Lebanon', code: 'LB', id: '122' },
    { name: 'Lesotho', code: 'LS', id: '123' },
    { name: 'Liberia', code: 'LR', id: '124' },
    { name: 'Libyan Arab Jamahiriya', code: 'LY', id: '125' },
    { name: 'Liechtenstein', code: 'LI', id: '126' },
    { name: 'Lithuania', code: 'LT', id: '127' },
    { name: 'Luxembourg', code: 'LU', id: '128' },
    { name: 'Macao', code: 'MO', id: '129' },
    { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK', id: '130' },
    { name: 'Madagascar', code: 'MG', id: '131' },
    { name: 'Malawi', code: 'MW', id: '132' },
    { name: 'Malaysia', code: 'MY', id: '133' },
    { name: 'Maldives', code: 'MV', id: '134' },
    { name: 'Mali', code: 'ML', id: '135' },
    { name: 'Malta', code: 'MT', id: '136' },
    { name: 'Marshall Islands', code: 'MH', id: '137' },
    { name: 'Martinique', code: 'MQ', id: '138' },
    { name: 'Mauritania', code: 'MR', id: '140' },
    { name: 'Mauritius', code: 'MU', id: '141' },
    { name: 'Mayotte', code: 'YT', id: '142' },
    { name: 'Mexico', code: 'MX', id: '143' },
    { name: 'Micronesia, Federated States of', code: 'FM', id: '144' },
    { name: 'Moldova, Republic of', code: 'MD', id: '145' },
    { name: 'Monaco', code: 'MC', id: '146' },
    { name: 'Mongolia', code: 'MN', id: '147' },
    { name: 'Montserrat', code: 'MS', id: '148' },
    { name: 'Morocco', code: 'MA', id: '149' },
    { name: 'Mozambique', code: 'MZ', id: '150' },
    { name: 'Myanmar', code: 'MM', id: '151' },
    { name: 'Namibia', code: 'NA', id: '152' },
    { name: 'Nauru', code: 'NR', id: '153' },
    { name: 'Nepal', code: 'NP', id: '154' },
    { name: 'Netherlands', code: 'NL', id: '155' },
    { name: 'Netherlands Antilles', code: 'AN', id: '156' },
    { name: 'New Caledonia', code: 'NC', id: '157' },
    { name: 'New Zealand', code: 'NZ', id: '158' },
    { name: 'Nicaragua', code: 'NI', id: '159' },
    { name: 'Niger', code: 'NE', id: '160' },
    { name: 'Nigeria', code: 'NG', id: '161' },
    { name: 'Niue', code: 'NU', id: '162' },
    { name: 'Norfolk Island', code: 'NF', id: '163' },
    { name: 'Northern Mariana Islands', code: 'MP', id: '164' },
    { name: 'Norway', code: 'NO', id: '165' },
    { name: 'Oman', code: 'OM', id: '166' },
    { name: 'Pakistan', code: 'PK', id: '167' },
    { name: 'Palau', code: 'PW', id: '168' },
    { name: 'Palestinian Territory, Occupied', code: 'PS', id: '169' },
    { name: 'Panama', code: 'PA', id: '170' },
    { name: 'Papua New Guinea', code: 'PG', id: '171' },
    { name: 'Paraguay', code: 'PY', id: '172' },
    { name: 'Peru', code: 'PE', id: '173' },
    { name: 'Philippines', code: 'PH', id: '174' },
    { name: 'Pitcairn', code: 'PN', id: '175' },
    { name: 'Poland', code: 'PL', id: '176' },
    { name: 'Portugal', code: 'PT', id: '177' },
    { name: 'Puerto Rico', code: 'PR', id: '178' },
    { name: 'Qatar', code: 'QA', id: '179' },
    { name: 'Reunion', code: 'RE', id: '180' },
    { name: 'Romania', code: 'RO', id: '181' },
    { name: 'Russian Federation', code: 'RU', id: '182' },
    { name: 'RWANDA', code: 'RW', id: '183' },
    { name: 'Saint Helena', code: 'SH', id: '184' },
    { name: 'Saint Kitts and Nevis', code: 'KN', id: '185' },
    { name: 'Saint Lucia', code: 'LC', id: '186' },
    { name: 'Saint Pierre and Miquelon', code: 'PM', id: '187' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC', id: '188' },
    { name: 'Samoa', code: 'WS', id: '189' },
    { name: 'San Marino', code: 'SM', id: '190' },
    { name: 'Sao Tome and Principe', code: 'ST', id: '191' },
    { name: 'Saudi Arabia', code: 'SA', id: '192' },
    { name: 'Senegal', code: 'SN', id: '193' },
    { name: 'Serbia and Montenegro', code: 'CS', id: '194' },
    { name: 'Seychelles', code: 'SC', id: '195' },
    { name: 'Sierra Leone', code: 'SL', id: '196' },
    { name: 'Singapore', code: 'SG', id: '197' },
    { name: 'Slovakia', code: 'SK', id: '198' },
    { name: 'Slovenia', code: 'SI', id: '199' },
    { name: 'Solomon Islands', code: 'SB', id: '200' },
    { name: 'Somalia', code: 'SO', id: '201' },
    { name: 'South Africa', code: 'ZA', id: '202' },
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS', id: '203' },
    { name: 'Spain', code: 'ES', id: '204' },
    { name: 'Sri Lanka', code: 'LK', id: '206' },
    { name: 'Sudan', code: 'SD', id: '207' },
    { name: 'Suriname', code: 'SR', id: '208' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ', id: '209' },
    { name: 'Swaziland', code: 'SZ', id: '210' },
    { name: 'Sweden', code: 'SE', id: '211' },
    { name: 'Switzerland', code: 'CH', id: '212' },
    { name: 'Syrian Arab Republic', code: 'SY', id: '213' },
    { name: 'Taiwan, Province of China', code: 'TW', id: '214' },
    { name: 'Tajikistan', code: 'TJ', id: '215' },
    { name: 'Tanzania, United Republic of', code: 'TZ', id: '216' },
    { name: 'Thailand', code: 'TH', id: '217' },
    { name: 'Timor-Leste', code: 'TL', id: '218' },
    { name: 'Togo', code: 'TG', id: '219' },
    { name: 'Tokelau', code: 'TK', id: '220' },
    { name: 'Tonga', code: 'TO', id: '221' },
    { name: 'Trinidad and Tobago', code: 'TT', id: '222' },
    { name: 'Tunisia', code: 'TN', id: '223' },
    { name: 'Turkey', code: 'TR', id: '224' },
    { name: 'Turkmenistan', code: 'TM', id: '225' },
    { name: 'Turks and Caicos Islands', code: 'TC', id: '226' },
    { name: 'Tuvalu', code: 'TV', id: '227' },
    { name: 'Uganda', code: 'UG', id: '228' },
    { name: 'Ukraine', code: 'UA', id: '229' },
    { name: 'United Arab Emirates', code: 'AE', id: '230' },
    { name: 'United Kingdom', code: 'GB', id: '231' },
    { name: 'United States', code: 'US', id: '232' },
    { name: 'United States Minor Outlying Islands', code: 'UM', id: '233' },
    { name: 'Uruguay', code: 'UY', id: '234' },
    { name: 'Uzbekistan', code: 'UZ', id: '235' },
    { name: 'Vanuatu', code: 'VU', id: '236' },
    { name: 'Venezuela', code: 'VE', id: '237' },
    { name: 'Viet Nam', code: 'VN', id: '238' },
    { name: 'Virgin Islands, British', code: 'VG', id: '239' },
    { name: 'Virgin Islands, U.S.', code: 'VI', id: '240' },
    { name: 'Wallis and Futuna', code: 'WF', id: '241' },
    { name: 'Western Sahara', code: 'EH', id: '242' },
    { name: 'Yemen', code: 'YE', id: '243' },
    { name: 'Zambia', code: 'ZM', id: '244' },
    { name: 'Zimbabwe', code: 'ZW', id: '245' }
]

export const customDateRangeList = [
    {
        "id": 1,
        "dateRange": "Today"
    },
    {
        "id": 2,
        "dateRange": "Yesterday"
    },
    {
        "id": 3,
        "dateRange": "This Week"
    },
    {
        "id": 4,
        "dateRange": "Last Week"
    },
    {
        "id": 5,
        "dateRange": "This Month"
    },
    {
        "id": 6,
        "dateRange": "Last Month"
    },
    {
        "id": 7,
        "dateRange": "This Quarter"
    },
    {
        "id": 8,
        "dateRange": "Last Quarter"
    },
    {
        "id": 9,
        "dateRange": "This Year"
    },
    {
        "id": 10,
        "dateRange": "Last Year"
    },
    {
        "id": 11,
        "dateRange": "Rolling 4 Weeks"
    },
    {
        "id": 12,
        "dateRange": "Rolling 2 Months"
    },
    {
        "id": 13,
        "dateRange": "Rolling 3 Months"
    },
    {
        "id": 14,
        "dateRange": "Rolling 4 Months"
    },
    {
        "id": 15,
        "dateRange": "Rolling 6 Months"
    },
]


export const DateRangeList = [
    {
        "id": 1,
        "dateRange": "Today"
    },
    {
        "id": 2,
        "dateRange": "Yesterday"
    },
    {
        "id": 3,
        "dateRange": "This Week"
    },
    {
        "id": 4,
        "dateRange": "Last Week"
    },
    {
        "id": 5,
        "dateRange": "This Month"
    },
    {
        "id": 6,
        "dateRange": "Last Month"
    },
    {
        "id": 7,
        "dateRange": "This Quarter"
    },
    {
        "id": 8,
        "dateRange": "Last Quarter"
    },
    {
        "id": 9,
        "dateRange": "This Year"
    },
    {
        "id": 10,
        "dateRange": "Last Year"
    },
    {
        "id": 11,
        "dateRange": "Custom Date Range"
    },
]

export const filterByList = [
    {
        "id": 'created_at',
        "type": "Score Date"
    },
    {
        "id": 'event_date',
        "type": "Event Date"
    },
    {
        "id": 'updated_at',
        "type": "Last Edit Date"
    }
]

export const formulaType = [
    {
        id: 10,
        type: 'True'
    },
    {
        id: 20,
        type: 'Potential'
    }
]

export const setButtonBg = (val) => {
    let color = '#FFEDB8';
    if (val === 20) {
        color = '#C5F7CB'

    }
    if (val === 10) {
        color = '#FFD4D5'
    }

    if (val === 30) {
        color = '#FFEDB8'
    }
    return color;
}

export const setButtonText = (val) => {
    const color = '#000000';
    // let color = '#CF9C07';
    // if (val === 20) {
    //     color = '#2E913A'

    // }
    // if (val === 10) {
    //     color = '#5e0505'
    // }

    // if (val === 30) {
    //     color = '#CF9C07'
    // }
    return color;
}

export const trainingRequiredList = [
    {
        "id": "10",
        "name": "Agent Insight"
    },
    {
        "id": "20",
        "name": "Calibrating Scores"
    },
    {
        "id": "30",
        "name": "Dashboards"
    },
    {
        "id": "40",
        "name": "Managing my BeyondQA Account"
    },
    {
        "id": "50",
        "name": "Managing Scorecards"
    },
    {
        "id": "60",
        "name": "Managing Staff"
    },
    {
        "id": "70",
        "name": "Scoring an Agent"
    },
    {
        "id": "80",
        "name": "Undetstanding Analytics"
    },
    {
        "id": "90",
        "name": "Understanding Reports"
    },
    {
        "id": "100",
        "name": "Something other than the above"
    }
]

export const trainingTimeList = [
    {
        "id": "10",
        "name": "04:00am - 04:30am (EDT)"
    },
    {
        "id": "20",
        "name": "04:30am - 05:00am (EDT)"
    },
    {
        "id": "30",
        "name": "05:00am - 05:30am (EDT)"
    },
    {
        "id": "40",
        "name": "05:30am - 06:00am (EDT)"
    },
    {
        "id": "50",
        "name": "06:00am - 06:30am (EDT)"
    },
    {
        "id": "60",
        "name": "06:30am - 07:00am (EDT)"
    },
    {
        "id": "70",
        "name": "07:00am - 07:30am (EDT)"
    },
    {
        "id": "80",
        "name": "07:30am - 08:00am (EDT)"
    },
    {
        "id": "90",
        "name": "08:00am - 08:30am (EDT)"
    },
    {
        "id": "100",
        "name": "08:30am - 09:00am (EDT)"
    },
    {
        "id": "110",
        "name": "09:00am - 09:30am (EDT)"
    },
    {
        "id": "120",
        "name": "09:30am - 10:00am (EDT)"
    },
    {
        "id": "130",
        "name": "10:00am - 10:30am (EDT)"
    },
    {
        "id": "140",
        "name": "10:30am - 11:00am (EDT)"
    },
    {
        "id": "150",
        "name": "11:00am - 11:30am (EDT)"
    },
    {
        "id": "160",
        "name": "11:30am - 12:00pm (EDT)"
    },
    {
        "id": "170",
        "name": "12:00pm - 12:30pm (EDT)"
    },
    {
        "id": "180",
        "name": "12:30pm - 13:00pm (EDT)"
    },
    {
        "id": "190",
        "name": "13:00pm - 13:30pm (EDT)"
    },
    {
        "id": "200",
        "name": "13:30pm - 14:00pm (EDT)"
    }
]


export const setFilterByHeader = (val) => {
    let filterHeader = 'Score Date';
    if (val === "created_at") {
        filterHeader = 'Score Date'

    }
    if (val === 'event_date') {
        filterHeader = 'Event Date'
    }

    if (val === "updated_at") {
        filterHeader = 'Updated Date'
    }
    return filterHeader
}


export const getTaskStatus = val => {
    const taskStatus = { bgColor: "#FFD6AC", textColor: '#9B750B', statusName: "Open" }
    if (val === 10) {
        taskStatus.bgColor = "#FFD6AC"
        taskStatus.textColor = '#9B750B';
        taskStatus.statusName = "Open";
    }
    if (val === 20) {
        taskStatus.bgColor = "#FAE7AF";
        taskStatus.textColor = '#946F00';
        taskStatus.statusName = "In Progress";
    }
    if (val === 30) {
        taskStatus.bgColor = "#E6DBFF";
        taskStatus.textColor = '#9B71FB';
        taskStatus.statusName = "Completed";
    }

    return taskStatus;

}

export const setTaskPriority = val => {
    const taskPriority = { bgColor: "#FFD6AC", textColor: '#000000', priorityName: "High" };
    if (val === 10) {
        taskPriority.bgColor = "#FFD6AC";
        taskPriority.textColor = '#000000'
        taskPriority.priorityName = "High"
    }
    if (val === 20) {
        taskPriority.bgColor = "#C5F7CB";
        taskPriority.textColor = '#000000'
        taskPriority.priorityName = "Low"
    }
    if (val === 30) {
        taskPriority.bgColor = "#FAE7AF";
        taskPriority.textColor = '#000000'
        taskPriority.priorityName = "Medium"
    }
    if (val === 40) {
        taskPriority.bgColor = "#E6DBFF";
        taskPriority.textColor = '#000000'
        taskPriority.priorityName = "Urgent"
    }

    return taskPriority;

}

export const setEmpTargetComment = val => {
    const empTargetComment = { bgColor: '#FC5160', targetName: "Fail ALL" }

    if (val === 20) {
        empTargetComment.bgColor = "#00b894";
        empTargetComment.targetName = "Above Target";
    }
    if (val === 30) {
        empTargetComment.bgColor = "#f1c40f";
        empTargetComment.targetName = "Below Target";
    }
    return empTargetComment;
}

export const getAxisList = (val) => {
    const arr = [
        { id: 10, name: "Question Number" },
        { id: 20, name: "Staff" },
        { id: 30, name: "Team" },
        { id: 40, name: "Evaluator" },
        { id: 50, name: "Scorecard" },
        { id: 60, name: "Group" },
        { id: 70, name: "Supervisors" },
        { id: 80, name: "Event Type" },
        { id: 90, name: "Event Sub Type" },
        { id: 100, name: "Week" },
        { id: 110, name: "Month" },
        { id: 120, name: "Quarter" },
        { id: 130, name: "Section" },
        { id: 140, name: "Reference" },
        { id: 150, name: "Causes" },
    ]

    if (val) {
        return arr?.filter(item => item?.id !== val);
    }
    return arr
}

export const dummyMonthlyTarget = {
    "status": 200,
    "results": [
        {
            "score_card__id": 90,
            "score_card__name": "Test Dec 9",
            "percentage": [
                {
                    "score_card": 90,
                    "created_at__month": 12,
                    "created_at__year": 2022,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 90
                },
                0,
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 91,
            "score_card__name": "Dec1",
            "percentage": [
                {
                    "score_card": 91,
                    "created_at__month": 12,
                    "created_at__year": 2022,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 10
                },
                0,
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 92,
            "score_card__name": "causes_with_sucessrate",
            "percentage": [
                {
                    "score_card": 92,
                    "created_at__month": 12,
                    "created_at__year": 2022,
                    "total_score": 6.0,
                    "max_val": 24.0,
                    "na_total": 10.0,
                    "percentage": 43
                },
                {
                    "score_card": 92,
                    "created_at__month": 1,
                    "created_at__year": 2023,
                    "total_score": 14.0,
                    "max_val": 24.0,
                    "na_total": 10.0,
                    "percentage": 100
                },
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 93,
            "score_card__name": "1.10 Causes by Staff-cop",
            "percentage": [
                {
                    "score_card": 93,
                    "created_at__month": 12,
                    "created_at__year": 2022,
                    "total_score": 24.0,
                    "max_val": 24.0,
                    "na_total": 0.0,
                    "percentage": 100
                },
                0,
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 94,
            "score_card__name": "test non numeric",
            "percentage": [
                {
                    "score_card": 94,
                    "created_at__month": 12,
                    "created_at__year": 2022,
                    "total_score": 92.0,
                    "max_val": 192.0,
                    "na_total": 0.0,
                    "percentage": 48
                },
                0,
                0,
                {
                    "score_card": 94,
                    "created_at__month": 7,
                    "created_at__year": 2023,
                    "total_score": 92.0,
                    "max_val": 192.0,
                    "na_total": 0.0,
                    "percentage": 48
                },
                0
            ]
        },
        {
            "score_card__id": 95,
            "score_card__name": "SucessReport",
            "percentage": [
                {
                    "score_card": 95,
                    "created_at__month": 12,
                    "created_at__year": 2022,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 0
                },
                0,
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 96,
            "score_card__name": "Test 6.3",
            "percentage": [
                0,
                {
                    "score_card": 96,
                    "created_at__month": 1,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 0
                },
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 97,
            "score_card__name": "Test 1.2",
            "percentage": [
                0,
                {
                    "score_card": 97,
                    "created_at__month": 1,
                    "created_at__year": 2023,
                    "total_score": 18.0,
                    "max_val": 60.0,
                    "na_total": 18.0,
                    "percentage": 43
                },
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 99,
            "score_card__name": "Test Kudos 1",
            "percentage": [
                0,
                {
                    "score_card": 99,
                    "created_at__month": 1,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 0
                },
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 100,
            "score_card__name": "Test 3.1",
            "percentage": [
                0,
                {
                    "score_card": 100,
                    "created_at__month": 1,
                    "created_at__year": 2023,
                    "total_score": 65.0,
                    "max_val": 120.0,
                    "na_total": 0.0,
                    "percentage": 54
                },
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 101,
            "score_card__name": "Test 3.1 (New)",
            "percentage": [
                0,
                {
                    "score_card": 101,
                    "created_at__month": 1,
                    "created_at__year": 2023,
                    "total_score": 90.0,
                    "max_val": 120.0,
                    "na_total": 0.0,
                    "percentage": 75
                },
                0,
                0,
                0
            ]
        },
        {
            "score_card__id": 102,
            "score_card__name": "Test causes 1/2/23",
            "percentage": [
                0,
                0,
                {
                    "score_card": 102,
                    "created_at__month": 2,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 0
                },
                0,
                0
            ]
        },
        {
            "score_card__id": 103,
            "score_card__name": "Test 4.6",
            "percentage": [
                0,
                0,
                {
                    "score_card": 103,
                    "created_at__month": 2,
                    "created_at__year": 2023,
                    "total_score": 93.0,
                    "max_val": 93.0,
                    "na_total": 0.0,
                    "percentage": 100
                },
                0,
                0
            ]
        },
        {
            "score_card__id": 104,
            "score_card__name": "Test 4.6 (New)",
            "percentage": [
                0,
                0,
                {
                    "score_card": 104,
                    "created_at__month": 2,
                    "created_at__year": 2023,
                    "total_score": 186.0,
                    "max_val": 198.0,
                    "na_total": 0.0,
                    "percentage": 94
                },
                0,
                {
                    "score_card": 104,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 62.0,
                    "max_val": 66.0,
                    "na_total": 0.0,
                    "percentage": 94
                }
            ]
        },
        {
            "score_card__id": 108,
            "score_card__name": "Test. Comments",
            "percentage": [
                0,
                0,
                0,
                {
                    "score_card": 108,
                    "created_at__month": 7,
                    "created_at__year": 2023,
                    "total_score": 73.0,
                    "max_val": 105.0,
                    "na_total": 0.0,
                    "percentage": 70
                },
                0
            ]
        },
        {
            "score_card__id": 109,
            "score_card__name": "Compliance SC",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 109,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 0
                }
            ]
        },
        {
            "score_card__id": 110,
            "score_card__name": "Test Comp.",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 110,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 10.0,
                    "max_val": 10.0,
                    "na_total": 0.0,
                    "percentage": 100
                }
            ]
        },
        {
            "score_card__id": 111,
            "score_card__name": "Test Comp.-copy",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 111,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 4.0,
                    "max_val": 10.0,
                    "na_total": 0.0,
                    "percentage": 40
                }
            ]
        },
        {
            "score_card__id": 114,
            "score_card__name": "Test SR",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 114,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 9.0,
                    "max_val": 15.0,
                    "na_total": 0.0,
                    "percentage": 60
                }
            ]
        },
        {
            "score_card__id": 115,
            "score_card__name": "Test SR-copy",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 115,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 3.0,
                    "max_val": 7.0,
                    "na_total": 1.0,
                    "percentage": 50
                }
            ]
        },
        {
            "score_card__id": 116,
            "score_card__name": "Test SR-copy-copy",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 116,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 7.0,
                    "max_val": 7.0,
                    "na_total": 0.0,
                    "percentage": 100
                }
            ]
        },
        {
            "score_card__id": 117,
            "score_card__name": "Test SR-copy (STN - NO STN MIX)",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 117,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 7.0,
                    "max_val": 7.0,
                    "na_total": 0.0,
                    "percentage": 100
                }
            ]
        },
        {
            "score_card__id": 123,
            "score_card__name": "Test No Stn",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 123,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 100.0,
                    "na_total": 0.0,
                    "percentage": 0
                }
            ]
        },
        {
            "score_card__id": 124,
            "score_card__name": "Test Stn, No Stn",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 124,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 0.0,
                    "na_total": 0.0,
                    "percentage": 0
                }
            ]
        },
        {
            "score_card__id": 125,
            "score_card__name": "Test Stn, No Stn-copy",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 125,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 90.0,
                    "na_total": 0.0,
                    "percentage": 0
                }
            ]
        },
        {
            "score_card__id": 126,
            "score_card__name": "Test No Stn-copy",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 126,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 0.0,
                    "max_val": 50.0,
                    "na_total": 0.0,
                    "percentage": 0
                }
            ]
        },
        {
            "score_card__id": 127,
            "score_card__name": "Test No Stn",
            "percentage": [
                0,
                0,
                0,
                0,
                {
                    "score_card": 127,
                    "created_at__month": 8,
                    "created_at__year": 2023,
                    "total_score": 30.0,
                    "max_val": 60.0,
                    "na_total": 0.0,
                    "percentage": 50
                }
            ]
        }
    ],
    "all_months": [
        {
            "created_at__month": 12,
            "created_at__year": 2022
        },
        {
            "created_at__month": 1,
            "created_at__year": 2023
        },
        {
            "created_at__month": 2,
            "created_at__year": 2023
        },
        {
            "created_at__month": 7,
            "created_at__year": 2023
        },
        {
            "created_at__month": 8,
            "created_at__year": 2023
        }
    ]
}

export const audioTimelineAgent = [
    { start_duration: 0, end_duration: 30, id: 0 }, { start_duration: 60, end_duration: 120, id: 2 }, { start_duration: 240, end_duration: 300, id: 4 }
]


export const audioTimelineCustomer = [
    { start_duration: 45, end_duration: 60, id: 1 }, { start_duration: 155, end_duration: 210, id: 3 }, { start_duration: 340, end_duration: 365, id: 5 }
]

export const textTranscript = [
    { comment: '<p>this is agent comment</p>', transcipt_id: 1, start_duration: 0.008, end_duration: 13.981, chat: 'Hello Test', user_type: 'AGENT' }, { comment: `<p>this is test comment</p>`, transcipt_id: 2, start_duration: 32.83, end_duration: 62.581, chat: 'this is not here right now', user_type: 'CUSTOMER' },
    { comment: '', transcipt_id: 3, start_duration: 70.0, end_duration: 120.0, chat: "okay maybe would you like to schedule the maintenance appointment that is included in the rent contract the customer doesn't paying any any stretch for this service this is the time for education maintenance", user_type: 'AGENT' },
    { comment: '', transcipt_id: 4, start_duration: 155.0, end_duration: 210.0, chat: "can you give a call", user_type: 'CUSTOMER' },
    { comment: '', transcipt_id: 5, start_duration: 240.0, end_duration: 300.0, chat: 'Sure, We will Reschdeule and call', user_type: 'AGENT' },
    { comment: `<p>this is test comment 2</p>`, transcipt_id: 6, start_duration: 340.0, end_duration: 365.0, chat: 'Thanks Bye', user_type: 'CUSTOMER' }
]

export const textTranscript1 = {
    "count": 1,
    "page_range": [
        1
    ],
    "per_page": 100,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 10,
            "agent": 108,
            "time_of_interaction": "2023-10-17T09:31:47",
            "agent_full_name": "BeyoundQAAI User",
            "agent_first_name": "BeyoundQAAI",
            "agent_last_name": "User",
            "interaction_duration": 207,
            "call_path": "media/score_attachment/testingcauses/after_call/88655915-e464-48c7-a598-044e79e2b59a_20231005T18_46_UTC.wav",
            "direction": "INBOUND",
            "disposition": "WebsiteIssuesFrench",
            "customer_phone": "+18337112333",
            "chat": [
                {
                    "_index": "calls_10",
                    "_type": "_doc",
                    "_id": "ECk3PYsB2sCaDTmhuibq",
                    "_score": 1,
                    "_source": {
                        "call_id": 10,
                        "type": 'AGENT',
                        "text": " Hello. Hello. Hello. Yes. Hi. My name is Maria. We're calling you from contact support 360 on behalf. Can you hold on a minute? Yeah.",
                        "timestamp": "2023-10-17T06:39:04.380395",
                        "start": 0.008,
                        "end": 13.981
                    }
                },
                {
                    "_index": "calls_10",
                    "_type": "_doc",
                    "_id": "ESk3PYsB2sCaDTmhuib8",
                    "_score": 1,
                    "_ignored": [
                        "text.keyword"
                    ],
                    "_source": {
                        "call_id": 10,
                        "text": " I'm sorry. Hello. Yes. Hello. Hi. I'm sorry for that. I had to turn the TV down. What can you do for you? I came in. Okay. Well, I'm calling you on behalf of intercury service to book the maintenance for your air conditioner. Well, you mean maintenance? Okay.",
                        "timestamp": "2023-10-17T06:39:04.443965",
                        "start": 32.83,
                        "end": 62.581
                    }
                },
                {
                    "_index": "calls_10",
                    "_type": "_doc",
                    "_id": "Eik3PYsB2sCaDTmhuib_",
                    "_score": 1,
                    "_source": {
                        "call_id": 10,
                        "text": " You know, I can have that done right now because I'm getting a new formation in September. Can you call me back in September? I mean later on, maybe October. Later on October. Sorry.",
                        "timestamp": "2023-10-17T06:39:04.446703",
                        "start": 64.15,
                        "end": 87.117
                    }
                },
                {
                    "_index": "calls_10",
                    "_type": "_doc",
                    "_id": "Eyk3PYsB2sCaDTmhuyYC",
                    "_score": 1,
                    "_ignored": [
                        "text.keyword"
                    ],
                    "_source": {
                        "call_id": 10,
                        "text": " She wants to be called back in October to both the meantime for your new October call me back. Okay, so is it because you want to. Check the main day air conditioner in October. But I can help you with that right now. Give me a call in October. I'm getting a new furnace put in at the end of September.",
                        "timestamp": "2023-10-17T06:39:04.449757",
                        "start": 89.648,
                        "end": 115.889
                    }
                },
                {
                    "_index": "calls_10",
                    "_type": "_doc",
                    "_id": "FCk3PYsB2sCaDTmhuyYE",
                    "_score": 1,
                    "_source": {
                        "call_id": 10,
                        "text": " So I want to make sure that's done first before I do anything else. Okay, miss. Okay. Okay. Thank you so much for taking my call to them behalf of the manager. Thank you so much. Bye bye.",
                        "timestamp": "2023-10-17T06:39:04.451588",
                        "start": 116.311,
                        "end": 132.899
                    }
                }
            ]
        }
    ]
}

export const dummyCategory = [
    { id: 1, name: 'Group-1' },
    { id: 2, name: 'Group-2' },
    { id: 3, name: 'Group-3' }
]

export const dummyMoments = [
    { id: 1, name: 'Event-1' },
    { id: 2, name: 'Event-2' },
    { id: 3, name: 'Event-3' }
]

export const dummyTimeslot = [
    { id: 1, name: '00:45', value: 45 },
    { id: 2, name: '01:15', value: 75 },
    { id: 3, name: '02:00', value: 120 },
]

export const dummyCallData1 =

{
    status: 200, count: 2, data: [
        { id: 1, comments: 3, agentName: 'Agent User1', duration: '6:13', moments: { positive: 0, negative: 0, neutral: 2 }, timeOfInteraction: 'Oct 9 2023,11:55 a.m.' },
        { id: 2, comments: 3, agentName: 'Agent User2', duration: '6:13', moments: { positive: 0, negative: 1, neutral: 1 }, timeOfInteraction: 'Oct 8 2023,16:23 p.m.' }
    ]
}


export const dummyCallData = {
    "count": 1,
    "page_range": [
        1
    ],
    "per_page": 100,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 10,
            "agent": 108,
            "events": { positive: 0, negative: 0, neutral: 2 },
            "comments": 1,
            "time_of_interaction": "2023-10-17T09:31:47",
            "agent_name": "Agent Name 1",
            "duration": 373.0
        },
        {
            "id": 11,
            "agent": 108,
            "events": { positive: 1, negative: 1, neutral: 2 },
            "comments": 3,
            "time_of_interaction": "2023-10-13T09:31:47",
            "agent_name": "Agent Name 2",
            "duration": 373.0
        }
    ]
}


// ====================================================================================================================================

export const questionDetails1 = {
    "id": 56,
    "score_card": {
        "id": 56,
        "category": {
            "id": 17,
            "name": "Inbound Call",
            "is_active": true,
            "is_deleted": false,
            "created_at": "2023-04-24T07:25:29.863874"
        },
        "groups": {
            "id": 18,
            "name": "DemoGroup"
        },
        "questions": [],
        "custom_fields": [],
        "based_on_section_question": [
            {
                "section_name": "sec1",
                "section_id": 65,
                "questions": [
                    {
                        "id": 172,
                        "score_card": 56,
                        "auto_qa_answer": null,
                        "question": "Dev",
                        "index": 1,
                        "question_tip": "",
                        "answers": [
                            {
                                "id": 253,
                                "score_card": 56,
                                "score_card_questions": 172,
                                "answer": "yes",
                                "answer_value": 10,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": true,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": true,
                                "answer_value_id": 298,
                                "created_at": "2022-12-01T03:22:33.761591"
                            },
                            {
                                "id": 254,
                                "score_card": 56,
                                "score_card_questions": 172,
                                "answer": "no",
                                "answer_value": 0,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": false,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": true,
                                "answer_value_id": 299,
                                "created_at": "2022-12-01T03:22:33.796358"
                            }
                        ],
                        "section": 65,
                        "section_name": "sec1",
                        "section_type": "Default",
                        "weighting": 1,
                        "preview_score": "",
                        "fail_section": [
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 253,
                                "answer": "yes",
                                "is_selected": true
                            },
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 254,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "fail_all": [
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 253,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 254,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "not_applicable": [
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 253,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 254,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes": [
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 253,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 172,
                                "score_card": 56,
                                "question_id": 254,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes_flag": false,
                        "max_score": 10,
                        "created_at": "2022-12-01T03:21:51.279682",
                        "exclude_na": false
                    }
                ]
            },
            {
                "section_name": "sec2",
                "section_id": 66,
                "questions": [
                    {
                        "id": 173,
                        "score_card": 56,
                        "auto_qa_answer": null,
                        "question": "test",
                        "index": 2,
                        "question_tip": "",
                        "answers": [
                            {
                                "id": 255,
                                "score_card": 56,
                                "score_card_questions": 173,
                                "answer": "yes",
                                "answer_value": 1,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": false,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": true,
                                "answer_value_id": 300,
                                "created_at": "2022-12-01T03:23:17.827095"
                            },
                            {
                                "id": 256,
                                "score_card": 56,
                                "score_card_questions": 173,
                                "answer": "no",
                                "answer_value": 0,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": true,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": true,
                                "answer_value_id": 301,
                                "created_at": "2022-12-01T03:23:17.863239"
                            }
                        ],
                        "section": 66,
                        "section_name": "sec2",
                        "section_type": "Default",
                        "weighting": 1,
                        "preview_score": "",
                        "fail_section": [
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 255,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 256,
                                "answer": "no",
                                "is_selected": true
                            }
                        ],
                        "fail_all": [
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 255,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 256,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "not_applicable": [
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 255,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 256,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes": [
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 255,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 173,
                                "score_card": 56,
                                "question_id": 256,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes_flag": false,
                        "max_score": 1,
                        "created_at": "2022-12-01T03:21:51.290611",
                        "exclude_na": false
                    }
                ]
            },
            {
                "section_name": "sec3",
                "section_id": 67,
                "questions": [
                    {
                        "id": 174,
                        "score_card": 56,
                        "auto_qa_answer": null,
                        "question": "cloud",
                        "index": 3,
                        "question_tip": "",
                        "answers": [
                            {
                                "id": 257,
                                "score_card": 56,
                                "score_card_questions": 174,
                                "answer": "yes",
                                "answer_value": 30,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": true,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": true,
                                "answer_value_id": 302,
                                "created_at": "2022-12-01T03:23:17.888330"
                            },
                            {
                                "id": 258,
                                "score_card": 56,
                                "score_card_questions": 174,
                                "answer": "no",
                                "answer_value": 0,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": false,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": false,
                                "answer_value_id": 303,
                                "created_at": "2022-12-01T03:23:17.911437"
                            }
                        ],
                        "section": 67,
                        "section_name": "sec3",
                        "section_type": "Default",
                        "weighting": 1,
                        "preview_score": "",
                        "fail_section": [
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 257,
                                "answer": "yes",
                                "is_selected": true
                            },
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 258,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "fail_all": [
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 257,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 258,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "not_applicable": [
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 257,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 258,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes": [
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 257,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 174,
                                "score_card": 56,
                                "question_id": 258,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes_flag": false,
                        "max_score": 30,
                        "created_at": "2022-12-01T03:21:51.295856",
                        "exclude_na": false
                    }
                ]
            },
            {
                "section_name": "sec4",
                "section_id": 68,
                "questions": [
                    {
                        "id": 175,
                        "score_card": 56,
                        "auto_qa_answer": null,
                        "question": "sales",
                        "index": 4,
                        "question_tip": "",
                        "answers": [
                            {
                                "id": 259,
                                "score_card": 56,
                                "score_card_questions": 175,
                                "answer": "yes",
                                "answer_value": 0,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": false,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": false,
                                "answer_value_id": 304,
                                "created_at": "2022-12-01T03:23:17.938845"
                            },
                            {
                                "id": 260,
                                "score_card": 56,
                                "score_card_questions": 175,
                                "answer": "no",
                                "answer_value": 0,
                                "answer_status": {
                                    "fail_all": false,
                                    "fail_Section": true,
                                    "not_applicable": false
                                },
                                "answers_causes_required": false,
                                "is_selected": true,
                                "answer_value_id": 305,
                                "created_at": "2022-12-01T03:23:17.960344"
                            }
                        ],
                        "section": 68,
                        "section_name": "sec4",
                        "section_type": "Default",
                        "weighting": 1,
                        "preview_score": "",
                        "fail_section": [
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 259,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 260,
                                "answer": "no",
                                "is_selected": true
                            }
                        ],
                        "fail_all": [
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 259,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 260,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "not_applicable": [
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 259,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 260,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes": [
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 259,
                                "answer": "yes",
                                "is_selected": false
                            },
                            {
                                "id": 175,
                                "score_card": 56,
                                "question_id": 260,
                                "answer": "no",
                                "is_selected": false
                            }
                        ],
                        "score_card_causes_flag": false,
                        "max_score": 0,
                        "created_at": "2022-12-01T03:21:51.312011",
                        "exclude_na": false
                    }
                ]
            }
        ],
        "score_card_question_no_view": false,
        "is_editable": false,
        "score_card_tips": [],
        "is_empty_question_exists": false,
        "created_at": "2022-12-01T03:21:40.903672",
        "updated_at": "2022-12-01T10:49:24.236195",
        "name": "Dec1",
        "type": "Standard",
        "location": null,
        "description": null,
        "is_locked": true,
        "is_archive": false,
        "number_of_questions": 4,
        "total_weighting": 4,
        "total_score": "41.00",
        "target": 0,
        "version": 1,
        "is_deleted": false,
        "event_reference": false,
        "event_type": false,
        "event_duration": false,
        "blind_scoring": false,
        "fail_all": false,
        "point_failure_email": false,
        "fail_emails_list": null,
        "compliance_fail_email": false,
        "compliance_emails_list": null,
        "fail_section_subtract": false,
        "custom_report_label": false,
        "report_label": null,
        "agent_dashboard": false,
        "short_label": null,
        "non_numeric": false,
        "self_score": false,
        "populate_event_datetime": false,
        "multi_comments_question": false,
        "multi_causes_question": false,
        "question_no_view": false,
        "success_rate_ans_choices": false,
        "success_rate_ans_positive_label": "Met",
        "success_rate_ans_negative_label": "Not Met"
    },
    "target": 0,
    "teams": null,
    "teams_list": [
        {
            "name": "Demo Team",
            "id": 19
        },
        {
            "name": "teamlog",
            "id": 32
        }
    ],
    "supervisor": null,
    "supervisor_details": [
        {
            "id": 70,
            "user__first_name": "manjusha",
            "user__last_name": "supervisor"
        },
        {
            "id": 44,
            "user__first_name": "Ranga",
            "user__last_name": "Swamy Best Tester CP-360"
        }
    ],
    "employees_details": [
        {
            "id": 47,
            "user__first_name": "muthu",
            "user__last_name": "m"
        },
        {
            "id": 63,
            "user__first_name": "test",
            "user__last_name": "employee"
        },
        {
            "id": 58,
            "user__first_name": "d",
            "user__last_name": "d"
        },
        {
            "id": 46,
            "user__first_name": "QA",
            "user__last_name": "Testing"
        },
        {
            "id": 49,
            "user__first_name": "django",
            "user__last_name": "python"
        },
        {
            "id": 69,
            "user__first_name": "manjusha",
            "user__last_name": "employee"
        },
        {
            "id": 67,
            "user__first_name": "jeep",
            "user__last_name": "cook"
        },
        {
            "id": 70,
            "user__first_name": "manjusha",
            "user__last_name": "supervisor"
        },
        {
            "id": 107,
            "user__first_name": "Rajendra",
            "user__last_name": "Varma"
        },
        {
            "id": 91,
            "user__first_name": "sam",
            "user__last_name": "yales"
        },
        {
            "id": 44,
            "user__first_name": "Ranga",
            "user__last_name": "Swamy Best Tester CP-360"
        },
        {
            "id": 108,
            "user__first_name": "BeyoundQAAI",
            "user__last_name": "User"
        }
    ],
    "event_date": null,
    "event_time": null,
    "event_duration": null,
    "event_type": null,
    "event_sub_type": null,
    "comments": [],
    "causes": [],
    "refrence": null,
    "random_user": "muthu m",
    "draft_score": 2255,
    "calibration_draft": null,
    "new_event_type": [],
    "new_custom_fields": [],
    "created_at": "2022-12-01T03:21:40.928081"
}

// ===================================================================================================================================

export const questionDetails = {
    'event_duration': null,
    'event_type': [
        {
            "id": 213,
            "score_card": 251,
            "event_type": "Event 1",
            "event_sub_type": "Event Sub 1",
            "created_at": "2023-03-09T07:51:17.765387"
        }
    ],
    'refrence': null,
    "customFields": [
        {
            "id": 256,
            "score_card": 417,
            "name": "CF 1",
            "type": "DATA TAG",
            "data_tags": [
                {
                    "id": 341,
                    "score_card": 417,
                    "custom_fields": 256,
                    "name": "opt 1",
                    "created_at": "2023-04-28T05:19:27.258694"
                },
                {
                    "id": 340,
                    "score_card": 417,
                    "custom_fields": 256,
                    "name": " opt 2",
                    "created_at": "2023-04-28T05:19:27.254780"
                }
            ],
            "is_required": true,
            "created_at": "2023-04-28T05:19:27.243570"
        },
        {
            "id": 255,
            "score_card": 417,
            "name": "CF 2",
            "type": "DATA TAG",
            "data_tags": [
                {
                    "id": 339,
                    "score_card": 417,
                    "custom_fields": 255,
                    "name": "opt 3",
                    "created_at": "2023-04-28T05:19:00.172310"
                },
                {
                    "id": 338,
                    "score_card": 417,
                    "custom_fields": 255,
                    "name": " opt 4",
                    "created_at": "2023-04-28T05:19:00.168897"
                }
            ],
            "is_required": false,
            "created_at": "2023-04-28T05:19:00.158877"
        },
        {
            "id": 254,
            "score_card": 417,
            "name": "CF 3",
            "type": "TEXT INPUT",
            "data_tags": [],
            "is_required": true,
            "created_at": "2023-04-28T05:18:06.530086"
        },
        {
            "id": 253,
            "score_card": 417,
            "name": "CF 4",
            "type": "TEXT INPUT",
            "data_tags": [],
            "is_required": false,
            "created_at": "2023-04-28T05:17:53.887188"
        }
    ],
    "causes": [], details: [
        {
            "section_name": "s1",
            "section_id": 1082,
            "questions": [
                {
                    "id": 5690,
                    "score_card": 417,
                    "question": "q1",
                    "index": 1,
                    "question_tip": "",
                    "answers": [
                        {
                            "id": 13456,
                            "score_card": 417,
                            "score_card_questions": 5690,
                            "answer": "yes",
                            "answer_value": 4,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15786,
                            "created_at": "2023-04-28T05:14:15.352625"
                        },
                        {
                            "id": 13457,
                            "score_card": 417,
                            "score_card_questions": 5690,
                            "answer": "no",
                            "answer_value": 1,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15787,
                            "created_at": "2023-04-28T05:14:15.366267"
                        }
                    ],
                    "section": 1082,
                    "section_name": "s1",
                    "section_type": "Default",
                    "weighting": 1,
                    "preview_score": "",
                    "fail_section": [
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13456,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13457,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "fail_all": [
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13456,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13457,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "not_applicable": [
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13456,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13457,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes": [
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13456,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5690,
                            "score_card": 417,
                            "question_id": 13457,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes_flag": false,
                    "max_score": 4,
                    "created_at": "2023-04-28T05:13:08.492256",
                    "exclude_na": false
                },
                {
                    "id": 5692,
                    "score_card": 417,
                    "question": "q3",
                    "index": 3,
                    "question_tip": "",
                    "answers": [
                        {
                            "id": 13466,
                            "score_card": 417,
                            "score_card_questions": 5692,
                            "answer": "yes",
                            "answer_value": 3,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15796,
                            "created_at": "2023-04-28T05:15:21.422207"
                        },
                        {
                            "id": 13467,
                            "score_card": 417,
                            "score_card_questions": 5692,
                            "answer": "no",
                            "answer_value": 1,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15797,
                            "created_at": "2023-04-28T05:15:21.427856"
                        }
                    ],
                    "section": 1082,
                    "section_name": "s1",
                    "section_type": "Default",
                    "weighting": 1,
                    "preview_score": "",
                    "fail_section": [
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13466,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13467,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "fail_all": [
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13466,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13467,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "not_applicable": [
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13466,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13467,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes": [
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13466,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5692,
                            "score_card": 417,
                            "question_id": 13467,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes_flag": false,
                    "max_score": 3,
                    "created_at": "2023-04-28T05:13:08.496222",
                    "exclude_na": false
                }
            ]
        },
        {
            "section_name": "s2",
            "section_id": 1083,
            "questions": [
                {
                    "id": 5691,
                    "score_card": 417,
                    "question": "q2",
                    "index": 2,
                    "question_tip": "",
                    "answers": [
                        {
                            "id": 13464,
                            "score_card": 417,
                            "score_card_questions": 5691,
                            "answer": "yes",
                            "answer_value": 2,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15794,
                            "created_at": "2023-04-28T05:15:21.407722"
                        },
                        {
                            "id": 13465,
                            "score_card": 417,
                            "score_card_questions": 5691,
                            "answer": "no",
                            "answer_value": 1,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15795,
                            "created_at": "2023-04-28T05:15:21.415031"
                        }
                    ],
                    "section": 1083,
                    "section_name": "s2",
                    "section_type": "Default",
                    "weighting": 1,
                    "preview_score": "",
                    "fail_section": [
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13464,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13465,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "fail_all": [
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13464,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13465,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "not_applicable": [
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13464,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13465,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes": [
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13464,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5691,
                            "score_card": 417,
                            "question_id": 13465,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes_flag": false,
                    "max_score": 2,
                    "created_at": "2023-04-28T05:13:08.494765",
                    "exclude_na": false
                },
                {
                    "id": 5693,
                    "score_card": 417,
                    "question": "q4",
                    "index": 4,
                    "question_tip": "",
                    "answers": [
                        {
                            "id": 13468,
                            "score_card": 417,
                            "score_card_questions": 5693,
                            "answer": "yes",
                            "answer_value": 0,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15798,
                            "created_at": "2023-04-28T05:15:21.434147"
                        },
                        {
                            "id": 13469,
                            "score_card": 417,
                            "score_card_questions": 5693,
                            "answer": "no",
                            "answer_value": 0,
                            "answer_status": {
                                "fail_all": false,
                                "fail_Section": false,
                                "not_applicable": false
                            },
                            "answers_causes_required": false,
                            "is_selected": false,
                            "answer_value_id": 15799,
                            "created_at": "2023-04-28T05:15:21.439684"
                        }
                    ],
                    "section": 1083,
                    "section_name": "s2",
                    "section_type": "Default",
                    "weighting": 1,
                    "preview_score": "",
                    "fail_section": [
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13468,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13469,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "fail_all": [
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13468,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13469,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "not_applicable": [
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13468,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13469,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes": [
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13468,
                            "answer": "yes",
                            "is_selected": false
                        },
                        {
                            "id": 5693,
                            "score_card": 417,
                            "question_id": 13469,
                            "answer": "no",
                            "is_selected": false
                        }
                    ],
                    "score_card_causes_flag": false,
                    "max_score": 0,
                    "created_at": "2023-04-28T05:13:08.497722",
                    "exclude_na": false
                }
            ]
        }
    ]
}

export const dummyColumnData = [
    { name: 'Complaint', id: '1', subColumnData: [{ name: 'complaint1', id: '1_1' }, { name: 'complaint2', id: '1_2' }, { name: 'complaint3', id: '1_3' }] },
    { name: 'Products OverAll', id: '2', subColumnData: [{ name: 'Electrical', id: '2_1' }, { name: 'Mechanical', id: '2_2' }] },
    { name: 'Dead Air %', id: '3' }, { name: 'Hold Time Violation', id: '4' }
]

export const dummyAutoQAData = [
    { formName: 'Outbound Maintenance Inbound Template - 2023', question: '1.1 - Greeted caller with company name & introduced yourself', event: '1.1 - Greeted caller with company name & introduced yourself', true_response: 'yes', false_response: 'no', status: 10 },
    { formName: 'Outbound Maintenance Inbound Template - 2023', question: '1.2 - Tone was inviting, and acknowledgement phrase was used', event: '1.2 - Tone was inviting, and acknowledgement phrase was used', true_response: 'yes', false_response: 'yes', status: 20 },
    { formName: 'Outbound Maintenance Inbound Template - 2023', question: '2.1 - Verified customer', event: '2.1 - Verified customer', true_response: 'no', false_response: 'no', status: 30 }
]


export const metaDropDownList = [
    { name: 'External Channel Type', value: 'external_channel_type' },
    { name: 'Voice signature', value: 'voice_signature' },
    { name: 'Service', value: 'service' },
    { name: 'Agent last name', value: 'agent_last_name' },
    { name: 'Agent first name', value: 'agent_first_name' },
    { name: 'Direction', value: 'direction' },
    { name: 'Export status', value: 'export_status' },
    { name: 'Type', value: 'type' },
    { name: 'Screen recording', value: 'screen_recording' },
    { name: 'Disposition', value: 'disposition' },
    { name: 'Flagged', value: 'flagged' },
    { name: 'Agent loginid', value: 'agent_loginid' },
]

export const durationDropDownList = [
    { name: 'Call Duration', value: 'Call Duration' },
    // { name: 'Between', value: 'between' },
]
export const dummySelectValue = [
    { name: 'emp1', id: 1 }, { name: 'emp2', id: 2 }, { name: 'emp3', id: 3 }, { name: 'emp4', id: 4 }
]

export const dummyCalldetailFilter = [
    {
        "category": 1,
        "events": [
            "\"Hello\"",
            "\"I'm sorry\"",
            "pay the bill",
            "HI",
            "voicemail"
        ],
        "events_ids": [
            2,
            3,
            5,
            6,
            10
        ],
        "status": [
            true,
            false,
            false,
            false,
            true
        ]
    },
    {
        "category": 2,
        "events": [
            "lllll"
        ],
        "events_ids": [
            11
        ],
        "status": [
            false
        ]
    }
]

export const sampleCoachingArea = {
    "status": 200,
    "data": [
        {
            "id": 1,
            "name": "Call Handling"
        },
        {
            "id": 2,
            "name": "Performance"
        },
        {
            "id": 3,
            "name": "Timing"
        }
    ]
}

export const sampleCallListing = {
    "status": 200,
    "count": 4,
    "page_range": [
        1
    ],
    "per_page": 100,
    "page": "1",
    "results": [
        {
            "id": 83,
            "agent": 108,
            "time_of_interaction": "2023-10-05T12:44:27",
            "interaction_duration": 240,
            "latest_coaching_date": "2024-03-18T06:05:26.416006"
        },
        {
            "id": 77,
            "agent": 108,
            "time_of_interaction": "2023-10-05T12:44:10",
            "interaction_duration": 328,
            "latest_coaching_date": 240
        },
        {
            "id": 76,
            "agent": 108,
            "time_of_interaction": "2023-10-05T12:43:56",
            "interaction_duration": 58,
            "latest_coaching_date": 240
        },
        {
            "id": 74,
            "agent": 108,
            "time_of_interaction": "2023-10-05T12:36:11",
            "interaction_duration": 1028,
            "latest_coaching_date": 240
        }
    ]
}

export const sampleScorecardListing = {
    "status": 200,
    "data": [
        {
            "score_card": 1249,
            "total": 2,
            "sc_name": "Dec1-Evaluation"
        },
        {
            "score_card": 1252,
            "total": 1,
            "sc_name": "Test multiple Causes"
        },
        {
            "score_card": 1254,
            "total": 10,
            "sc_name": "TestAutoQA"
        },
        {
            "score_card": 1255,
            "total": 5,
            "sc_name": "melbourne"
        },
        {
            "score_card": 1256,
            "total": 4,
            "sc_name": "AI Test"
        }
    ]
}

export const sampleScoreLisitng = {
    "status": 200,
    "count": 5,
    "page_range": [
        1
    ],
    "per_page": 100,
    "page": "1",
    "results": [
        {
            "id": 2379,
            "score_card__name": "melbourne",
            "percentage": 0,
            "score_result": 20,
            "created_at": "2024-03-01T03:54:31.967449",
            "evaluator_name": "global admin",
            "latest_coaching_date": "2024-03-18T04:03:41.456286"
        },
        {
            "id": 2378,
            "score_card__name": "melbourne",
            "percentage": 0,
            "score_result": 20,
            "created_at": "2024-03-01T03:30:04.795976",
            "evaluator_name": "global admin",
            "latest_coaching_date": "2024-03-15T10:41:58.247572"
        },
        {
            "id": 2376,
            "score_card__name": "melbourne",
            "percentage": 0,
            "score_result": 20,
            "created_at": "2024-03-01T03:27:43.209658",
            "evaluator_name": "global admin",
            "latest_coaching_date": "2024-03-15T10:41:58.247572"
        },
        {
            "id": 2375,
            "score_card__name": "melbourne",
            "percentage": 0,
            "score_result": 20,
            "created_at": "2024-02-29T11:26:17.393675",
            "evaluator_name": "global admin",
            "latest_coaching_date": "2024-03-18T04:28:31.122841"
        },
        {
            "id": 2361,
            "score_card__name": "melbourne",
            "percentage": 0,
            "score_result": 20,
            "created_at": "2024-02-05T03:17:58.088724",
            "evaluator_name": "global admin",
            "latest_coaching_date": "2024-03-18T06:05:26.416006"
        }
    ]
}


export const sampleCoachingSessionListing = {
    "count": 43,
    "page_range": [
        1
    ],
    "per_page": 100,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 55,
            "created_at": "2024-03-18T05:15:49.033067",
            "updated_at": "2024-03-18T10:56:01.070133",
            "feedback": "1",
            "action_plan": "2",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-18T05:15:49.032731",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2361,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-02-05T03:17:58.088724"
                }
            ]
        },
        {
            "id": 53,
            "created_at": "2024-03-18T04:28:31.123251",
            "updated_at": "2024-03-18T04:28:31.123259",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-18T04:28:31.122841",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2375,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-02-29T11:26:17.393675"
                }
            ]
        },
        {
            "id": 52,
            "created_at": "2024-03-18T04:03:41.456607",
            "updated_at": "2024-03-18T04:03:41.456615",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-18T04:03:41.456286",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 50,
            "created_at": "2024-03-15T11:23:31.991515",
            "updated_at": "2024-03-15T11:23:31.991524",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": "2024-03-18",
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2308,
                    "scorecard_name": "Dec1-Evaluation",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-01-24T05:55:07.928303"
                }
            ]
        },
        {
            "id": 48,
            "created_at": "2024-03-15T10:41:58.247941",
            "updated_at": "2024-03-15T10:41:58.247947",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-15T10:41:58.247572",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 47,
            "created_at": "2024-03-15T10:40:36.443217",
            "updated_at": "2024-03-15T10:40:36.443224",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-15T10:40:36.442885",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 46,
            "created_at": "2024-03-15T10:40:01.662269",
            "updated_at": "2024-03-15T10:40:01.662299",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": "2024-03-15T10:40:01.661580",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 45,
            "created_at": "2024-03-15T10:39:15.827537",
            "updated_at": "2024-03-15T10:39:15.827548",
            "feedback": null,
            "action_plan": null,
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 44,
            "created_at": "2024-03-15T10:36:07.244497",
            "updated_at": "2024-03-15T10:36:07.244509",
            "feedback": null,
            "action_plan": null,
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 43,
            "created_at": "2024-03-15T10:35:50.421234",
            "updated_at": "2024-03-15T10:35:50.421267",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 42,
            "created_at": "2024-03-15T10:10:34.869759",
            "updated_at": "2024-03-15T10:10:34.869769",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 41,
            "created_at": "2024-03-15T10:10:23.581695",
            "updated_at": "2024-03-15T10:10:23.581703",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": "2024-03-15T10:10:23.581255",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 40,
            "created_at": "2024-03-15T10:10:02.414271",
            "updated_at": "2024-03-15T10:10:02.414288",
            "feedback": null,
            "action_plan": null,
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 39,
            "created_at": "2024-03-15T10:07:05.216380",
            "updated_at": "2024-03-15T10:07:05.216394",
            "feedback": null,
            "action_plan": null,
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": "2024-03-15T10:07:05.216013",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 38,
            "created_at": "2024-03-15T10:06:42.009896",
            "updated_at": "2024-03-15T10:06:42.009905",
            "feedback": null,
            "action_plan": null,
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": "2024-03-15T10:06:42.009329",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 37,
            "created_at": "2024-03-15T10:06:04.112628",
            "updated_at": "2024-03-15T10:06:04.112637",
            "feedback": "1",
            "action_plan": "1",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-15T10:06:04.112103",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 36,
            "created_at": "2024-03-15T09:50:23.809843",
            "updated_at": "2024-03-15T09:50:23.809856",
            "feedback": "",
            "action_plan": "",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-15T09:50:23.809153",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 35,
            "created_at": "2024-03-15T09:39:37.004416",
            "updated_at": "2024-03-15T09:39:37.004425",
            "feedback": null,
            "action_plan": "AP 12345",
            "follow_up_date": "2024-03-18",
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 34,
            "created_at": "2024-03-15T09:13:04.924357",
            "updated_at": "2024-03-15T09:13:04.924370",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": "2024-03-18",
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 33,
            "created_at": "2024-03-15T09:12:21.296812",
            "updated_at": "2024-03-15T09:12:21.296825",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-15T09:12:21.296341",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 32,
            "created_at": "2024-03-15T09:09:10.385499",
            "updated_at": "2024-03-15T09:09:10.385506",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-15T09:09:10.385068",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 31,
            "created_at": "2024-03-15T09:07:36.122318",
            "updated_at": "2024-03-15T09:07:36.122326",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": "2024-03-15T09:07:36.121960",
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 30,
            "created_at": "2024-03-15T05:44:19.955681",
            "updated_at": "2024-03-15T05:44:19.955696",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": "2024-03-18",
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 29,
            "created_at": "2024-03-15T05:41:36.215524",
            "updated_at": "2024-03-15T05:41:36.215536",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 28,
            "created_at": "2024-03-14T10:36:05.347833",
            "updated_at": "2024-03-14T10:36:05.347853",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [
                {
                    "id": 1,
                    "name": "Call Handling"
                },
                {
                    "id": 2,
                    "name": "Performance"
                }
            ],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 27,
            "created_at": "2024-03-13T10:28:06.835567",
            "updated_at": "2024-03-13T10:28:06.835581",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 26,
            "created_at": "2024-03-13T10:27:22.819958",
            "updated_at": "2024-03-13T10:27:22.819969",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 25,
            "created_at": "2024-03-13T10:27:01.923478",
            "updated_at": "2024-03-13T10:27:01.923488",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 24,
            "created_at": "2024-03-13T10:20:53.938251",
            "updated_at": "2024-03-13T10:20:53.938260",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 23,
            "created_at": "2024-03-13T10:19:24.577761",
            "updated_at": "2024-03-13T10:19:24.577770",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 22,
            "created_at": "2024-03-13T10:17:46.478861",
            "updated_at": "2024-03-13T10:17:46.478872",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 21,
            "created_at": "2024-03-13T10:04:47.866356",
            "updated_at": "2024-03-13T10:04:47.866376",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 20,
            "created_at": "2024-03-13T10:02:33.567009",
            "updated_at": "2024-03-13T10:02:33.567023",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 19,
            "created_at": "2024-03-13T10:01:30.975401",
            "updated_at": "2024-03-13T10:01:30.975435",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 18,
            "created_at": "2024-03-13T09:59:57.185639",
            "updated_at": "2024-03-13T09:59:57.185652",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [],
            "scores": []
        },
        {
            "id": 17,
            "created_at": "2024-03-13T09:58:36.396446",
            "updated_at": "2024-03-13T09:58:36.396495",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": true,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 16,
            "created_at": "2024-03-13T08:22:51.481850",
            "updated_at": "2024-03-13T08:22:51.481859",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2376,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:27:43.209658"
                },
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 15,
            "created_at": "2024-03-13T08:22:33.351544",
            "updated_at": "2024-03-13T08:22:33.351557",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 14,
            "created_at": "2024-03-13T08:15:00.406560",
            "updated_at": "2024-03-13T08:15:00.406573",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                },
                {
                    "id": 84,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T14:45:08",
                    "interaction_duration": 207
                }
            ],
            "scores": [
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 13,
            "created_at": "2024-03-13T07:49:42.334588",
            "updated_at": "2024-03-13T07:49:42.334599",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": {
                "id": 2,
                "name": "global admin"
            },
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                }
            ],
            "scores": [
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 12,
            "created_at": "2024-03-13T07:41:07.381355",
            "updated_at": "2024-03-13T07:41:07.381373",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": null,
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                }
            ],
            "scores": [
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 2,
            "created_at": "2024-03-13T05:32:03.445920",
            "updated_at": "2024-03-13T05:32:03.445930",
            "feedback": "12345",
            "action_plan": "AP 12345",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": null,
            "coaching_areas": [],
            "calls": [
                {
                    "id": 83,
                    "agent": 108,
                    "time_of_interaction": "2023-10-05T12:44:27",
                    "interaction_duration": null
                }
            ],
            "scores": [
                {
                    "id": 2378,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:30:04.795976"
                },
                {
                    "id": 2379,
                    "scorecard_name": "melbourne",
                    "evaluator_name": "global admin",
                    "percentage": "0.00",
                    "score_result": 20,
                    "created_at": "2024-03-01T03:54:31.967449"
                }
            ]
        },
        {
            "id": 1,
            "created_at": "2024-03-13T05:14:16.175736",
            "updated_at": "2024-03-13T05:14:16.175756",
            "feedback": "123",
            "action_plan": "AP 123",
            "follow_up_date": null,
            "is_draft": false,
            "coaching_date": null,
            "agent": {
                "id": 108,
                "name": "BeyoundQAAI User"
            },
            "coach": null,
            "coaching_areas": [],
            "calls": [],
            "scores": []
        }
    ]
}


export const masterConfigEmpTable = {
    "staff_data": {
        "status": 200,
        "count": 3,
        "page_range": [
            1
        ],
        "per_page": 100,
        "page": 1,
        "results": [
            {
                "id": 49,
                "staff_type": 10,
                "staff_name": "django python",
                "no_evaluation": 10,
                "call_count": 0
            },
            {
                "id": 62,
                "staff_type": 10,
                "staff_name": "nathu nath",
                "no_evaluation": 10,
                "call_count": 0
            },
            {
                "id": 114,
                "staff_type": 10,
                "staff_name": "sravan k",
                "no_evaluation": 10,
                "call_count": 0
            }
        ]
    },
    "total_user": 3
}

export const masterConfigEmpDialogTable = {
    "staff_data": {
        "status": 200,
        "count": 21,
        "page_range": [
            1
        ],
        "per_page": 100,
        "page": 1,
        "results": [
            {
                "id": 86,
                "staff_type": 10,
                "staff_name": "emp2 mrch",
                "call_count": 0
            },
            {
                "id": 47,
                "staff_type": 10,
                "staff_name": "muthu m",
                "call_count": 0
            },
            {
                "id": 61,
                "staff_type": 10,
                "staff_name": "Anadhu Drapers",
                "call_count": 0
            },
            {
                "id": 64,
                "staff_type": 10,
                "staff_name": "Clasius Hank",
                "call_count": 0
            },
            {
                "id": 56,
                "staff_type": 10,
                "staff_name": "dashboard 3",
                "call_count": 0
            },
            {
                "id": 83,
                "staff_type": 10,
                "staff_name": "emp test2",
                "call_count": 0
            },
            {
                "id": 69,
                "staff_type": 10,
                "staff_name": "manjusha employee",
                "call_count": 0
            },
            {
                "id": 75,
                "staff_type": 10,
                "staff_name": "test emp feb22",
                "call_count": 0
            },
            {
                "id": 74,
                "staff_type": 20,
                "staff_name": "testsup123 testsup123",
                "call_count": 0
            },
            {
                "id": 70,
                "staff_type": 20,
                "staff_name": "manjusha supervisor",
                "call_count": 0
            },
            {
                "id": 87,
                "staff_type": 10,
                "staff_name": "ann user",
                "call_count": 0
            },
            {
                "id": 94,
                "staff_type": 20,
                "staff_name": "testsuper visor",
                "call_count": 0
            },
            {
                "id": 92,
                "staff_type": 20,
                "staff_name": "sup_April Test",
                "call_count": 0
            },
            {
                "id": 93,
                "staff_type": 10,
                "staff_name": "test name",
                "call_count": 0
            },
            {
                "id": 109,
                "staff_type": 10,
                "staff_name": "new Staff",
                "call_count": 0
            },
            {
                "id": 110,
                "staff_type": 20,
                "staff_name": "Supe-New Test",
                "call_count": 0
            },
            {
                "id": 112,
                "staff_type": 10,
                "staff_name": "Aug Test",
                "call_count": 0
            },
            {
                "id": 111,
                "staff_type": 10,
                "staff_name": "Daniel Sebastian",
                "call_count": 0
            },
            {
                "id": 72,
                "staff_type": 10,
                "staff_name": "feb test",
                "call_count": 0
            },
            {
                "id": 73,
                "staff_type": 10,
                "staff_name": "testemp emptest",
                "call_count": 0
            },
            {
                "id": 114,
                "staff_type": 10,
                "staff_name": "sravan k",
                "call_count": 0
            }
        ]
    },
    "total_user": 21,
    "selected_agent_list": [
        73,
        87,
        47
    ]
}

export const coachingCategory = [
    { name: 'Performane', id: 1 }, { name: 'Attendance', id: 2 }
]


export const dummyWordCloud = {
    "status": 200,
    "max_words": [
        [
            "okay",
            19
        ],
        [
            "yeah",
            12
        ],
        [
            "eastern",
            12
        ],
        [
            "no",
            11
        ],
        [
            "bad",
            10
        ],
        [
            "individual",
            10
        ],
        [
            "repayment",
            9
        ],
        [
            "come",
            8
        ],
        [
            "no-thank",
            8
        ],
        [
            "free",
            7
        ]
    ],
    "max_phrases": [
        [
            [
                "western",
                "union"
            ],
            10
        ],
        [
            [
                "payment",
                "system"
            ],
            6
        ],
        [
            [
                "go",
                "ahead"
            ],
            3
        ],
        [
            [
                "good",
                "day"
            ],
            3
        ],
        [
            [
                "could",
                "go"
            ],
            3
        ],
        [
            [
                "account",
                "information"
            ],
            3
        ],
        [
            [
                "oops",
                "oops"
            ],
            3
        ],
        [
            [
                "current",
                "balance"
            ],
            2
        ],
        [
            [
                "online",
                "banking"
            ],
            2
        ],
        [
            [
                "oh",
                "okay"
            ],
            2
        ]
    ]
}

export const getAutoQAStatus = val => {
    const taskStatus = { bgColor: "#E0F9EC", textColor: '#3C9D6E', statusName: "Build" }
    if (val === 'Fully Automated') {
        taskStatus.bgColor = "#E0F9EC"
        taskStatus.textColor = '#000000';
        taskStatus.id = 20
        // taskStatus.textColor = '#3C9D6E';
        taskStatus.statusName = "Fully Automated";
    }
    if (val === 'Partially Automated') {
        taskStatus.bgColor = "#FFE8A4";
        taskStatus.textColor = '#000000';
        taskStatus.id = 20
        // taskStatus.textColor = '#D1A41F';
        taskStatus.statusName = "Partially Automated";
    }
    if (val === 'Build') {
        taskStatus.bgColor = "#FFE8DC";
        taskStatus.textColor = '#000000';
        taskStatus.id = 10
        // taskStatus.textColor = '#F2864E';
        taskStatus.statusName = 'Build';
    }

    return taskStatus;

}
