import * as Yup from 'yup';
import { useState, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { useSnackbar } from 'notistack5';
import { useFormik, Form, FormikProvider } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//
import moment from 'moment';
// material
import { Close } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Stack,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  FormControl,
  IconButton,
  Divider,
  Box, Autocomplete,
  Typography,
  OutlinedInput, Chip
} from '@material-ui/core';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../../../components/@material-extend';
import ScoreCardsContext from '../../../../contexts/ScoreCardsContext';
import { AnalyticContext } from '../../../../contexts/AnalyticContext';
import { SettingsContext } from "../../../../contexts/SettingsContext"

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  dropdownListStyle: {
    padding: 5
  },
  select: {
    backgroundColor: '#FFFFFF'
  },
  selectDisabled: {
    backgroundColor: '#E9ECEF'
  },
  textField: {
    backgroundColor: '#FFFFFF'
  },
  textFieldLock: {
    backgroundColor: '#E9ECEF'
  },
  selectLock: {
    backgroundColor: '#E9ECEF'
  }
}));

const formulaType = [
  {
    id: 10,
    type: 'True'
  },
  {
    id: 20,
    type: 'Potential'
  }
]
// ----------------------------------------------------------------------

export default function AnalyticsTeamsFilterDialog({
  addUserButton,
  setAddMode,
  closeAddUserDialog,
}) {

  const [searchParams, setSearchParams] = useSearchParams()
  const dateRange = [
    {
      id: 1,
      dateRange: 'Today'
    },
    {
      id: 2,
      dateRange: 'Yesterday'
    },
    {
      id: 3,
      dateRange: 'This Week'
    },
    {
      id: 4,
      dateRange: 'Last Week'
    },
    {
      id: 5,
      dateRange: 'This Month'
    },
    {
      id: 6,
      dateRange: 'Last Month'
    },
    {
      id: 7,
      dateRange: 'This Quarter'
    },
    {
      id: 8,
      dateRange: 'Last Quarter'
    },
    {
      id: 9,
      dateRange: 'This Year'
    },
    {
      id: 10,
      dateRange: 'Last Year'
    },
    {
      id: 11,
      dateRange: 'Custom Date Range'
    }
  ];

  const filterBy = [
    {
      id: 1,
      type: 'Score Date'
    },
    {
      id: 2,
      type: 'Event Date'
    },
    {
      id: 3,
      type: 'Last Edit Date'
    }
  ];

  const getWeekDay = (date) => {
    if (date !== undefined && date !== "") {
      const weeknumber = moment(date, "YYYY-MM-DD").isoWeek();
      return weeknumber;
    }
    return ""
  };

  // const { userLevels, groups, teams, getUserLevels, getGroups, getTeams, addUser, getAllUsers, editUser, resetPassword } = useContext(AddUserContext);
  const {
    getScoreLogGroups,
    getScoreLogScorecards,
    getScoreLogTeams,
    getScoreLogEmployee,
    getSectionDropdown,
    reportsScoreLogGroups,
    getScoreLogEventType,
    reportsScoreLogEventType,
    getScoreLogEventSubType,
    reportsScoreLogEventSubType,
    reportsScoreLogScorecards,
    reportsScoreLogEmployee,
    scorecardSection,
    reportsScoreLogTeams,
    reportsScoreLogSupervisor
  } = useContext(ScoreCardsContext);
  const {
    subModLabel,
    getPointsOfFailureByTeam, dateTypeSecTrend,
    setSelAnaTeamsFilters, setIsLoadingTeams,
    selectedTeamsGroupFilter, setSelectedTeamsGroupFilter,
    selectedDateAnaTeamsRange, setSelectedDateAnaTeamsRange,
    selectedTeamsFromDate, setSelectedTeamsFromDate,
    selectedTeamsToDate, setSelectedTeamsToDate,
    selectedTeamsHighlight, setSelectedTeamsHighlight,
    selectedTeamsFilterByValue, setSelectedTeamsFilterByValue,
    selectedTeamsFilterBy, setSelectedTeamsFilterBy, setSelectedTeamsEventFilter,
    setSelectedTeamsEventSubTypeFilter,
    selectedTeamsEventFilter, getOverAllTeamTrend, getStaffTrendBySupervisor,
    selectedTeamsEventSubTypeFilter, getTeamSupervisorList,
    selectedTeamsFromWeek, setSelectedTeamsFromWeek,
    selectedTeamsToWeek, setSelectedTeamsToWeek,
    getQuestionTrendByIndividualTeam,
    getSectionTrendByTeam,
    multipleScoreCard, setMultipleScoreCard,
    singleScoreCard, setSingleScoreCard,
    getDataTagsByScoreCard, setselectedDataTagFilter,
    dataTagByScoreCard, setselectedDataTag,
    setDataTagByScoreCard,
    setSelectedTeamStaffFilter, selectedTeamStaffFilter,
    setSelectedSelectionFilter,
    setSelectedTeamsSupervisorFilter,
    selectedTeamsSupervisorFilter,
    selectedTeamFilterDialog, setSelectedTeamFilterDialog
  } = useContext(AnalyticContext);

  const { showSidebar, setShowSidebar, dataCreatedFrom, setDataCreatedFrom } = useContext(SettingsContext);

  const [open, setOpen] = useState(false);

  const [advanceFilters, setAdvanceFilters] = useState();
  const [customFilters, setCustomFilters] = useState(false);
  const [fromWeekNo, setFromWeekNo] = useState([]);
  const [toWeekNo, setToWeekNo] = useState([]);

  const isMountedRef = useIsMountedRef();
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

  useEffect(() => {
    const groupFilterObj = {
      fromDate: selectedTeamsFromDate ? moment(selectedTeamsFromDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      toDate: selectedTeamsToDate ? moment(selectedTeamsToDate).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
      filterBy: selectedTeamsFilterByValue || "created_at",
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };
    const scoreCardfilterObj = {
      fromDate: selectedTeamsFromDate ? moment(selectedTeamsFromDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
      toDate: selectedTeamsToDate ? moment(selectedTeamsToDate).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
      filterBy: selectedTeamsFilterByValue || "created_at",
      group: selectedTeamsGroupFilter || "",
      teams: "",
      scoreCard: '',
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: '',
      staff: '',
      scoreCardCategory: '',
      evaluator: '',
      highlightValue: '',
      sup_grp: (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ?
        loggedUserData?.groups?.map(obj => obj.id) : ''

    };
    getScoreLogScorecards(scoreCardfilterObj);
    getScoreLogGroups(groupFilterObj);
  }, []);

  useEffect(() => {
    if (open) {
      if (selectedDateAnaTeamsRange) {
        setFieldValue('dateRange', selectedDateAnaTeamsRange);
        setFieldValue('fromDate', selectedTeamsFromDate);
        setFieldValue('toDate', selectedTeamsToDate);
      } else {
        setFieldValue('dateRange', 'This Month');
        setFieldValue('fromDate', moment().startOf('month').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('month').format('YYYY-MM-DD'));
      }

      if (selectedDateAnaTeamsRange === 'Custom Date Range') {
        setCustomFilters(true);
        setFieldValue('fromDate', selectedTeamsFromDate);
        setFieldValue('toDate', selectedTeamsToDate);
        setFieldValue('fromWeek', selectedTeamsFromWeek)
        setFieldValue('toWeek', selectedTeamsToWeek)
      }

      if (selectedTeamsFilterBy) {
        setFieldValue('filterByRange', selectedTeamsFilterBy);
        setFieldValue('filterBy', selectedTeamsFilterByValue);
      } else {
        setFieldValue('filterByRange', 'Score Date');
        setFieldValue('filterBy', 'created_at');
      }

      if (selectedTeamsGroupFilter) {
        setFieldValue('group', selectedTeamsGroupFilter);
      } else {
        setFieldValue('group', '');
      }
      // singleScoreCard
      if (singleScoreCard) {
        setFieldValue('singleScoreCard', singleScoreCard);
      } else {
        setFieldValue('singleScoreCard', '');
      }
      // multipleScorecard
      if (multipleScoreCard) {
        setFieldValue('multipleScoreCard', multipleScoreCard);
      } else {
        setFieldValue('multipleScoreCard', []);
      }

      // selectedTeamStaffFilter
      if (selectedTeamStaffFilter) {
        setFieldValue('staff', selectedTeamStaffFilter);
      } else {
        setFieldValue('staff', '');
      }

      if (selectedTeamsEventFilter) {
        setFieldValue('eventType', selectedTeamsEventFilter);
      } else {
        setFieldValue('eventType', '');
      }
      if (selectedTeamsEventSubTypeFilter) {
        setFieldValue('eventTypeEventSubType', selectedTeamsEventSubTypeFilter);
      } else {
        setFieldValue('eventTypeEventSubType', '');
      }
      if (selectedTeamsHighlight) {
        setFieldValue('highlightValue', selectedTeamsHighlight);
      } else {
        setFieldValue('highlightValue', '');
      }
    }
  }, [open]);

  const ReportSchema = Yup.object().shape({
    multipleScoreCard: (subModLabel === 5.4) ? Yup.number().transform((value) => Number.isNaN(value) ? null : value).required('This Value is required').nullable() : '',
    singleScoreCard: (subModLabel === 5.3) ? Yup.number().required('This Value is required').nullable() : '',
    staff: (subModLabel === 5.3) ? Yup.number().required('This Value is required').nullable() : Yup.string().nullable(),
    group: Yup.string().required('This Value is required')
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      dateRange: '',
      filterByRange: '',
      fromDate: '',
      toDate: '',
      filterBy: '',
      group: '',
      eventType: '',
      eventTypeEventSubType: '',
      supervisor: '',
      highlightValue: '',
      fromweekValue: '',
      toweekValue: '',
      teams: '',
      staff: '',
      reference: '',
      section: '',
      question: '',
      formula: 10,
      // teamsId: searchParams.get('teams__id') || '',
      allOfTag: '',
      anyOfTag: '',
      dataTag: [],
      singleScoreCard: '',
      multipleScoreCard: []
    },
    validationSchema: ReportSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {

      try {
        // persist filtered values on refresh
        localStorage.setItem("selectedDateAnaTeamsRange", JSON.stringify(selectedDateAnaTeamsRange || 'This Month'));
        localStorage.setItem("selectedTeamsFilterBy", JSON.stringify(selectedTeamsFilterBy || 'Score Date'));
        localStorage.setItem("selectedTeamsFilterByValue", JSON.stringify(selectedTeamsFilterByValue || "created_at"));
        localStorage.setItem("selectedTeamsFromDate", JSON.stringify(selectedTeamsFromDate));
        localStorage.setItem("selectedTeamsToDate", JSON.stringify(selectedTeamsToDate));
        localStorage.setItem("selectedTeamsGroupFilter", JSON.stringify(selectedTeamsGroupFilter));
        localStorage.setItem("selectedTeamsScorecardFilter", JSON.stringify(singleScoreCard));
        localStorage.setItem("selectedTeamsMultiScorecardFilter", JSON.stringify(multipleScoreCard));
        localStorage.setItem("selectedTeamsStaffFilter", JSON.stringify(selectedTeamStaffFilter));
        localStorage.setItem("selectedTeamsEventFilter", JSON.stringify(selectedTeamsEventFilter));
        localStorage.setItem("selectedTeamsEventSubTypeFilter", JSON.stringify(selectedTeamsEventSubTypeFilter));

        const teamFilterObj =
        {
          "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
          "toDate": moment(values.toDate).format('YYYY-MM-DD'),
          "filterBy": values.filterBy,
          "group": values.group,
          "teams": values.teams,
          "scoreCardGroups": values?.group,
          "eventType": values.eventType,
          "eventTypeEventSubType": values.eventTypeEventSubType,
          "staff": values.staff,
          "scoreCardCategory": values.scoreCardCategory,
          "page": "1",
          "filterName": "",
          "highlightValue": values.highlightValue,
          "flag": dateTypeSecTrend,
          "formula": values.formula,
          "reference": values.reference,
          "section": values.section,
          "supervisor": values.supervisor,
          "question": values.question,
          "teamsId": values.teamsId,
          "filterByRange": values.filterByRange

        }

        if (subModLabel === 5.2) {

          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            filterBy: values.filterBy,
            group: values.group,
            scoreCardGroups: values.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            filterByRange: values.filterByRange,
          };

          await getPointsOfFailureByTeam(filterObj);
          setSelAnaTeamsFilters(filterObj);
          localStorage.setItem("selAnaTeamsFilters", JSON.stringify({ ...filterObj }));
          handleClose();
        }
        if (subModLabel === 5.1) {
          const teamFilterObj =
          {
            "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
            "toDate": moment(values.toDate).format('YYYY-MM-DD'),
            "filterBy": values.filterBy,
            "group": values.group,
            "teams": values.teams,
            "scoreCardGroups": values?.group,
            "eventType": values.eventType,
            "eventTypeEventSubType": values.eventTypeEventSubType,
            "staff": values.staff,
            "scoreCardCategory": values.scoreCardCategory,
            "page": "1",
            "filterName": "",
            "highlightValue": values.highlightValue,
            "flag": dateTypeSecTrend === 'year' ? 'quarter' : dateTypeSecTrend,
            "formula": values.formula,
            "reference": values.reference,
            "section": values.section,
            "supervisor": values.supervisor,
            "question": values.question,
            "teamsId": values.teamsId,
            "filterByRange": values.filterByRange

          }
          setIsLoadingTeams(true);
          setSelAnaTeamsFilters(teamFilterObj);
          setSearchParams(teamFilterObj)
          localStorage.setItem("selAnaTeamsFilters", JSON.stringify({ ...teamFilterObj }));
          await getOverAllTeamTrend(teamFilterObj);
          handleClose();
        }
        else if (subModLabel === 5.11) {
          setIsLoadingTeams(true);
          setSelAnaTeamsFilters(teamFilterObj);
          setSearchParams(teamFilterObj)

          await getTeamSupervisorList(teamFilterObj);
          handleClose();
        }
        else if (subModLabel === 5.12) {
          setIsLoadingTeams(true);
          setSelAnaTeamsFilters(teamFilterObj);
          setSearchParams(teamFilterObj)
          await getStaffTrendBySupervisor(teamFilterObj);
          handleClose();
        }

        if (subModLabel === 5.3) {
          const filterObj = {
            'fromDate': moment(values.fromDate).format('YYYY-MM-DD'),
            'toDate': moment(values.toDate).format('YYYY-MM-DD'),
            'filterBy': values.filterBy,
            'filterByRange': values.filterByRange,
            'group': values.group,
            'teams': values.teams,
            'singleScoreCard': values.singleScoreCard !== "" ? values.singleScoreCard : values?.multipleScoreCard[0],
            'scoreCardGroups': values?.group,
            'eventType': values.eventType,
            'eventTypeEventSubType': values.eventTypeEventSubType,
            'staff': values.staff,
            // 'scoreCardCategory': values.scoreCardCategory,
            'page': '1',
            'filterName': '',
            'highlightValue': values.highlightValue,
            'flag': dateTypeSecTrend,
            'section': values.section || '',
            'question': values.question || '',
            'supervisor': values.supervisor || '',
            'numericValues': values.numericValues || '',
            'formula': values.formula || '',
            'dataTags': values?.dataTag || '',
            'dataTagsOperator': values.anyOfTag || values.allOfTag || ''
          };

          setSelAnaTeamsFilters(filterObj);
          await getQuestionTrendByIndividualTeam(filterObj);
          localStorage.setItem("selAnaTeamsFilters", JSON.stringify({ ...filterObj }));
          handleClose();

        }
        console.log('subModLabel', subModLabel)
        if (subModLabel === 5.4) {
          const filterObj = {
            fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
            toDate: moment(values.toDate).format('YYYY-MM-DD'),
            filterBy: values.filterBy,
            group: values.group,
            scoreCard: values.multipleScoreCard,
            scoreCardGroups: values.group,
            eventType: values.eventType,
            eventTypeEventSubType: values.eventTypeEventSubType,
            page: '1',
            filterName: '',
            highlightValue: values.highlightValue,
            filterByRange: values.filterByRange,
            flag: dateTypeSecTrend,
            fromWeek: values.fromWeek,
            toWeek: values.toWeek,
            teams: values.teams,
            supervisor: values.supervisor,
            numeric: values.numericValues || '',
            reference: values.reference,
            formula: values.formula,
            dataTag: values.dataTag || '',
            dataTagsOperator: values?.allOfTag || values?.anyOfTag || '10'

          };

          await getSectionTrendByTeam(filterObj);
          setSelAnaTeamsFilters(filterObj);
          localStorage.setItem("selAnaTeamsFilters", JSON.stringify({ ...filterObj }));
          handleClose();

        }

      } catch (error) {
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    },
    enableReinitialze: true
  });

  const { errors, touched, values, setFieldValue, handleSubmit, isSubmitting, getFieldProps } = formik;

  // Open user dialog modal
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close user dialog modal
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);

      if (!setAddMode) {
        closeAddUserDialog(false);
      }
    }
  };

  const handleDateRangeChange = (event) => {
    // Resetting remaining fields
    setFieldValue('group', '');
    setFieldValue('teams', '');
    setFieldValue('scoreCard', '');
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');

    const dateRange = event.target.value;
    setFieldValue('dateRange', dateRange);
    switch (dateRange) {
      case 'Today':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('Today');
        break;
      case 'Yesterday':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('Yesterday');
        break;
      case 'This Week':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().startOf('week').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('week').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().startOf('week').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().endOf('week').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('This Week');
        break;
      case 'Last Week':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('Last Week');
        break;
      case 'This Month':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().startOf('month').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().startOf('month').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().endOf('month').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('This Month');
        break;
      case 'Last Month':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('Last Month');
        break;
      case 'This Quarter':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('This Quarter');
        break;
      case 'Last Quarter':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().subtract(1, 'quarter').endOf('quarter').endOf('month').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('Last Quarter');
        break;
      case 'This Year':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().startOf('year').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().endOf('year').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().startOf('year').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().endOf('year').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('This Year');
        break;
      case 'Last Year':
        setCustomFilters(false);
        setFieldValue('fromDate', moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
        setSelectedTeamsFromDate(moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'));
        setSelectedTeamsToDate(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'));
        setSelectedDateAnaTeamsRange('Last Year');
        break;
      case 'Custom Date Range':
        setCustomFilters(true);
        setFieldValue('fromDate', moment());
        setFieldValue('toDate', moment());

        setSelectedTeamsFromDate(moment());
        setSelectedTeamsToDate(moment());

        if (moment().format('MM-DD') === '01-01') {
          setFieldValue("fromWeek", 1)
          setSelectedTeamsFromWeek(1);
        }
        else {
          setFieldValue("fromWeek", moment().year(moment().format('YYYY')).week());
          setSelectedTeamsFromWeek(moment().year(moment().format('YYYY')).week());
        }
        if (moment().format('MM-DD') === '01-01') {
          setFieldValue("toWeek", 1)
          setSelectedTeamsToWeek(1)
        }
        else {
          setFieldValue("toWeek", moment().year(moment().format('YYYY')).week());
          setSelectedTeamsToWeek(moment().year(moment().format('YYYY')).week());
        }

        setSelectedDateAnaTeamsRange('Custom Date Range');
        break;
      default:
        setFieldValue('fromDate', moment().format('YYYY-MM-DD'));
        setFieldValue('toDate', moment().format('YYYY-MM-DD'));
        break;
    }
  };

  useEffect(() => {

    if (open) {
      const arrFromWeek = [];
      const arrToWeek = [];
      if (formik.values.fromWeek > moment().week()) {
        for (let i = 1; i <= formik.values.fromWeek; i) {
          arrFromWeek.push(i);
          i += 1;
        }
        setFromWeekNo(arrFromWeek);
      } else {
        for (let i = 1; i <= moment().week(); i) {
          arrFromWeek.push(i);
          i += 1;
        }
        setFromWeekNo(arrFromWeek);
      }

      if (formik.values.toWeek > moment().week()) {
        for (let i = 1; i <= formik.values.toWeek; i) {
          arrToWeek.push(i);
          i += 1;
        }
        setToWeekNo(arrToWeek);
      } else {
        for (let i = 1; i <= moment().week(); i) {
          arrToWeek.push(i);
          i += 1;
        }
        setToWeekNo(arrToWeek);
      }
    }

    const groupFilterObj = {
      fromDate: moment(values.fromDate).format('YYYY-MM-DD'),
      toDate: moment(values.toDate).format('YYYY-MM-DD'),
      filterBy: values.filterBy,
      group: '',
      eventType: values.eventType,
      eventTypeEventSubType: values.eventTypeEventSubType,
      highlightValue: values.highlightValue,
      sup_grp:
        loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30
          ? loggedUserData?.groups?.map((obj) => obj.id)
          : ''
    };

    const evntTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "eventType": '',
      "eventTypeEventSubType": '',
      "highlightValue": '',
      "teams": '',
      "scoreCard": '',
      "evaluator": '',
      "staff": '',
      "scoreCardCategory": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }

    const evntSubTypeFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "eventType": values.eventType,
      "eventTypeEventSubType": '',
      "highlightValue": '',
      "teams": '',
      "scoreCard": '',
      "evaluator": '',
      "staff": '',
      "scoreCardCategory": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }
    const scoreCardfilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }
    const teamFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": '',
      "eventType": '',
      "eventTypeEventSubType": '',
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }
    const empFilterObj =
    {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "group": values.group,
      "teams": values.teams,
      "scoreCard": (subModLabel === 5.4) ? values.multipleScoreCard : values.singleScoreCard,
      "eventType": values.eventType,
      "eventTypeEventSubType": values.eventTypeEventSubType,
      "supervisor": '',
      "staff": '',
      "scoreCardCategory": '',
      "evaluator": '',
      "highlightValue": '',
      "sup_grp": (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? loggedUserData?.groups?.map(obj => obj.id) : ''
    }
    if (formik.values.fromDate && formik.values.toDate && formik.values.filterBy) {
      getScoreLogGroups(groupFilterObj);
      getScoreLogEventType(evntTypeFilterObj);
      getScoreLogEventSubType(evntSubTypeFilterObj);
      getScoreLogScorecards(scoreCardfilterObj)
      getScoreLogTeams(teamFilterObj);
      getScoreLogEmployee(empFilterObj);
      if (subModLabel === '5.3') {
        getSectionDropdown(values?.singleScoreCard);
      }

    }
  }, [formik?.values]);

  const handleFilterByChange = (event) => {

    // Resetting remaining fields
    setFieldValue('scoreCard', '');
    setFieldValue('group', '');
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('scoreCardCategory', '');
    setFieldValue('evaluator', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('section', '');
    setFieldValue('evaluator', '');

    const filterBy = event.target.value;
    switch (filterBy) {
      case 'Score Date':
        setFieldValue('filterByRange', 'Score Date');
        setFieldValue('filterBy', 'created_at');
        setSelectedTeamsFilterBy('Score Date');
        setSelectedTeamsFilterByValue('created_at')
        break;
      case 'Event Date':
        setFieldValue('filterByRange', 'Event Date');
        setFieldValue('filterBy', 'event_date');
        setSelectedTeamsFilterBy('Event Date');
        setSelectedTeamsFilterByValue('event_date')
        break;
      case 'Last Edit Date':
        setFieldValue('filterByRange', 'Last Edit Date');
        setFieldValue('filterBy', 'updated_at');
        setSelectedTeamsFilterBy('Last Edit Date');
        setSelectedTeamsFilterByValue('updated_at')
        break;
      default:
        setFieldValue('filterByRange', 'Last Edit Date');
        setFieldValue('filterBy', 'updated_at');
        setSelectedTeamsFilterBy('updated_at');
        setSelectedTeamsFilterByValue('updated_at')
        break;
    }

  };

  const handleGroupChange = (event, newVal) => {
    // Resetting remaining fields
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('highlightValue', '');
    setFieldValue('group', newVal?.id ?? '');
    setSelectedTeamsGroupFilter(newVal?.id ?? '');
  };

  const handleTeamChange = (event) => {
    // Resetting remaining fields
    setFieldValue("teams", event.target.value);
    setSelectedTeamFilterDialog(event.target.value);
    setFieldValue("multipleScoreCard", []);
    setFieldValue("singleScoreCard", '');
    // setselectedStaFilter([]);
    // setSelectedScoreCardSingle('')
  };

  const handleSupervisorChange = (event) => {
    setFieldValue("supervisor", event.target.value);
    setSelectedTeamsSupervisorFilter(event.target.value);
  };
  const handleEventTypeChange = (event) => {
    // Resetting remaining fields
    setFieldValue('eventType', event.target.value);
    setSelectedTeamsEventFilter(event.target.value);
  };
  const handleEventSubTypeChange = (event) => {
    // Resetting remaining fields
    setFieldValue('eventTypeEventSubType', event.target.value);
    setSelectedTeamsEventSubTypeFilter(event.target.value);
  };

  const handleAdvancedFilters = (event) => {
    setAdvanceFilters((advanceFilters) => !advanceFilters);
  };

  const handleHighlightChange = (event) => {
    setFieldValue('highlightValue', event.target.value);
    setSelectedTeamsHighlight(event.target.value)
  };
  const handleScorecardMultipleChange = (event, newValue) => {
    // Resetting remaining fields
    setFieldValue('eventType', '');
    setFieldValue('eventTypeEventSubType', '');
    setFieldValue('staff', '');
    setFieldValue('supervisor', '');
    setFieldValue('highlightValue', '');
    setFieldValue('dataTag', []);
    setDataTagByScoreCard([]);
    localStorage.setItem('selectedDataTag', JSON.stringify([]))
    setselectedDataTagFilter([])

    const value = [];
    newValue?.map(option => value.push(option?.scoreCard));
    setFieldValue("multipleScoreCard", value ?? []);
    setFieldValue("singleScoreCard", value?.length > 0 ? value[0] : '');
    setSingleScoreCard(value?.length > 0 ? value[0] : '')

    setMultipleScoreCard(value ?? []);
    // setselectedScFilter(value?.length > 0 ? value[0] : '');

    // if (subModLabel === 4.7) {
    const filterScoreCardObj = {
      "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
      "toDate": moment(values.toDate).format('YYYY-MM-DD'),
      "filterBy": values.filterBy,
      "scoreCard": value ?? '',
    }
    getDataTagsByScoreCard(filterScoreCardObj)
    // }
  };

  const handleStaffChange = (event) => {
    setFieldValue("highlightValue", '');
    setFieldValue("staff", event.target.value);
    setSelectedTeamStaffFilter(event.target.value);
  };

  const handleSectionField = (event) => {
    setFieldValue('section', event.target.value)
    setSelectedSelectionFilter(event.target.value)

  }

  const renderOptions = (props, option) => {
    return <li {...props} key={option?.scoreCard} style={{ color: option?.scoreCardIsArchive ? 'red' : '' }}>
      {option?.scoreCardName}
    </li>
  }
  console.log('errore', errors, values)
  return (
    <div>
      {addUserButton && (
        <Button variant="contained" onClick={handleClickOpen} className="halloween-animation">
          Filter
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle sx={{ pb: 2 }}>
              {' '}
              <h3>Analytics Report Filter </h3>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 15,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingTop: '20px' }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ p: 0 }}>
                  <FormControl fullWidth />

                  <FormControl fullWidth>
                    <Button
                      startIcon={advanceFilters ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      sx={{ p: 0 }}
                      onClick={handleAdvancedFilters}
                    >
                      {' '}
                      {advanceFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters'}{' '}
                    </Button>
                  </FormControl>
                </Stack>
                <Divider />

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="date-range-label">Date Range</InputLabel>
                    <Select
                      labelId="date-range-label"
                      id="demo-select-small"
                      value={values.dateRange}
                      label="Date Range"
                      {...getFieldProps('dateRange')}
                      onChange={(e) => {
                        handleDateRangeChange(e);
                      }}
                    >
                      {dateRange?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.dateRange}>
                          {item.dateRange}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="filter-by-label">Filter By</InputLabel>
                    <Select
                      defaultValue="Score Date"
                      labelId="filter-by-label"
                      id="demo-select-small"
                      value={values.filterByRange}
                      label="Filter By"
                      {...getFieldProps('filterByRange')}
                      onChange={handleFilterByChange}
                    >
                      {filterBy?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.type}>
                          {item.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>

                {customFilters && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Box sx={{ width: '50%' }}>
                        <DesktopDatePicker
                          label="Date From"
                          inputFormat="yyyy/MM/dd"
                          name="eventDate"
                          minDate={new Date(dataCreatedFrom)}
                          disableFuture
                          {...getFieldProps('fromDate')}
                          onChange={(newValue) => {
                            setFieldValue("fromDate", newValue);
                            setFieldValue("fromweekValue", getWeekDay(moment(newValue).format('YYYY-MM-DD')))
                            setSelectedTeamsFromDate(newValue);

                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("fromWeek", 1)
                              setSelectedTeamsFromWeek(1)
                            } else {
                              formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedTeamsFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                            }

                            if (moment(newValue, "YYYY-MM-DD").isAfter(formik.values.toDate)) {
                              formik.setFieldValue("toDate", moment(newValue).format('YYYY-MM-DD'))
                              if (moment(newValue).format('MM-DD') === '01-01') {
                                formik.setFieldValue("toWeek", 1)
                                setSelectedTeamsToWeek(1)

                              } else {
                                formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                setSelectedTeamsToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                              }

                            }

                          }}

                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        {formik.errors.eventDate && formik.touched.eventDate && (
                          <Typography variant="caption" sx={{ color: 'red', ml: 1 }}>
                            {formik.errors.eventDate}
                          </Typography>
                        )}

                      </Box>

                      <Box sx={{ width: '50%' }}>
                        <DesktopDatePicker
                          label="Date To"
                          inputFormat="yyyy/MM/dd"
                          name="eventDate"
                          minDate={new Date(dataCreatedFrom)}
                          disableFuture
                          {...getFieldProps('toDate')}
                          onChange={(newValue) => {
                            setFieldValue('toDate', newValue);
                            setSelectedTeamsToDate(newValue);

                            if (moment(newValue).format('MM-DD') === '01-01') {
                              formik.setFieldValue("toWeek", 1)
                              setSelectedTeamsToWeek(1)
                            } else {
                              formik.setFieldValue("toWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                              setSelectedTeamsToWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                            }

                            // Extract year from full date
                            const fullDate = moment(newValue);
                            const extractyear = fullDate.year();

                            if (moment(newValue, "YYYY-MM-DD").isBefore(formik.values.fromDate) && /^\d{4}$/.test(extractyear)) {
                              formik.setFieldValue("fromDate", moment(newValue).format('YYYY-MM-DD'))
                              if (moment(newValue).format('MM-DD') === '01-01') {
                                formik.setFieldValue("fromWeek", 1)
                                setSelectedTeamsFromWeek(1)

                              } else {
                                formik.setFieldValue("fromWeek", moment(newValue).year(moment(newValue).format('YYYY')).isoWeek());
                                setSelectedTeamsFromWeek(moment(newValue).year(moment(newValue).format('YYYY')).isoWeek())
                              }

                            }

                          }}
                          renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                        {formik.errors.eventDate && formik.touched.eventDate && (
                          <Typography variant="caption" sx={{ color: 'red', ml: 1 }}>
                            {formik.errors.eventDate}
                          </Typography>
                        )}

                      </Box>
                    </Stack>
                  </LocalizationProvider>
                )}

                {customFilters && <Stack direction="row" sx={{ width: "100%", mb: 2 }} spacing={2}>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      required
                      value={formik.values.fromWeek}
                      multiple={false}
                      select
                      name="fromWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("fromWeek", e.target.value);
                        setSelectedTeamsFromWeek(e.target.value);
                        setSelectedTeamsFromDate(moment().week(e.target.value).startOf('week'));
                        formik.setFieldValue("fromDate", moment().week(e.target.value).startOf('week'));
                      }}

                    >
                      {fromWeekNo?.map((item) => (
                        <MenuItem key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}

                    </TextField>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      fullWidth
                      multiple={false}
                      value={formik.values.toWeek}
                      required
                      select
                      name="toWeek"
                      onChange={(e) => {
                        moment.updateLocale('en', {
                          week: {
                            dow: 1
                          }
                        });
                        formik.setFieldValue("toWeek", e.target.value);
                        setSelectedTeamsToWeek(e.target.value);
                        setSelectedTeamsToDate(moment().week(e.target.value).endOf('week'));
                        formik.setFieldValue("toDate", moment().week(e.target.value).endOf('week'));
                      }}

                    >
                      {toWeekNo?.map((item) => (
                        <MenuItem disabled={formik.values.fromWeek > item} key={item} id={item} value={item}>
                          Week&nbsp;{item}
                        </MenuItem >
                      ))}
                    </TextField>
                  </Box>

                </Stack>}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={reportsScoreLogGroups?.find((option) => option?.id === values.group)}
                      fullWidth
                      options={reportsScoreLogGroups}
                      getOptionLabel={(option) => option?.name || ""}
                      onChange={(event, newValue) => {
                        handleGroupChange(event, newValue);
                      }}
                      id="controllable-states-demo"
                      renderInput={(params) => <TextField {...params} label="Group"
                        popoverProps={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto'
                          }
                        }}
                        variant="outlined"
                        error={Boolean((touched.group && errors.group))}
                        helperText={(touched.group && errors.group)}
                        required
                      />}
                    />
                  </FormControl>

                  {(subModLabel === 5.4) && <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">Teams</InputLabel>
                    <Select
                      // defaultValue='All'
                      required
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      value={values.teams}
                      {...getFieldProps('teams')}
                      // label="Teams"
                      input={<OutlinedInput notched label="Teams" />}
                      onChange={handleTeamChange}
                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogTeams?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item.id}>
                          {item.name}
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>
                  }
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  {(subModLabel === 5.3) && <FormControl fullWidth>
                    <Autocomplete
                      value={(reportsScoreLogScorecards)?.find((option) => {
                        return option?.scoreCard === (singleScoreCard || values?.singleScoreCard)
                      }
                      )}

                      fullWidth
                      options={reportsScoreLogScorecards}
                      getOptionLabel={(option) => option?.scoreCardName || ""}
                      renderOption={renderOptions}
                      onChange={(event, newValue) => {
                        setFieldValue("eventType", '');
                        setFieldValue("eventTypeEventSubType", '');
                        setFieldValue("staff", '');
                        setFieldValue("highlightValue", '');
                        setFieldValue("section", '');

                        setFieldValue('singleScoreCard', newValue?.scoreCard ?? '')
                        setFieldValue("multipleScoreCard", [newValue?.scoreCard] ?? []);

                        localStorage.setItem('selectedDataTagValue', JSON.stringify([]))

                        setFieldValue("dataTag", []);
                        setselectedDataTag([])
                        setMultipleScoreCard([newValue?.scoreCard] ?? []);

                        setSingleScoreCard(newValue?.scoreCard ?? '')
                        const filterScoreCardObj = {
                          "fromDate": moment(values.fromDate).format('YYYY-MM-DD'),
                          "toDate": moment(values.toDate).format('YYYY-MM-DD'),
                          "filterBy": values.filterBy || 'created_at',
                          "scoreCard": newValue?.scoreCard !== '' ? newValue?.scoreCard : '',
                        }
                        getDataTagsByScoreCard(filterScoreCardObj)

                      }}
                      id="controllable-states-demo"
                      renderInput={(params) => <TextField {...params} label="Scorecard"
                        popoverProps={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto'
                          }
                        }}
                        error={((values?.singleScoreCard === '') && true)}
                        helperText={(values?.singleScoreCard === '') && 'This value is required'}
                        variant="outlined"
                        // error={Boolean((touched.singleScoreCard && errors.singleScoreCard))}
                        // helperText={(touched.singleScoreCard && errors.singleScoreCard)}
                        required
                        onBlur={formik.handleBlur}

                      />}
                    />
                  </FormControl>

                  }
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  {(subModLabel === 5.4) && <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      freeSolo
                      value={reportsScoreLogScorecards?.filter((option) => {
                        return values?.multipleScoreCard?.includes(option?.scoreCard)
                      })}
                      fullWidth
                      options={reportsScoreLogScorecards}
                      getOptionLabel={(option) => option?.scoreCardName || ""}
                      renderOption={renderOptions}
                      onChange={(event, newValue) => {
                        handleScorecardMultipleChange(event, newValue);
                      }}

                      id="controllable-states-demo"
                      isOptionEqualToValue={(option, value) => option?.scoreCard === value?.scoreCard}
                      renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={typeof option === "string" ? option : option?.scoreCardName}
                            {...getTagProps({ index })}
                          />
                        ));
                      }}
                      renderInput={(params) => <TextField {...params} label="Scorecard"
                        popoverProps={{
                          style: {
                            bottom: 0,
                            overflowY: 'auto',

                          }
                        }}
                        variant="outlined"
                        required
                        // value={selectedScMultipleFilter}
                        name='multipleScoreCard'
                        {...getFieldProps('multipleScoreCard')}
                        error={Boolean(touched.multipleScoreCard && errors.multipleScoreCard)}
                      // helperText={touched.multipleScoreCard && errors.multipleScoreCard}
                      />}
                    />
                    {(touched.multipleScoreCard && errors.multipleScoreCard) ?
                      // {(errors.multipleScoreCard) ?
                      <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>
                        This value is required
                      </Typography>
                      : null}
                  </FormControl>}


                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="group-label">
                      Event Type
                    </InputLabel>
                    <Select
                      labelId="group-label"
                      id="demo-select-small"
                      displayEmpty
                      input={<OutlinedInput notched label="Event Type" />}
                      {...getFieldProps('eventType')}
                      onChange={handleEventTypeChange}
                    >
                      <MenuItem value=""> All </MenuItem>
                      {reportsScoreLogEventType?.map((item) => (
                        <MenuItem key={item.eventType} id={item.eventType} value={item.eventType}>
                          <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel shrink id="teams-label">
                      Event Sub Type
                    </InputLabel>
                    <Select
                      labelId="teams-label"
                      id="demo-select-small"
                      displayEmpty
                      input={<OutlinedInput notched label="Event Sub Type" />}
                      {...getFieldProps('eventTypeEventSubType')}
                      onChange={handleEventSubTypeChange}
                    >
                      <MenuItem value=""> All </MenuItem>
                      {reportsScoreLogEventSubType?.map(
                        (item, index) =>
                          item?.eventSubType &&
                          item.eventTypeEventSubType && (
                            <MenuItem key={index} id={item.eventSubType} value={item.eventSubType}>
                              <Typography sx={{ fontSize: '14px', display: 'inline' }}>{item.eventTypeEventSubType}</Typography>&nbsp;<Typography sx={{ display: 'inline-block', fontStyle: 'italic', fontSize: '12px', fontWeight: 'bold' }}>{`(${item?.scorecard})`}</Typography>
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                </Stack>
                {(subModLabel === 5.3) &&
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel shrink id="scorecard-label" required>Employee</InputLabel>
                    <Select
                      labelId="scorecard-label"
                      id="demo-select-small"
                      // displayEmpty
                      required
                      input={<OutlinedInput notched label="employee" />}
                      error={Boolean(touched.staff && errors.staff)}
                      helperText={touched.staff && errors.staff}
                      value={values?.staff}
                      {...getFieldProps('staff')}
                      onChange={handleStaffChange}
                    >
                      {reportsScoreLogEmployee?.map((item) => (
                        <MenuItem key={item.staff} id={item.staff} value={item.staff}>
                          {`${item.staffUserFirstName} ${item.staffUserLastName}`}
                        </MenuItem >
                      ))}
                    </Select>
                    {formik.errors.staff && formik.touched.staff ? <Typography sx={{ color: 'red', pl: 1, fontSize: '12px', p: 1 }}>{formik.errors.staff}</Typography> : null}
                  </FormControl>
                }
                {
                  <FormControl fullWidth>
                    {advanceFilters && (subModLabel === 5.3) && <FormControl>
                      <InputLabel shrink id="scorecard-label">Sections</InputLabel>
                      <Select
                        defaultValue='All'
                        labelId="scorecard-label"
                        id="demo-select-small"
                        displayEmpty
                        input={<OutlinedInput notched label="Sections" />}
                        onChange={handleSectionField}
                        // error={Boolean(touched.userLev && errors.userLev)}
                        // helperText={touched.userLev && errors.userLev}
                        {...getFieldProps("section")}
                      >
                        <MenuItem value=""> All </MenuItem >
                        {scorecardSection?.map((item) => (
                          <MenuItem key={item.id} id={item.id} value={item.id}>
                            {item.name}
                          </MenuItem >
                        ))}
                      </Select>
                    </FormControl>
                    }
                  </FormControl>
                }
                {
                  advanceFilters && (subModLabel === 5.4) && <FormControl fullWidth>
                    <InputLabel shrink id="group-label">Supervisor</InputLabel>
                    <Select
                      // defaultValue='All'
                      labelId="group-label"
                      id="demo-select-small"
                      displayEmpty
                      value={values.supervisor}
                      // label="Supervisor"
                      input={<OutlinedInput notched label="Supervisor" />}
                      {...getFieldProps('supervisor')}
                      onChange={handleSupervisorChange}
                    >
                      <MenuItem value=""> All </MenuItem >
                      {reportsScoreLogSupervisor?.map((item) => (
                        <MenuItem key={item.supervisor} id={item.supervisor} value={item.supervisor}>
                          {`${item.supervisorUserFirstName} ${item.supervisorUserLastName}`}
                        </MenuItem >
                      ))}
                    </Select>
                  </FormControl>
                }
                {advanceFilters && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type='number'
                      label="Highlight Below"
                      value={values.highlightBelow}
                      {...getFieldProps('highlightValue')}
                      error={Boolean(touched.highlightValue && errors.highlightValue)}
                      helperText={touched.highlightValue && errors.highlightValue}
                      onChange={handleHighlightChange}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel shrink id="group-label">Formula</InputLabel>
                    <Select
                      labelId="group-label"
                      id="demo-select-small"
                      displayEmpty
                      value={values.formula}
                      input={<OutlinedInput notched label="Formula" />}
                      {...getFieldProps('formula')}
                    >
                      {(subModLabel === 5.4 || subModLabel === 5.3) && formulaType?.map((item) => (
                        <MenuItem key={item.id} id={item.id} value={item?.id}>
                          {item.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>}
                {/* comment for some time-- */}
                {/* {advanceFilters && subModLabel === 5.4 && (
                  <FormControlLabel
                    label="Numeric Values"
                    control={
                      <Checkbox
                        checked={values.numericValues}
                        {...getFieldProps('numericValues')}
                        size="small" />
                    }
                  />
                )} */}

              </Stack>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                }}
                color="inherit"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Apply
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}