import { useState, useReducer, createContext, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import humps from 'humps';
import { useSnackbar } from 'notistack5';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';

// API IMPORT
// import { API } from '../backend';
import { MIconButton } from '../components/@material-extend';

export const AnalyticContext = createContext();

const handlers = {
  GET_STAFF_TREND_REPORT: (state, action) => {
    const staffTrendReportDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendReportDetails
    };
  },
  GET_STAFF_TREND_IND: (state, action) => {
    const staffTrendByInd = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendByInd
    };
  },
  GET_STAFF_TREND_DIS: (state, action) => {
    const staffTrendDisDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendDisDetails
    };
  },
  GET_STAFF_TREND_ABOBELOW: (state, action) => {
    const staffTrendAboBelow = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendAboBelow
    };
  },
  GET_STAFF_TREND_RESULTS: (state, action) => {
    const staffTrendResDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendResDetails
    };
  },
  GET_EVALUATION_BY_STAFF: (state, action) => {
    const evaluationResult = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      evaluationResult
    };
  },
  GET_PER_PERIOD_RESULTS: (state, action) => {
    const evaluationperPeriodResult = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      evaluationperPeriodResult
    };
  },
  GET_REVIEW_BY_SUPERVISOR_RESULTS: (state, action) => {
    const evaluationperReviewBySupervisorResult = action.payload;


    return {
      ...state,
      isAuthenticated: true,
      evaluationperReviewBySupervisorResult
    };
  },

  GET_POINTS_OF_FAILURE_RESULTS: (state, action) => {
    const pointsOfFailureResult = action.payload;


    return {
      ...state,
      isAuthenticated: true,
      pointsOfFailureResult
    };
  },
  GET_STAFF_TREND_IND_RESULTS: (state, action) => {
    const staffTrendIndResDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendIndResDetails
    };
  },
  CAUSE_BY_STAFF_RESULTS: (state, action) => {
    const causeByStaffResDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causeByStaffResDetails
    };
  },
  // 1.2 question by staff ---START---
  GET_QUESTION_BY_STAFF_REPORT: (state, action) => {
    const questionByStaffData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      questionByStaffData
    };
  },
  GET_QUESTION_BY_STAFF_RESULTS_DETAILS: (state, action) => {
    const questionByStaffResultDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      questionByStaffResultDetails
    };
  },
  // 1.2 question by staff ---END---


  // 1.3 Answers by staff ----START

  GET_ANSWER_BY_STAFF_REPORT: (state, action) => {
    const answerByStaffData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      answerByStaffData
    };
  },
  GET_ANSWER_BY_STAFF_DISTRIBUTION_REPORT: (state, action) => {
    const answerByStaffDistributionData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      answerByStaffDistributionData
    };
  },
  GET_ANSWER_BY_STAFF_RESULTS_DETAILS: (state, action) => {
    const answerByStaffResultDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      answerByStaffResultDetails
    };
  },

  // 1.3 Answers by staff ----START

  GET_QUESTION_TREND_REPORT: (state, action) => {
    const questionTrendReportDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      questionTrendReportDetails
    };
  },
  GET_QUES_TREND_RESULTS: (state, action) => {
    const quesTrendResDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      quesTrendResDetails
    };
  },
  GET_OVERALL_TREND_REPORT: (state, action) => {
    const overallTrendReportDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      overallTrendReportDetails
    };
  },
  SET_OVERALL_TREND_REPORT: (state, action) => {
    const overallTrendReportDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      overallTrendReportDetails
    };
  },

  GET_QUES_TREND_INDIVIDUAL_RESULTS: (state, action) => {
    const quesTrendResDetailsInd = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      quesTrendResDetailsInd
    };
  },
  GET_SCORECARD_OVERALL_RESULTS: (state, action) => {
    const overallTrendResDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      overallTrendResDetails
    };
  },
  GET_SECTION_TREND_REPORT: (state, action) => {
    const sectionTrendDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      sectionTrendDetails
    };
  },


  // 2.3  SUPERVISOR BY QUESTION ---START
  GET_QUESTION_BY_SUPERVISOR_REPORT: (state, action) => {
    const questionBySuperVisorData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      questionBySuperVisorData
    };
  },
  GET_QUESTION_BY_SUPERVISOR_DETAILS_REPORT: (state, action) => {
    const SupervisorByQuestionDetailsData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      SupervisorByQuestionDetailsData
    };
  },

  // 2.3  SUPERVISOR BY QUESTION ---ENDS

  // 2.2  POINTS OF FAILURE BY SUPERVISOR ---STARTS

  POINTS_OF_FAILURE_BY_SUPERVISOR: (state, action) => {
    const supervisorPointsOfFailureDetailsData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      supervisorPointsOfFailureDetailsData
    };
  },

  // 2.2  POINTS OF FAILURE BY SUPERVISOR ---ENDS

  // 4.8 Causes Trend ---START--- comment for some time 28-03-2024
  // GET_CAUSES_TREND_REPORT: (state, action) => {
  //   const causesTrendDetails = action.payload;

  //   return {
  //     ...state,
  //     isAuthenticated: true,
  //     causesTrendDetails
  //   };
  // },
  // SET_CAUSES_TREND_REPORT: (state, action) => {
  //   const causesTrendDetails = action.payload;

  //   return {
  //     ...state,
  //     isAuthenticated: true,
  //     causesTrendDetails
  //   };
  // },

  GET_CAUSES_TREND_DETAILED_REPORT: (state, action) => {
    const causesTrendDetailedResult = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      causesTrendDetailedResult
    }
  },
  // 4.8 Causes Trend ---END---

  // 5.1 Teams Trends --- Start
  GET_OVERALL_TEAM_TREND_REPORT: (state, action) => {
    const overAllTeamTrendData = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      overAllTeamTrendData
    }
  },
  SET_OVERALL_TEAM_TREND_REPORT: (state, action) => {
    const overAllTeamTrendData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      overAllTeamTrendData
    };
  },
  GET_OVERALL_TEAM_TREND_DETAIL_REPORT: (state, action) => {
    const overAllTeamTrendDetailResult = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      overAllTeamTrendDetailResult
    }
  },

  // 5.11 start
  GET_TEAM_SUPERVISOR_LIST_REPORT: (state, action) => {
    const teamSupervisorData = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      teamSupervisorData
    }
  },
  SET_TEAM_SUPERVISOR_LIST_REPORT: (state, action) => {
    const teamSupervisorData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      teamSupervisorData
    };
  },
  // 5.11 End

  // 5.12 start

  GET_STAFF_TREND_BY_SUPERVISOR_REPORT: (state, action) => {
    const staffTrendBySupervisorData = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      staffTrendBySupervisorData
    }
  },
  SET_STAFF_TREND_BY_SUPERVISOR_REPORT: (state, action) => {
    const staffTrendBySupervisorData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendBySupervisorData
    };
  },
  GET_STAFF_TREND_BY_STAFF_RESULT_REPORT: (state, action) => {
    const staffTrendByStaffResultData = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      staffTrendByStaffResultData
    }
  },
  SET_STAFF_TREND_BY_STAFF_RESULT_REPORT: (state, action) => {
    const staffTrendByStaffResultData = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      staffTrendByStaffResultData
    };
  },

  // 5.12 End

  // 5.1 Teams Trends --- End

  // 4.7 Causes --- START
  GET_CAUSES_BY_SCORECARD: (state, action) => {
    const causesByScorecardDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causesByScorecardDetails
    };
  },
  GET_CAUSES_BY_SCORECARD_GRAPH: (state, action) => {
    const causesByScorecardGraphDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causesByScorecardGraphDetails
    };
  },
  GET_CAUSES_BY_QUESTION_GRAPH: (state, action) => {
    const causesByQuestionGraphDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causesByQuestionGraphDetails
    };
  },
  GET_CAUSES_BY_SECTIONS: (state, action) => {
    const causesBySectionsDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causesBySectionsDetails
    };
  },
  GET_CAUSES_BY_QUESTION: (state, action) => {
    const causesByQuestionDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causesByQuestionDetails
    };
  },
  GET_CAUSES_BY_IND_QUESTION: (state, action) => {
    const causesByIndQuestionDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causesByIndQuestionDetails
    };
  },
  GET_CAUSES_REPORT_RESULTS: (state, action) => {
    const causesReportResults = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      causesReportResults
    };
  },
  // 4.7 Causes --- END
  // 5.2 
  GET_POINTS_OF_FAILURE_TEAM: (state, action) => {
    const pointsOfFailureByTeamDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      pointsOfFailureByTeamDetails
    };
  },
  GET_POINTS_OF_FAILURE_SCORECARD: (state, action) => {
    const pointsOfFailureByScorecardDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      pointsOfFailureByScorecardDetails
    };
  },
  GET_POINTS_OF_FAILURE_TEAM_RESULT: (state, action) => {
    const pointsOfFailureByTeamResultDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      pointsOfFailureByTeamResultDetails
    };
  },
  GET_POINTS_OF_FAILURE_SCORECARD_RESULT: (state, action) => {
    const pointsOfFailureByScorecardResultDetails = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      pointsOfFailureByScorecardResultDetails
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const AnalyticProviders = ({ children }) => {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
  // STATES
  const initialState = {
    userLevels: []
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showSpinner, setShowSpinner] = useState();
  const [isLoadingTimeRange, setIsLoadingTimeRange] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // -------------Team Table---------------------------------------------------
  const [acceptanceByTeamData, setacceptanceByTeamData] = useState([]);

  // --------------------------------------------------------------------------
  /**
   * * Staff Module states
   */
  // 1.1 Overall Staff Trend
  const [selDuration, setSelDuration] = useState([]);
  const [subModLabel, setSubModLabel] = useState([]);
  const [selAnaFilters, setSelAnaFilters] = useState(JSON.parse(window.localStorage.getItem('selAnaFilters')));
  const [selAnaStaffId, setSelAnaStaffId] = useState();
  const [timeBtnGroupStaTrend, setTimeBtnGroupStaTrend] = useState('week');
  const [timeBtnGroupQueTrend, setTimeBtnGroupQueTrend] = useState('week');
  const [staffTrendResDetailsData, setStaffTrendResDetailsData] = useState({
    results: [],
    count: 0
  });

  // 1.4 Overall Trend By Individual
  const [timeBtnGroup, setTimeBtnGroup] = useState('week');
  // filters state
  const [selectedDateAnaRange, setSelectedAnaDateRange] = useState(JSON.parse(window.localStorage.getItem('selectedAnaDateRange')));
  const [selectedFilterBy, setSelectedFilterBy] = useState(JSON.parse(window.localStorage.getItem('selectedFilterBy')));
  const [selectedFilterByValue, setSelectedFilterByValue] = useState(JSON.parse(window.localStorage.getItem('selectedFilterByValue')));
  const [selectedFilterEventType, setSelectedFilterEventType] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsEventFilter')));
  const [selectedFilterSubType, setSelectedFilterSubType] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsEventFilter')));
  const [selectedFilterEventTypeStaffTrend, setSelectedFilterEventTypeStaffTrend] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsEventFilter')));
  const [selectedFilterSubTypeStaffTrend, setSelectedFilterSubTypeStaffTrend] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsEventFilter')));
  const [selectedStaFilter, setselectedStaFilter] = useState(JSON.parse(window.localStorage.getItem('selectedStaFilter')));
  const [selectedStaSingleFilter, setSelectedStaSingleFilter] = useState();
  const [selectedGroupFilter, setSelectedGroupFilter] = useState(JSON.parse(window.localStorage.getItem('selectedGroupFilter')));
  const [selectedTeamFilter, setSelectedTeamFilter] = useState(JSON.parse(window.localStorage.getItem('selectedTeamFilter')));
  const [selectedStaffFilter, setSelectedStaffFilter] = useState(JSON.parse(window.localStorage.getItem('selectedStaffFilter')));
  const [selectedSupervisorFilter, setSelectedSupervisorFilter] = useState(JSON.parse(window.localStorage.getItem('selectedSupervisorFilter')));
  const [selectedFromDate, setSelectedFromDate] = useState(JSON.parse(window.localStorage.getItem('selectedFromDate')));
  const [selectedToDate, setSelectedToDate] = useState(JSON.parse(window.localStorage.getItem('selectedToDate')));

  const [selectedFDate, setSelectedFDate] = useState();
  const [selectedTDate, setSelectedTDate] = useState();
  // ----------------------------------------------------------------------------

  /**
   * 1.6 section state
   */

  // from date to date filter state ---
  const [selectedStaffFromDate, setSelectedStaffFromDate] = useState();
  const [selectedStaffToDate, setSelectedStaffToDate] = useState();
  const [selectedStaffFromWeek, setSelectedStaffFromWeek] = useState(JSON.parse(window.localStorage.getItem('fromWeek')));
  const [selectedStaffToWeek, setSelectedStaffToWeek] = useState(JSON.parse(window.localStorage.getItem('toWeek')));

  const [selScDurationInd, setSelScDurationInd] = useState('week');
  const [selectedScoreCardSingle, setSelectedScoreCardSingle] = useState(JSON.parse(window.localStorage.getItem('selectedScoreCardSingle')));
  // const [selectedScoreCardSingle, setSelectedScoreCardSingle] = useState();

  /**
   * 1.10 section state
   */

  const [causesByStaffData, setCausesByStaffData] = useState([]);
  const [causesByStaffLoading, setCausesByStaffLoading] = useState(false);

  /**
 * 1.8 section by event type state
 */

  const [sectionByEventTypeData, setSectionByEventTypeData] = useState([]);
  const [sectionByEventTypeGraphData, setSectionByEventTypeGraphData] = useState([]);
  const [sectionByEventTypeGraphCategory, setSectionByEventTypeGraphCategory] = useState([]);
  const [sectionByEventTypeLoading, setSectionByEventTypeLoading] = useState(false);
  const [selectedEventTypeFilter, setSelectedEventTypeFilter] = useState(JSON.parse(window.localStorage.getItem('selectedFilterEventType')));
  const [selectedEventSubTypeFilter, setSelectedEventSubTypeFilter] = useState(JSON.parse(window.localStorage.getItem('selectedFilterSubType')));

  /**
 * 1.7 section by result
 */

  const [sectionByResultData, setSectionByResultData] = useState([]);
  const [sectionByResultGraphData, setSectionByResultGraphData] = useState([]);
  const [sectionByResultGraphCategory, setSectionByResultGraphCategory] = useState([]);
  const [sectionByResultLoading, setSectionByResultLoading] = useState(false);

  // filters state
  const [selectedDateAnaScRangeInd, setSelectedDateAnaScRangeInd] = useState();
  const [selectedScFilterByInd, setSelectedScFilterByInd] = useState();
  const [selectedScGroupFilterInd, setSelectedScGroupFilterInd] = useState();
  const [selectedScTeamFilterInd, setSelectedScTeamFilterInd] = useState();
  const [selectedScSupervisorFilterInd, setSelectedScSupervisorFilterInd] = useState();
  const [selectedScSecFilterInd, setSelectedScSecFilterInd] = useState();
  const [selectedScFromDateInd, setSelectedScFromDateInd] = useState();
  const [selectedScToDateInd, setSelectedScToDateInd] = useState();
  const [questionTrendIndividualData, setQuestionTrendIndividualData] = useState([])
  const [isQuesIndiLoading, setIsQuesIndiLoading] = useState(false);

  // 1.9 Kudos By Staff ---------

  const [dateTypeFlag, setDateTypeFlag] = useState('week')
  const [kudosByStaffData, setKudosByStaffData] = useState([])
  const [kudosByStaffResultDetails, setKudosByStaffResultDetails] = useState()
  const [kudosByStaffWithDataTags, setKudosByStaffWithDataTags] = useState()
  const [dataTagByScoreCard, setDataTagByScoreCard] = useState([])
  const [selectedDataTag, setselectedDataTag] = useState(JSON?.parse(localStorage?.getItem('selectedDataTagValue')) ?? []);


  // --------------------------------------------------------------------------
  /**
   * * Scorecards Module states
   */
  //  4.1 Overall Trend

  const [overallScoreCardFilters, setOverallScoreCardFilters] = useState(JSON.parse(window.localStorage.getItem('selScorecardFilters')));
  const [overallTrendResDetailsData, setOverallTrendResDetailsData] = useState({
    results: '',
    count: ''
  });
  // 4.4 Question Trend

  const [selScDuration, setSelScDuration] = useState("week");
  const [selAnaScStaffId, setSelAnaScStaffId] = useState();
  const [selAnaScoreCardFilters, setSelAnaScoreCardFilters] = useState(JSON.parse(window.localStorage.getItem('selAnaScoreCardFilters')));
  const [selAnaTeamsFilters, setSelAnaTeamsFilters] = useState(JSON.parse(window.localStorage.getItem('selAnaTeamsFilters')));

  // 4.5 Section Trend
  const [dateTypeSecTrend, setDateTypeSecTrend] = useState('week');
  const [sectionTrendData, setSectionTrendData] = useState([])
  const [warning, setWarning] = useState('')
  // filters state
  const [selectedDateAnaScRange, setSelectedDateAnaScRange] = useState(JSON.parse(window.localStorage.getItem('selectedDateAnaScRange')));
  const [selectedScFilterBy, setSelectedScFilterBy] = useState(JSON.parse(window.localStorage.getItem('selectedScFilterBy')));
  const [selectedScFilter, setselectedScFilter] = useState(JSON.parse(window.localStorage.getItem('selectedScFilter')));
  const [selectedScMultipleFilter, setselectedScMultipleFilter] = useState(JSON.parse(window.localStorage.getItem('selectedScMultipleFilter')));
  const [selectedScGroupFilter, setSelectedScGroupFilter] = useState(JSON.parse(window.localStorage.getItem('selectedScGroupFilter')));
  const [selectedScTeamFilter, setSelectedScTeamFilter] = useState(JSON.parse(window.localStorage.getItem('selectedScTeamFilter')));
  const [selectedScSupervisorFilter, setSelectedScSupervisorFilter] = useState(JSON.parse(window.localStorage.getItem('selectedScSupervisorFilter')));
  const [selectedScSecFilter, setSelectedScSecFilter] = useState();
  const [selectedScFromDate, setSelectedScFromDate] = useState(JSON.parse(window.localStorage.getItem('selectedScFromDate')));
  const [selectedScToDate, setSelectedScToDate] = useState(JSON.parse(window.localStorage.getItem('selectedScToDate')));
  const [selectedScFromWeek, setSelectedScFromWeek] = useState();
  const [selectedScToWeek, setSelectedScToWeek] = useState();
  const [selectedScFilterByValue, setSelectedScFilterByValue] = useState(JSON.parse(window.localStorage.getItem('selectedScFilterByValue')));
  const [selectedScEventType, setSelectedScEventType] = useState(JSON.parse(window.localStorage.getItem('selectedScEventType')));
  const [selectedScStaff, setSelectedScStaff] = useState(JSON.parse(window.localStorage.getItem('selectedScStaff')));
  const [selectedScSubType, setSelectedScSubType] = useState(JSON.parse(window.localStorage.getItem('selectedScSubType')));
  const [selectedScFormula, setSelectedScFormula] = useState();
  const [selectedScHighlight, setSelectedScHighlight] = useState();
  const [selectedScNumericValue, setSelectedScNumericValue] = useState();
  // 5 module state
  const [selectedDateAnaTeamsRange, setSelectedDateAnaTeamsRange] = useState(JSON.parse(window.localStorage.getItem('selectedDateAnaTeamsRange')));
  const [selectedTeamsFilterBy, setSelectedTeamsFilterBy] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsFilterBy')));
  const [selectedTeamsFilterByValue, setSelectedTeamsFilterByValue] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsFilterByValue')));
  const [selectedTeamsGroupFilter, setSelectedTeamsGroupFilter] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsGroupFilter')));
  const [selectedTeamsEventFilter, setSelectedTeamsEventFilter] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsEventFilter')));
  const [selectedTeamsEventSubTypeFilter, setSelectedTeamsEventSubTypeFilter] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsEventSubTypeFilter')));

  const [selectedTeamsFromDate, setSelectedTeamsFromDate] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsFromDate')));
  const [selectedTeamsToDate, setSelectedTeamsToDate] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsToDate')));
  const [selectedTeamsHighlight, setSelectedTeamsHighlight] = useState();
  const [selectedTeamsFromWeek, setSelectedTeamsFromWeek] = useState();
  const [selectedTeamsToWeek, setSelectedTeamsToWeek] = useState();

  const [pointsOfFailureByTeamResultDetailsData, setPointsOfFailureByTeamResultDetailsData] = useState({
    results: '',
    count: ''
  });

  // 5.3
  const [quesTrendByIndTeam, setQuesTrendByIndTeam] = useState([])
  const [quesTrendResByIndTeam, setQuesTrendResByIndTeam] = useState([])
  const [singleScoreCard, setSingleScoreCard] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsScorecardFilter')))
  const [selectedTeamStaffFilter, setSelectedTeamStaffFilter] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsStaffFilter')))
  // 5.4
  const [sectionTrendByTeam, setSectionTrendByTeam] = useState([])
  const [sectionTrendByTeamRes, setSectionTrendByTeamRes] = useState([])
  const [multipleScoreCard, setMultipleScoreCard] = useState(JSON.parse(window.localStorage.getItem('selectedTeamsMultiScorecardFilter')))
  const [selectedTeamsSupervisorFilter, setSelectedTeamsSupervisorFilter] = useState()
  const [selectedTeamFilterDialog, setSelectedTeamFilterDialog] = useState()
  /**
* 4.6 section by result
*/
  const [commentData, setCommentData] = useState([]);
  const [commentType, setCommentType] = useState("10");
  const [commentWordGraph, setCommentWordGraph] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);

  /**
* 4.9 compliance By Question
*/
  const [complianceByQuestionData, setComplianceByQuestionData] = useState([]);
  const [complianceByDateType, setComplianceByDateType] = useState("month");
  const [complianceByGraphData, setComplianceByGraphData] = useState([]);
  const [complianceByGraphCategory, setComplianceByGraphCategory] = useState([]);
  const [complianceByLoading, setComplianceByLoading] = useState(false);
  const [complianceByQuestionDetailArray, setComplianceByQuestionDetailArray] = useState([]);

  // 4.8 
  const [failAllOnly, setFailAllOnly] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(false)


  // 4.7
  const [fullscreenOn, setFullscreenOn] = useState();

  // ----------------------------------------------------------------------------

  /**
   * * Staff Module functions
   */
  // 1.1 Overall Staff Trend

  // List all staff trend
  const getStaffTrend = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/staff_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&date_type=${timeBtnGroupStaTrend}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        dispatch({
          type: 'GET_STAFF_TREND_REPORT',
          payload: results
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // Get analytics report in xls format
  const downloadAnalyticsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/staff_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&supervisor_id=${obj.supervisor}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&date_type=${obj.flag}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.1 Staff Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get analytics report for graph in xls format
  const downloadAnalyticsGraphXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/staff_trend/xlxs_staff_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&is_distribution_graph=${obj.isDistributionGraph}&from_percentage=${obj.fromPercentage}&to_percentage=${obj.toPercentage}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.1 Staff Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get analytics report for graph in xls format
  const downloadAnalyticsBelowTargetXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&below_target=${true}&above_target=&export=${true}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.1 Staff Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get analytics report for graph in xls format
  const downloadAnalyticsAboveTargetXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&below_target=&above_target=${true}&export=${true}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.1 Staff Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get analytics report for graph in xls format
  const downloadAnalyticsAblovBelowXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/staff_trend/xlxs_staff_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&from_percentage=${0}&to_percentage=${101}&is_distribution_graph="True"&graph_ids=${obj.graphIds}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.1 Staff Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };


  // Get analytics report for graph in xls format
  const downloadAnalyticsEmployeeXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&export=${true}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.1 Staff Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  // Get analytics report for graph in xls format
  const downloadAnalyticsTimeRangeXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&${`${obj.createdAtKey}=${obj.createdAtValue}`}&below_target=&above_target=&export=${true}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.1 Staff Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // List staff trend ditribution details--
  const getStaffTrendDistribution = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/staff_trend/staff_trend_distribution_graph?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&flag=${timeBtnGroupStaTrend === 'quarter' ? 'year' : timeBtnGroupStaTrend}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_DIS',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // List staff trend above and below graph
  const getStaffTrendAboBelow = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/staff_trend/staff_trend_above_below_graph?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&flag=${timeBtnGroupStaTrend === 'quarter' ? 'year' : timeBtnGroupStaTrend}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_ABOBELOW',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // List staff trend result details
  const getStaffTrendResult = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&page=${page}&${`${obj.createdAtKey}=${obj.createdAtValue}`}&below_target=&above_target=&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&from_date=${obj?.fromDate}&to_date=${obj?.toDate}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        // const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_RESULTS',
          payload: { results, count }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // List staff trend result details
  const getStaffTrendResultAboveBelow = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/staff_trend/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&evaluator=${obj?.evaluator}&flag=${obj.flag}&from_percentage=${0}&to_percentage=${101}&is_distribution_graph="True"&graph_ids=${obj.graphIds}&page=${page}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_RESULTS',
          payload: { results, count }
        });
        setIsLoading(false);

      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);

      });
  };

  // List staff trend result details for below target
  const getStaffTrendBelowResultTarget = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&page=${page}&below_target=${true}&above_target=&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_RESULTS',
          payload: { results, count }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // List staff trend result details for below target
  const getStaffTrendAboveResultTarget = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&page=${page}&below_target=&above_target=${true}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_RESULTS',
          payload: { results, count }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // List staff trend result details
  const getStaffTrendResultEmployee = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&page=${page}&below_target=&above_target=&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_RESULTS',
          payload: { results, count }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };


  // List staff trend result details
  const getStaffTrendResultDistribution = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/staff_trend/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&evaluator=${obj?.evaluator}&from_percentage=${obj?.fromPercentage}&to_percentage=${obj?.toPercentage}&is_distribution_graph=${obj?.isDistributionGraph}&flag=${obj.flag}&score_card__in=${obj.scoreCard}&page=${page}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_RESULTS',
          payload: { results, count }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);

      });
  };

  // Get result details in xls
  const downloadTrendResDetXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/staff_trend/xlxs_staff_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj.evaluator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  const newdownloadResultDetailsEvaluationXls = async (obj, module, subModule) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: !obj?.dateKey ?
        `${process.env.REACT_APP_API_URL}/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj?.teams || ''}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj?.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&target=${obj?.target || ""}&export=1`
        :
        `${process.env.REACT_APP_API_URL}/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj?.teams || ''}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&target=${obj?.target || ""}&export=1`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');
      // handlePromptMessage("Downloading File...", 'info', 1500)
      const outputFilename = `${module}.${subModule}_Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {

      setShowSpinner(false);
      throw Error(error);
    }
  };

  const downloadResultDetailsEvaluationXls = async (obj, module, subModule) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/staff_trend/xlxs_staff_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj.evaluator}&from_percentage=${0}&to_percentage=${101}&is_distribution_graph=True`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);
      // handlePromptMessage("Downloading File...", "info", 1500)
      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `${module}.${subModule}_Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {

      setShowSpinner(false);
      throw Error(error);
    }
  };

  const downloadResultDetailsEvaluationPerPeriodXls = async (obj, module, subModule) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      // url: `${process.env.REACT_APP_API_URL}/staff_trend/xlxs_staff_result_details_log?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj.evaluator}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}`,
      url: `${process.env.REACT_APP_API_URL}/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj.evaluator}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&export=True`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);
      // handlePromptMessage("Downloading File...", 'info', 1500)
      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `${module}.${subModule}_result_details_${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {

      setShowSpinner(false);
      throw Error(error);
    }
  };


  // 1.2 Question By Staff ---START---

  const [isLoadingQuestionStaffPage, setIsLoadingQuestionStaffPage] = useState(false)
  const [isLoadingQuestionStaffResPage, setIsLoadingQuestionStaffResPage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // list of all Question by Staff
  const getQuestionByStaff = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingQuestionStaffPage(true)
    dispatch({
      type: 'GET_QUESTION_BY_STAFF_REPORT',
      payload: ''
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&score_card=${obj.scoreCard}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&formula=${obj.formula || 10}&reference=${obj.reference}&staff_type=10`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        dispatch({
          type: 'GET_QUESTION_BY_STAFF_REPORT',
          payload: results
        });
        setErrorMessage('')
        setIsLoadingQuestionStaffPage(false);
      })
      .catch((error) => {
        console.error('this error occure', error);
        if (error?.code === 'ERR_NETWORK') {
          downloadQuestionByStaffAnalyticsXlsBigData(obj);
        }
        dispatch({
          type: 'GET_QUESTION_BY_STAFF_REPORT',
          payload: ''
        });
        setErrorMessage(error.message)
        setIsLoadingQuestionStaffPage(false);
      });

  };

  // list of Question by Staff Result details
  const getQuestionByStaffResultDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingQuestionStaffResPage(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff_question_details?question_id=${obj.questionId}&staff_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&reference=${obj.reference}&search=${obj?.search || ''}&page=${obj.page}&staff__staff_type=10`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        // const { results } = humps.camelizeKeys(response?.data);
        const results = response?.data;
        dispatch({
          type: 'GET_QUESTION_BY_STAFF_RESULTS_DETAILS',
          payload: results
        });
        setIsLoadingQuestionStaffResPage(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingQuestionStaffResPage(false);
      });
  };

  // Get analytics report in xls format
  const downloadQuestionByStaffAnalyticsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingQuestionStaffPage(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/xlxs_question_staff_log?filter_by=${obj.filterBy}&from_date=${obj.fromDate}&to_date=${obj.toDate}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&formula=${obj.formula || '10'}&reference=${obj.reference}&staff_type=10&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.2 Question By Staff - ${currentDateTime}.xlsx`;

      setIsLoadingQuestionStaffPage(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setIsLoadingQuestionStaffPage(false);
      throw Error(error);
    }
  };

  const downloadQuestionByStaffAnalyticsXlsBigData = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingQuestionStaffResPage(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/xlxs_question_staff_log?filter_by=${obj.filterBy}&from_date=${obj.fromDate}&to_date=${obj.toDate}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&formula=${obj.formula || '10'}&reference=${obj.reference}&staff_type=10&send_email=true`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        // const { results } = humps.camelizeKeys(response?.data);
        setIsLoadingQuestionStaffResPage(false);
        const results = response?.data?.message;
        setErrorMessage(results)
        // dispatch({
        //   type: 'GET_QUESTION_BY_STAFF_RESULTS_DETAILS',
        //   payload: results
        // });
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingQuestionStaffResPage(false);
      });
  };

  // get detailed analytics report n xls format
  const downloadQuestionByStaffDetailsAnalyticsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff_question_details?question_id=${obj.questionId}&staff_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&reference=${obj.reference}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {

      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.2 Question By Staff Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
    }
  };
  // 1.2 Question By Staff ---END---


  // 1.3 Answers By Staff -----START

  const [selectedSectionFilter, setSelectedSelectionFilter] = useState(JSON.parse(window.localStorage.getItem('selectedSelectionFilter')))
  const [selectedQuestionFilter, setSelectedQuestionFilter] = useState(JSON.parse(window.localStorage.getItem('selectedQuestionFilter')))
  // list of all Answers by Staff
  const getAnswerByStaff = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/answers_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&score_card=${obj.scoreCard}&staff=${obj.staff}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&question=${obj.question}&highlight_below=${obj.highlightValue}&section=${obj.section}&formula=${obj.formula}&reference=${obj.reference}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        dispatch({
          type: 'GET_ANSWER_BY_STAFF_REPORT',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // list of Answer by Staff Distribution
  const getAnswerByStaffDistribution = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/distribution_of_answers?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&score_card=${obj.scoreCard}&staff=${obj.staff}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&question=${obj.question}&section=${obj.section}&highlight_below=${obj.highlightValue}&formula=${obj.formula}&reference=${obj.reference}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        dispatch({
          type: 'GET_ANSWER_BY_STAFF_DISTRIBUTION_REPORT',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // get Answer by Staff analytics report in xls format
  const downloadAnswerByStaffAnalyticsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // {{baseurl}}/analytic_staff/answers_by_staff?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=8&export=True
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/answers_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&score_card=${obj.scoreCard}&staff=${obj.staff}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&section=${obj.section}&question=${obj.question}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&reference=${obj.reference}&export=True`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.3 Answer By Staff - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // get Answer by Staff analytics report in xls format
  const downloadAnswerByStaffDistributionAnalyticsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/distribution_of_answers?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&score_card=${obj.scoreCard}&staff=${obj.staff}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&question=${obj.question}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&reference=${obj.reference}&export=True`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.3 Answer By Staff Distribution - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // list of Answers by Staff Result details
  const getAnswersByStaffResultDetails = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff_question_details?question_id=${obj.questionId}&answer_id=${obj.answerId}&staff_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&reference=${obj.reference}&page=${pageNo}&search=${obj?.search || ''}`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        // const { results } = humps.camelizeKeys(response?.data);
        const results = response?.data;
        dispatch({
          type: 'GET_ANSWER_BY_STAFF_RESULTS_DETAILS',
          payload: results
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  // get detailed analytics report in xls format
  const downloadAnswersByStaffResultDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff_question_details?question_id=${obj.questionId}&answer_id=${obj.answerId}&staff_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&reference=${obj.reference}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.3 Answer By Staff Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 1.3 Answers by Staff ends ---------

  // 1.4 Start

  // List all staff trend
  const getStaOverTrendByInd = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/overall_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${timeBtnGroup === 'quarter' ? 'year' : timeBtnGroup}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&data_tags=${obj?.dataTags}&data_tags_operator=${obj?.dataTagsOperator}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_IND',
          payload: results
        });
        // setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // setIsLoading(false);
      });
  };

  // Get analytics report in xls format
  const downloadTrendIndXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/overall_trend_individual/xlxs_staff_individual_result_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.4 Staff Trend Ind - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  const getStaffTrendIndResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/overall_trend_individual/staff_individual_result_details?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&data_tags=${obj?.dataTags}&data_tags_operator=${obj?.dataTagsOperator}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&from_date=${obj.fromDate}&to_date=${obj.toDate}&page=${obj.page}&search=${obj.search}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_STAFF_TREND_IND_RESULTS',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // 1.5
  const [switchView, setSwitchView] = useState(false);
  const [numeric, setNumeric] = useState(false);
  const [sectionTrndData, setSectionTrndData] = useState([]);
  const [sectionGraphTrndData, setSectionGraphTrndData] = useState([]);
  const [sectionGraphCategory, setSectionGraphCategory] = useState([]);
  const [isSectionLoading, setIsSectionLoading] = useState(false);
  const [dateType, setDateType] = useState("month");


  /**
   *
   * @description get question trend list 1.6 section api
   */
  const [questionTrendIndividualReportDetails, setQuestionTrendIndividualReportDetails] = useState([])
  const [questionTrendIndividualRes, setQuestionTrendIndividualRes] = useState([])

  const getQuestionTrendIndividual = async (obj) => {
    setIsQuesIndiLoading(true)

    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&staff=${obj.staff}&score_card__groups=${obj.group}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&section_id=${obj.section}&numeric_values=${obj.numericValues}&formula=${obj.formula}&data_tags=${obj.dataTags}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        setIsQuesIndiLoading(false)
        setQuestionTrendIndividualReportDetails(results)
      })
      .catch((error) => {
        setIsQuesIndiLoading(false)
        console.error(error);

      });
  };

  /**
   *
   * @description 1.6 section question trend individual details
   */

  const handleWeekMonthYear = (obj) => {
    if (obj?.month) return 'month'

    if (obj?.week) return 'week'

    if (obj?.quarter) return 'quarter'
  }
  const getWeekMonthYear = (obj) => {
    if (obj?.month) return obj?.month;
    if (obj?.week) return obj?.week;
    if (obj?.quarter) return obj?.quarter;
  }

  const getQuesTrendIndividualResult = async (obj) => {
    setIsQuesIndiLoading(true)
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/question_details?created_at__${handleWeekMonthYear(obj)}=${getWeekMonthYear(obj)}&created_at__year=${obj.year}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&numeric_values=${obj.numericValues}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&flag=${obj.flag}&staff_id=${obj.staff}&question_id=${obj.questionId}&formula=${obj.formula}&section_id=${obj.sectionId}&section=${obj.section}&from_date=${obj.fromDate}&to_date=${obj.toDate}&formula=${obj.formula}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&page=${obj.page}&search=${obj.search}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setIsQuesIndiLoading(false)
        const { data } = humps.camelizeKeys(response);
        setQuestionTrendIndividualRes(data)
      })
      .catch((error) => {
        console.error(error);
        setIsQuesIndiLoading(false);
      });
  };

  /**
   * 
   * @description  download list in xls form of 1.6 section 
   */
  const downloadQuestionTrendIndividualListXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_staff_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&flag=${obj.flag || ''}&formula=${obj.formula}&staff_id=${obj.staff}&section_id=${obj.section}&numeric_values=${obj.numericValues}&data_tags=${obj.dataTags}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.6 Question Trend Individual List- ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  /**
   * 
   * @description  download result details in xls form of 1.6 section 
   */
  const downloadQuestionTrendIndividualDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    const handleWeekMonthYear = (obj) => {
      if (obj.month) return 'month'

      if (obj.week) return 'week'

      if (obj.quarter) return 'quarter'
    }
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_staff_inside_result_details_log?created_at__${handleWeekMonthYear(obj)}=${obj.week || obj.month || obj.quarter}&created_at__year=${obj.year}&numeric_values=${obj.numericValues}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__groups=${obj.group}&supervisor_id=${obj.supervisor}&staff_id=${obj.staffId}&question_id=${obj.questionId}&section_id=${obj.sectionId}&section=${obj.section}&from_date=${obj.fromDate}&to_date=${obj.toDate}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&formula=${obj.formula}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.6 Question Trend Individual Detail - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };


  // 1.9 Kudos By Staff -----------------------------------

  const getKudosByStaff = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/kudos_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_below=${obj.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setKudosByStaffData(data)
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // 1.9 Download Kudos By Staff main page ----

  const downloadKudosByStaff = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/kudos_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_below=${obj.highlightValue}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.9 Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 1.9 Kudos Api with data tags

  const getKudosByStaffWithDataTags = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/kudos_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&date_type=${obj.flag || ''}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_below=${obj.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        // setKudosByStaffWithDataTags(data)
        setKudosByStaffData(data)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 1.9 module api to xls download a kuos By staff with data tags

  const downloadKudosByStaffWithDataTags = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/kudos_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&date_type=${obj.flag || ''}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_below=${obj.highlightValue}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.9 Kudos By Staff - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  // 1.9 Kudos Result Detials Api -----

  const [kudosLoading, setKudosLoading] = useState(false)
  const getKudosByStaffResultDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setKudosLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard || ''}&score_card__groups=${obj.group || ''}&is_kudos=${obj.isKudos}&staff=${obj.staff}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || ''}&search=${obj.search || ''}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&page=${obj.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setKudosByStaffResultDetails(data)
        setKudosLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setKudosLoading(false)
      });
  };

  // 1.9 Result details xls api

  const downloadKudosByStaffResultDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&is_kudos=${obj.isKudos}&staff=${obj.staff}&score_card__in=${obj.scoreCard || ''}&score_card__groups=${obj.group || ''}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || '10'}&search=${obj.search}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.9 Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // common api to Get Data tags from score id for all the module ------

  const getDataTagsByScoreCard = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios.get(
      `${process.env.REACT_APP_API_URL}/reports/score_log_data_tags?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}`,
      {
        headers: {
          Authorization: `Token ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        if (data?.status === 200) {
          setDataTagByScoreCard(data?.data)

        } else if (data?.status === 400) {
          setDataTagByScoreCard([])

        }
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        setDataTagByScoreCard([])

        // setShowSpinner(false);
      });
  };

  // 1.10 RESULT DETAILS
  const getCauseByStaffResult = async (obj, module, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&user_id=${obj.user_id || ''}&staff=${obj.staff || ''}&cause_id=${obj?.cause_id || ''}&page=${pgNo || 1}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&search=${obj.filterName || ''}&supervisor_id=${obj.supervisor || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'CAUSE_BY_STAFF_RESULTS',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // Get analytics report in xls format
  const downloadStaTrendResultXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/overall_trend_individual/xlxs_staff_individual_result_details_log?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&data_tags=${obj?.dataTags}&data_tags_operator=${obj?.dataTagsOperator}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&from_date=${obj.fromDate}&to_date=${obj.toDate}&page=${obj.page}&search=${obj?.search || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `1.4 Result Details- ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 2.1 section state 

  const [selAnaSupervisorFilters, setSelAnaSupervisorFilters] = useState();
  const [supervisorPerScoreCardResponse, setSupervisorPerScoreCardResponse] = useState([])
  const [selectedSupervisorTeamFilter, setSelectedSupervisorTeamFilter] = useState();
  const [selectedSupervisorGroupFilter, setSelectedSupervisorGroupFilter] = useState();
  const [selectedSupervisorFilterBy, setSelectedSupervisorFilterBy] = useState();
  const [selectedSupervisorFromDate, setSelectedSupervisorFromDate] = useState();
  const [selectedSupervisorEventTypeFilter, setSelectedSupervisorEventTypeFilter] = useState();
  const [selectedSupervisorScorecardFilter, setSelectedSupervisorScorecardFilter] = useState();
  const [selectedSupervisorEventSubTypeFilter, setSelectedSupervisorEventSubTypeFilter] = useState();
  const [selectedSupervisorHighlightFilter, setSelectedSupervisorHighlightFilter] = useState();
  const [selectedSupervisorEvaluatorFilter, setSelectedSupervisorEvaluatorFilter] = useState();
  const [selectedSupervisorToDate, setSelectedSupervisorToDate] = useState();
  const [selectedSupervisorToWeek, setSelectedSupervisorToWeek] = useState();
  const [selectedSupervisorFromWeek, setSelectedSupervisorFromWeek] = useState();
  const [selectedSupervisorResultDetails, setSelectedSupervisorResultDetails] = useState([])
  const [supervisorSectionDetailApiRes, setSupervisorSectionDetailApiRes] = useState([])
  const [supervisorBySectionSuperIdApiRes, setSupervisorBySectionSuperIdApiRes] = useState([])
  const [supervisorStaffBySectionApiRes, setSupervisorStaffBySectionApiRes] = useState([])
  const [selectedSupervisorDateAnaRange, setSelectedSupervisorDateAnaRange] = useState();
  const [selectedSupervisorDataTagFilter, setselectedSupervisorDataTagFilter] = useState([]);
  const [selectedSupervisorDataTagOperator, setselectedSupervisorDataTagOperator] = useState();
  const [loading, setLoading] = useState(false)

  /**
   * 
   * @description 2.1 Section Api-1
   */
  //
  const getSupervisorBySectionDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/supervisor_by_section_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType || ''}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {

        const results = humps.camelizeKeys(response?.data);
        setSupervisorPerScoreCardResponse(results)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 2.1 section 2nd api---2
  const getSupervisorBySectionSuperId = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/supervisor_by_section_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&formula=${obj.formula}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setSupervisorBySectionSuperIdApiRes(results)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 2.1 section staff section api3
  const getStaffBySectionResultDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&staff_id=${obj.staff}&search=${obj?.search ?? ''}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || '10'}&page=${obj.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setSupervisorStaffBySectionApiRes(results)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 2.1 section supervisor section details---Api4
  const getSupervisorSectionDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setLoading(true)
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/supervisor_section_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&section_id=${obj.section}&staff_id=${obj.staff}&formula=${obj.formula}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setSupervisorSectionDetailApiRes(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });
  };

  // 2.1 supervisor by section details export api ----
  const supervisorSectionDetailExportXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/supervisor_by_section_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.1 Supervisor By Section Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 2.1 superviosr by section details xls api -2
  const supervisorSectionDetailWithSuperIdExportXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/supervisor_by_section_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj?.supervisor}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.1 Supervisor By Section Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 2.1 section staff details page xls api--
  const supervisorStaffDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj?.supervisor}&staff_id=${obj.staff}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.1 Supervisor By Section Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 2.1 section staff details page xls api--
  const supervisorSectionDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/supervisor_section_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj?.supervisor}&staff_id=${obj.staff}&section_id=${obj.section}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.1 Superviosr By Section Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };


  /**
   * 
   * @description 2.2 POINTS OF FAILURE BY SUPERVISORS ---starts---
   * 
   */


  const [pointOfFailureSupervisorsData, setPointOfFailureSupervisorsData] = useState([]);
  const [pointOfFailureSupervisorsLoading, setPointOfFailureSupervisorsLoading] = useState(false);
  const [pointOfFailureSupervisorsGraphData, setPointOfFailureSupervisorsGraphData] = useState(false);
  const [pointOfFailureSupervisorsGraphCategory, setPointOfFailureSupervisorsGraphCategory] = useState(false);


  // 2.2 RESULT DETAILS process.env.REACT_APP_API_URL

  const getPointsOfFailureSupDetails = async (obj, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.filterName || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&search=${obj.search || ''}&supervisor_id=${obj?.supervisor}&page=${pgNo || 1}`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        setIsLoading(false)

        dispatch({
          type: 'POINTS_OF_FAILURE_BY_SUPERVISOR',
          payload: results
        });
      })
      .catch((error) => {
        setIsLoading(false)

        console.error(error);
      });
  };

  // 2.2 fail All section api & Fail All Percentage download in result page -- 
  const getPointsOfFailureFailAllSupDetails = async (obj, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true)

    axios
      .get(
        // http://test-api.beyondqa.io/analytic_groups/points_of_failure_result_details?from_date=2024-01-01&to_date=2024-01-31&filter_by=created_at&score_card__groups=73&event_type=&event_sub_type=&search=&result_details_choice=10&page=1&supervisor_id=&score_card__in=&data_tags=&data_tags_operator=
        `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.search || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&result_details_choice=10&supervisor_id=${obj?.supervisor || ''}&page=${pgNo || 1}`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        setIsLoading(false)

        dispatch({
          type: 'POINTS_OF_FAILURE_BY_SUPERVISOR',
          payload: results
        });
      })
      .catch((error) => {
        setIsLoading(false)

        console.error(error);
      });
  };

  // 2.2 fail All section api & Fail All Percentage  download in result page
  const downPointsOfFailureFailAllSupDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url:
        `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.filterName || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&result_details_choice=10&supervisor_id=${obj?.supervisor || ''}&page=&export=True`,

      responseType: 'arraybuffer',
      headers
    };

    try {

      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.2_Points_Of_Failure_Supervisor_details_${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
    }
  };

  // 2.2 fail all results api & fail all percentage  
  const getPointOfFailureFailAllResultSupDetails = async (obj, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.search || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&supervisor__id=${obj?.supervisor}&page=${pgNo || 1}&result_details_choice=20`,

        // `${process.env.REACT_APP_API_URL}/points_of_failure_supervisor/supervisor_point_of_failure_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventSubType}&highlight_value&score_card__in=${obj?.scorecard}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&supervisor__id=${obj?.supervisor}&page=${pgNo}`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        setIsLoading(false)

        dispatch({
          type: 'POINTS_OF_FAILURE_BY_SUPERVISOR',
          payload: results
        });
      })
      .catch((error) => {
        setIsLoading(false)

        console.error(error);
      });
  };

  const downPointOfFailureFailAllResultSupDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url:
        `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.filterName || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&supervisor__id=${obj?.supervisor}&page=&result_details_choice=20&export=True`,
      responseType: 'arraybuffer',
      headers
    };

    try {

      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.2_Points_Of_Failure_Supervisor_details_${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
    }
  };

  // 2.2 fail all results api & fail all percentage  
  const getPointOfFailureFailAllSectionSupDetails = async (obj, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.search || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&supervisor__id=${obj?.supervisor}&page=${pgNo || 1}&result_details_choice=30`,

        // `${process.env.REACT_APP_API_URL}/points_of_failure_supervisor/supervisor_point_of_failure_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventSubType}&highlight_value&score_card__in=${obj?.scorecard}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&supervisor__id=${obj?.supervisor}&page=${pgNo}`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        setIsLoading(false)

        dispatch({
          type: 'POINTS_OF_FAILURE_BY_SUPERVISOR',
          payload: results
        });
      })
      .catch((error) => {
        setIsLoading(false)

        console.error(error);
      });
  };

  const downPointOfFailureFailAllSectionSupDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url:
        `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.filterName || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&supervisor__id=${obj?.supervisor}&page=&result_details_choice=30&export=True`,
      responseType: 'arraybuffer',
      headers
    };

    try {

      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.2_Points_Of_Failure_Supervisor_details_${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
    }
  };

  const SupervisorPointsOfFailureDetailsDownloadXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url:
        `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj?.scorecard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventSubType}&search=${obj.filterName || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&search=${obj.search || ''}&supervisor_id=${obj?.supervisor}&page=&export=True`,

      // `${process.env.REACT_APP_API_URL}/points_of_failure_supervisor/xlxs_point_of_failure_details_log?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventSubType}&highlight_value&score_card__in=${obj?.scorecard}&status=${obj?.status}&supervisor__id=${obj?.supervisor}&data_tags=${obj?.dataTags || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {

      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.2_Points_Of_Failure_Supervisor_details_${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
    }
  };

  // 2.2 POINTS OF FAILURE BY SUPERVISORS ---ends---

  // 2.3 Supervisor By Questions Listing Api
  const [supervisorByQuestionData, setSupervisorQuestionData] = useState([])
  const getSupervisorByQuestion = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&score_card=${obj.scoreCard}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&formula=${obj.formula}&staff__staff_type__gte=20`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        setIsLoading(false);
        setSupervisorQuestionData(results)
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // Supervisor By Questions Xls Api

  // 2.3 Supervisor By Questions Details Listing Api
  const getSupervisorByQuestionDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff_question_details?question_id=${obj.questionId}&supervisor_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&formula=${obj.formula}&search=${obj.search}&page=${obj.page}&staff__staff_type__gte=20`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        // const { results } = humps.camelizeKeys(response?.data);
        const results = response?.data;
        dispatch({
          type: 'GET_QUESTION_BY_SUPERVISOR_DETAILS_REPORT',
          payload: results
        });
        setIsLoading(false);

        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const dowSupervisorByQuestionAnalyticsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/xlxs_question_staff_log?filter_by=${obj.filterBy}&from_date=${obj.fromDate}&to_date=${obj.toDate}&score_card__groups=${obj.scoreCardGroups}&score_card=${obj.scoreCard}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&formula=${obj.formula}&staff__staff_type__gte=20`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.3 Supervisor By Question - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  const downSupervisorByQuestionDetailsAnalyticsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/question_by_staff_question_details?question_id=${obj.questionId}&supervisor_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&highlight_value=${obj.highlightValue}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&formula=${obj.formula}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {

      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.3 Supervisor By Question Details Analytics Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
    }
  };

  // 2.3 Ends

  /**
   * 
   * @description 2.4 Result Acceptence --
   * 
   */

  const [resultAcceptanceListRes, setResultAcceptanceListRes] = useState([])
  const [acceptanceByResultApiRes, setAcceptanceByResultApiRes] = useState([])

  const getResultAcceptanceBySupervisor = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/result_acceptance_by_supervisor_list?from_date=${obj?.fromDate || moment().format("YYYY-MM-DD")}&to_date=${obj?.toDate || moment().format("YYYY-MM-DD")}&filter_by=${obj?.filterBy || "created_at"}&score_card=${obj.scoreCard || ''}&score_card__groups=${obj.scoreCardGroups || ''}&event_type=${obj.eventType || ''}&event_sub_type=${obj.eventTypeEventSubType || ''}&teams=${obj.teams || ''}&evaluator_id=${obj.evaluator || ''}&search=${obj.filterName || ""}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {

        const results = humps.camelizeKeys(response?.data);
        setResultAcceptanceListRes(results)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 2.4 Result acceptance by supervisor  this api is used in 6.7 module also--
  const getAcceptanceByResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/result_acceptance_by_supervisor_score_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&teams=${obj.teams}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType || ''}&evaluator_id=${obj.evaluator}&supervisor_id=${obj.supervisor}&is_score_viewed=${obj.isScoreViewed || ''}&is_score_accepted=${obj.isScoreAccepted || ''}&is_onetoone_req=${obj.isOneToOneReq || ''}&is_score_reviewed=${obj.isScoreReviewed || ''}&no_action=${obj.noAction || ''}&search=${obj.search || ''}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&page=${obj.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {

        const results = humps.camelizeKeys(response?.data);
        setAcceptanceByResultApiRes(results)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 2.4 download xls api main-page
  const downloadResultAcceptanceList = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/result_acceptance_by_supervisor_list?from_date=${obj?.fromDate || moment().startOf('month').format('YYYY-MM-DD')}&to_date=${obj?.toDate || moment().endOf('month').format('YYYY-MM-DD')}&filter_by=${obj?.filterBy || "created_at"}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType || ''}&evaluator_id=${obj.evaluator}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.4 Result Acceptance By Supervisor Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 2.4 download xls api Acceptance by result page--
  const downloadAcceptanceByResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/result_acceptance_by_supervisor_score_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType || ''}&evaluator_id=${obj.evaluator}&supervisor_id=${obj.supervisor}&is_score_viewed=${obj.isScoreViewed || ''}&is_score_accepted=${obj.isScoreAccepted || ''}&is_onetoone_req=${obj.isOneToOneReq || ''}&is_score_reviewed=${obj.isScoreReviewed || ''}&no_action=${obj.noAction || ''}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || '10'}&page=${obj.page}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `2.4 Result Acceptance By Supervisor Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 3.1 Module Api-----
  const [scoringPatternsData, setScoringPatternsData] = useState([])
  const [isLoadingScore, setIsLoadingScore] = useState(false)
  const [resultData1, setResultData1] = useState('')
  const [resultData2, setResultData2] = useState('')

  const getScoringPatterns = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingScore(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_below=${obj.highlightBelow}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setScoringPatternsData(data)
        setIsLoadingScore(false)
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingScore(false);
      });
  };

  const downloadScoringPatternsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_below=${obj.highlightBelow}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `3.1 Scoring Patterns-${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  // 3.1 Result details api For evaluator, date value ---

  const handleScoringDateType = (obj) => {
    if (obj?.filterBy === 'event_date') {
      if (obj.flag === 'month') {
        return 'event_date__month'
      }
      if (obj.flag === 'week') {
        return 'event_date__week'
      }
      if (obj.flag === 'quarter') {
        return 'event_date__quarter'
      }
    } else if (obj?.filterBy === "created_at") {
      if (obj.flag === 'month') {
        return 'created_at__month'
      }
      if (obj.flag === 'week') {
        return 'created_at__week'
      }
      if (obj.flag === 'quarter') {
        return 'created_at__quarter'
      }
    } else if (obj?.filterBy === 'updated_at') {
      if (obj.flag === 'month') {
        return 'updated_at__month'
      }
      if (obj.flag === 'week') {
        return 'updated_at__week'
      }
      if (obj.flag === 'quarter') {
        return 'updated_at__quarter'
      }
    }
  }

  const getScoringDateType = (obj) => {
    if (obj?.filterBy === 'event_date') {
      if (obj.flag === 'month') {
        return obj?.eventDateMonth
      }
      if (obj.flag === 'week') {
        return obj?.eventDateWeek
      }
      if (obj.flag === 'quarter') {
        return obj?.eventDateQuarter
      }
    } else if (obj?.filterBy === 'created_at') {
      if (obj.flag === 'month') {
        return obj?.createdAtMonth
      }
      if (obj.flag === 'week') {
        return obj?.createdAtWeek
      }
      if (obj.flag === 'quarter') {
        return obj?.createdAtQuarter
      }
    } else if (obj?.filterBy === 'updated_at') {
      if (obj.flag === 'month') {
        return obj?.updatedAtMonth
      }
      if (obj.flag === 'week') {
        return obj?.updatedAtWeek
      }
      if (obj.flag === "quarter") {
        return obj?.updatedAtQuarter
      }
    }
  }

  const handleDateTypeYear = (item) => {
    if (item?.filterBy === 'created_at') {
      return "created_at__year"
    }
    if (item?.filterBy === 'event_date') {
      return "event_date__year"
    }
    if (item?.filterBy === 'updated_at') {
      return "updated_at__year"
    }
  }
  const getDateTypeYear = (item) => {
    if (item?.filterBy === 'created_at') {
      return item?.createdAtYear
    }
    if (item?.filterBy === 'event_date') {
      return item?.eventDateYear
    }
    if (item?.filterBy === 'updated_at') {
      return item?.updatedAtYear
    }
  }
  const getScoringResultApi1 = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingScore(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&evaluator=${obj.evaluator}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.search}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setResultData1(results)
        setIsLoadingScore(false)
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingScore(false);
      });
  };

  // result details downlaod api -1

  const downloadXlsInResultPageApi1 = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&evaluator=${obj.evaluator}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `3.1 Scoring Patterns-${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 3.1 Result details api For Under Target/Above Target APIs ---
  const getScoringResultApi2 = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingScore(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&evaluator=${obj.evaluator}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&score_result=${obj.scoreResult}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.search}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setResultData1(results)
        setIsLoadingScore(false)
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingScore(false);
      });
  };

  // 3.1 result details api 2 for under target/Above target -----
  const downloadXlsInResultPageApi2 = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&evaluator=${obj.evaluator}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&score_result=${obj.scoreResult}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `3.1 Scoring Patterns-${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 3.4
  const getEvaluationByStaffResult = async (obj, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        // `${process.env.REACT_APP_API_URL}/staff_trend/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${pgNo}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&from_percentage=${obj?.fromPercentage}&to_percentage=${obj?.toPercentage}&is_distribution_graph=${obj?.isDistributionGraph}`,
        !obj?.dateKey ? `${process.env.REACT_APP_API_URL}/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj?.teams || ''}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj?.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&page=${pgNo}&target=${obj?.target || ""}` : `${process.env.REACT_APP_API_URL}/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj?.teams || ''}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj?.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&page=${pgNo}&target=${obj?.target || ""}`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_EVALUATION_BY_STAFF',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  const getEvaluationPerPeriodResultDetails = async (obj, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        // `${API}/staff_trend/staff_result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&page=${pgNo}`,
        !obj?.dateKey ? `${process.env.REACT_APP_API_URL}/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj?.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&page=${pgNo || ''}&target=${obj?.target || ""}&data_tags=${obj?.dataTag || ""}&data_tags_operator=${obj?.dataTagsOperator || ''}` : `${process.env.REACT_APP_API_URL}/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj?.filterName || ''}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&page=${pgNo || ''}&target=${obj?.target || ""}`,

        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_PER_PERIOD_RESULTS',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // 3 EVALUATION FILTER

  // FILTERS
  // filters state
  const [selectedEvaluationDateAnaRange, setSelectedEvaluationAnaDateRange] = useState();
  const [selectedEvaluationHighlight, setSelectedEvaluationHighlight] = useState();
  const [selectedEvaluationDataTags, setSelectedEvaluationDataTags] = useState([]);
  /**
  * @description 3.2 state
  */
  const [perPeriodGraphData, setPerPeriodGraphData] = useState([]);
  const [perPeriodGraphCategory, setPerPeriodGraphCategory] = useState([]);
  const [perPeriodData, setPerPeriodData] = useState([])
  const [perPeriodLoading, setPerPeriodLoading] = useState(false)
  const [perPeriodCount, setPerPeriodCount] = useState(0)
  const [perPeriodDateType, setPerPeriodDateType] = useState(localStorage.getItem('dateType') || 'month');


  /**
  * @description 3.3 state
  */

  const [perScorecardGraphData, setPerScorecardGraphData] = useState([]);
  const [perScorecardGraphCategory, setPerScorecardGraphCategory] = useState([]);
  const [perScorecardData, setPerScorecardData] = useState([])
  const [perScorecardLoading, setPerScorecardLoading] = useState(false)
  const [perScorecardCount, setPerScorecardCount] = useState(0)
  const [perScorecardDateType, setPerScorecardDateType] = useState(localStorage.getItem('flagType') || 'month');

  /**
  * @description 3.4 state
  */

  const [perScorecardByStaffGraphData, setPerScorecardByStaffGraphData] = useState([]);
  const [perScorecardByStaffGraphCategory, setPerScorecardByStaffGraphCategory] = useState([]);
  const [perScorecardByStaffData, setPerScorecardByStaffData] = useState([])
  const [perScorecardByStaffLoading, setPerScorecardByStaffLoading] = useState(false)
  const [perScorecardByStaffCount, setPerScorecardByStaffCount] = useState(0)
  const [perScorecardByStaffDateType, setPerScorecardByStaffDateType] = useState('month');



  /**
   * @description 3.5 filter state
   */

  const [selAnaEvaluationFilters, setSelAnaEvaluationFilters] = useState();
  const [evaluationPerScoreCardResponse, setEvaluationPerScoreCardResponse] = useState([])
  const [selectedEvaluationTeamFilter, setSelectedEvaluationTeamFilter] = useState();
  const [selectedEvaluationGroupFilter, setSelectedEvaluationGroupFilter] = useState();
  const [selectedEvaluationFilterBy, setSelectedEvaluationFilterBy] = useState();
  const [selectedEvaluationFromDate, setSelectedEvaluationFromDate] = useState();
  const [selectedEvaluationToDate, setSelectedEvaluationToDate] = useState();
  const [selectedEvaluationScoreCard, setSelectedEvaluationScoreCard] = useState();
  const [selectedEvaluationScoreCardMultiple, setSelectedEvaluationScoreCardMultiple] = useState(JSON.parse(localStorage.getItem('scoreCardMutliple')));
  const [selectedEvaluationEventType, setSelectedEvaluationEventType] = useState();
  const [selectedEvaluationEventSubType, setSelectedEvaluationEventSubType] = useState();
  const [selectedEvaluationfromWeek, setSelectedEvaluationfromWeek] = useState();
  const [selectedEvaluationtoWeek, setSelectedEvaluationtoWeek] = useState();
  const [selectedEvaluationsResultDetails, setSelectedEvaluationsResultDetails] = useState([])

  /**
  * @description 3.6 filter state
  */
  const [reviewByEvaluatorGraphData, setReviewByEvaluatorGraphData] = useState([]);
  const [reviewByEvaluatorGraphCategory, setReviewByEvaluatorGraphCategory] = useState([]);
  const [reviewByEvaluatorData, setReviewByEvaluatorData] = useState([])
  const [reviewByEvaluatorLoading, setReviewByEvaluatorLoading] = useState(false)
  const [reviewByEvaluatorCount, setReviewByEvaluatorCount] = useState(0)
  const [reviewByEvaluatorDateType, setReviewByEvaluatorDateType] = useState('month');

  /**
   * @description 3.7 filter state
   */
  const [reviewBySupervisorGraphData, setReviewBySupervisorGraphData] = useState([]);
  const [reviewBySupervisorGraphCategory, setReviewBySupervisorGraphCategory] = useState([]);
  const [reviewBySupervisorData, setReviewBySupervisorData] = useState([])
  const [reviewBySupervisorLoading, setReviewBySupervisorLoading] = useState(false)
  const [reviewBySupervisorCount, setReviewBySupervisorCount] = useState(0)
  const [reviewBySupervisorDateType, setReviewBySupervisorDateType] = useState(localStorage.getItem('dateType') || 'month');


  /**
   * @description Api for 3.5 section main page-----
   */
  const getEvaluationPerScoreCard = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_evaluation/evaluations_per_scorecard_by_evaluator?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&highlight_below=${obj.highlightBelow}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setEvaluationPerScoreCardResponse(results)
        // dispatch({
        //   type: 'GET_SECTION_TREND_REPORT',
        //   payload: results
        // });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * download Xls api for 3.5 sectoin main page---
   */
  const dwnldXlsEvaluationPerEvaluator = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_evaluation/evaluations_per_scorecard_by_evaluator?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `3.5 Evaluation Per Scorecard By Evaluatoe - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  /**
   *  3.5 section Api for scorecard evaluation based on evaluator
   */
  const evaluationScorecardBasedOnEvaluator = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_evaluation/evaluations_per_scorecard_by_evaluator_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&evaluator=${obj.evaluator}&score_card=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&page=${obj.page}&search=${obj.search || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setSelectedEvaluationsResultDetails(results)
        // dispatch({
        //   type: 'GET_STAFF_TREND_RESULTS',
        //   payload: results
        // });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };
  /**
   * download xls api for 3.5 section in result details page
   */
  const downloadEvaluationPerEvaluatorResDetXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_evaluation/evaluations_per_scorecard_by_evaluator_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj?.group}&teams=${obj?.teams}&evaluator=${obj.evaluator || ''}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&page=${obj.page}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 3.7 result details methods //

  const getEvaluationReviewBySupervisorResultDetails = async (obj, pgNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);
    const response = axios
      .get(
        !obj?.dateKey ?
          `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.filterName}&supervisor_id=${obj.supervisor}&evaluator_id=${obj?.evaluator}&is_score_reviewed=${obj.isScoreReviewed}&score_card__in=${obj?.scoreCard}` :
          `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.filterName}&supervisor_id=${obj.supervisor}&is_score_reviewed=${obj.isScoreReviewed}&evaluator_id=${obj?.evaluator}&score_card__in=${obj?.scoreCard}&page=${pgNo}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_REVIEW_BY_SUPERVISOR_RESULTS',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  }

  const downloadEvaluationReviewBySupervisorXls = async (obj, subModule) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: !obj?.dateKey ? `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.filterName}&supervisor_id=${obj.supervisor}&evaluator_id=${obj.evaluator}&is_score_reviewed=${obj.isScoreReviewed}&score_card__in=${obj?.scoreCard}&export=True` : `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.filterName}&supervisor_id=${obj.supervisor}&evaluator_id=${obj?.evaluator}&is_score_reviewed=${obj.isScoreReviewed}&score_card__in=${obj?.scoreCard}&export=True`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `3.${subModule}_Review_By_${subModule === 7 ? 'Supervisors' : 'Evaluators'}_Result_Details_${currentDateTime}.xlsx`;
      // handlePromptMessage("Downloading File...", "info", 1500)
      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {

      setShowSpinner(false);
      throw Error(error);
    }
  };


  // Scorecard -> 4.1 ( Overall trends internal details excel file download function)
  const dowScorecardOverallTrendXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/overall_trend/xlxs_overall_trend_result_details_log?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue || ''}&supervisor=${obj.supervisor}&score_card__in=${obj.scoreCardIn}&staff_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&search=${''}`,
      // url: `${process.env.REACT_APP_API_URL}/overall_trend/xlxs_overall_trend_result_details_log?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue || ''}&supervisor=${obj.supervisor}&score_card__in=${obj.scoreCardIn}&staff_id=${obj.staffId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.1 Overall Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Scorecard -> 4.1 ( Overall trends Home excel file download function)
  const dowScorecardOverallTrendsHomeXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/overall_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&staff_id=${obj.staffId}&date_type=${selScDuration}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&export=${true}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.1 Overall Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Scorecard -> 4.1 ( Overall trends listing process.env.REACT_APP_API_URL function)
  const getOverAllTrend = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/overall_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${selScDuration}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = response?.data;
        dispatch({
          type: 'GET_OVERALL_TREND_REPORT',
          payload: results
        });
        setIsLoading(false);
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        // setShowSpinner(false);
      });
  };
  const setOverAllTrend = async (obj) => {
    dispatch({
      type: 'SET_OVERALL_TREND_REPORT',
      payload: obj
    });
  };

  // Scorecard -> 4.1 ( Overall trends detailed result of single user process.env.REACT_APP_API_URL function)

  const getOverallTrendResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${obj.dateKey}=${obj.dateValue}&year=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&search=${obj.search || ''}&date_type=${obj.flag}&from_date=${obj.fromDate}&to_date=${obj.toDate}&page=${obj.page}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        // setOverallTrendResDetails(results)
        dispatch({
          type: 'GET_SCORECARD_OVERALL_RESULTS',
          payload: { results, count }
        });
        setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // 4.2 Event Type Trend-------

  const [eventDetailsLoading, setEventDetailsLoading] = useState(false)
  const [eventTypeTrendApiRes, setEventTypeTrendApiRes] = useState([])
  const [eventTypeTrendDetailsApiRes, setEventTypeTrendDetailsApiRes] = useState([])
  const [eventSubTypeTrendDetailsApiRes, setEventSubTypeTrendDetailsApiRes] = useState([])
  const [eventSubTypeTrendApiRes, setEventSubTypeTrendApiRes] = useState([])
  const [selWeekMonthYearDuration, setSelWeekMonthYearDuration] = useState("week")

  const handleEventWeekMonthYear = (obj) => {
    if (obj?.month !== 'undefined') return 'month'

    if (obj?.week !== 'undefined') return 'week'

    if (obj?.quarter !== 'undefined') return 'quarter'
  }
  const getEventWeekMonthYear = (obj) => {
    if (obj?.month !== 'undefined') return obj?.month;
    if (obj?.week !== 'undefined') return obj?.week;
    if (obj?.quarter !== 'undefined') return obj?.quarter;

  }
  const getEventTypeTrend = async (obj) => {
    setIsLoading(true)

    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    setEventTypeTrendApiRes([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/event_type_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag || 'week'}&highlight_value=${obj.highlightValue}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__groups=${obj.group}&teams=${obj.teams}&supervisor__id=${obj.supervisor}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps?.camelizeKeys(response?.data);
        setIsLoading(false)
        setEventTypeTrendApiRes(results)
        setShowSpinner(false);

      })
      .catch((error) => {
        console.error(error);
        setShowSpinner(false);
        setIsLoading(false)

      });
  };

  const getEventTypeTrendDetails = async (obj) => {
    setEventDetailsLoading(true)
    const accessToken = window.localStorage.getItem('accessToken');
    setLoading(true);
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/event_type_trend_details?filter_by=${obj.filterBy}&event_type=${obj.eventType}&score_card__in=${obj.scoreCard}&created_at__${handleEventWeekMonthYear(obj)}=${getEventWeekMonthYear(obj)}&created_at__year=${obj.year}&score_card__groups=${obj.scoreCardGroups || obj.group || ''}&teams=${obj.teams}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&from_date=${obj.fromDate}&to_date=${obj.toDate}&teams=${obj.teams}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&search=${obj?.search || ''}&page=${obj?.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setEventTypeTrendDetailsApiRes(results)
        setEventDetailsLoading(false)
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setEventDetailsLoading(false)

      });
  };

  const getEventSubType = async (obj) => {
    setIsLoading(true)
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/event_sub_type_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups || obj.group || ''}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&teams=${obj.teams}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        setEventSubTypeTrendApiRes(results)
        setShowSpinner(false);
        setIsLoading(false)

      })
      .catch((error) => {
        setShowSpinner(false);
        setIsLoading(false)

      });
  };

  const getEventSubTypeTrendDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setLoading(true);
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/event_sub_type_trend_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&created_at__${handleEventWeekMonthYear(obj)}=${getEventWeekMonthYear(obj)}&created_at__year=${obj.year}&score_card__groups=${obj.group || obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType || ''}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&search=${obj?.search || ''}&page=${obj?.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setEventSubTypeTrendDetailsApiRes(results)
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const downloadEventTypeTrendXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_event_type_result_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&flag=${obj.flag}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__groups=${obj.group}&teams=${obj.teams}&supervisor__id=${obj.supervisor}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.2 Event Type Trend Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // const handleWeekMonthYear2 = (obj) => {
  //   if (obj?.week) return 'week'
  //   if (obj?.month) return 'month'
  //   if (obj?.quarter) return 'quarter'
  // }
  // const getWeekMonthYear2 = (obj) => {
  //   if (obj?.week) return obj?.week;
  //   if (obj?.month) return obj?.month;
  //   if (obj?.quarter) return obj?.quarter;
  // }

  // 4.2 Event type Trend Details api--
  const downloadEventTypeTrendDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_event_type_trend_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&created_at__${handleEventWeekMonthYear(obj)}=${getEventWeekMonthYear(obj)}&created_at__year=${obj.year}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&highlight_value=${obj.highlightValue}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__groups=${obj.group}&teams=${obj.teams}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.2 Event Type Trend Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  const downloadEventSubTypeTrendXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_event_sub_type_result_log?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__groups=${obj.group}&flag=${obj.flag}&teams=${obj.teams}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.2 Event Type Trend Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  // 4.2 EventSubTypeTrendDetails xls api---

  const downloadEventSubTypeTrendDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_event_sub_type_trend_details_log?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&created_at__${handleEventWeekMonthYear(obj)}=${getEventWeekMonthYear(obj)}&created_at__year=${obj.year}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&event_sub_type_id=${obj.eventTypeEventSubType}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&export=${true}&teams=${obj.teams}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.2 Event Type Trend Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 4.2 END---

  // 4.3 Performance against target

  const [performanceAgainstTargetData, setPerformanceAgainstTargetData] = useState([])
  const [performanceAgainstTargetResultData, setPerformanceAgainstTargetResultData] = useState([])
  const [performanceAgainstEventSubTypeData, setPerformanceAgainstEventSubTypeData] = useState([])

  // 4.3 Api#1 -----
  const getPerformanceAgainstTarget = async (obj) => {
    setIsLoading(true)
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/performance_against_target?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor || ''}&highlight_value=${obj.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps?.camelizeKeys(response?.data);
        setPerformanceAgainstTargetData(results ?? '')
        setIsLoading(false)

      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false)

      });
  };

  const downloadPerformanceAgainstTargetXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/performance_against_target?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor || ''}&highlight_value=${obj.highlightValue}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.3 Performance Against Target Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 4.3 event sub type -------
  const getPerformanceAgainstEventSubType = async (obj) => {
    setIsLoading(true)
    const accessToken = window.localStorage.getItem('accessToken');

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/event_sub_type_trend_in_performance_against_target?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setPerformanceAgainstEventSubTypeData(results)
        setIsLoading(false)

      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false)

      });
  };

  const downloadPerformanceAgainstEventSubXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/event_sub_type_trend_in_performance_against_target?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.3 Performance Against Target Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  const handleDateTypeEvent = (obj) => {
    if (obj?.month) return 'month'
    if (obj?.week) return 'week'
    if (obj?.quarter) return 'quarter'
  }
  const getDateTypeEvent = (obj) => {
    if (obj?.month) return obj?.month
    if (obj?.week) return obj?.week
    if (obj?.quarter) return obj?.quarter
  }
  // performance result details page process.env.REACT_APP_API_URL#2-------
  const getPerformanceAgainstTargetResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&created_at__${handleDateTypeEvent(obj)}=${getDateTypeEvent(obj)}&created_at__year=${obj.year}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor}&highlight_value=${obj.highlightValue}&below_target=${obj.belowTarget}&above_target=${obj.aboveTarget}&search=${obj?.search || ''}&page=${obj.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setPerformanceAgainstTargetResultData(results)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // download 4.3 ---
  const downloadResultDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&created_at__${handleDateTypeEvent(obj)}=${getDateTypeEvent(obj)}&created_at__year=${obj.year}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor}&highlight_value=${obj.highlightValue}&below_target=${obj.belowTarget}&above_target=${obj.aboveTarget}&page=${obj.page}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.3 Performance Against Target Xls - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 4.4 Question Trend ---START---

  // List all question trend
  const getQuestionTrend = async (obj) => {

    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&section_id=${obj.section}&supervisor__id=${obj.supervisor}&numeric_values=${obj.numericValues === true ? 'True' : 'False'}&formula=${obj.formula}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_QUESTION_TREND_REPORT',
          payload: results
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // List question trend result details
  const getQuesTrendResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/question_details?staff_id=${obj.staffId}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardIn}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&numeric_values=${obj.numericValues === true ? 'True' : 'False'}&formula=${obj.formula}&question_id=${obj.questionId}&from_date=${obj.fromDate}&to_date=${obj.toDate}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || '10'}&page=${obj.page || 1}&search=${obj.search || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response);
        setLoading(false)
        dispatch({
          type: 'GET_QUES_TREND_RESULTS',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)

        // setShowSpinner(false);
      });
  };

  // Get question trend analytics report in xls format
  const dowAnaQuesTrendXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_staff_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor__id=${obj.supervisor}&section_id=${obj.section}&formula=${obj.formula}&numeric_values=${obj.numericValues === true ? 'True' : 'False'}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || '10'}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.4 Question Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Get result details in xls
  const dowQueTreDetXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_staff_inside_result_details_log?filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&page=${obj.page}&search=${obj.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&question_id=${obj.questionId}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&from_date=${obj.fromDate}&to_date=${obj.toDate}&formula=${obj.formula}&numeric_values=${obj.numericValues === true ? 'True' : 'False'}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator || '10'}&page=${obj.page || 1}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `Question Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 4.4 Question Trend ---END---

  // 4.5 Section Trend
  // List all section trend
  const getSectionTrend = async (obj) => {
    setLoading(true)
    setSectionTrendData([])

    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/section_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&section=${obj.section}&supervisor=${obj.supervisor}&data_tags=${obj.dataTag}&data_tags_operator=${obj?.dataTagsOperator || ''}&formula=${obj.formula}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setLoading(false)
        const results = response?.data;
        setSectionTrendData(results)
        setWarning('')
      })
      .catch((error) => {
        setLoading(false)
        console.error(error);
        if (error.name === "AxiosError" || error.code === "ERR_NETWORK" || error.message === "Network Error") {
          setWarning('Too much data to load please reduce the date range')
        }
      });
  };

  // Get section by trend in xls format also used in 5.4 module and 4.5
  const dowSecByTrendXls = async (obj, fileName) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: fileName === '4.5 Section Trend' ? `${process.env.REACT_APP_API_URL}/analytic_staff/section_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&formula=${obj.formula}&numeric=${obj.numeric}&export=${true}` :
        `${process.env.REACT_APP_API_URL}/analytic_staff/section_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&formula=${obj.formula}&numeric=${obj.numeric}&export=${true}&teams_trend=true`,
      responseType: 'arraybuffer',
      headers
    };
    enqueueSnackbar('Downloading file...', {
      variant: 'info',
      autoHideDuration: 2000,
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `${fileName} - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {

      setShowSpinner(false);
      // re-calling the api so that if download data is large then api is failed with 504 time out status code
      if (error.name === "AxiosError" || error.code === "ERR_NETWORK" || error.message === "Network Error") {
        // enqueueSnackbar('Downloading file...', {
        //   variant: 'info',
        //   autoHideDuration: 5000,
        //   action: (key) => (
        //     <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //       <Icon icon={closeFill} />
        //     </MIconButton>
        //   )
        // });
        const config = {
          method: 'GET',
          url: fileName === '4.5 Section Trend' ? `${process.env.REACT_APP_API_URL}/analytic_staff/section_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&formula=${obj.formula}&numeric=${obj.numeric}&export=${true}&send_email=${true}` :
            `${process.env.REACT_APP_API_URL}/analytic_staff/section_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&formula=${obj.formula}&numeric=${obj.numeric}&export=${true}&teams_trend=true`,
          responseType: 'arraybuffer',
          headers
        };
        setShowSpinner(true);

        const response = await axios(config);
        enqueueSnackbar('Too much information to load. As soon as the export is finished, you will receive an email.', {
          variant: 'info',
          autoHideDuration: 2000,
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } else {
        setShowSpinner(false);
        enqueueSnackbar('Downloading file...', {
          variant: 'info',
          autoHideDuration: 1500,
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }

      // throw Error(error);
    }
  };

  // Get section by trend details in xls format used in 5.4 and 4.5 module
  const dowSecByTrendDetailsXls = async (obj, fileName) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/section_details_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&export=${true}&date_num=${obj.dateNum}&section=${obj.section}&formula=${obj.formula}&numeric=${obj.numeric}&date_year=${obj.year}&data_tags=${obj?.dataTag}&data_tags_operator=${obj?.dataTagsOperator}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `${fileName} - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      console.error('Erros occure while downloading', error)
      throw Error(error);
    }
  };

  // 4.7 Causes by Scorecard ---START---
  // List causes by scorecard
  const getCausesByScorecard = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_by_scorecard?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data?.results);
        dispatch({
          type: 'GET_CAUSES_BY_SCORECARD',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // List causes by scorecard graph
  const getCausesByScorecardGraph = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_by_scorecard_graph?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&data_tags=${obj.dataTag}&data_tags_operator=${obj.dataTagsOperator}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data?.results);
        dispatch({
          type: 'GET_CAUSES_BY_SCORECARD_GRAPH',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // List causes by question graph
  const getCausesByQuestionGraph = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_by_scorecard_graph?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&section_id=${obj.sectionId}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data?.results);
        dispatch({
          type: 'GET_CAUSES_BY_QUESTION_GRAPH',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Get causes by scorecard details in xls format
  const dowCausesByScorecardXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_causes_by_scorecard_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.7 Overall Team Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // List causes by sections
  const getCausesBySections = async (obj) => {

    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_by_section?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data?.results);
        dispatch({
          type: 'GET_CAUSES_BY_SECTIONS',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // List causes by questions
  const getCausesByQuestion = async (obj) => {

    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_by_questions?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&section_id=${obj.sectionId}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data?.results);
        dispatch({
          type: 'GET_CAUSES_BY_QUESTION',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // List causes by Individual questions
  const getCausesByIndQuestion = async (obj) => {

    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_by_individual_questions?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCardMultiple}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&question_id=${obj.questionId}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data?.results);
        dispatch({
          type: 'GET_CAUSES_BY_IND_QUESTION',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // List causes report results
  const getCausesReportResults = async (obj) => {

    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.group}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&question_id=${obj.questionId}&cause_id=${obj.causeId}&page=${obj.page}&search=${obj.filterName}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_CAUSES_REPORT_RESULTS',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Get causes by scorecard details in xls format
  const dowCausesReportResultsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_causes_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&supervisor__id=${obj.supervisor}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&question_id=${obj.questionId}&cause_id=${obj.causeId}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);
      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');
      const outputFilename = `4.7 Result Details - ${currentDateTime}.xlsx`;
      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 4.7 Causes by Scorecard ---END---


  // 4.8 Causes Trend ---START---
  const handleCauseTrendDateType = (obj) => {
    if (obj?.filterBy === 'event_date') {
      if (obj.flag === 'month') {
        return 'event_date__month'
      }
      if (obj.flag === 'week') {
        return 'event_date__week'
      }
      if (obj.flag === 'quarter') {
        return 'event_date__quarter'
      }
    } else if (obj?.filterBy === "created_at") {
      if (obj.flag === 'month') {
        return 'created_at__month'
      }
      if (obj.flag === 'week') {
        return 'created_at__week'
      }
      if (obj.flag === 'quarter') {
        return 'created_at__quarter'
      }
    } else if (obj?.filterBy === 'updated_at') {
      if (obj.flag === 'month') {
        return 'updated_at__month'
      }
      if (obj.flag === 'week') {
        return 'updated_at__week'
      }
      if (obj.flag === 'quarter') {
        return 'updated_at__quarter'
      }
    }
  }

  const getCauseTrendDateType = (obj) => {
    if (obj?.filterBy === 'event_date') {
      if (obj.flag === 'month') {
        return obj?.eventDateMonth
      }
      if (obj.flag === 'week') {
        return obj?.eventDateWeek
      }
      if (obj.flag === 'quarter') {
        return obj?.eventDateQuarter
      }
    } else if (obj?.filterBy === 'created_at') {
      if (obj.flag === 'month') {
        return obj?.createdAtMonth
      }
      if (obj.flag === 'week') {
        return obj?.createdAtWeek
      }
      if (obj.flag === 'quarter') {
        return obj?.createdAtQuarter
      }
    } else if (obj?.filterBy === 'updated_at') {
      if (obj.flag === 'month') {
        return obj?.updatedAtMonth
      }
      if (obj.flag === 'week') {
        return obj?.updatedAtWeek
      }
      if (obj.flag === "quarter") {
        return obj?.updatedAtQuarter
      }
    }
  }
  // Cause Trends Listing Api 
  const [causesTrendDetails, setCausesTrendDetails] = useState([])
  const getCausesTrend = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setShowSpinner(true);
    setIsLoadingPage(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/causes_trend_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&sections=${obj.section}&numeric_view=${obj.numericValues}&supervisor=${obj.supervisor}&questions=${obj.scoreCardQuestions}&fail_all_only=${obj.failAllOnly || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setIsLoadingPage(false)
        setCausesTrendDetails(results)
      })
      .catch((error) => {
        setIsLoadingPage(false)
        console.error(error);

      });
  };

  // comment for some time 28-03-2024
  // const setCausesTrend = async (obj) => {
  //   dispatch({
  //     type: 'SET_CAUSES_TREND_REPORT',
  //     payload: obj
  //   });
  // };

  // Causes Trends Home Xls file 
  const dowCausesTrendsHomeXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/causes_trend_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&sections=${obj.section}&numeric_view=${obj.numericValues}&supervisor=${obj.supervisor}&questions=${obj.scoreCardQuestions}&fail_all_only=${obj.failAllOnly}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.8 Cause Trend Home - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // Causes Trends Details Api Listing 
  const getCausesTrendDetails = async (obj) => {
    setIsLoading(true)
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleCauseTrendDateType(obj)}=${getCauseTrendDateType(obj)}&year=${obj.year}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&causes_id=${obj.causeId}&search=${obj.search || ''}&page=${obj.page}&supervisor_id=${obj.supervisor}&from_date=${obj.fromDate}&to_date=${obj.toDate}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_CAUSES_TREND_DETAILED_REPORT',
          payload: results
        });
        setIsLoading(false)

      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false)

      });
  };

  // Causes Trends Details Xls file
  const dowCausesTrendsDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&year=${obj.year}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&causes_id=${obj.causeId}&page=${obj.page}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.8 Causes Trends Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 4.8 Causes Trend ---END---

  const [selectedDataTagOperators, setSelectedDataTagOperators] = useState('10')
  // 4.10 Data Tags By Event Type -------

  const [dataTagsByEventData, setDataTagsByEventData] = useState('')
  const [dataEventByTags, setDataEventByTags] = useState('')
  const [resultDataEventByTags, setResultDataEventByTags] = useState('')
  const [selectedDataTagFilter, setselectedDataTagFilter] = useState(JSON.parse(localStorage.getItem('selectedDataTag')) ?? []);

  const getDataTagsByEventData = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_scorecards/data_tags_by_event_type?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups || obj?.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card=${obj.scoreCard}&data_tags=${obj.dataTags || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&percentage_view=${obj.percentageView || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setDataTagsByEventData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const downloadDataTagsByEventData = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_scorecards/data_tags_by_event_type?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card=${obj.scoreCard}&data_tags=${obj.dataTags || ''}&data_tags_operator=${obj.dataTagsOperators || ''}&percentage_view=${obj.percentageView || ''}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);
      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');
      const outputFilename = `4.10 Result Details - ${currentDateTime}.xlsx`;
      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 4.10 Get data tag api result page, call after clicking on event ----
  const getDataEventByTagsResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card=${obj.scoreCard}&data_tags=${obj.dataTags || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&search=${obj.search || ''}&page=${obj?.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setResultDataEventByTags(results)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 4.10 Result page api ---, call when click on data tag name
  const getTotalByDataTagResultApi = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card=${obj.scoreCard}&data_tags=${obj.dataTags || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&search=${obj.search || ''}&page=${obj?.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setResultDataEventByTags(results)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const downloadTotalByDataTagResultApi = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card=${obj.scoreCard}&data_tags=${obj.dataTags || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);
      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');
      const outputFilename = `4.10 Result Details - ${currentDateTime}.xlsx`;
      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };


  //  4.11 Customer Reference By Data Tags ---Start---
  const [customerRefDataTagData, setCustomerRefDataTagData] = useState([]);
  const [customerRefDataTagDataDetails, setCustomerRefDataTagDataDetails] = useState([]);
  const [percentageFlag, setPercentageFlag] = useState(false);
  const [customerRefDataTagLoading, setCustomerRefDataTagLoading] = useState(false);
  const [agentData, setAgentData] = useState([]);
  const [isLoadingAgent, setLoadingAgent] = useState(false);
  const [countAgent, setCountAgent] = useState(0);
  const [pageAccpAgnt, setPageAccpAgnt] = useState(0);
  const [filterNameAcceptanceByAgnt, setFilterNameAcceptanceByAgnt] = useState('');


  // 4.11 CUSTOMER BY REF DATA TAGS
  const getCustomerRefDataTagsDetails = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true)
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card=${obj?.scoreCard}&score_card__groups=${obj?.group}&teams=${obj?.teams}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&data_tags_id=${obj?.dataTagsId}&reference=${obj?.reference}&supervisor_id=${obj?.supervisor}&search=${obj?.filterName}&page=${obj?.page || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setCustomerRefDataTagDataDetails(results);
        setIsLoading(false)

      })
      .catch((error) => {
        console.error(error);
        setCustomerRefDataTagDataDetails([]);
        setIsLoading(false)

      });
  };

  const customerRefDataTagsDownloadXLS = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card=${obj?.scoreCard}&score_card__groups=${obj?.group}&teams=${obj?.teams}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&data_tags_id=${obj?.dataTagsId}&reference=${obj?.reference}&supervisor_id=${obj?.supervisor}&search=${obj?.filterName}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `4.11 Result Details-${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };


  //  4.11 Customer Reference By Data Tags ---END---



  // 5 --------- Team Start ---------

  const [isLoadingTeams, setIsLoadingTeams] = useState(false);
  const [flag, setFlag] = useState(localStorage.getItem('flag') || 'week')

  // 5.1 OverAll Team Trends Listing Api 

  const getOverAllTeamTrend = async (obj) => {
    setIsLoadingTeams(true);
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/overall_team_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setIsLoadingTeams(false);
        dispatch({
          type: 'GET_OVERALL_TEAM_TREND_REPORT',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingTeams(false);
      });
  };
  const setOverAllTeamsTrend = async (obj) => {
    dispatch({
      type: 'SET_OVERALL_TEAM_TREND_REPORT',
      payload: obj
    });

  };
  const [overAllSuperviosrTeamData, setOverAllSupervisorteamData] = useState('')
  const getOverAllTeamTrendDetails = async (obj) => {
    setIsLoadingTeams(true);
    // overall_team_trend/team_result_details?from_date=2022-05-10&to_date=2022-12-14&filter_by=created_at&score_card__groups=12&flag=year&is_above=&is_below=True
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/overall_team_trend/team_result_details?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&is_above=${obj.aboveTar}&is_below=${obj.belowTar}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&from_date=${obj.fromDate}&to_date=${obj.toDate}&data_tags=&data_tags_operator=`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data.results);
        setIsLoadingTeams(false);
        dispatch({
          type: 'GET_OVERALL_TEAM_TREND_DETAIL_REPORT',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingTeams(false);
      });
  };

  // OverAll Team Trends Listing  Xls
  const dowOverAllTeamsTrendsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // overall_team_trend/xlxs_team_trend_log?from_date=2022-05-10&to_date=2022-12-12&flag=month&filter_by=created_at&score_card__groups=12"
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/overall_team_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&export=true`,
      // `${process.env.REACT_APP_API_URL}/overall_team_trend/xlxs_team_trend_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=&data_tags_operator=&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.1  OverAll Team Trends - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  // OverAll Teams Trends Details Xls
  const dowOverAllTeamsTrendsDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    let url = '';
    // /analytic_evaluation/scoring_patterns_result_details
    const getUrl = () => {
      if (obj?.fromTarget === "True") {
        url = `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}&page=${obj.page}&export=true&score_result=${obj.scoreResult}`
        // url = `${process.env.REACT_APP_API_URL}/overall_team_trend/xlxs_team_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&is_above=${obj.isAbove}&is_below=${obj.isBelow}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}&page=${obj.page}&data_tags=&data_tags_operator=`
      } else {
        url = `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&is_above=${obj.isAbove}&is_below=${obj.isBelow}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}&page=${obj.page}&export=true`
        // url = `${process.env.REACT_APP_API_URL}/overall_team_trend/xlxs_team_result_details_log?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&is_above=${obj.isAbove}&is_below=${obj.isBelow}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}&page=${obj.page}&data_tags=&data_tags_operator=`
      }
      return url;
    }

    setShowSpinner(true);
    // overall_team_trend/xlxs_team_result_details_log?from_date=2022-05-10&to_date=2022-12-14&filter_by=created_at&score_card__groups=12&flag=year&is_above=&is_below=
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: getUrl(),
      // url: `${process.env.REACT_APP_API_URL}/overall_team_trend/xlxs_team_result_details_log?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&is_above=${obj.isAbove}&is_below=${obj.isBelow}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.1  OverAll Team Trends - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  const [teamSupervisorData, setTeamSupervisorData] = useState([])
  // 5.11 Team Supervisor List ---Start
  const getTeamSupervisorList = async (obj) => {
    setIsLoadingTeams(true);
    // overall_team_trend/team_supervisor_list?from_date=2022-05-10&to_date=2022-12-12&filter_by=created_at&score_card__groups=12&flag=year&teams__id=15
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/overall_team_trend/team_supervisor_list?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        // setOverAllSupervisorteamData(results)
        setTeamSupervisorData(results)
        setIsLoadingTeams(false);
        // dispatch({
        //   type: 'GET_TEAM_SUPERVISOR_LIST_REPORT',
        //   payload: results
        // });
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingTeams(false);
      });
  };
  const setTeamSupervisorList = async (obj) => {
    dispatch({
      type: 'SET_TEAM_SUPERVISOR_LIST_REPORT',
      payload: obj
    });
  };
  const dowTeamSupervisorListXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // overall_team_trend/xlxs_supervisor_team_trend_log?from_date=2022-05-10&to_date=2022-12-12&filter_by=created_at&score_card__groups=12&flag=week&teams__id=15
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/overall_team_trend/team_supervisor_list?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = ` Team SupervisorList - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  // 5.11 Team Supervisor List ---End

  // 5.12 Staff Trend by Supervisor
  const getStaffTrendBySupervisor = async (obj) => {
    setIsLoadingTeams(true);
    // overall_team_trend/team_staff_by_supervisor?from_date=2022-05-10&to_date=2022-12-12&filter_by=created_at&score_card__groups=12&flag=week&teams__id=15&supervisor__id=75
    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/overall_team_trend/team_staff_by_supervisor?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId || ''}&supervisor__id=${obj.supervisorId || ''}&event_type=${obj.eventType || ''}&event_sub_type=${obj.eventTypeEventSubType || ''}&highlight_value=${obj.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        setIsLoadingTeams(false);
        dispatch({
          type: 'GET_STAFF_TREND_BY_SUPERVISOR_REPORT',
          payload: results
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingTeams(false);
      });
  };
  const setStaffTrendBySupervisor = async (obj) => {
    dispatch({
      type: 'SET_STAFF_TREND_BY_SUPERVISOR_REPORT',
      payload: obj
    });
  };
  const getStaffTrendByStaffResult = async (obj, staff = false) => {
    // overall_team_trend/staff_result_details?from_date=2022-05-10&to_date=2022-12-12&filter_by=created_at&score_card__groups=12&teams__id=15&supervisor__id=28&staff_id=5
    setIsLoadingTeams(true);
    const accessToken = window.localStorage.getItem('accessToken');
    if (staff) {
      // from target = false
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teamsId}&supervisor__id=${obj.supervisorId || ''}&search=${obj.filterName || ''}&staff=${obj.staffId || ''}&event_type=${obj.eventType || ''}&event_sub_type=${obj.eventTypeEventSubType || ''}&highlight_value=${obj.highlightValue}&is_above=${obj.isAbove || ''}&is_below=${obj.isBelow || ''}`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          const results = humps.camelizeKeys(response?.data?.results);
          setIsLoadingTeams(false);
          dispatch({
            type: 'GET_STAFF_TREND_BY_STAFF_RESULT_REPORT',
            payload: results
          });
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingTeams(false);
        });
    }
    else {
      // from target = true
      // const accessToken = window.localStorage.getItem('accessToken');
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&score_result=${obj.scoreResult}&page=${obj?.page}&search=${obj.filterName}`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          const results = humps.camelizeKeys(response?.data);
          setOverAllSupervisorteamData(response?.data)
          setIsLoadingTeams(false);
          dispatch({
            type: 'GET_STAFF_TREND_BY_STAFF_RESULT_REPORT',
            payload: results
          });
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingTeams(false);
        });
    }
  };

  const getStaffTrendByStaffTimeRangeResult = async (obj) => {
    // overall_team_trend/staff_result_details?from_date=2022-05-10&to_date=2022-12-12&filter_by=created_at&score_card__groups=12&teams__id=15&supervisor__id=28&staff_id=5
    setIsLoadingTeams(true);
    if (obj.fromTarget === "True") {
      const accessToken = window.localStorage.getItem('accessToken');
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}&search=${obj.filterName}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&is_above=${obj.isAbove}&is_below=${obj.isBelow}&page=${obj.page}`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          const results = humps.camelizeKeys(response?.data);
          setOverAllSupervisorteamData(response?.data)
          setIsLoadingTeams(false);
          dispatch({
            type: 'GET_STAFF_TREND_BY_STAFF_RESULT_REPORT',
            payload: results
          });
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingTeams(false);
        });
    }
    else {
      const accessToken = window.localStorage.getItem('accessToken');
      const response = axios
        .get(
          `${process.env.REACT_APP_API_URL}/analytic_evaluation/scoring_patterns_result_details?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj.filterBy}&search=${obj.filterName}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&staff_id=${obj.staffId}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&is_above=${obj.isAbove}&is_below=${obj.isBelow}&page=${obj.page}`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          const results = humps.camelizeKeys(response?.data);
          setIsLoadingTeams(false);
          setOverAllSupervisorteamData(response?.data)
          dispatch({
            type: 'GET_STAFF_TREND_BY_STAFF_RESULT_REPORT',
            payload: results
          });
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingTeams(false);
        });
    }
  };

  const setStaffTrendByStaffResult = async (obj) => {
    dispatch({
      type: 'SET_STAFF_TREND_BY_STAFF_RESULT_REPORT',
      payload: obj
    });
  };
  const dowStaffTrendBySupervisorXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // overall_team_trend/xlxs_supervisor_staff_team_trend_log?from_date=2022-05-10&to_date=2022-12-12&filter_by=created_at&score_card__groups=12&flag=year&teams__id=15&supervisor__id=75
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url:
        `${process.env.REACT_APP_API_URL}/overall_team_trend/team_staff_by_supervisor?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId || ''}&supervisor__id=${obj.supervisorId || ''}&event_type=${obj.eventType || ''}&event_sub_type=${obj.eventTypeEventSubType || ''}&highlight_value=${obj.highlightValue}&export=true`,
      // ${process.env.REACT_APP_API_URL}/overall_team_trend/xlxs_supervisor_staff_team_trend_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=&data_tags_operator=&export=true`,

      // url: `${process.env.REACT_APP_API_URL}/overall_team_trend/xlxs_supervisor_staff_team_trend_log?${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&flag=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.teamsId}&supervisor__id=${obj.supervisorId}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.1  OverAll Team Trends - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 5.2 Points of Failure by Team 
  const getPointsOfFailureByTeam = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/points_of_failure?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=&data_tags_operator=`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_POINTS_OF_FAILURE_TEAM',
          payload: results
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 5.2 Points of Failure by Team xls
  const getPointsOfFailureByTeamXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/points_of_failure/xlxs_point_of_failure?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=&data_tags_operator=`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.2 Points of Failure by Team - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 5.2 Points of Failure by Team By Scorecard 

  const getPointsOfFailureByTeamByScorecard = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/points_of_failure/point_of_failure_by_team_scorecard?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.team}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=&data_tags_operator=`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_POINTS_OF_FAILURE_SCORECARD',
          payload: results
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };


  // 5.2 Points of Failure by Team By Scorecard xls
  const getPointsOfFailureByTeamByScorecardXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/points_of_failure/xlxs_point_of_failure_by_team_scorecard?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams__id=${obj.team}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&data_tags=&data_tags_operator=`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.2 Points of Failure by Team - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 5.2 Points of Failure by team Result Details
  const getPointsOfFailureByTeamResultDetails = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // setIsLoading(true);

    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/points_of_failure/point_of_failure_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams_id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&search=${obj?.filterName || ''}&status=${obj.status}&page=${page}&data_tags=&data_tags_operator=`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_POINTS_OF_FAILURE_TEAM_RESULT',
          payload: { results, count }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 5.2 Points of Failure by team Result Details xls
  const getPointsOfFailureByTeamResultXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/points_of_failure/xlxs_point_of_failure_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&teams_id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&status=${obj.status}&data_tags=&data_tags_operator=`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.2 Points of Failure by Team Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 5.2 Points of Failure by team Result Details
  const getPointsOfFailureByScorecardResultDetails = async (page, obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/points_of_failure/point_of_failure_scorecard_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&score_card__in=${obj.scoreCardIn}&teams_id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&status=${obj.status}&search=${obj.filterName || ''}&page=${obj.page}&data_tags=&data_tags_operator=`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results, count } = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_POINTS_OF_FAILURE_TEAM_RESULT',
          payload: { results, count }
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 5.2 Points of Failure by team Result Details xls
  const getPointsOfFailureByScorecardResultXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/points_of_failure/xlxs_point_of_failure_scorecard_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups=${obj.scoreCardGroups}&score_card__in=${obj.scoreCardIn}&teams_id=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&status=${obj.status}&data_tags=&data_tags_operator=`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.2 Points of Failure by Team By Scorecard Result Details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  /**
   * @description 5.3 Overall Question Trend By Individual Team
   */
  const getQuestionTrendByIndividualTeam = async (obj) => {
    setIsLoadingTeams(true)

    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&staff=${obj.staff}&score_card__groups=${obj.group}&score_card__in=${obj.singleScoreCard}&flag=${obj.flag}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj.highlightValue}&section_id=${obj.section}&numeric_values=${obj.numericValues}&formula=${obj.formula}&data_tags=${obj.dataTags}&data_tags_operator=${obj.dataTagsOperator || '10'}&teams_trend=true`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { results } = humps.camelizeKeys(response?.data);
        setIsLoadingTeams(false)
        setQuesTrendByIndTeam(results)
      })
      .catch((error) => {
        setIsLoadingTeams(false)
        console.error(error);

      });
  };
  /**
   * 
   * @description  download list in xls form of 5.3 section 
   */
  const dwnldQuesTrendIndXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingTeams(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_staff_result_details_log?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.singleScoreCard}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&flag=${obj.flag || ''}&formula=${obj.formula}&staff_id=${obj.staff}&section_id=${obj.section}&numeric_values=${obj.numericValues}&data_tags=${obj.dataTags}&data_tags_operator=${obj.dataTagsOperator || '10'}&teams_trend=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.3 Question Trend Individual List By Team- ${currentDateTime}.xlsx`;

      setIsLoadingTeams(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setIsLoadingTeams(false);
      throw Error(error);
    }
  };

  const getQuesTrendIndByTeamResult = async (obj) => {
    setIsLoadingTeams(true)
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/question_trend/question_details?created_at__${handleWeekMonthYear(obj)}=${getWeekMonthYear(obj)}&created_at__year=${obj.year}&filter_by=${obj.filterBy}&score_card__in=${obj.singleScoreCard}&numeric_values=${obj.numericValues}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&flag=${obj.flag}&staff_id=${obj.staff}&question_id=${obj.questionId}&formula=${obj.formula}&section_id=${obj.sectionId}&section=${obj.section}&from_date=${obj.fromDate}&to_date=${obj.toDate}&formula=${obj.formula}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&page=${obj.page}&search=${obj.search || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setIsLoadingTeams(false)
        const { data } = humps.camelizeKeys(response);
        setQuesTrendResByIndTeam(data)
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingTeams(false);
      });
  };

  const dwnldQuesTrendIndByTeamDetailsXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoadingTeams(true);
    const handleWeekMonthYear = (obj) => {
      if (obj.month) return 'month'

      if (obj.week) return 'week'

      if (obj.quarter) return 'quarter'
    }
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/question_trend/xlxs_staff_inside_result_details_log?created_at__${handleWeekMonthYear(obj)}=${obj.week || obj.month || obj.quarter}&created_at__year=${obj.year}&numeric_values=${obj.numericValues}&filter_by=${obj.filterBy}&score_card__in=${obj.singleScoreCard}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__groups=${obj.group}&supervisor_id=${obj.supervisor}&staff_id=${obj.staffId}&question_id=${obj.questionId}&section_id=${obj.sectionId}&section=${obj.section}&from_date=${obj.fromDate}&to_date=${obj.toDate}&data_tags=${obj.dataTag || ''}&data_tags_operator=${obj.dataTagsOperator || ''}&formula=${obj.formula}&search=${obj?.search || ''}`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `5.3 Question Trend Individual Detail By Team- ${currentDateTime}.xlsx`;

      setIsLoadingTeams(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setIsLoadingTeams(false);
      throw Error(error);
    }
  };

  /**
   * @description 5.4 Team Performance Trend By Individual Team
   *
   */
  const getSectionTrendByTeam = async (obj) => {
    setIsLoadingTeams(true)
    setSectionTrendByTeam([])

    const accessToken = window.localStorage.getItem('accessToken');
    const response = axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_staff/section_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&date_type=${obj.flag}&score_card__groups=${obj.scoreCardGroups}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor=${obj.supervisor}&data_tags=${obj.dataTag}&data_tags_operator=${obj?.dataTagsOperator || ''}&formula=${obj.formula}&numeric=${obj.numeric}&teams_trend=true`,

        // `${process.env.REACT_APP_API_URL}/analytic_staff/section_trend_individual?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__in=&score_card__groups=${obj.scoreCardGroups}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&highlight_value=${obj?.highlightValue}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        setIsLoadingTeams(false)
        const results = response?.data;
        setSectionTrendByTeam(results)
        // setWarning('')
      })
      .catch((error) => {
        setIsLoadingTeams(false)
        console.error(error);
        // if (error.name === "AxiosError" || error.code === "ERR_NETWORK" || error.message === "Network Error") {
        //   setWarning('Too much data to load please reduce the date range')
        // }
      });
  };
  /**
   * @description 6.1 state
   */

  const [groupSummaryGraphData, setGroupSummaryGraphData] = useState([]);
  const [groupSummaryGraphCategory, setGroupSummaryGraphCategory] = useState([]);
  const [groupSummaryData, setGroupSummaryData] = useState([])
  const [groupSummaryLoading, setGroupSummaryLoading] = useState(false)
  const [groupSummaryCount, setGroupSummaryCount] = useState(0)
  const [groupSummaryDateType, setGroupSummaryDateType] = useState('month');

  const [groupSummaryByGroupGraphData, setGroupSummaryByGroupGraphData] = useState([]);
  const [groupSummaryByGroupGraphCategory, setGroupSummaryByGroupGraphCategory] = useState([]);
  const [groupSummaryByGroupData, setGroupSummaryByGroupData] = useState([])
  const [groupSummaryByGroupDateType, setGroupSummaryByGroupDateType] = useState('month');

  /**
   * 6.2 SCorecard Categories --------
   */
  const [scoreCardCategoriesData, setScoreCardCategroiesData] = useState([])
  const [scoreCardCategoriesResultData, setScoreCardCategroiesResultData] = useState([])

  const getScoreCardCategroies = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/scorecard_categories?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps?.camelizeKeys(response);
        setScoreCardCategroiesData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const downloadScoreCardCategroies = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/scorecard_categories?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.2 Scorecard Categroies - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.2 result page api --
  const getScoreCardCategroiesResultPage = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/scorecard_categories_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__category=${obj.scoreCardCategory}&page=${obj.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response);
        setScoreCardCategroiesResultData(results)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 6.2 result xls download ---
  const downloadScoreCardCategroiesResultPageXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/scorecard_categories_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&score_card__category=${obj.scoreCardCategory}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.2 Scorecard Categories details - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };


  /**
 * @description 6.3 state
 */

  const [pointsOfFailureGraphData, setPointsOfFailureGraphData] = useState([]);
  const [pointsOfFailureGraphCategory, setPointsOfFailureGraphCategory] = useState([]);
  const [pointsOfFailureData, setPointsOfFailureData] = useState([])
  const [pointsOfFailureLoading, setPointsOfFailureLoading] = useState(false)
  const [pointsOfFailureCount, setPointsOfFailureCount] = useState(0)

  // 6.3 result details methods //

  // this api is reusable (its use in 2.2 result page and 6.3 result page---)
  const getPointsOfFailureResultDetails = async (obj, page) => {
    const accessToken = window.localStorage.getItem('accessToken');
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy || ''}&score_card__groups=${obj.group || ''}&event_type=${obj.eventType || ''}&event_sub_type=${obj.eventTypeEventSubType || ''}&search=${obj.filterName || ''}&result_details_choice=${obj.result_details_choice || ''}&page=${page || 1}&supervisor_id=${obj?.supervisor || ''}&score_card__in=${obj?.scoreCard || ''}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const results = humps.camelizeKeys(response?.data);
        dispatch({
          type: 'GET_POINTS_OF_FAILURE_RESULTS',
          payload: results
        });
        // setShowSpinner(false);
      })
      .catch((error) => {
        console.error(error);
        // setShowSpinner(false);
      });
  };

  // this api is reusable (i.e  it is used in 2.2 and 6.3 result page download---)
  const downloadPointsOfFailureXls = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/points_of_failure_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&score_card=${obj?.scoreCard || ''}&filter_by=${obj.filterBy}&score_card__groups=${obj.group}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&search=${obj.filterName || ''}&supervisor_id=${obj?.supervisor || ''}&result_details_choice=${obj.result_details_choice || ''}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `${obj?.scoreCard ? '2.2' : '6.3'}_Points_Of_Failure_Result_Details_${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.4 Customer Reference --

  const [customerReferenceData, setCustomerReferenceData] = useState('')
  const [customerReferenceResultData, setCustomerReferenceResultData] = useState('')
  const getCustomerReference = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/customer_reference_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor}&staff_id=${obj.staff}&evaluator_id=${obj.evaluator}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&search=${obj.search || ''}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setCustomerReferenceData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 6.4 main page download --
  const downloadCustomerReference = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/customer_reference_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor}&staff_id=${obj.staff}&evaluator_id=${obj.evaluator}&highlight_value=${obj.highlightValue}&formula=${obj.formula}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.4 Customer Reference - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.4 Result details page -----
  const getCustomerReferenceResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor}&staff_id=${obj.staff}&evaluator_id=${obj.evaluator}&reference=${obj.reference}&search=${obj.search || ''}&page=${obj.page}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setCustomerReferenceResultData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 6.4 result page download
  const downloadCustomerReferenceResult = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card=${obj.scoreCard}&score_card__groups=${obj.group}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&supervisor_id=${obj.supervisor}&staff_id=${obj.staff}&evaluator_id=${obj.evaluator}&reference=${obj.reference}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.4 Customer Reference Result - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.5 Compliance Overview -------
  const [complianceOverviewData, setComplianceOverviewData] = useState([])
  const [complianceByTeamData, setComplianceByTeamData] = useState([])
  const [complianceByStaffData, setComplianceByStaffData] = useState([])
  const [complianceByStaffResultData, setComplianceByStaffResultData] = useState([])
  const [complianceOverviewResultData, setComplianceOverviewResultData] = useState([])
  const [complianceTeamResultData, setComplianceTeamResultData] = useState([])

  const getComplianceOverview = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/compliance_overview_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setComplianceOverviewData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const downloadComplianceOverview = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/compliance_overview_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.5 Compliance By Overview - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.5 Compliance By Team -- -

  const getComplianceByTeam = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/compliance_overview_by_team_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setComplianceByTeamData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const downloadComplianceByTeam = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/compliance_overview_by_team_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.5 Compliance By Team - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  // 6.5 Compliance By Staff -
  const getComplianceByStaff = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/analytic_groups/compliance_overview_by_staff_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.teamId}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setComplianceByStaffData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const downloadComplianceByStaff = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/compliance_overview_by_staff_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams_id=${obj.teamId}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `Compliance By Staff - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.5 result page --

  const getComplianceOverviewResult = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        (obj.fromDate || obj.toDate) ? `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}`
          :
          `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&score_card__groups=${obj.group}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}`,
        // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setComplianceOverviewResultData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const downloadComplianceOverviewResult = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: (obj.fromDate || obj.toDate) ? `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}&export=true`
        :
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&score_card__groups=${obj.group}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}&export=true`,
      // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.5 Compliance Overview Result - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.5 compliance by teams result page --
  const getComplianceByTeamResult = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        (obj.fromDate || obj.toDate) ? `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&teams=${obj.team}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}`
          :
          `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&teams=${obj.team}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}`,
        // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setComplianceOverviewResultData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const downloadComplianceByTeamResult = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: (obj.fromDate || obj.toDate) ? `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.team}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}&export=true`
        :
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams=${obj.team}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}&export=true`,
      // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.5 Compliance By Team Result - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };


  // 6.5 Compliance by staff result page when click on staff name

  const getComplianceByStaffNameResult = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams_id=${obj.teams}&staff_id=${obj.staffId}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}`,
        // :
        // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&score_card__groups=${obj.group}`,
        // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setComplianceOverviewResultData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  // 6.5 Compliance by staff result page when click on pass/fail count

  const getComplianceByStaffResult = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setIsLoading(true);
    axios
      .get(
        (obj.fromDate || obj.toDate) ? `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}`
          :
          `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&score_card__groups=${obj.group}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}`,
        // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&${handleDateTypeYear(obj)}=${getDateTypeYear(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {
        const { data } = humps.camelizeKeys(response);
        setComplianceOverviewResultData(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  const downloadComplianceByStaffResult = async (obj, pageNo) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: (obj.fromDate || obj.toDate) ? `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams_id=${obj.teams}&staff_id=${obj.staffId}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}&export=true`
        :
        `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&score_card__groups=${obj.group}&teams_id=${obj.teams}&staff_id=${obj.staffId}&search=${obj?.search || ''}&score_answers__score_card_questions__section__type=${obj?.isSectionCompliance || ''}&page=${pageNo}&export=true`,
      // `${process.env.REACT_APP_API_URL}/supervisor_analytic/staff_result_details?&${handleScoringDateType(obj)}=${getScoringDateType(obj)}&filter_by=${obj.filterBy}&year=${obj.year}&date_type=${obj.flag}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.5 Compliance By Staff Result - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };
  /**
   * @description 6.6 Callibration Trend---
   */
  const [callibrationTrendData, setCallibrationTrendData] = useState([])
  const [selectedFlag, setSelectedFlag] = useState('month');


  // 6.6 download callibration xlxs----
  const downloadCallibrationXlxs = async (obj) => {
    const accessToken = window.localStorage.getItem('accessToken');
    setShowSpinner(true);

    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
    const config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/analytic_groups/calibrations_trend?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&date_type=${obj.dateType}&evaluator=${obj.evaluator}&score_card__groups=${obj.group}&teams=${obj.teams}&export=true`,
      responseType: 'arraybuffer',
      headers
    };

    try {
      const response = await axios(config);

      const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

      const outputFilename = `6.6 Callibration Trend - ${currentDateTime}.xlsx`;

      setShowSpinner(false);
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      setShowSpinner(false);
      throw Error(error);
    }
  };

  // 6.7 ACCEPTANCE BY GROUP
  const [selAnaGroupFilters, setSelAnaGroupFilters] = useState({
    group: '', teams: '', score_card: '', fromDate: moment().startOf('month').format('YYYY-MM-DD'), toDate: moment().endOf('month').format('YYYY-MM-DD'), filter_By: 'created_at', event_Type: '', sub_type: '', highlightBelow: 0
  });
  // ------------Grp Table---------------------------------------------------
  const [acceptanceByGrpData, setacceptanceByGrpData] = useState([]);
  const [isGroupFilterApplied, setGroupFilterApplied] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(5);
  const [selectedFilter, setSelectedFilter] = useState('created_at');
  const [selectedScoreCard, setSelectedScoreCard] = useState('');
  const [selectedEventType, setSelectedEventType] = useState('');
  const [selectedEventSubType, setSelectedEventSubType] = useState('');
  const [selectedEvaluator, setSelectedEvaluator] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedHighLightBelow, setSelectedHighLightBelow] = useState(0);
  const [selectedDate, setSelectedDate] = useState({
    date1: moment().startOf('month').format('YYYY-MM-DD'),
    date2: moment().endOf('month').format('YYYY-MM-DD')
  });
  const [selectedGroupDataTag, setselectedGroupDataTag] = useState([]);
  const [selectedGroupDataTagsOperator, setselectedGroupDataTagsOperator] = useState('');
  const [selectedWeek, setSelectedWeek] = useState();
  const [selectedGroupToWeek, setSelectedGroupToWeek] = useState();
  const [selectedGroupFromWeek, setSelectedGroupFromWeek] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageAccpGrp, setPageAccpGrp] = useState(0);
  const [filterNameAcceptanceByGrp, setFilterNameAcceptanceByGrp] = useState('');

  // ADVANCED ANALYTICS
  const [savedFilterListDatas, setSavedFilterListDatas] = useState([]);
  const [advanceTableData, setAdvancedTableData] = useState([]);
  const [advanceTableLoader, setAdvanceTableLoader] = useState(false);
  const [filterResultParam, setFilterResultParam] = useState();
  const [graphType, setGraphType] = useState('10');
  const [resetAdvanceAnalytics, setResetAdvanceAnalytics] = useState(false);
  const [advanceGraphCategory, setAdvanceGraphCategory] = useState([]);
  const [advanceGraphData, advanceSetGraphData] = useState([]);
  const [advancePage, setAdvancePage] = useState(0);
  const [advanceCount, setAdvanceCount] = useState(0);
  const [advanceIsGraph, setAdvanceIsGraph] = useState(false);

  // CUSTOM ANALYTICS DASHBOARD
  const [open, setOpen] = useState(false);
  const [reportFieldValue, setReportFieldValue] = useState("");
  const [radioBtnValue, setRadioBtnValue] = useState();
  const [titleText, setTitleText] = useState("Add Custom Report");
  const [reportSettingObj, setReportSettingObject] = useState();
  const [customData, setCustomData] = useState([]);
  const [updateCustomId, setUpdateCustomId] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);


  return (
    <AnalyticContext.Provider
      value={{
        // 1 -Start---------------------------------------
        // from date to date state ----
        selectedStaffFromDate, setSelectedStaffFromDate,
        selectedStaffToDate, setSelectedStaffToDate,
        selectedStaffFromWeek, setSelectedStaffFromWeek,
        selectedStaffToWeek, setSelectedStaffToWeek,

        isLoadingTimeRange, setIsLoadingTimeRange,
        // 1.1 -Overall Staff Trend-----------------------
        staffTrendReportDetails: state.staffTrendReportDetails,
        staffTrendDisDetails: state.staffTrendDisDetails,
        staffTrendAboBelow: state.staffTrendAboBelow,
        staffTrendResDetails: state.staffTrendResDetails,
        timeBtnGroupStaTrend,
        setTimeBtnGroupStaTrend,
        timeBtnGroupQueTrend,
        setTimeBtnGroupQueTrend,
        selDuration,
        setSelDuration,
        selScDuration,
        setSelScDuration,
        subModLabel,
        setSubModLabel,
        selAnaFilters,
        setSelAnaFilters,
        selAnaScoreCardFilters,
        setSelAnaScoreCardFilters,
        selAnaTeamsFilters,
        setSelAnaTeamsFilters,
        selAnaStaffId,
        setSelAnaStaffId,
        selAnaScStaffId,
        setSelAnaScStaffId,
        selectedDateAnaRange,
        setSelectedAnaDateRange,
        selectedFilterBy,
        setSelectedFilterBy,
        selectedFilterByValue, setSelectedFilterByValue,
        setselectedScFilter,
        selectedScMultipleFilter,
        setselectedScMultipleFilter,
        selectedStaFilter,
        setselectedStaFilter,
        selectedStaSingleFilter,
        setSelectedStaSingleFilter,
        selectedGroupFilter,
        setSelectedGroupFilter,
        selectedTeamFilter,
        setSelectedTeamFilter,
        selectedStaffFilter,
        setSelectedStaffFilter,
        selectedSupervisorFilter,
        setSelectedSupervisorFilter,
        selectedFromDate,
        setSelectedFromDate,
        selectedToDate,
        setSelectedToDate,
        selectedFDate, setSelectedFDate,
        selectedTDate, setSelectedTDate,
        selectedFilterEventType,
        setSelectedFilterEventType,
        selectedFilterSubType,
        setSelectedFilterSubType,
        selectedFilterEventTypeStaffTrend, setSelectedFilterEventTypeStaffTrend,
        selectedFilterSubTypeStaffTrend, setSelectedFilterSubTypeStaffTrend,
        getStaffTrend,
        downloadAnalyticsXls,
        downloadAnalyticsGraphXls,
        downloadAnalyticsBelowTargetXls,
        downloadAnalyticsAboveTargetXls,
        downloadAnalyticsAblovBelowXls,
        downloadAnalyticsEmployeeXls,
        downloadAnalyticsTimeRangeXls,
        getStaffTrendDistribution,
        getStaffTrendAboBelow,
        getStaffTrendResult,
        getStaffTrendBelowResultTarget,
        getStaffTrendAboveResultTarget,
        getStaffTrendResultAboveBelow,
        getStaffTrendResultEmployee,
        getStaffTrendResultDistribution,
        getCauseByStaffResult,
        getEvaluationByStaffResult,
        causeByStaffResDetails: state?.causeByStaffResDetails,
        evaluationResult: state?.evaluationResult,
        downloadTrendResDetXls,
        downloadResultDetailsEvaluationXls,
        newdownloadResultDetailsEvaluationXls,
        staffTrendResDetailsData, setStaffTrendResDetailsData,



        // 1.2 -Question by staff START-----------------------
        getQuestionByStaff,
        questionByStaffData: state.questionByStaffData,
        questionByStaffResultDetails: state.questionByStaffResultDetails,
        questionByStaffResultTotalDetails: state.questionByStaffResultTotalDetails,
        errorMessage, setErrorMessage,
        isLoadingQuestionStaffPage, setIsLoadingQuestionStaffPage,
        isLoadingQuestionStaffResPage, setIsLoadingQuestionStaffResPage,
        getQuestionByStaffResultDetails,
        downloadQuestionByStaffAnalyticsXls,
        downloadQuestionByStaffAnalyticsXlsBigData,
        downloadQuestionByStaffDetailsAnalyticsXls,

        // 1.3 Answers by staff START
        answerByStaffData: state.answerByStaffData,
        answerByStaffDistributionData: state.answerByStaffDistributionData,
        answerByStaffResultDetails: state.answerByStaffResultDetails,
        selectedSectionFilter, setSelectedSelectionFilter,
        selectedQuestionFilter, setSelectedQuestionFilter,
        getAnswerByStaff,
        getAnswerByStaffDistribution,
        getAnswersByStaffResultDetails,
        downloadAnswerByStaffAnalyticsXls,
        downloadAnswerByStaffDistributionAnalyticsXls,
        downloadAnswersByStaffResultDetailsXls,

        // 1.4 -Overall Staff Trend Ind-----------------------
        staffTrendByInd: state.staffTrendByInd,
        staffTrendIndResDetails: state.staffTrendIndResDetails,
        timeBtnGroup,
        setTimeBtnGroup,
        getStaOverTrendByInd,
        downloadTrendIndXls,
        getStaffTrendIndResult,
        downloadStaTrendResultXls,

        // 1.11
        agentData,
        setAgentData,
        isLoadingAgent,
        setLoadingAgent,
        countAgent,
        setCountAgent,
        pageAccpAgnt,
        setPageAccpAgnt,
        filterNameAcceptanceByAgnt,
        setFilterNameAcceptanceByAgnt,

        // 1.5

        switchView,
        setSwitchView,
        numeric,
        setNumeric,
        sectionTrndData,
        setSectionTrndData,
        sectionGraphTrndData,
        setSectionGraphTrndData,
        isSectionLoading,
        setIsSectionLoading,
        dateType,
        setDateType,
        sectionGraphCategory,
        setSectionGraphCategory,

        // 1.6 -Question Trend individual -----------------------------
        // quesTrendResDetailsInd: state.quesTrendResDetailsInd,
        questionTrendIndividualRes, setQuestionTrendIndividualRes,
        // questionTrendIndividualReportDetails: state.questionTrendIndividualReportDetails,
        questionTrendIndividualReportDetails, setQuestionTrendIndividualReportDetails,
        selectedScoreCardSingle, setSelectedScoreCardSingle,
        selectedDateAnaScRangeInd,
        setSelectedDateAnaScRangeInd,
        selectedScFilterByInd,
        setSelectedScFilterByInd,
        selectedScGroupFilterInd,
        setSelectedScGroupFilterInd,
        selectedScTeamFilterInd,
        setSelectedScTeamFilterInd,
        selectedScSupervisorFilterInd,
        setSelectedScSupervisorFilterInd,
        selectedScSecFilterInd,
        setSelectedScSecFilterInd,
        selectedScFromDateInd,
        setSelectedScFromDateInd,
        selectedScToDateInd,
        setSelectedScToDateInd,
        selScDurationInd,
        setSelScDurationInd,
        questionTrendIndividualData,
        setQuestionTrendIndividualData,
        setIsQuesIndiLoading,
        isQuesIndiLoading,

        getQuestionTrendIndividual,
        getQuesTrendIndividualResult,
        downloadQuestionTrendIndividualListXls,
        downloadQuestionTrendIndividualDetailsXls,

        // 1.10 -Causes By Staff -----------------------------
        causesByStaffData,
        setCausesByStaffData,
        causesByStaffLoading,
        setCausesByStaffLoading,
        // 1.8 -Section By Event Type -----------------------------
        sectionByEventTypeData, setSectionByEventTypeData,
        sectionByEventTypeLoading, setSectionByEventTypeLoading,
        sectionByEventTypeGraphData, setSectionByEventTypeGraphData,
        sectionByEventTypeGraphCategory, setSectionByEventTypeGraphCategory,
        selectedEventTypeFilter, setSelectedEventTypeFilter,
        selectedEventSubTypeFilter, setSelectedEventSubTypeFilter,

        // 1.8 -Section By Result -----------------------------
        sectionByResultData, setSectionByResultData,
        sectionByResultLoading, setSectionByResultLoading,
        sectionByResultGraphData, setSectionByResultGraphData,
        sectionByResultGraphCategory, setSectionByResultGraphCategory,

        // 1.9 ------

        kudosByStaffData, setKudosByStaffData,
        kudosByStaffWithDataTags, setKudosByStaffWithDataTags,
        kudosByStaffResultDetails, setKudosByStaffResultDetails,
        dataTagByScoreCard, setDataTagByScoreCard,
        dateTypeFlag, setDateTypeFlag,
        selectedDataTag, setselectedDataTag,
        kudosLoading, setKudosLoading,
        getKudosByStaff,
        getDataTagsByScoreCard,
        getKudosByStaffWithDataTags,
        downloadKudosByStaffWithDataTags,
        getKudosByStaffResultDetails,
        downloadKudosByStaff,
        downloadKudosByStaffResultDetails,

        // 1 -end-----------------------------------

        /**
         * @description 2.1 section provider state
         */
        selAnaSupervisorFilters,
        setSelAnaSupervisorFilters,
        setSupervisorPerScoreCardResponse,
        supervisorPerScoreCardResponse,
        selectedSupervisorGroupFilter,
        setSelectedSupervisorGroupFilter,
        setSelectedSupervisorTeamFilter,
        selectedSupervisorTeamFilter,
        setSelectedSupervisorFilterBy,
        selectedSupervisorFilterBy,
        setSelectedSupervisorFromDate,
        selectedSupervisorFromDate,
        setSelectedSupervisorToDate,
        selectedSupervisorToDate,
        selectedSupervisorEventTypeFilter,
        setSelectedSupervisorEventTypeFilter,
        selectedSupervisorEventSubTypeFilter,
        setSelectedSupervisorEventSubTypeFilter,
        selectedSupervisorHighlightFilter,
        setSelectedSupervisorHighlightFilter,
        selectedSupervisorEvaluatorFilter,
        setSelectedSupervisorEvaluatorFilter,
        selectedSupervisorScorecardFilter,
        setSelectedSupervisorScorecardFilter,
        setSelectedSupervisorResultDetails,
        selectedSupervisorToWeek, setSelectedSupervisorToWeek,
        selectedSupervisorFromWeek, setSelectedSupervisorFromWeek,
        selectedSupervisorResultDetails,
        supervisorSectionDetailApiRes,
        setSupervisorSectionDetailApiRes,
        setSupervisorBySectionSuperIdApiRes,
        supervisorBySectionSuperIdApiRes,
        setSupervisorStaffBySectionApiRes,
        supervisorStaffBySectionApiRes,
        setSelectedSupervisorDateAnaRange,
        selectedSupervisorDateAnaRange,
        selectedSupervisorDataTagFilter,
        setselectedSupervisorDataTagFilter,
        selectedSupervisorDataTagOperator,
        setselectedSupervisorDataTagOperator,
        setLoading,
        loading,

        getSupervisorBySectionDetails,
        getSupervisorBySectionSuperId,
        getStaffBySectionResultDetails,
        getSupervisorSectionDetails,
        supervisorSectionDetailExportXls,
        supervisorSectionDetailWithSuperIdExportXls,
        supervisorStaffDetailsXls,
        supervisorSectionDetailsXls,

        // 2.2 Points of Failure by Supervisor
        pointOfFailureSupervisorsData, setPointOfFailureSupervisorsData,
        pointOfFailureSupervisorsLoading, setPointOfFailureSupervisorsLoading,
        pointOfFailureSupervisorsGraphData, setPointOfFailureSupervisorsGraphData,
        pointOfFailureSupervisorsGraphCategory, setPointOfFailureSupervisorsGraphCategory,
        getPointsOfFailureSupDetails,
        getPointsOfFailureFailAllSupDetails,
        downPointsOfFailureFailAllSupDetails,
        downPointOfFailureFailAllResultSupDetails,
        getPointOfFailureFailAllResultSupDetails,
        getPointOfFailureFailAllSectionSupDetails,
        downPointOfFailureFailAllSectionSupDetails,
        supervisorPointsOfFailureDetailsData: state.supervisorPointsOfFailureDetailsData,
        SupervisorPointsOfFailureDetailsDownloadXls,

        // 2.3 Question by Supervisor
        questionBySuperVisorData: state.questionBySuperVisorData,
        supervisorByQuestionData, setSupervisorQuestionData,
        getSupervisorByQuestion,
        getSupervisorByQuestionDetails,
        dowSupervisorByQuestionAnalyticsXls,
        downSupervisorByQuestionDetailsAnalyticsXls,
        SupervisorByQuestionDetailsData: state.SupervisorByQuestionDetailsData,

        // 2.4 Result Acceptance By Supervisor
        resultAcceptanceListRes,
        setResultAcceptanceListRes,
        acceptanceByResultApiRes,
        setAcceptanceByResultApiRes,

        getResultAcceptanceBySupervisor,
        getAcceptanceByResult,
        downloadResultAcceptanceList,
        downloadAcceptanceByResult,

        // 3.1 module ---------
        scoringPatternsData, setScoringPatternsData,
        isLoadingScore, setIsLoadingScore,
        resultData1, setResultData1,
        resultData2, setResultData2,

        getScoringPatterns,
        getScoringResultApi1,
        getScoringResultApi2,
        downloadScoringPatternsXls,
        downloadXlsInResultPageApi1,
        downloadXlsInResultPageApi2,
        /**
         * @description 3.5 section start -------------------
         */
        selAnaEvaluationFilters,
        setSelAnaEvaluationFilters,
        setEvaluationPerScoreCardResponse,
        evaluationPerScoreCardResponse,
        selectedEvaluationGroupFilter,
        setSelectedEvaluationGroupFilter,
        setSelectedEvaluationTeamFilter,
        selectedEvaluationTeamFilter,
        setSelectedEvaluationFilterBy,
        selectedEvaluationFilterBy,
        setSelectedEvaluationFromDate,
        selectedEvaluationFromDate,
        setSelectedEvaluationToDate,
        selectedEvaluationToDate,
        setSelectedEvaluationsResultDetails,
        selectedEvaluationsResultDetails,
        selectedEvaluationScoreCard,
        setSelectedEvaluationScoreCard,
        selectedEvaluationScoreCardMultiple,
        setSelectedEvaluationScoreCardMultiple,
        selectedEvaluationEventType,
        setSelectedEvaluationEventType,
        selectedEvaluationEventSubType,
        setSelectedEvaluationEventSubType,
        selectedEvaluationHighlight,
        setSelectedEvaluationHighlight,
        getEvaluationPerScoreCard,
        dwnldXlsEvaluationPerEvaluator,
        evaluationScorecardBasedOnEvaluator,
        downloadEvaluationPerEvaluatorResDetXls,
        // searchApiResultDeatil,

        /**
         * @description 3.3 section start -------------------
         */
        selectedEvaluationDateAnaRange, setSelectedEvaluationAnaDateRange,
        perScorecardGraphData, setPerScorecardGraphData,
        perScorecardGraphCategory, setPerScorecardGraphCategory,
        perScorecardData, setPerScorecardData,
        perScorecardLoading, setPerScorecardLoading,
        perScorecardCount, setPerScorecardCount,
        perScorecardDateType, setPerScorecardDateType,
        selectedEvaluationfromWeek, setSelectedEvaluationfromWeek,
        selectedEvaluationtoWeek, setSelectedEvaluationtoWeek,
        selectedEvaluationDataTags, setSelectedEvaluationDataTags,

        /**
         * @description 3.2 section start -------------------
         */
        perPeriodGraphData, setPerPeriodGraphData,
        perPeriodGraphCategory, setPerPeriodGraphCategory,
        perPeriodData, setPerPeriodData,
        perPeriodLoading, setPerPeriodLoading,
        perPeriodCount, setPerPeriodCount,
        perPeriodDateType, setPerPeriodDateType,
        getEvaluationPerPeriodResultDetails,
        downloadResultDetailsEvaluationPerPeriodXls,
        evaluationperPeriodResult: state.evaluationperPeriodResult,


        /**
         * @description 3.4 section start -------------------
         */
        perScorecardByStaffGraphData, setPerScorecardByStaffGraphData,
        perScorecardByStaffGraphCategory, setPerScorecardByStaffGraphCategory,
        perScorecardByStaffData, setPerScorecardByStaffData,
        perScorecardByStaffLoading, setPerScorecardByStaffLoading,
        perScorecardByStaffCount, setPerScorecardByStaffCount,
        perScorecardByStaffDateType, setPerScorecardByStaffDateType,

        /**
         * @description 3.7 section start -------------------
         */
        reviewBySupervisorGraphData, setReviewBySupervisorGraphData,
        reviewBySupervisorGraphCategory, setReviewBySupervisorGraphCategory,
        reviewBySupervisorData, setReviewBySupervisorData,
        reviewBySupervisorLoading, setReviewBySupervisorLoading,
        reviewBySupervisorCount, setReviewBySupervisorCount,
        reviewBySupervisorDateType, setReviewBySupervisorDateType,
        getEvaluationReviewBySupervisorResultDetails,
        evaluationperReviewBySupervisorResult: state.evaluationperReviewBySupervisorResult,
        downloadEvaluationReviewBySupervisorXls,

        /**
         * @description 3.7 section start -------------------
         */
        reviewByEvaluatorGraphData, setReviewByEvaluatorGraphData,
        reviewByEvaluatorGraphCategory, setReviewByEvaluatorGraphCategory,
        reviewByEvaluatorData, setReviewByEvaluatorData,
        reviewByEvaluatorLoading, setReviewByEvaluatorLoading,
        reviewByEvaluatorCount, setReviewByEvaluatorCount,
        reviewByEvaluatorDateType, setReviewByEvaluatorDateType,

        // 4 -Start---------------------------------------

        // 4.1 -Overall Trend START-----------------------------
        // overallTrendResDetails, setOverallTrendResDetails,
        dowScorecardOverallTrendsHomeXls,
        dowScorecardOverallTrendXls,
        setOverallScoreCardFilters,
        overallScoreCardFilters,
        getOverAllTrend,
        setOverAllTrend,
        getOverallTrendResult,
        overallTrendReportDetails: state.overallTrendReportDetails,
        overallTrendResDetailsData, setOverallTrendResDetailsData,
        overallTrendResDetails: state.overallTrendResDetails,
        // 4.1 -Question Trend END-----------------------------

        // 4.2 Event Trend Type--
        eventTypeTrendApiRes,
        eventTypeTrendDetailsApiRes,
        eventSubTypeTrendDetailsApiRes,
        eventSubTypeTrendApiRes,
        setEventDetailsLoading,
        eventDetailsLoading,
        selWeekMonthYearDuration,
        setSelWeekMonthYearDuration,

        getEventTypeTrend,
        getEventTypeTrendDetails,
        getEventSubType,
        getEventSubTypeTrendDetails,
        downloadEventTypeTrendXls,
        downloadEventTypeTrendDetailsXls,
        downloadEventSubTypeTrendXls,
        downloadEventSubTypeTrendDetailsXls,

        // 4.3 ----
        performanceAgainstTargetData, setPerformanceAgainstTargetData,
        performanceAgainstTargetResultData, setPerformanceAgainstTargetResultData,
        performanceAgainstEventSubTypeData, setPerformanceAgainstEventSubTypeData,
        getPerformanceAgainstTarget,
        getPerformanceAgainstEventSubType,
        getPerformanceAgainstTargetResult,
        downloadPerformanceAgainstEventSubXls,
        downloadPerformanceAgainstTargetXls,
        downloadResultDetailsXls,

        // 4.4 -Question Trend-----------------------------
        quesTrendResDetails: state.quesTrendResDetails,
        questionTrendReportDetails: state.questionTrendReportDetails,
        selectedDateAnaScRange,
        setSelectedDateAnaScRange,
        selectedScFilterBy,
        setSelectedScFilterBy,
        selectedScGroupFilter,
        selectedScFilterByValue,
        setSelectedScFilterByValue,
        setSelectedScGroupFilter,
        selectedScTeamFilter,
        setSelectedScTeamFilter,
        selectedScSupervisorFilter,
        setSelectedScSupervisorFilter,
        selectedScSecFilter,
        setSelectedScSecFilter,
        selectedScFromDate,
        setSelectedScFromDate,
        selectedScToDate,
        setSelectedScToDate,
        selectedScFromWeek,
        setSelectedScFromWeek,
        selectedScToWeek,
        setSelectedScToWeek,
        selectedScEventType,
        setSelectedScEventType,
        selectedScStaff,
        setSelectedScStaff,
        selectedScSubType,
        setSelectedScSubType,
        selectedScFormula,
        setSelectedScFormula,
        selectedScHighlight,
        setSelectedScHighlight,
        selectedScNumericValue,
        setSelectedScNumericValue,
        getQuestionTrend,
        getQuesTrendResult,
        dowAnaQuesTrendXls,
        dowQueTreDetXls,

        // 4.5 -section Trend-----------------------------
        sectionTrendDetails: state.sectionTrendDetails,
        dateTypeSecTrend, setDateTypeSecTrend,
        sectionTrendData, setSectionTrendData,
        selectedScFilter,
        getSectionTrend,
        dowSecByTrendXls,
        dowSecByTrendDetailsXls,
        warning, setWarning,
        // 4.6 -Comments----------------------------
        commentData, setCommentData,
        commentType, setCommentType,
        commentLoading, setCommentLoading,
        commentWordGraph, setCommentWordGraph,

        // 4.8 Causes Trend ---START---
        getCausesTrend,
        // setCausesTrend,
        getCausesTrendDetails,
        // causesTrendDetails: state.causesTrendDetails,
        causesTrendDetailedResult: state.causesTrendDetailedResult,
        dowCausesTrendsHomeXls,
        dowCausesTrendsDetailsXls,
        failAllOnly, setFailAllOnly,
        isLoadingPage, setIsLoadingPage,
        causesTrendDetails, setCausesTrendDetails,

        // 4.8 Causes Trend ---END---

        // 4.9 Compliance By Question ---START---
        complianceByQuestionData,
        setComplianceByQuestionData,
        complianceByDateType,
        setComplianceByDateType,
        complianceByGraphData,
        setComplianceByGraphData,
        complianceByGraphCategory,
        setComplianceByGraphCategory,
        complianceByLoading,
        setComplianceByLoading,
        complianceByQuestionDetailArray,
        setComplianceByQuestionDetailArray,

        // 4.9 Compliance By Question ---END---

        // 4.11 Customer Reference By Data Tags ---Start---
        percentageFlag, setPercentageFlag,
        customerRefDataTagData, setCustomerRefDataTagData,
        customerRefDataTagLoading, setCustomerRefDataTagLoading,
        customerRefDataTagDataDetails, setCustomerRefDataTagDataDetails,
        getCustomerRefDataTagsDetails, customerRefDataTagsDownloadXLS,


        // 4.11 Customer Reference By Data Tags ---END---

        // 4.7 -causes by scorecard---START---
        getCausesByScorecard,
        getCausesByScorecardGraph,
        getCausesByQuestionGraph,
        dowCausesByScorecardXls,
        getCausesBySections,
        getCausesByQuestion,
        getCausesByIndQuestion,
        getCausesReportResults,
        dowCausesReportResultsXls,
        causesByIndQuestionDetails: state.causesByIndQuestionDetails,
        causesReportResults: state.causesReportResults,
        causesByScorecardDetails: state.causesByScorecardDetails,
        causesByScorecardGraphDetails: state.causesByScorecardGraphDetails,
        causesBySectionsDetails: state.causesBySectionsDetails,
        causesByQuestionDetails: state.causesByQuestionDetails,
        causesByQuestionGraphDetails: state.causesByQuestionGraphDetails,
        fullscreenOn, setFullscreenOn,
        // 4.7 -causes by scorecard---END---

        // 4.10 Data tags By Event Type ---
        selectedDataTagOperators, setSelectedDataTagOperators,
        dataTagsByEventData, setDataTagsByEventData,
        selectedDataTagFilter, setselectedDataTagFilter,
        dataEventByTags, setDataEventByTags,
        resultDataEventByTags, setResultDataEventByTags,
        getDataTagsByEventData,
        downloadDataTagsByEventData,
        getDataEventByTagsResult,
        getTotalByDataTagResultApi,
        downloadTotalByDataTagResultApi,

        // ---- 5 Team Section Starts

        // 5.1 ---- OverAll Team start
        overAllSuperviosrTeamData,
        setOverAllSupervisorteamData,
        getOverAllTeamTrend,
        setOverAllTeamsTrend,
        dowOverAllTeamsTrendsXls,
        getOverAllTeamTrendDetails,
        dowOverAllTeamsTrendsDetailsXls,
        getTeamSupervisorList,
        setTeamSupervisorList,
        dowTeamSupervisorListXls,
        getStaffTrendBySupervisor,
        setStaffTrendBySupervisor,
        dowStaffTrendBySupervisorXls,
        getStaffTrendByStaffResult,
        getStaffTrendByStaffTimeRangeResult,
        setStaffTrendByStaffResult,
        teamSupervisorData, setTeamSupervisorData,
        overAllTeamTrendData: state.overAllTeamTrendData,
        overAllTeamTrendDetailResult: state.overAllTeamTrendDetailResult,
        // teamSupervisorData: state.teamSupervisorData,
        flag, setFlag,
        staffTrendBySupervisorData: state.staffTrendBySupervisorData,
        staffTrendByStaffResultData: state.staffTrendByStaffResultData,
        isLoadingTeams, setIsLoadingTeams,
        pointsOfFailureByTeamResultDetailsData, setPointsOfFailureByTeamResultDetailsData,
        selectedTeamsFromWeek, setSelectedTeamsFromWeek,
        selectedTeamsToWeek, setSelectedTeamsToWeek,
        // ---- 5 Team Section Ends
        getPointsOfFailureByTeam,
        getPointsOfFailureByTeamXls,
        getPointsOfFailureByTeamByScorecard,
        getPointsOfFailureByTeamByScorecardXls,
        getPointsOfFailureByTeamResultDetails,
        getPointsOfFailureByTeamResultXls,
        getPointsOfFailureByScorecardResultDetails,
        getPointsOfFailureByScorecardResultXls,
        pointsOfFailureByTeamDetails: state.pointsOfFailureByTeamDetails,
        pointsOfFailureByScorecardDetails: state.pointsOfFailureByScorecardDetails,
        pointsOfFailureByTeamResultDetails: state.pointsOfFailureByTeamResultDetails,
        pointsOfFailureByScorecardResultDetails: state.pointsOfFailureByScorecardResultDetails,
        selectedDateAnaTeamsRange, setSelectedDateAnaTeamsRange,
        selectedTeamsFilterBy, setSelectedTeamsFilterBy,
        selectedTeamsGroupFilter, setSelectedTeamsGroupFilter,
        selectedTeamsEventFilter, setSelectedTeamsEventFilter,
        selectedTeamsEventSubTypeFilter, setSelectedTeamsEventSubTypeFilter,

        selectedTeamsFromDate, setSelectedTeamsFromDate,
        selectedTeamsToDate, setSelectedTeamsToDate,
        selectedTeamsHighlight, setSelectedTeamsHighlight,
        selectedTeamsFilterByValue, setSelectedTeamsFilterByValue,

        // 5.3 
        getQuestionTrendByIndividualTeam,
        dwnldQuesTrendIndXls,
        getQuesTrendIndByTeamResult,
        dwnldQuesTrendIndByTeamDetailsXls,
        quesTrendByIndTeam, setQuesTrendByIndTeam,
        quesTrendResByIndTeam, setQuesTrendResByIndTeam,
        singleScoreCard, setSingleScoreCard,
        selectedTeamStaffFilter, setSelectedTeamStaffFilter,
        // 5.4---
        getSectionTrendByTeam,
        sectionTrendByTeam, setSectionTrendByTeam,
        sectionTrendByTeamRes, setSectionTrendByTeamRes,
        multipleScoreCard, setMultipleScoreCard,
        setSelectedTeamsSupervisorFilter, selectedTeamsSupervisorFilter,
        selectedTeamFilterDialog, setSelectedTeamFilterDialog,
        // 6 -Start--- analytics---------------------------
        count,
        setCount,
        selectedDateRange,
        setSelectedDateRange,
        selectedFilter,
        setSelectedFilter,
        selectedScoreCard,
        setSelectedScoreCard,
        selectedEventType,
        setSelectedEventType,
        selectedEventSubType,
        setSelectedEventSubType,
        selectedEvaluator,
        setSelectedEvaluator,
        selectedDate,
        setSelectedDate,
        selectedWeek,
        setSelectedWeek,
        acceptanceByGrpData,
        setacceptanceByGrpData,
        isLoading,
        setIsLoading,
        acceptanceByTeamData,
        setacceptanceByTeamData,
        pageAccpGrp,
        setPageAccpGrp,
        filterNameAcceptanceByGrp,
        setFilterNameAcceptanceByGrp,
        selectedGroup, setSelectedGroup,
        selectedTeam, setSelectedTeam,
        selectedHighLightBelow, setSelectedHighLightBelow,
        selAnaGroupFilters, setSelAnaGroupFilters,
        selectedGroupDataTag, setselectedGroupDataTag,
        selectedGroupDataTagsOperator, setselectedGroupDataTagsOperator,

        // 6.1------------------------------------- //
        groupSummaryGraphData, setGroupSummaryGraphData,
        groupSummaryGraphCategory, setGroupSummaryGraphCategory,
        groupSummaryData, setGroupSummaryData,
        groupSummaryLoading, setGroupSummaryLoading,
        groupSummaryCount, setGroupSummaryCount,
        groupSummaryDateType, setGroupSummaryDateType,
        groupSummaryByGroupData, setGroupSummaryByGroupData,
        groupSummaryByGroupDateType, setGroupSummaryByGroupDateType,
        groupSummaryByGroupGraphData, setGroupSummaryByGroupGraphData,
        groupSummaryByGroupGraphCategory, setGroupSummaryByGroupGraphCategory,
        isGroupFilterApplied, setGroupFilterApplied,

        // 6.2 ----
        scoreCardCategoriesData, setScoreCardCategroiesData,
        scoreCardCategoriesResultData, setScoreCardCategroiesResultData,
        getScoreCardCategroies,
        downloadScoreCardCategroies,
        getScoreCardCategroiesResultPage,
        downloadScoreCardCategroiesResultPageXls,

        // 6.4 ---------
        customerReferenceData, setCustomerReferenceData,
        customerReferenceResultData, setCustomerReferenceResultData,
        getCustomerReference,
        downloadCustomerReference,
        getCustomerReferenceResult,
        downloadCustomerReferenceResult,

        // 6.5 -----------
        complianceOverviewData, setComplianceOverviewData,
        complianceByTeamData, setComplianceByTeamData,
        complianceByStaffData, setComplianceByStaffData,
        complianceByStaffResultData, setComplianceByStaffResultData,
        complianceOverviewResultData, setComplianceOverviewResultData,
        getComplianceOverview,
        getComplianceByTeam,
        getComplianceByStaff,
        downloadComplianceOverview,
        downloadComplianceByTeam,
        downloadComplianceByStaff,
        getComplianceByStaffResult,
        getComplianceOverviewResult,
        downloadComplianceOverviewResult,
        getComplianceByTeamResult,
        downloadComplianceByTeamResult,
        getComplianceByStaffNameResult,
        downloadComplianceByStaffResult,

        // -- 6.6----
        callibrationTrendData,
        setCallibrationTrendData,
        setSelectedFlag,
        selectedFlag,

        downloadCallibrationXlxs,

        // 6.3 ------------------------------------------- //
        pointsOfFailureGraphData, setPointsOfFailureGraphData,
        pointsOfFailureGraphCategory, setPointsOfFailureGraphCategory,
        pointsOfFailureData, setPointsOfFailureData,
        pointsOfFailureLoading, setPointsOfFailureLoading,
        pointsOfFailureCount, setPointsOfFailureCount,
        getPointsOfFailureResultDetails,
        pointsOfFailureResult: state.pointsOfFailureResult,
        downloadPointsOfFailureXls,
        selectedGroupToWeek, setSelectedGroupToWeek,
        selectedGroupFromWeek, setSelectedGroupFromWeek,

        // 6 -end --- analytics---------------------------

        // ADVANCED ANALYTICS
        savedFilterListDatas, setSavedFilterListDatas,
        advanceTableData, setAdvancedTableData,
        advanceTableLoader, setAdvanceTableLoader,
        filterResultParam, setFilterResultParam,
        graphType, setGraphType,
        advanceGraphCategory, setAdvanceGraphCategory,
        advanceGraphData, advanceSetGraphData,
        advancePage, setAdvancePage,
        advanceCount, setAdvanceCount,
        advanceIsGraph, setAdvanceIsGraph,

        // CUSTOM DASHBOARD
        open, setOpen,
        reportFieldValue, setReportFieldValue,
        radioBtnValue, setRadioBtnValue,
        titleText, setTitleText,
        reportSettingObj, setReportSettingObject,
        customData, setCustomData,
        updateCustomId, setUpdateCustomId,
        dialogVisible, setDialogVisible
      }}
    >
      {children}
    </AnalyticContext.Provider>
  );
};
