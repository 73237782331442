import React, { useState, useRef, useEffect } from "react";
import { Box, TextField, Stack, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { useSnackbar } from "notistack5";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from '../../@material-extend';
import useAuth from '../../../hooks/useAuth';


const OTPInput = ({ length = 6, onComplete, email, deviceChecked, setEnterOtp, handleCheckedTrust, setIsEnteredFullOtp }) => {
    const inputRefs = useRef([]);
    const [otp, setOtp] = useState(new Array(length).fill(""));
    const { twoStepResendOtp } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [timer, setTimer] = useState(30);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isIncognito, setIsIncognito] = useState();
    // METHODS
    const devices = Cookies.get(`device_ids_${window?.location?.hostname}`) ? JSON.parse(Cookies.get(`device_ids_${window?.location?.hostname}`)) : {};
    useEffect(() => {
        // Check if checkbox was previously checked
        const storedCheck = Cookies.get("deviceChecked") === "true";

        // If checkbox was not checked, clear the device ID
        if (!storedCheck) {
            const devices = Cookies.get(`device_ids_${window?.location?.hostname}`) ? JSON.parse(Cookies.get(`device_ids_${window?.location?.hostname}`)) : {};
            const encodedEmail = btoa(email);

            if (devices[encodedEmail]) {
                delete devices[encodedEmail];
                Cookies.set(`device_ids_${window?.location?.hostname}`, JSON.stringify(devices), {
                    expires: 365,
                    secure: true, // use when deploy in production mode
                    sameSite: "Strict",
                    // sameSite: 'Lax' // allows cookies for most same-site requests while protecting from CSRF attacks
                });
            }
        }
    }, [email]);

    const checkIncognito = async () => {
        return new Promise((resolve) => {
            // Method 1: Storage Quota Check (Works in Chrome, Firefox, Edge, Brave)
            if (navigator.storage && navigator.storage.estimate) {
                navigator.storage.estimate().then(({ quota }) => {
                    if (quota && quota < 120000000) { // Less than 120MB means likely Incognito
                        console.log('abc')
                        resolve(true);
                    } else {
                        console.log("xyz")
                        resolve(false);
                    }
                });
            }

            // Method 2: IndexedDB Check (Works in Safari)
            try {
                const db = indexedDB.open("test");
                db.onerror = () => resolve(true);  // Error means Incognito Mode
                db.onsuccess = () => resolve(false);
            } catch (e) {
                resolve(true);  // Exception means Incognito
            }
        });
    };
    const handleCreateDevId = (e) => {
        const isChecked = e?.target?.checked;
        handleCheckedTrust(isChecked);

        const encodedEmail = btoa(email);
        // Retrieve existing device IDs or initialize a new object
        let devices = Cookies.get(`device_ids_${window?.location?.hostname}`);
        devices = devices ? JSON.parse(devices) : {};
        // Set device ID only if checkbox is checked 
        if (isChecked) {
            devices[encodedEmail] = uuidv4();

            Cookies.set(`device_ids_${window?.location?.hostname}`, JSON.stringify(devices), {
                expires: 365, // Set for 365 day
                secure: true,
                sameSite: "Strict"
                // sameSite: 'Lax'
            });
            Cookies.set("deviceChecked", 'true');
        } else if (!isChecked) {
            // getting existing devices from cookies
            const devices = Cookies.get(`device_ids_${window?.location?.hostname}`) ? JSON.parse(Cookies.get(`device_ids_${window?.location?.hostname}`)) : {};

            if (!devices) return; // Exit if no devices are stored

            // Remove the device matched with the provided email
            if (devices[encodedEmail]) {
                delete devices[encodedEmail];
                Cookies.set(`device_ids_${window?.location?.hostname}`, JSON.stringify(devices), {
                    expires: 365, // Set for 1/2 hours
                    secure: true,
                    sameSite: "Strict",
                    // sameSite: 'Lax',
                });
            }
            Cookies.remove("deviceChecked");
        }
    }
    useEffect(() => {
        let countdown;
        if (isDisabled) {
            countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(countdown);
                        setIsDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [isDisabled]);
    const handleChange = (index, event) => {
        const value = event.target.value;
        if (!/^\d*$/.test(value)) return; // Only enter numbers

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to next input if a number is entered
        if (value && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }

        // Call onComplete method when OTP is fully entered
        if (newOtp.every((digit) => digit !== "")) {
            if (onComplete) {
                setIsEnteredFullOtp(true)
                onComplete(newOtp.join(""))
            };
        } else {
            setIsEnteredFullOtp(false)
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            // Move to previous input on Backspace if current is empty
            inputRefs.current[index - 1].focus();
        }
    };

    const handleResendOtp = () => {
        setEnterOtp('')
        setIsEnteredFullOtp(false)
        setOtp(new Array(length).fill(""));
        twoStepResendOtp(email)
        setTimer(30);  // Reset timer
        setIsDisabled(true); // Disable button
        enqueueSnackbar('Otp resent successfully', {
            variant: 'success',
            action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });
    }

    return (
        <>
            <Box display="flex" justifyContent="center" gap={1}>

                {otp.map((digit, index) => (
                    <TextField
                        key={index}
                        value={digit}
                        onChange={(event) => handleChange(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        variant="outlined"
                        type="text"
                        inputProps={{
                            maxLength: 1,
                            style: { textAlign: "center", fontSize: "20px", width: "40px", height: "20px" },
                        }}
                    />
                ))}

            </Box>
            <Stack direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
            >
                {
                    // isIncognito  && 
                    <FormControlLabel
                        sx={{ marginRight: "0px" }}
                        control={<Checkbox checked={deviceChecked}
                            size='small'
                            onChange={(e) => handleCreateDevId(e)} />}
                        label={
                            <Typography
                                variant='tableCell'
                            >
                                Trust this device, do not ask otp again
                            </Typography>
                        }
                    />}
                <Button
                    // component={RouterLink}
                    variant="subtitle2"
                    type='button'
                    onClick={() => handleResendOtp()}
                    // to={PATH_AUTH.resetPassword}
                    sx={{
                        textDecoration: 'none',
                        fontSize: { lg: '16px', md: '13px', sm: '15px', xs: '11px' },
                        '&hover': {
                            textDecoration: 'none',
                            background: 'transparant'
                        }
                    }}
                    disabled={isDisabled}
                >
                    {isDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
                </Button>
            </Stack>
        </>

    );
};

// Usage Example
const TwoStepVerification = (props) => {
    const { setEnterOtp, email, deviceChecked, setDeviceChecked, handleCheckedTrust, setIsEnteredFullOtp } = props
    const handleComplete = (otpValue) => {
        setEnterOtp(otpValue);
    };

    return (
        <Box mt={4}>
            <OTPInput length={6}
                onComplete={handleComplete}
                setEnterOtp={setEnterOtp}
                email={email?.toLowerCase()}
                deviceChecked={deviceChecked}
                setDeviceChecked={setDeviceChecked}
                setIsEnteredFullOtp={setIsEnteredFullOtp}
                handleCheckedTrust={handleCheckedTrust}
            />
        </Box>
    );
};

export default TwoStepVerification;
