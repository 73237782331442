import React from 'react';

const DisableWrapper = ({ children, exclude = [] }) => {
  const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

  // Recursive function to disable elements
  const recursivelyDisable = (element) => {
    if (React.isValidElement(element)) {
      const { id, className, disabled } = element.props;

      const isExcluded = exclude.some((exclusion) => id === exclusion || className?.split(' ').includes(exclusion));

      const shouldDisable = loggedUserData?.readOnly && !isExcluded;

      return React.cloneElement(element, {
        ...element.props,
        disabled: shouldDisable ? true : disabled,
        children: React.Children.map(element.props.children, recursivelyDisable),
      });
    }
    return element;
  };

  return <>{React.Children.map(children, recursivelyDisable)}</>;
};

export default DisableWrapper;
