import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
import moment from 'moment';


// material
import {
    Box,
    Table,
    Switch,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    tableCellClasses,
    Button,
    IconButton,
    Avatar,
    TableContainer,
    TablePagination,
    FormControlLabel,
    Stack,
    Typography,
    CircularProgress,
    Divider,
    OutlinedInput, InputAdornment,
    Tooltip,
    Menu,
    MenuItem,
    Pagination

} from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';
import { MoreHoriz, Visibility, Delete } from '@material-ui/icons';
import { makeStyles } from '@mui/styles';

// components../
import { MIconButton } from '../../../../../components/@material-extend';
import Scrollbar from '../../../../../components/Scrollbar';
// import SearchNotFound from '../../../../../../components/SearchNotFound';
import { delGroup, getAllGroups, searchAllGroups } from '../../../../../redux/action/staffAction';
import { getScoreList, getScoreSearch } from '../../../../../redux/action/scoreAction';
import { PATH_DASHBOARD, PATH_EMP } from "../../../../../routes/paths";
import { scoreDraftListApi, deleteDraftApi, grpDraftListApi, AllSearchDraftListApi, scoreSearchDraftListApi } from '../../../../../_apis_/authApi.js/empapi';
import { singleStaffApi } from '../../../../../_apis_/authApi.js/staffApi';
//
import SortingSelectingHeadScore from './sortSelectingHeadStaff';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import SearchBox from '../../../../Common Component/SearchBox';
import GlobalLoaders from '../../../../Common Component/GlobalLoaders';
import PaginationTable from '../../../../Common Component/PaginationTable';
import { encrpytNumData } from '../../../../../utils/dec_enc_rpytData'
import { applySortFilter, getComparator } from '../../../../../utils/tableDataSorter';





// ----------------------------------------------------------------------



const TABLE_HEAD = [
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Draft Date'
    },
    {
        id: 'score_card_name',
        numeric: false,
        disablePadding: false,
        label: 'Scorecard Name'
    },
    {
        id: 'groups_name',
        numeric: false,
        disablePadding: false,
        label: 'Group Name'
    },
    {
        id: 'staff_name',
        numeric: false,
        disablePadding: false,
        label: 'Agent Name'
    },
    {
        id: 'draft_type',
        numeric: false,
        disablePadding: false,
        label: 'Draft Type'
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action'
    },

];

const useStyles = makeStyles({
    pagination: {
        '& .MuiTablePagination-actions > button:first-of-type': {
            display: 'none', // Hide the "Previous" button
        },
        '& .MuiTablePagination-actions > button:last-of-type': {
            display: 'none', // Hide the "Next" button
        },
        '& .MuiTablePagination-toolbar': {
            paddingLeft: 1,
        },
    },
    paginationText: {
        color: '#212668', // Change this to the color you want,
        fontWeight: '600 !important'
    }
});

// ----------------------------------------------------------------------


const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 150,
    height: 45,
    color: `${theme.palette.functionalityBtn} !important`,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 150, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
        border: 'none !important',
        color: `${theme.palette.functionalityBtn} !important`, fontWeight: '100 !important'
    },
    '& .MuiOutlinedInput-input::placeholder': {
        color: `${theme.palette.functionalityBtn} !important`, // Change this color to your desired placeholder color,
        fontFamily: 'Nunito, sans-serif'
    }
}));


export default function ScoreDraftTable() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const group = useSelector(state => state.scoreList);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageRange, setPageRange] = useState(0)
    const [empTable, setEmpTable] = useState({})
    // const [anchorElP, setAnchorElP] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuData();
    };



    useEffect(() => {
        // dispatch(getScoreList(searchParams.get("empId"), 1))
        (async function () {
            setData([]);
            try {
                setIsLoading(true);
                if (searchParams.get("empId")) {
                    const response = await scoreDraftListApi(searchParams.get("empId"), page, searchParams.get("draftType") || 10);
                    if (order === 'asc') {
                        setData(response?.data?.results);
                    } else {
                        setData(response?.data?.results?.reverse());
                    }

                    setCount(response?.data?.count)
                    // console.log(response?.data)
                    setPageRange(response?.data?.page_range?.length)
                    return setIsLoading(false);
                }

                const response = await grpDraftListApi(1, searchParams.get("draftType") || 10);
                if (order === 'asc') {
                    setData(response?.data?.results);
                } else {
                    setData(response?.data?.results?.reverse());
                }
                setCount(response?.data?.count)
                setPageRange(response?.data?.page_range?.length)
                return setIsLoading(false);



            } catch (err) {
                if (err) {
                    setIsLoading(false);
                }
            }

        })();
    }, [searchParams.get("draftType")])

    const getDraftData = async (pg) => {
        setData([]);
        setIsLoading(true);
        if (searchParams.get("empId")) {
            const response = await scoreDraftListApi(searchParams.get("empId"), pg, searchParams.get("draftType") || 10);
            if (order === 'asc') {
                setData(response?.data?.results);
            } else {
                setData(response?.data?.results?.reverse());
            }
            setPageRange(response?.data?.page_range?.length)
            setCount(response?.data?.count)
            return setIsLoading(false);
        }

        const response = await grpDraftListApi(pg, searchParams.get("draftType") || 10);
        if (order === 'asc') {
            setData(response?.data?.results);
        } else {
            setData(response?.data?.results?.reverse());
        }
        setCount(response?.data?.count);
        setPageRange(response?.data?.page_range?.length)
        return setIsLoading(false);

    }


    const searchDraftData = async (value, pg) => {
        setData([]);
        setIsLoading(true);
        if (searchParams.get("empId")) {
            const response = await scoreSearchDraftListApi(searchParams.get("empId"), value, pg);
            setIsLoading(false);
            setCount(response?.data?.count)
            setPageRange(response?.data?.page_range?.length)

            if (order === 'asc') {
                return setData(response?.data?.results);
            }
            return setData(response?.data?.results?.reverse());


        }
        setData([]);
        setIsLoading(true);
        const response = await AllSearchDraftListApi(value, pg, searchParams.get("draftType") || 10);
        setIsLoading(false);
        setCount(response?.data?.count)
        setPageRange(response?.data?.page_range?.length)
        if (order === 'asc') {
            return setData(response?.data?.results);
        }
        return setData(response?.data?.results?.reverse());

    }

    // useEffect(() => {
    //     setData(group?.results);
    // }, [group])
    // console.log(group)
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [menuData, setMenuData] = useState();

    const mbLink = [];
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {

        if (filterName && filterName !== "") {
            searchDraftData(newPage);
            return setPage(newPage);
        }

        getDraftData(newPage);
        return setPage(newPage);

    };



    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const sendQuery = (queryText) => {
        if (queryText?.length === 0) {
            return getDraftData(1)
        }
        if (queryText?.length > 0) {
            return searchDraftData(queryText, 1);
        }
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setIsLoading(true);
        setData([]);
        setPage(1);
        setFilterName(event.target.value);
        delayedQuery(event.target.value);
    };

    const mbLink2 = [];



    switch (loggedUserData?.staff?.staffType) {
        case 10:
            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/select_scorecard?empId=${searchParams.get("empId")}&grpId=${searchParams.get("gpId")}&sup_dash=False` }, { name: 'Select Drafts' })
            mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })
            break;
        case 20:

            // if (searchParams.get("sup_dash") === "True") {
            //     mbLink.push({ name: 'Home', href: "/supervisor/dashboard" }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Scorecards' })
            // }

            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Drafts' })
            if (!searchParams?.get("draftType")?.includes("10")) {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Employee", href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Drafts' })
            } else {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })
            }
            // if (searchParams.get("sup_dash") === "False") {
            //     mbLink.push({ name: 'Home', href: PATH_EMP?.dashboard }, { name: "Select Groups", href: `/score/select_scorecard?empId=${searchParams.get("empId")}&sup_dash=False` }, { name: 'Select Scorecards' })

            // }
            break;
        case 30:

            // if (searchParams.get("sup_dash") === "True") {
            //     mbLink.push({ name: 'Home', href: "/supervisor/dashboard" }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Scorecards' })
            // }

            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Drafts' })
            mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })

            // if (searchParams.get("sup_dash") === "False") {
            //     mbLink.push({ name: 'Home', href: PATH_EMP?.dashboard }, { name: "Select Groups", href: `/score/select_scorecard?empId=${searchParams.get("empId")}&sup_dash=False` }, { name: 'Select Scorecards' })

            // }
            break;

        default:
            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/selectgroup` }, { name: 'Select Drafts' })
            if (!searchParams?.get("draftType")?.includes("10")) {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `/score/selectgroup` }, { name: 'Select Drafts' })
            } else {
                mbLink2.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Calibration Lists", href: PATH_DASHBOARD.general.calibration.index }, { name: 'Select Drafts' })
            }
            break;
    }

    async function handleDelDraft(id) {

        await confirm({ description: 'This action is permanent! It will permanently delete the draft. ' })
        const res = await deleteDraftApi(id);
        getDraftData(page)

        enqueueSnackbar('Deleted Draft Successfully', {
            variant: 'success',
            action: (key) => (

                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                </MIconButton>
            )
        });

    }



    const isSelected = (name) => selected.indexOf(name) !== -1;




    // SORTING
    const handleRequestSort = (event, property) => {
        // const isAsc = orderBy === property && order === 'asc';
        // console.log(order)
        setOrder(prev => orderBy === property && prev === 'desc' ? 'asc' : 'desc');
        setOrderBy(property);
    };


    const filteredData = applySortFilter(data?.map(obj => ({ ...obj, score_card_name: obj?.score_card?.name || "", staff_name: obj?.staff?.name || "", groups_name: obj?.groups?.name || '' })), getComparator(order, orderBy), filterName);

    const handleStaffInfo = async (row) => {
        try {
            const empResponse = await singleStaffApi(row?.staff?.id);
            // setDisField(false);
            const empTable = { "staff_type": empResponse?.data?.staff_type, "teams": empResponse?.data?.user?.teams?.map(obj => ({ id: obj?.id, name: obj?.name })), "name": `${empResponse?.data?.user?.first_name}${empResponse?.data?.user?.last_name}`, "supervisor_id": empResponse?.data?.supervisor?.id }
            navigate(`/score/reviewPerformance?gpId=${row?.groups?.id}&staff_type=${row?.staff?.staff_type}&empId=${searchParams.get("empId") || row?.staff?.id}&score_card_Id=${row?.score_card?.id}&draftId=${row?.id}&empTable=${JSON.stringify(empTable)}`)
        } catch (err) {
            console.log("draft redirect err", err)
        }
    }



    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

    return (
        <>

            <ScoreWrapper
                title="Select Drafts"
                link={(searchParams.get("empId") || searchParams.get("draftType") === '20') ? mbLink : mbLink2}
                btnType={<SearchBox filterName={filterName} handleFilterByName={handleFilterByName} />}
            >


                <Scrollbar sx={{ mt: { xs: 0, xl: 1 } }}>
                    <TableContainer variant='standard' sx={{ minWidth: "800px", py: 2 }}>
                        <Table size={dense ? 'small' : 'medium'}>
                            <SortingSelectingHeadScore
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                rowCount={data?.length}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredData?.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    py: 1.35,
                                                    borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                },
                                            }}
                                        >
                                            <TableCell scope="row" align="center" >
                                                <Link
                                                    to={`/score/reviewPerformance?gpId=${row?.groups?.id}&empId=${row?.staff?.id || searchParams?.get('empId')}&staff_type=${row.staff?.staff_type}&score_card_Id=${row.score_card?.id}&draftId=${row?.id}`}
                                                    style={{ color: '#212668', cursor: 'pointer', textDecoration: 'underline' }}
                                                >
                                                    <Typography variant='tableCell'>{moment(row?.created_at).format('MMM DD, YYYY, hh:mm A')}</Typography>


                                                </Link>

                                            </TableCell>
                                            <TableCell align='center' id={labelId} scope="row" padding="normal">

                                                {row.score_card_name?.length <= 20 ? <Typography variant='tableCell' >{row.score_card_name}</Typography> : <Tooltip title={row.score_card?.name}><Box>
                                                    <Typography variant='tableCell' >
                                                        {`${row.score_card?.name?.slice(0, 16)}...`}
                                                    </Typography>

                                                </Box></Tooltip>}


                                            </TableCell>

                                            <TableCell align="center" scope="row" padding="normal">
                                                {row?.groups_name?.length <= 20 ? <Typography variant='tableCell' >{row?.groups_name}</Typography> : <Tooltip title={row?.groups_name}><Box>
                                                    <Typography variant='tableCell' >
                                                        {`${row?.groups_name?.slice(0, 16)}...`}
                                                    </Typography>

                                                </Box></Tooltip>}

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                {row?.staff_name?.length <= 20 ? <Typography variant='tableCell' >{row?.staff_name}</Typography> : <Tooltip title={row?.staff_name}><Box><Typography variant='tableCell' >{`${row?.staff_name?.slice(0, 16)}...`}</Typography></Box></Tooltip>}

                                                {/* <Box sx={{ width: "100%" }}>{row?.staff?.name}</Box> */}

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Button
                                                    style={{
                                                        cursor: 'default',
                                                        padding: '0px 15px',
                                                        fontWeight: '400',
                                                        marginRight: '5px',
                                                        marginBottom: '2px',
                                                        fontSize: '12px',
                                                        width: '100px',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '100px',
                                                        minWidth: '100px',
                                                        height: '25px'
                                                    }}
                                                    // variant="outlined"
                                                    sx={{
                                                        // backgroundColor: getColor(row?.type).bgColor,
                                                        color: { bgColor: '#D9E2EF', color: '#212668', border: '1px solid #676A9D' },
                                                        border: { bgColor: '#D9E2EF', color: '#212668', border: '1px solid #676A9D' }
                                                    }}>
                                                    {row?.draft_type === "10" ? "Calibrate" : "Score"}
                                                </Button>

                                            </TableCell>
                                            <TableCell align="center"
                                                scope="row"
                                                padding="normal"

                                            >

                                                {/* <Button
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    onClick={(event) => {
                                                        handleClickMenu(event);
                                                        setMenuData(row);
                                                    }}
                                                    sx={{
                                                        p: 0,
                                                        minWidth: '30px',
                                                        color: '#5F6368',
                                                        paddingLeft: '10px',
                                                        maxHeight: '15px',
                                                        border: '1px solid #5F6368'
                                                    }}
                                                    startIcon={<MoreHoriz sx={{ fontSize: '20px', color: '#5F6368' }} />}
                                                /> */}
                                                <IconButton onClick={() => {
                                                    handleClose();
                                                    navigate(row?.draft_type !== "10" ? handleStaffInfo(row)
                                                        :
                                                        `${PATH_DASHBOARD.general.calibration.calibratePerformance}?score_Id=${encrpytNumData(row?.id)}&score_card_Id=${row?.score_card?.id}&listName=${row?.calibration?.name}&calibrate_Id=${row?.calibration?.id}&toScore=True`)
                                                }} size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px', mr: 1 }}>
                                                    <Visibility fontSize='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                                </IconButton>
                                                <IconButton onClick={() => {
                                                    handleDelDraft(row?.id);
                                                    handleClose()
                                                }} size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }}>
                                                    <Delete fontSize='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}


                            </TableBody>
                        </Table>
                        <>
                            {data?.length === 0 && <GlobalLoaders query={filterName} isLoading={isLoading} />}
                        </>
                    </TableContainer>

                </Scrollbar>
                <PaginationTable
                    count={count || 0}
                    tablePage={(page - 1)}
                    // tablePage={showPage ? cPage - 1 : page - 1} // for tablepagination component
                    pageRange={pageRange}
                    // page={showPage ? cPage : page} // for pagination component
                    page={page}
                    handlePagination={handleChangePage}
                    style={{ ml: 'auto', color: '#212668', fontWeight: 700 }}
                />


            </ScoreWrapper>
            <Box className="menu">
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    autoFocus={false}
                    sx={{ boxShadow: 0, border: "1px solid transparent" }}
                >
                    <MenuItem
                        sx={{ fontSize: "13px", minWidth: '125px' }}
                        onClick={() => {
                            handleClose();
                            navigate(menuData?.draft_type !== "10" ? `/score/reviewPerformance?gpId=${menuData?.groups?.id}&staff_type=${menuData?.staff?.staff_type}&empId=${searchParams.get("empId") || menuData?.staff?.id}&score_card_Id=${menuData?.score_card?.id}&draftId=${menuData?.id}`
                                :
                                `${PATH_DASHBOARD.general.calibration.calibratePerformance}?score_Id=${encrpytNumData(menuData?.id)}&score_card_Id=${menuData?.score_card?.id}&listName=${menuData?.calibration?.name}&calibrate_Id=${menuData?.calibration?.id}&toScore=True`)
                        }}
                    >Resume</MenuItem>
                    <MenuItem
                        sx={{ fontSize: "13px", minWidth: '125px' }}
                        onClick={() => {
                            handleDelDraft(menuData?.id);
                            handleClose();
                        }}
                    >Delete</MenuItem>
                </Menu>
            </Box>
        </>
    );
}