import { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios'
import humps from 'humps';

import moment from "moment";
// import { API } from '../backend';
// import { getTaskApi } from '../_apis_/authApi.js/staffApi';
import { getTaskApi } from '../_apis_/authApi.js/staffApi';
import { getGdashboardGroupApi, getGdashboardTeamApi } from '../_apis_/authApi.js/globalDashboardApi';
import { setTasks, getListAllGrp, getListAllSup } from "../redux/action/staffAction";

export const EvaluatorDashboardContext = createContext();

export default function EvalDashboardProviders({ children }) {

    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));


    // DATE STATE

    const [fromDate, setFromDate] = useState();
    const [isCustomDate, setCustomDate] = useState(false);
    // const [gDashboardOpen, setGdashboardOpen] = useState(true);
    const [toDate, setToDate] = useState();
    const [dateData, setDateData] = useState();
    const [test, setTest] = useState(0);
    const [filter, setfilter] = useState("created_at");
    const [selectedItem, setSelectedItem] = useState(2);
    const [groupId, setGroupId] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [teamListsContext, setTeamListsContext] = useState([]);
    const [evalDashTeamId, setEvalDashTeamId] = useState(null);
    // const [dashboardDetail, setDashboardId] = useState();
    const [test11, setTest11] = useState([]);

    const [messageData, setMessageData] = useState([]);
    const [query, setQuery] = useState();

    // SCORE STATES
    const [scoreForDraft, setScoreForDraft] = useState();

    // EMP STATE
    const [evalDialog, setEvaldialog] = useState(false);
    const [evalId, setEvalId] = useState();
    const [evalData, setEvalData] = useState();

    // SUPERVISOR STATE
    const [supDialog, setSupDialog] = useState(false);
    const [supId, setSupId] = useState();
    const [supData, setSupData] = useState();

    // SUP DASHBOARD STATES
    const [selectedSupGrp, setSelectedSupGrp] = useState([]);
    const [selectedSupTm, setSelectedSupTm] = useState([]);
    const [selectedSupScrds, setSelectedSupScrds] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);


    // TASK STATES
    const [TaskName, SetTaskName] = useState();
    const [taskDialog, setTaskDialog] = useState();
    const [TaskId, SetTaskId] = useState("");
    const [TaskOpen, SetTaskOpen] = useState(false);
    const [isTask, SetIsTask] = useState(false);

    // TEAM  STATES
    const [IsTeamOpen, SetTeamOpen] = useState(false);
    const [TeamId, SetTeamId] = useState("");

    // GROUP STATES
    const [IsGrpOpen, SetGrpOpen] = useState(false);
    const [GrpId, SetGrpId] = useState(false);

    //  GOALS STATES
    const [IsGoalOpen, SetGoalOpen] = useState(false)
    const [GoaldId, SetGoalId] = useState("");
    const [NewGoal, SetNewGoal] = useState(false);
    const [GoalPanel, SetGoalPanel] = useState(0);

    // PIE CHART SUPERVISOR

    const [pieChartData, setPieChartData] = useState([]);

    useEffect(() => {
        // dispatch(getListAllGrp()); dispatch(getListAllSup());
        setFromDate(moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'));
        setToDate(moment().format('YYYY-MM-DD'));
        setDateData({ date1: moment(0, "HH").subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD') })

    }, [])

    const [evaluatorData, setEvaluatorData] = useState('')
    const [evalDashboardQueriedData, setEvalDashboardQueriedData] = useState('')
    const [evalDashboardData, setEvalDashboardData] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [recentGraphData, setRecentGraphData] = useState('')

    const getEvaluatorDashboardQueriedApi = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/evaluator_dashboard/queried_evaluations?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups__in=${obj.group}&score_card=${obj.scoreCard || ''}&teams__in=${obj.teams || ''}&reference=${obj.reference || ''}&page=${obj.page}&search=${obj.search || ''}`,
                {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                const { data } = humps.camelizeKeys(response);
                console.log("data compl", data, response)
                setEvalDashboardQueriedData(data)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    // evaluation graph and 4 small card api--
    const getEvaluatorDashboardApi = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/evaluator_dashboard/performance_summary?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups__in=${obj.group}&score_card=${obj.scoreCard || ''}&teams__in=${obj.teams || ''}&reference=${obj.reference || ''}&search=${obj.search || ''}`,
                {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                const { data } = humps.camelizeKeys(response);
                console.log("data compl", data, response)
                setEvalDashboardData(data)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    // const grpDraftListApi = (pgNo, drftType) => process.env.REACT_APP_API_URL.get(pgNo ? `/score/draft_list?page=${pgNo}&draft_type=${[drftType] || 20}` : `/score/draft_list?page=1&draft_type=${[drftType] || 20}`);
    const getRecentDraftData = async (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/score/draft_list?page=${obj.pgNo || '1'}&draft_type=${[obj.drftType] || 20}&from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__groups__in=${obj.scoreCardGroupsIn}&teams__in=${obj.teamsIn}`,
                {
                    headers: {
                        Authorization: `Token ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((response) => {
                const { data } = humps.camelizeKeys(response);
                setRecentGraphData(data)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const groupIds = []
        if (loggedUserData?.userLevel?.name === 'Team Admin') {
            loggedUserData?.groups?.map(row => groupIds.push(row?.id));
        }

        if (pathname?.split("/")[1] !== "evaluator_dashboard") {
            setGroupList([]);
        }

        async function getList() {
            if (fromDate && toDate && pathname?.split("/")[1] === "evaluator_dashboard" && !searchParams.get('custom_group')) {
                const { data } = await getGdashboardGroupApi(fromDate, toDate, groupId?.length === 0 ? groupIds : groupId, filter);
                setGroupList(data?.data);

                if (selectedSupGrp?.length === 0) { setSelectedSupGrp([]) }
                if (selectedSupTm?.length === 0) { setSelectedSupTm([]) }
                if (selectedSupScrds?.length === 0) { setSelectedSupScrds([]) }
            }


            if (pathname?.split("/")[1] === "evaluator_dashboard" && searchParams.get('custom_group')) {
                const { data } = await getGdashboardGroupApi(searchParams.get('custom_from_date'), searchParams.get('custom_to_date'), [searchParams.get('custom_group')], 'created_at');

                setGroupList(data?.data);
                setGroupId([searchParams.get('custom_group')])

                if (searchParams.get('custom_teams') !== '') {
                    setEvalDashTeamId([searchParams.get('custom_teams')])
                }

                setFromDate(moment(searchParams.get('custom_from_date')).format('YYYY-MM-DD'))
                setToDate(moment(searchParams.get('custom_to_date')).format('YYYY-MM-DD'))
                setCustomDate(true);
                if (selectedSupGrp?.length === 0) { setSelectedSupGrp([]) }
                if (selectedSupTm?.length === 0) { setSelectedSupTm([]) }
                if (selectedSupScrds?.length === 0) { setSelectedSupScrds([]) }
            }
            if (loggedUserData?.userLevel?.name === 'Team Admin' && teamListsContext?.length === 0) {
                const res = await getGdashboardTeamApi(fromDate, toDate, groupIds, filter)

                setTeamListsContext(res?.data?.data);

                console.log(res?.data?.data);
            }

        }
        getList()


    }, [dateData, pathname])

    const Groups = useSelector(state => state.groups);
    const Teams = useSelector(state => state.teams);
    const Staffs = useSelector(state => state.staff);
    const emp = useSelector(state => state.emps);
    const sup = useSelector(state => state.sups);
    const supAllList = useSelector(state => state.stateSupList);
    const grpAllList = useSelector(state => state.stateGrpList);

    return (
        <>
            <EvaluatorDashboardContext.Provider
                value={{
                    searchText: query, setSearchText: setQuery,
                    dateObj: dateData, setDateObj: setDateData,
                    listMessage: messageData, setListMessage: setMessageData,
                    dateItem: selectedItem, setDateItem: setSelectedItem,
                    globalTeamId: evalDashTeamId, setGlobalTeamId: setEvalDashTeamId,
                    strtDate: fromDate,
                    setStrtDate: setFromDate,
                    grpList: groupList,
                    setGrpList: setGroupList,
                    tester: test,
                    filterby: filter, setFilterBy: setfilter,
                    endDate: toDate, setEndDate: setToDate,
                    getEmployee: emp.results, getSupervisor: sup.results,
                    getGroups: Groups.results, getTeams: Teams.results,
                    getStaffs: Staffs, employeeID: evalId,
                    setEmployeeID: setEvalId, emp: evalData,
                    setEmp: setEvalData, empForm: evalDialog, setEmpForm: setEvaldialog,
                    supervisrID: supId, setSupervisrID: setSupId,
                    supForm: supDialog, setSupForm: setSupDialog, sup: supData, setSup: setSupData,
                    taskName: TaskName, setTaskName: SetTaskName, isTaskDialogOpen: taskDialog, setTaskDialogOpen: setTaskDialog, taskId: TaskId, setTaskId: SetTaskId,
                    taskEditorOpen: TaskOpen, setEditorTaskOpen: SetTaskOpen,
                    isTeamOpen: IsTeamOpen, setTeamOpen: SetTeamOpen,
                    teamId: TeamId, setTeamId: SetTeamId,
                    grpId: GrpId, setGrpId: SetGrpId,
                    isGrpOpen: IsGrpOpen, setGrpOpen: SetGrpOpen,
                    isGoalOpen: IsGoalOpen, setGoalOpen: SetGoalOpen, goalId: GoaldId, setGoalId: SetGoalId,
                    newGoal: NewGoal, setNewGoal: SetNewGoal, goalPanel: GoalPanel, setGoalPanel: SetGoalPanel,
                    allGroup: grpAllList, allSup: supAllList,
                    t11: test11, setT11: setTest11,
                    teamListsContext, setTeamListsContext,
                    isTask, SetIsTask,
                    selectedSupGrp, setSelectedSupGrp,
                    selectedSupTm, setSelectedSupTm,
                    selectedSupScrds, setSelectedSupScrds,
                    groupId, setGroupId,
                    selectedTeam, setSelectedTeam,
                    selectedGroup, setSelectedGroup,
                    pieChartData, setPieChartData,
                    isCustomDate, setCustomDate,
                    scoreForDraft, setScoreForDraft,

                    isLoading, setIsLoading,
                    evaluatorData,
                    setEvaluatorData,
                    evalDashboardQueriedData, setEvalDashboardQueriedData,
                    evalDashboardData, setEvalDashboardData,
                    recentGraphData, setRecentGraphData,
                    getEvaluatorDashboardQueriedApi,
                    getEvaluatorDashboardApi,
                    getRecentDraftData
                }}>
                {children}
            </EvaluatorDashboardContext.Provider>
        </>
    )
}