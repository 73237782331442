// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTable: {
      defaultProps: {
        stickyHeader: true /* eslint-disable-line */
        // stickyHeader: window?.screen?.width > 400 ? false : true /* eslint-disable-line */
      },
      // styleOverrides: {
      //   root: {

      //   }
      // }

    },

    // .MuiTable-root
    // MuiTableContainer
    MuiTableContainer: {
      variants: [
        {
          props: { variant: "tab" },
          style: {

            height: `calc(100vh - 395px)`,
            [theme.breakpoints.down("sm")]: {
              height: "calc(100vh - 395px)",
            },
            [theme.breakpoints.up("md")]: {
              height: "calc(100vh - 350px)",
            },
            [theme.breakpoints.up("lg")]: {
              height: "calc(100vh - 320px)",
            },
            [theme.breakpoints.up("xl")]: {
              height: "calc(100vh - 337px)",
            },
          },
        },
        {
          props: { variant: "standard" },
          style: {

            height: `calc(100vh - 395px)`,
            [theme.breakpoints.down("sm")]: {
              height: "calc(100vh - 395px)",
            },
            [theme.breakpoints.up("md")]: {
              height: "calc(100vh - 350px)",
            },
            [theme.breakpoints.up("lg")]: {
              height: "calc(100vh - 295px)",
            },
            [theme.breakpoints.up("xl")]: {
              height: "calc(100vh - 300px)",
            },
          },
        },
        {
          props: { variant: "evaluate" },
          style: {

            height: `calc(100vh - 395px)`,
            [theme.breakpoints.down("sm")]: {
              height: "calc(100vh - 395px)",
            },
            [theme.breakpoints.up("md")]: {
              height: "calc(100vh - 350px)",
            },
            [theme.breakpoints.up("lg")]: {
              height: "calc(100vh - 355px)",
            },
            [theme.breakpoints.up("xl")]: {
              height: "calc(100vh - 375px)",
            },
          },
        },
        {
          props: { variant: "fullLength" },
          style: {

            height: `calc(100vh - 395px)`,
            [theme.breakpoints.down("sm")]: {
              height: "calc(100vh - 395px)",
            },
            [theme.breakpoints.up("md")]: {
              height: "calc(100vh - 350px)",
            },
            [theme.breakpoints.up("lg")]: {
              height: "calc(100vh - 100px)",
            },
            [theme.breakpoints.up("xl")]: {
              height: "calc(100vh - 300px)",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          height: `calc(100vh - 395px)`,
          [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 395px)',
          },
          [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 350px)',
          },
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 348px)',
          },
          [theme.breakpoints.up('xl')]: {
            height: 'calc(100vh - 358px)',
          },
          padding: '0px !important'
        }
      }
    },
    MuiTableRow: {

      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: theme.palette.mode === 'light' ? theme.palette.action.hover : '#636e72',
            }
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.custom.main,
            '&:hover': {
              backgroundColor: theme.palette.mode === 'light' ? theme.palette.action.hover : '#636e72',
            }
          }
        },
        // head: {
        //   [theme.breakpoints.up('lg')]: {
        //     borderBottom: `2px solid ${theme.palette.text.primaryBolderBorder}`
        //   },
        //   [theme.breakpoints.up('xl')]: {
        //     borderBottom: `4px solid ${theme.palette.text.primaryBolderBorder}`
        //   }

        // },

      },

    },
    MuiTableHead: { // Added this block to style TableHead
      styleOverrides: {
        root: {
          backgroundColor: '#F1F2FF', // Red background for the table head
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none'
        },
        head: {
          color: theme.palette.text.primary,
          // backgroundColor: 'white',
          backgroundColor: '#F1F2FF',
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
            // borderTopLeftRadius: theme.shape.borderRadius,
            // borderBottomLeftRadius: theme.shape.borderRadius,
            // boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
            // borderTopRightRadius: theme.shape.borderRadius,
            // borderBottomRightRadius: theme.shape.borderRadius,
            // boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`
          }
        },
        stickyHeader: {
          // position: 'sticky',
          // top: 0,
          // zIndex: 1,
          backgroundColor: theme.palette.background.paper,
          // backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
          backgroundImage: `linear-gradient(to bottom, #F1F2FF 0%, #F1F2FF 100%)`
        },
        body: {

          // borderBottom: `1px solid ${theme.palette.text.primaryBorder}`,


          '&:first-of-type': {
            paddingLeft: theme.spacing(3)
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3)
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}
