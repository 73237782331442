import axios from 'axios';
// // URL
// import { URL } from "../../backend";

// -----------------------------------------------------------

// const API = axios.create({ baseURL: 'http://127.0.0.1:8000' })
export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL })
// console.log(process.env.REACT_APP_URL)

API.interceptors.request.use(req => {
    // const { token } = JSON?.parse(localStorage?.getItem("user_info"));
    const token = window.localStorage.getItem('accessToken');
    if (token) {
        req.headers.Authorization = `Token ${token}`;
    }
    return req
})