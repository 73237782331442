import React from 'react';
import { Box } from '@mui/material';
import { Grid, Typography } from '@material-ui/core';

const CustomCardImage = (props) => {
    const { title, scorePercentage, icon, alt, firstCircleColour, secondCircleColour, iconBg } = props

    return (
        <Grid sx={{
            display: 'flex',
            alignItems: 'center',
            width: { lg: '25%', md: '25%', sm: '50%', xs: '90%' },
            height: '90px',
            backgroundColor: '#e9e2e21a',
            borderRadius: '16px',
            boxShadow: '1px 1px 5px 0px #0006',
            position: 'relative',
            overflow: 'hidden',
            padding: '24px',
            margin: { lg: '0px 15px 0 15px', md: '0px 15px 0 15px', sm: '0px 15px 0 15px', xs: '16px 0px' }

        }} xs={12}>
            <Box sx={{
                content: '""',
                position: 'absolute',
                bottom: '20px',
                right: '-63px',
                width: '105px',
                height: '105px',
                background: firstCircleColour,
                borderRadius: '50%',
            }} />
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px',
                backgroundColor: iconBg,
                borderRadius: '8px',
            }}>
                <img src={icon} alt={alt} style={{ height: '30px' }} />
            </Box>
            <Box sx={{
                marginLeft: { lg: '32px', sm: '32px', xs: '16px', md: '16px' },
            }}>

                <Typography
                    sx={{
                        fontWeight: "normal",
                        fontSize: { lg: '15px', xl: '17px', sm: '14px', md: '16px' },
                    }} >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { lg: '20px', xl: '25px', sm: '14px', md: '16px' },
                        fontWeight: 600
                    }}>
                    {scorePercentage}
                </Typography>
            </Box>
            <Box sx={{
                content: '""',
                position: 'absolute',
                bottom: '-50px',
                right: '-50px',
                width: '120px',
                height: '105px',
                background: secondCircleColour,
                borderRadius: '50%',
            }} />
        </Grid>
    );
};

export default CustomCardImage;
