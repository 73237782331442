import moment from 'moment';
import { API } from '../../hooks/apiHook'
// import axios from 'axios';
// process.env.REACT_APP_API_URL
// import { process.env.REACT_APP_API_URL } from "../../backend";

// -----------------------------------------------------------

// const API = axios.create({ baseURL: 'http://127.0.0.1:8000' })
// const API = axios.create({ baseURL: process.env.REACT_APP_API_URL })
// API.interceptors.request.use(req => {
//     // const { token } = JSON?.parse(localStorage?.getItem("user_info"));

//     const token = window.localStorage.getItem('accessToken');
//     if (token) {
//         req.headers.Authorization = `Token ${token}`;
//     }
//     return req
// })
const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));


// STAFF API
// export const getAcceptanceByAgentApi = (obj, pgNo, query) => API.get(`/analytic_staff/staff_result_acceptance?from_date=${obj?.fromDate || moment().startOf('month').format('YYYY-MM-DD')}&to_date=${obj?.toDate || moment().endOf('month').format('YYYY-MM-DD')}&filter_by=${obj?.filterBy || "created_at"}&score_card=${obj?.scoreCard || ""}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&evaluator=${obj?.evaluator || ""}&score_card__groups=${obj?.group || ""}&teams=${obj?.teams || ""}&sup_groups=${(loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10) ? loggedUserData?.groups?.map(obj => obj.id) : ""}&page=${pgNo}&search=${query}`);
export const getAcceptanceByAgentApi = (obj, pgNo, query) => API.get(`/analytic_staff/staff_result_acceptance?from_date=${obj?.fromDate || moment().startOf('month').format('YYYY-MM-DD')}&to_date=${obj?.toDate || moment().endOf('month').format('YYYY-MM-DD')}&filter_by=${obj?.filterBy || "created_at"}&score_card__in=${obj?.scoreCardSingle || ""}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&evaluator=${obj?.evaluator || ""}&score_card__groups=${obj?.group || ""}&teams=${obj?.teams || ""}&sup_groups=${(loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10) ? loggedUserData?.groups?.map(obj => obj.id) : ""}&data_tags=${obj?.dataTag || ""}&data_tags_operator=${obj?.dataTagsOperator || ''}&page=${pgNo}&search=${query}`);

// for 1.5
export const getSectionTrendByIndividualApi = (obj, dateType, view, isNumeric) => API.get(`/analytic_staff/section_trend_individual?score_card__groups=${obj?.group}&from_date=${moment(obj?.fromDate).format('YYYY-MM-DD') || moment().startOf('month').format('YYYY-MM-DD')}&to_date=${moment(obj?.toDate).format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD')}&filter_by=${obj?.filterBy || "created_at"}&staff=${obj?.staff || ""}&score_card=${obj?.scoreCardSingle}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&date_type=${dateType}&switch_view=${view === 1 ? 1 : ''}&numeric=${isNumeric || ""}&formula=${obj?.formula || 10}`);
export const getScoreTrendByIndividualDetailsApi = (obj, pgNo) => API.get(`/analytic_staff/section_details_individual?from_date=${obj.date1}&to_date=${obj?.date2}&filter_by=${obj?.filterBy}&page=${pgNo}&staff=${obj.staff}&score_card=${obj.scorecard}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.subType || ""}&date_type=${obj.dateType}&date_num=${obj.dateNum}&section=${obj.section}&formula=${obj?.formula}&numeric=${obj?.isNumeric}&date_year=${obj?.year}`)
// for 4.5
export const getScoreTrendDetailsApi = (obj, pgNo) => API.get(`/analytic_staff/section_details_individual?from_date=${obj.date1}&to_date=${obj?.date2}&filter_by=${obj?.filterBy}&page=${pgNo}&staff=${obj.staff}&score_card__in=${obj.scoreCardMultiple}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.subType || ""}&date_type=${obj.dateType}&date_num=${obj.dateNum}&section=${obj.section}&formula=${obj?.formula}&numeric=${obj?.isNumeric}&score_card__groups=${obj?.group}&teams=${obj?.teams}&date_year=${obj?.year}&data_tags=${obj?.dataTag}&data_tags_operator=${obj?.dataTagsOperator}&search=${obj?.search || ''}`)

/**
* @description 1.10 causes by staff ---------------------------------------------------------
 */

export const getCausesByStaffApi = (obj) => API.get(`/analytic_staff/causes_by_staff?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj?.toDate).format('YYYY-MM-DD')}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&score_card=${obj?.scoreCard}&staff=${obj?.staff}&supervisor=${obj?.supervisor}&section=${obj?.section}&question=${obj?.question}&data_tags=${obj?.dataTag || ""}&data_tags_operator=${obj.dataTagsOperator || ''}`)

// -------------------------------------------------------------------------------------------//
/**
* @description 1.8 SECTION BY EVENT TYPE ---------------------------------------------------------
 */

export const getSectionByEventTypeApi = (obj) => API.get(`/analytic_staff/section_event_type_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card=${obj?.scoreCard}&score_card__groups=${obj?.group}&staff_id=${obj?.staff}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&formula=${obj?.formula || ''}&highlight_value=${obj?.highlightValue}`)
export const getSectionByEventTypeScoreDetailsApi = (obj, pgNo) => API.get(`/supervisor_analytic/supervisor_section_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card=${obj?.scorecard}&score_card__groups=${obj?.group}&staff_id=${obj?.staff}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventSubType || ""}&section_id=${obj?.section}&page=${pgNo}`)


// -------------------------------------------------------------------------------------------//
/**
* @description 1.7 SECTION BY RESULT ---------------------------------------------------------
 */

export const getSectionByResultApi = (obj) => API.get(`/analytic_staff/section_result_details?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj?.toDate).format('YYYY-MM-DD')}&filter_by=${obj?.filterBy}&score_card=${obj?.scoreCardSingle}&score_card__groups=${obj?.group}&staff_id=${obj?.staff}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&formula=${obj?.formula}`)

// -------------------------------------------------------------------------------------------//
/**
* @description 2.2 Failure ---------------------------------------------------------
 */

export const getPointsOfFailureSupervisorApi = (obj) => API.get(`/points_of_failure_supervisor?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value&score_card__in=${obj?.scoreCard}&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=fail_section`)
// export const getPointsOfFailureSupervisorApi = (obj) => API.get(`/points_of_failure_supervisor?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value&score_card__in=${obj?.scoreCard}&status=fail_section&data_tags=${obj?.dataTag || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}`)
// export const getPointsOfFailureSupervisorDetailsApi = (obj) => API.get(`/points_of_failure_supervisor/supervisor_point_of_failure_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventSubType}&highlight_value&score_card__in=${obj?.scorecard}&data_tags=${obj?.dataTags || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=${obj?.status}&supervisor__id=${obj?.supervisorId}`)
// -------------------------------------------------------------------------------------------//

// ----STAFF API ENDSs-------------------------------------------------------------

// ----EVALUATION API STARTS-------------------------------------------------------------

// -------------------------------------------3.2--api----------------------------------------

export const getEvaluationPerPeriodApi = (obj, dateType) => API.get(`/analytic_evaluation/evaluation_periods?filter_by=${obj.filterBy}&page=1&export=&event_date=&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&to_date=${obj?.toDate}&from_date=${obj?.fromDate}&date_type=${dateType}&score_card__groups=${obj?.group}&teams=${obj?.teams || ""}&score_card=${obj?.scoreCard}`)

// -------------------------------------------3.2--api----------------------------------------

// -------------------------------------------3.3--api----------------------------------------

export const getEvaluationPerScorecardApi = (obj, dateType) => API.get(`/analytic_evaluation/evaluation_scorecard?filter_by=${obj.filterBy}&page=1&export=&event_date=&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&to_date=${obj?.toDate}&from_date=${obj?.fromDate}&date_type=${dateType}&score_card__groups=${obj?.group}&teams=${obj?.teams || ""}`)

// -------------------------------------------3.3--api----------------------------------------

// -------------------------------------------3.4--api----------------------------------------

export const getEvaluationPerScorecardByStaffApi = (obj) => API.get(`/analytic_evaluation/evaluation_scorecard_staff?filter_by=${obj.filterBy}&page=1&export=&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&to_date=${obj?.toDate}&from_date=${obj?.fromDate}&score_card__groups=${obj?.group}&teams=${obj?.teams || ""}`)

// ---------------------------------------------3.4--api----------------------------------------

// ---------------------------------------------3.6--api-------------------------------------------------- //

export const getReviewByEvaluatorApi = (obj, dateType) => API.get(`/analytic_evaluation/reviews_by_evaluator?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&date_type=${dateType}&score_card__in=${obj?.scoreCardMultiple}&score_card__groups=${obj?.group}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}`);


// ----------------------------------------------3.6--api--------------------------------------------------------- //
// ---------------------------------------------3.7--api-------------------------------------------------- //

export const getReviewBySupervisorApi = (obj, dateType) => API.get(`/analytic_evaluation/reviews_by_supervisor?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&date_type=${dateType}&score_card__in=${obj?.scoreCardMultiple}&score_card__groups=${obj?.group}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}`);


// ----------------------------------------------3.7--api--------------------------------------------------------- //

// Scorecard API--------------------------------------------------------------------------

// --------------------------------------------------------------------4.6-------------------------------
export const getAnswerSummaryCommentApi = (obj, commentType) => API.get(`/analytic_scorecards/comments?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&score_card=${obj?.scoreCard}&comments_type=${commentType}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor=${obj?.supervisor}`)

// --------------------------------------------------------------------4.6-------------------------------


// --------------------------------------------------------------------4.7------------------------------
export const getCauseByScorecardGraphApi = (obj) => API.get(`question_trend/causes_by_scorecard_graph?data_limit=${obj?.dataLimit}&filter_by=${obj?.filterBy}&score_card__in=${obj?.scorecard}&supervisor__id=&score_card__groups=${obj?.group}&teams__id=&event_type=&event_sub_type=`)

// --------------------------------------------------------------------4.7------------------------------

// --------------------------------------------------------------------4.9------------------------------

export const getComplianceByQuestionsApi = (obj, dateType) => API.get(`/question_trend/compliance_by_questions?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&date_type=${dateType}&score_card__groups=${obj?.group}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&section_id=${obj?.section}&staff_id=${obj?.staff}`);
export const getComplianceByQuestionDetailsApi = (obj) => API.get(`/analytic_staff/question_by_staff_question_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scorecard}&event_type=${obj?.eventType}&event_sub_type=${obj?.subType}&question_id=${obj?.question}`)

// --------------------------------------------------------------------4.9------------------------------


// --------------------------------------------------------------------4.11------------------------------

export const getCustomerRefDataTagsApi = (obj) => API.get(obj?.reference ? `/question_trend/customer_reference_by_data_tags?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor_id=${obj?.supervisor}&reference=${obj?.reference}&search=${obj?.filterName || ""}` : `/question_trend/customer_reference_by_data_tags?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor_id=${obj?.supervisor}&search=${obj?.filterName || ""}&data_tags=${obj?.dataTag}`);
export const getCustomerRefDataTagsDetailsApi = (obj) => API.get(`/supervisor_analytic/staff_result_details?from_date=2023-01-01&to_date=2023-03-31&filter_by=created_at&score_card=252&score_card__groups=18&teams=&event_type=&event_sub_type=&data_tags_id=103&reference=`);

// --------------------------------------------------------------------4.11------------------------------

// ---------------------------------------------------------------------5.1--------------------------------------------- //
export const getOverAllTeamTrendApi = (obj, dateType) => API.get(`/overall_team_trend?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&flag=${dateType}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value=&data_tags=${[]}`)
// ---------------------------------------------------------------------5.1---------------------------------------------------- //


// GROUP API--------------------------------------------------------------------------

// --------------------------------------------------------------------6.1-------------------------------
export const getGroupSummaryApi = (obj, dateType) => API.get(`/analytic_groups/summary?filter_by=${obj?.filter_By}&page=1&export=&event_type=${obj?.event_Type}&event_sub_type=${obj?.sub_type}&from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj.toDate).format('YYYY-MM-DD')}&date_type=${dateType}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.score_card}`)
export const getSummaryByGroupApi = (obj, dateType) => API.get(`/analytic_groups/summary_scorecard?filter_by=${obj?.filter_By}&page=1&export=&from_date=${obj?.fromDate}&to_date=${obj?.toDate}&date_type=${dateType}&score_card__groups=${obj?.group}&score_card__in=${obj?.score_card}&teams=${obj?.teams}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventSubType}`)

// --------------------------------------------------------------------6.3-------------------------------

export const getPointsOfFailureApi = (obj) => API.get(`/analytic_groups/points_of_failure?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj.toDate).format('YYYY-MM-DD')}&filter_by=${obj?.filter_By}&score_card__groups=${obj?.group}&event_type=${obj?.event_Type}&event_sub_type=${obj?.sub_type}`)

// --------------------------------------------------------------------6.3-------------------------------


// --------------------------------------------------------------------6.1-------------------------------

// ---------------------6.6 api----------------
export const getCallibrationTrend = (date1, date2, filterBy, dateType, evaluator, group, teams) => API.get(`/analytic_groups/calibrations_trend?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&date_type=${dateType}&evaluator=${evaluator}&score_card__groups=${group}&teams=${teams}`);
export const downloadCallibrationTrend = (date1, date2, filterBy, dateType, evaluator, group, teams) => API.get(`/analytic_groups/calibrations_trend?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&date_type=${dateType}&evaluator=${evaluator}&score_card__groups=${group}&teams=${teams}&export=true`);

// -------------------------------------------6.7--api----------------------------------------

export const getAcceptanceByGroupApi = (date1, date2, filterBy, scrd, evntType, evntSubtype, evaluator, supGrp, pgNo, query, dataTag, dataTagsOperator) => API.get(`/analytic_groups/result_acceptance_group?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&score_card=${scrd}&event_type=${evntType}&event_sub_type=${evntSubtype}&evaluator=${evaluator}&sup_groups=${supGrp}&page=${pgNo}&search=${query}&data_tags=${dataTag || ''}&data_tags_operator=${dataTagsOperator || '10'}`);
export const getAcceptanceByTeamApi = (date1, date2, filterBy, scrd, evntType, evntSubtype, evaluator, scrdGrp, supGrp, pgNo, query, dataTag, dataTagsOperator) => API.get(`/analytic_groups/result_acceptance_group?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&score_card=${scrd}&event_type=${evntType}&event_sub_type=${evntSubtype}&evaluator=${evaluator}&score_card__groups=${scrdGrp}&sup_groups=${supGrp}&page=${pgNo}&search=${query}&data_tags=${dataTag || ''}&data_tags_operator=${dataTagsOperator || '10'}`);
export const getAcceptanceByResultApi = (date1, date2, filterBy, agent, scrd, evntType, evntSubtype, evaluator, scrdGrp, supGrp, isViewed, isAccepted, isOnetoOne, isReviewed, noAction, pgNo, query, dataTag, dataTagsOperator) => API.get(`/reports?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&score_card__groups=${scrdGrp}&teams=&score_card=${scrd}&event_type=${evntType}&event_sub_type=${evntSubtype}&staff=${agent}&sup_groups=${supGrp}&scorecardcategory=&evaluator=${evaluator}&is_score_viewed=${isViewed}&is_score_accepted=${isAccepted}&is_onetoone_req=${isOnetoOne}&is_score_reviewed=${isReviewed}&no_action=${noAction}&page=${pgNo}&search=${query}&data_tags=${dataTag || ''}&data_tags_operator=${dataTagsOperator || '10'}`)
export const getAcceptanceByTeamResultApi = (date1, date2, filterBy, agent, scrd, evntType, evntSubtype, evaluator, scrdGrp, supGrp, team, isViewed, isAccepted, isOnetoOne, isReviewed, noAction, pgNo, query, dataTag, dataTagsOperator) => API.get(`/reports?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&score_card__groups=${scrdGrp}&teams=${team}&score_card=${scrd}&event_type=${evntType}&event_sub_type=${evntSubtype}&staff=${agent}&sup_groups=${supGrp}&scorecardcategory=&evaluator=${evaluator}&is_score_viewed=${isViewed}&is_score_accepted=${isAccepted}&is_onetoone_req=${isOnetoOne}&is_score_reviewed=${isReviewed}&no_action=${noAction}&page=${pgNo}&search=${query}&data_tags=${dataTag || ''}&data_tags_operator=${dataTagsOperator || '10'}`)
export const getAcceptanceByGrpDownloadXLS = (date1, date2, filterBy, scrd, evntType, evntSubtype, evaluator, supGrp, dataTag, dataTagsOperator) => API.get(`${process.env.REACT_APP_API_URL}/analytic_groups/result_acceptance_group?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&score_card=${scrd}&event_type=${evntType}&event_sub_type=${evntSubtype}&evaluator=${evaluator}&sup_groups=${supGrp}&data_tags=${dataTag || ''}&data_tags_operator=${dataTagsOperator || '10'}&export=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

// -------------------------------------------6.7-----api----------------------------------------

// ----------------------------------------ADVANCED API STARTS------------------------------------------------------------------------------ //

export const createNewFilterApi = (obj) => API.post(`/advanced_analytics/save_advanced_reporting_filter_params`, obj)
export const getSavedFilterListApi = () => API.get(`/advanced_analytics/save_advanced_reporting_filter_params`);
export const getSavedFilterApi = (id) => API.get(`/advanced_analytics/save_advanced_reporting_filter_params/${id}`);
export const delSavedFilterApi = (id) => API.delete(`/advanced_analytics/save_advanced_reporting_filter_params/${id}`);
export const getAdvanceTableApi = (obj, pg) => API.get(`/advanced_analytics/advanced_reporting_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__in=${obj?.scoreCard}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value=${obj?.highlightValue}&formula=${obj?.formula}&data_tags=${obj?.dataTags}&x_axis=${obj?.xAxis}&y_axis=${obj?.yAxis}&page=${pg || 1}`)
export const getAdvanceResultDetailsApi = (obj) => API.get(`/supervisor_analytic/staff_result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__in=${obj?.scoreCard}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value=${obj?.highlightValue}&formula=${obj?.formula}&data_tags=${obj?.dataTags}&x_axis=${obj?.xAxis}&y_axis=${obj?.yAxis}&${obj?.key1}=${obj?.keyValue1}&${obj?.key2}=${obj?.keyValue2}&search=${obj?.search || ""}`)
// ----------------------------------------ADVANCED API ENDS------------------------------------------------------------------------------ //


// -------------------------------------CUSTOM DASHBOARD----------------------------------------------------------------------------- //
export const getAllCustomAnalyticReportApi = () => API.get(`/custom_dashboard_analytics/add_report`);
export const getQuestionByStaffApi = (obj) => API.get(`/analytic_staff/question_by_staff?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&score_card=${obj?.scorecard}&teams=&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor=&highlight_value=&formula=${obj?.formula}&reference=&staff_type=10`)
export const delCustomAnalyticReportApi = (id) => API.delete(`/custom_dashboard_analytics/add_report/${id}`)
export const addCustomAnalyticReportApi = (obj) => API.post(`/custom_dashboard_analytics/add_report`, obj);
export const updateCustomAnalyticReportApi = (id, obj) => API.put(`/custom_dashboard_analytics/add_report/${id}`, obj)














// api is reusable i.e used in 1.11 and 6.7 result page download ---
export const getAcceptanceByResultDownloadXLS = (date1, date2, filterBy, agent, scrd, evntType, evntSubtype, evaluator, scrdGrp, supGrp, team, isViewed, isAccepted, isOnetoOne, isReviewed, noAction, dataTag, dataTagsOperator) => API.get(`${process.env.REACT_APP_API_URL}/analytic_groups/acceptance_score_export?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&score_card__groups=${scrdGrp}&teams=${team}&score_card=${scrd}&event_type=${evntType}&event_sub_type=${evntSubtype}&staff=${agent}&sup_groups=${supGrp}&scorecardcategory=&evaluator=${evaluator}&is_score_viewed=${isViewed}&is_score_accepted=${isAccepted}&is_onetoone_req=${isOnetoOne}&is_score_reviewed=${isReviewed}&no_action=${noAction}&data_tags=${dataTag || ''}&data_tags_operator=${dataTagsOperator || ''}&export=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

// 6.7 accept by team page
export const getAcceptanceByTeamDownloadXLS = (date1, date2, filterBy, scrd, evntType, evntSubtype, evaluator, scrdGrp, supGrp) => API.get(`${process.env.REACT_APP_API_URL}/analytic_groups/result_acceptance_group?from_date=${date1}&to_date=${date2}&filter_by=${filterBy}&score_card=${scrd}&event_type=${evntType}&event_sub_type=${evntSubtype}&evaluator=${evaluator}&score_card__groups=${scrdGrp}&sup_groups=${supGrp}&export=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

// 1.11 acceptance by agent
export const getAcceptanceByAgentDownloadXLS = (obj) => API.get(`${process.env.REACT_APP_API_URL}/analytic_staff/staff_result_acceptance?from_date=${obj?.fromDate || moment().startOf('month').format('YYYY-MM-DD')}&to_date=${obj?.toDate || moment().endOf('month').format('YYYY-MM-DD')}&filter_by=${obj?.filterBy || "created_at"}&score_card__in=${obj?.scoreCardSingle || ""}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&evaluator=${obj?.evaluator || ""}&score_card__groups=${obj?.group || ""}&teams=${obj?.teams || ""}&data_tags=${obj?.dataTag || ""}&data_tags_operator=${obj.dataTagsOperator || ''}&sup_groups=${(loggedUserData?.staff && loggedUserData?.staff?.staffType !== 10) ? loggedUserData?.groups?.map(obj => obj.id) : ""}&export=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

// 1.5 module
export const getSectionTrendByIndividualDownloadXLS = (obj, dateType, view, isNumeric) => API.get(`/analytic_staff/section_trend_individual?score_card__groups=${obj?.group}&from_date=${moment(obj?.fromDate).format('YYYY-MM-DD') || moment().startOf('month').format('YYYY-MM-DD')}&to_date=${moment(obj?.toDate).format('YYYY-MM-DD') || moment().endOf('month').format('YYYY-MM-DD')}&filter_by=${obj?.filterBy || "created_at"}&staff=${obj?.staff || ""}&score_card=${obj?.scoreCardSingle}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&date_type=${dateType}&switch_view=${view === 1 ? 1 : ''}&numeric=${isNumeric || ""}&formula=${obj?.formula || 10}&export=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

export const getSectionTrendByIndividualSectionDetailsDownloadXLS = (obj, isNumeric) => API.get(`/analytic_staff/section_details_individual?from_date=${obj.date1}&to_date=${obj?.date2}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group || ""}&staff=${obj.staff}&score_card=${obj.scorecard}&date_type=${obj.dateType}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.subType || ""}&date_num=${obj.dateNum}&section=${obj.section}&numeric=${isNumeric || ''}&formula=${obj?.formula}&numeric=${obj?.isNumeric}&date_year=${obj?.year}&export=true`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
});

export const getEvaluationPerPeriodDownloadXLS = (obj, dateType) => API.get(`/analytic_evaluation/evaluation_periods?filter_by=${obj.filterBy}&page=1&export=true&event_date=&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&to_date=${obj?.toDate}&from_date=${obj?.fromDate}&date_type=${dateType}&score_card__groups=${obj?.group}&teams=${obj?.teams || ""}&score_card=${obj?.scoreCard}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})
export const getEvaluationPerDownloadXLS = (obj, dateType) => API.get(`/analytic_evaluation/evaluation_scorecard?filter_by=${obj.filterBy}&page=1&export=true&event_date=&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&to_date=${obj?.toDate}&from_date=${obj?.fromDate}&date_type=${dateType}&score_card__groups=${obj?.group}&teams=${obj?.teams || ""}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})
export const getEvaluationPerScorecardByStaffDownloadXLS = (obj) => API.get(`/analytic_evaluation/evaluation_scorecard_staff?filter_by=${obj.filterBy}&page=1&export=true&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&to_date=${obj?.toDate}&from_date=${obj?.fromDate}&score_card__groups=${obj?.group}&teams=${obj?.teams || ""}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getCausesByStaffDownloadXLS = (obj, percentageView, viewType) => API.get(`/analytic_staff/causes_by_staff?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj?.toDate).format('YYYY-MM-DD')}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&score_card=${obj?.scoreCard}&staff=${obj?.staff}&supervisor=${obj?.supervisor}&section=${obj?.section}&question=${obj?.question}&export=true&percentage_view=${percentageView === true ? 'true' : ''}&view_type=${viewType}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getCausesByStaffResultDetailsDownloadXLS = (obj) => API.get(`/analytic_staff/causes_by_staff_result_details?from_date=${obj.fromDate}&to_date=${obj.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard || ''}&score_card__groups=${obj.group || ''}&teams=${obj.teams}&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&user_id=${obj.user_id || ''}&cause_id=${obj?.cause_id || ''}&page=${obj.page || 1}&search=${obj.filterName || ''}&supervisor_id=${obj.supervisor || ''}&score_result=${obj?.scoreResult || ''}&export=True`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getReviewBySupervisorDownloadXLS = (obj, dateType) => API.get(`/analytic_evaluation/reviews_by_supervisor?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&date_type=${dateType}&score_card__in=${obj?.scoreCardMultiple}&score_card__groups=${obj?.group}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&export=True`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getReviewByEvaluatorDownloadXLS = (obj, dateType) => API.get(`/analytic_evaluation/reviews_by_evaluator?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&date_type=${dateType}&score_card__in=${obj?.scoreCardMultiple}&score_card__groups=${obj?.group}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&export=True`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getPointOfFailureDownloadXLS = (obj) => API.get(`/analytic_groups/points_of_failure?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filter_By}&score_card__groups=${obj?.group}&event_type=${obj?.event_Type}&event_sub_type=${obj?.sub_type}&data_tags=${obj?.dataTag}&data_tags_operator=${obj?.dataTagsOperator}&export=True`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getSummaryDownloadXLS = (obj, dateType) => API.get(`/analytic_groups/summary?filter_by=${obj?.filter_By}&page=1&export=1&event_type=${obj?.event_Type}&event_sub_type=${obj?.sub_type}&from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj.toDate).format('YYYY-MM-DD')}&date_type=${dateType}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.score_card}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})
export const getSummaryResultDetailsDownloadXLS = (obj) => API.get(!obj?.dateKey ? `/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=${obj?.filterName}&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&target=${obj?.target || ""}&export=1` : `/analytic_evaluation/result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&${obj.dateKey}=${obj.dateValue}&${obj.createdAtKey}=${obj.createdAtValue}&filter_by=${obj.filterBy}&score_card__in=${obj.scoreCard}&score_card__groups=${obj.group}&teams=&event_type=${obj.eventType}&event_sub_type=${obj.eventTypeEventSubType}&staff_id=${obj.staff}&search=&highlight_value=${obj.highlightValue}&supervisor_id=${obj.supervisor}&score_result=${obj.scoreResult}&evaluator=${obj?.evaluator}&target=${obj?.target || ""}&export=1`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getSummaryByGroupDownloadXLS = (obj, dateType) => API.get(`/analytic_groups/summary_scorecard?filter_by=${obj?.filter_By}&page=1&export=1&from_date=${obj?.fromDate}&to_date=${obj?.toDate}&date_type=${dateType}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card__in=${obj?.score_card}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventSubType}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})


export const getSectionByEventTypeDownloadXLS = (obj) => API.get(`/analytic_staff/section_event_type_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card=${obj?.scoreCardSingle}&score_card__groups=${obj?.group}&staff_id=${obj?.staff}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&formula=${obj?.formula}&export=${true}`, {

    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getSectionByEventTypeSectionDetailsDownloadXLS = (obj) => API.get(`/supervisor_analytic/supervisor_section_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card=${obj?.scorecard}&score_card__groups=${obj?.group}&staff_id=${obj?.staff}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventSubType || ""}&section_id=${obj?.section}&export=${true}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getSectionByResultDownloadXLS = (obj) => API.get(`/analytic_staff/section_result_details?from_date=${moment(obj.fromDate).format('YYYY-MM-DD')}&to_date=${moment(obj?.toDate).format('YYYY-MM-DD')}&filter_by=${obj?.filterBy}&score_card=${obj?.scoreCardSingle}&score_card__groups=${obj?.group}&staff_id=${obj?.staff}&event_type=${obj?.eventType || ""}&event_sub_type=${obj?.eventTypeEventSubType || ""}&formula=${obj?.formula}&export=${true}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getAnswerSummaryCommentDownloadXLS = (obj, commentType) => API.get(`/analytic_scorecards/comments?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&comments_type=${commentType}&supervisor=${obj?.supervisor}&export=${true}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getSupervisorPointsOfFailureDownloadXLS = (obj) => API.get(`/points_of_failure_supervisor/xlxs_point_of_failure_supervisor?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value&score_card__in=${obj?.scoreCard}&data_tags=${obj?.dataTags || ''}&data_tags_operator=${obj?.dataTagsOperator || ''}&status=`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})


export const getComplianceByQuestionsDownlaodXLS = (obj, dateType) => API.get(`/question_trend/compliance_by_questions?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&date_type=${dateType}&score_card__groups=${obj?.group}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&section_id=${obj?.section}&staff_id=${obj?.staff}&export=${true}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getComplianceByQuestionDetailsDownloadXLS = (obj) => API.get(`/analytic_staff/question_by_staff_question_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scorecard}&event_type=${obj?.eventType}&event_sub_type=${obj?.subType}&question_id=${obj?.question}&export=${true}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getCustomerRefDataTagsDownloadXLS = (obj, numeric) => API.get(obj?.reference ? `/question_trend/customer_reference_by_data_tags?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor_id=${obj?.supervisor}&reference=${obj?.reference}&search=${obj?.filterName || ""}&export=${true}&numeric_view=${numeric || ""}` : `/question_trend/customer_reference_by_data_tags?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__groups=${obj?.group}&teams=${obj?.teams}&score_card=${obj?.scoreCard}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&supervisor_id=${obj?.supervisor}&search=${obj?.filterName || ""}&data_tags=${obj?.dataTag}&export=${true}&numeric_view=${numeric || ""}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})

export const getAdvanceReprotingDownloadXLS = (obj) => API.get(`/advanced_analytics/advanced_reporting_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card__in=${obj?.scoreCard?.filter(item => item !== 'all')}&score_card__groups=${obj?.group?.filter(item => item !== 'all')}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value=${obj?.highlightValue}&formula=${obj?.formula}&data_tags=${obj?.dataTags}&x_axis=${obj?.xAxis}&y_axis=${obj?.yAxis}&export=${true}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})
export const getAdvanceResultDetailsDownloadXLS = (obj) => API.get(`/supervisor_analytic/staff_result_details?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&filter_by=${obj?.filterBy}&score_card=${obj?.scoreCard}&score_card__groups=${obj?.group}&event_type=${obj?.eventType}&event_sub_type=${obj?.eventTypeEventSubType}&highlight_value=${obj?.highlightValue}&formula=${obj?.formula}&data_tags=&x_axis=${obj?.xAxis}&y_axis=${obj?.yAxis}&${obj?.key1}=${obj?.keyValue1}&${obj?.key2}=${obj?.keyValue2}&search=${obj?.search || ""}&export=${true}`, {
    responseType: 'arraybuffer', headers: { 'Content-Type': 'blob', }
})






