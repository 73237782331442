import { useRef, useState, useContext } from 'react';
// REACT ROUTER STATES
import { Link, useLocation } from 'react-router-dom';
// NPM IMPORTS
import { Icon } from '@iconify/react';
// MATERIAL IMPORTS
import { Box, Button, Menu, MenuItem, Stack, Typography } from '@material-ui/core';
import {
  AccountTreeOutlined, AutoGraphOutlined, AssignmentOutlined,
  BorderClearOutlined,
  CallOutlined,
  Dashboard, DashboardCustomizeOutlined,
  FlareOutlined,
  KeyboardArrowDown,
  VisibilityOutlined,
} from '@material-ui/icons';
import { PATH_DASHBOARD } from "../../routes/paths";
// import context 
import { SettingsContext } from "../../contexts/SettingsContext"

// ----------------------------------------------------------------------

export default function AccountPopover({ fromDashboard }) {

  const dashboardDropDownOptions = [
    { label: 'Global Dashboard', link: 'dashboard', icon: <DashboardCustomizeOutlined sx={{ mr: 2, width: 20, height: 20 }} /> },
    { label: 'Evaluator Dashboard', link: 'evaluator_dashboard', icon: <BorderClearOutlined sx={{ mr: 2, width: 20, height: 20 }} /> }
  ]

  const speechAIDropDownOptions = [
    { label: 'Events', link: PATH_DASHBOARD.beyondqaAi.events, icon: <FlareOutlined sx={{ mr: 2, width: 20, height: 20 }} /> },
    { label: 'Calls', link: PATH_DASHBOARD.beyondqaAi.omniInteraction.call, icon: <CallOutlined sx={{ mr: 2, width: 20, height: 20 }} /> },
    { label: 'Analyze', link: PATH_DASHBOARD.beyondqaAi.analyze, icon: <VisibilityOutlined sx={{ mr: 2, width: 20, height: 20 }} /> },
    { label: 'Auto QA', link: PATH_DASHBOARD.beyondqaAi.autoqa, icon: <AccountTreeOutlined sx={{ mr: 2, width: 20, height: 20 }} /> },
    { label: 'Coaching', link: PATH_DASHBOARD.beyondqaAi.coaching, icon: <AutoGraphOutlined sx={{ mr: 2, width: 20, height: 20 }} /> },
    { label: 'Assignment', link: PATH_DASHBOARD.beyondqaAi.assignment, icon: <AssignmentOutlined sx={{ mr: 2, width: 20, height: 20 }} /> },
  ]

  // REACT CONTEXT STATES
  const { setColor, themeMode } = useContext(SettingsContext)

  // REACT REF STATES
  const anchorRef = useRef(null);
  const anchorRef1 = useRef(null);
  const { pathname } = useLocation();

  // REACT STATES
  const [open, setOpen] = useState(false);
  const [openDashboard, setOpenDashboard] = useState(false);




  // METHODS / FUNCTIONS
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenMenu = () => {
    setOpenDashboard(true);
  };
  const handleCloseMenu = () => {
    setOpenDashboard(false);
  };

  const getThemeColor = () => {
    if (themeMode === 'light') {
      return setColor?.main
    }
    return setColor?.dark
  }


  const MenuItemList = (label, link, Icon) => {
    return (
      <Link key={label} to={link} style={{ textDecoration: 'none', color: '#000000' }}>
        <MenuItem sx={{ minWidth: '150px', typography: 'body2', py: 1, px: 2.5 }} onClick={() => { handleClose(); }}>
          <Stack flexDirection='row' alignItems='center'>

            {Icon}

            <Typography sx={{ fontSize: { lg: '11px', xl: '13px' }, fontWeight: 'bold' }} >
              {label}</Typography>
          </Stack>

        </MenuItem>
      </Link>
    )
  }


  return (
    <>

      {/* ----------------------------------------------xxxxxxxxxxxxxxxxxxxxxx FOR DASHBOARD DROP DOWN xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx------------------------------------- */}


      {fromDashboard && <>

        <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px" }, '&:hover': { backgroundColor: 'transparent' } }}
          // startIcon={<Dashboard sx={{ width: '27px', height: '27px', mx: 0, color: theme => ["dashboard", "evaluator_dashboard"].includes(pathname.split("/")[1]) ? theme.palette.action.selected : theme.palette.functionalityBtn }} />}
          endIcon={<KeyboardArrowDown sx={{ color: theme => ["dashboard", "evaluator_dashboard"].includes(pathname.split("/")[1]) ? theme.palette.action.selected : theme.palette.functionalityBtn }} fontSize='small' />} ref={anchorRef1} disableRipple onClick={handleOpenMenu} >
          <Box sx={{ width: '26px', height: '26px' }}>
            <img alt='icons' src={["dashboard", "evaluator_dashboard"].includes(pathname.split("/")[1]) ? "/static/illustrations/navbar/dashboard_nav.svg" : "/static/illustrations/navbar/dashboard_nav_gray.svg"} style={{ display: 'block', width: '100%', height: '100%' }} />
          </Box>
          <Typography sx={{ color: theme => ["dashboard", "evaluator_dashboard"].includes(pathname.split("/")[1]) ? theme.palette.action.selected : theme.palette.functionalityBtn, fontSize: { lg: '12px', newLg: '13px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Dashboard</Typography>
        </Button>

      </>
      }

      <Menu
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
        PaperProps={{
          sx: { width: 200, zIndex: 1300, }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {speechAIDropDownOptions?.map(item => MenuItemList(item?.label, item?.link, item?.icon))}
      </Menu>

      {/* ----------------------------------------------xxxxxxxxxxxxxxxxxxxxxx FOR DASHBOARD DROP DOWN xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx------------------------------------- */}




      {/* --------------------------------xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx-----------------xxxxxxxxxxxxxxxxxxxx-------------xxxxxxxxxxxxxxxxxxxxxxxxxxxx---------------xxxxxxxxxxxxxxxxxx-----------xxxxx */}





      {/* ----------------------------------------------xxxxxxxxxxxxxxxxxxxxxx FOR SPEEECHAI DROP DOWN xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx------------------------------------- */}


      {!fromDashboard && <Button ref={anchorRef} endIcon={<KeyboardArrowDown sx={{ width: { lg: '12px', newLg: '16px' }, height: { lg: '12px', newLg: '16px' } }} />} sx={{
        ml: 5,
        backgroundColor: theme => theme.palette.text.buttonText, color: '#FFFFFF', p: { lg: 1, newLg: 1.5 }, '&:focus-visible': {
          backgroundColor: '#D65A29',  // Focused background color
          color: '#E0E0E0',             // Focused text color
        },
        '&:hover': {
          backgroundColor: '#f38f5b',  // Hover state keeps the same background
        },
        '& .MuiSvgIcon-root': {
          color: '#FFFFFF', // Default icon color
        },
        '&:focus-visible .MuiSvgIcon-root': {
          color: '#E0E0E0', // Icon color on focus
        },
        '&:focus-visible .MuiTypography-root': {
          color: '#E0E0E0', // Typography color on focus
        }
      }} size='small' onClick={handleOpen}>
        <Icon
          sx={{
            fontSize: { lg: '10px', newLg: '16px', xl: '18px' },
            mr: 1,
            color: "#FFFFFF" // Ensures the icon color matches the text color
          }}
        >
          <Box component='img' alt="Custom SVG Icon" src="/static/illustrations/navbar/download.svg" sx={{ width: { lg: '20px', newLg: '24px' }, height: { lg: '20px', newLg: '24px' } }} />


        </Icon>
        <Typography sx={{ fontSize: { lg: '10px', newLg: '13px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Speech AI Hub</Typography>
      </Button>}


      <Menu
        open={openDashboard}
        onClose={() => handleCloseMenu()}
        anchorEl={anchorRef1.current}
        PaperProps={{
          sx: { width: 200 }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {dashboardDropDownOptions?.map(item => MenuItemList(item?.label, item?.link, item?.icon))}
      </Menu>


      {/* ----------------------------------------------xxxxxxxxxxxxxxxxxxxxxx FOR SPEEECHAI DROP DOWN xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx------------------------------------- */}
    </>
  );
}