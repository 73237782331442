import PropTypes from 'prop-types';

import { useMemo } from 'react';
// material
import { CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@material-ui/core/styles';
// hooks
import ApexCharts from 'apexcharts';
import useSettings from '../hooks/useSettings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

export default function ThemeConfig({ children }) {
  const { themeMode, themeDirection, setColor } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, action: { selected: setColor?.main, hover: "#e3e4e9e8" }, mode: 'light' } : { ...palette.dark, action: { selected: setColor?.dark, hover: "#e3e4e9e8" }, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      // components: {
      //   MuiTableHead: {
      //     styleOverrides: {
      //       root: {
      //         '&&': {
      //           backgroundColor: '#1976d2 !important',
      //         },
      //         '& tr': {
      //           backgroundColor: '#1976d2 !important',
      //         },
      //         '& th': {
      //           backgroundColor: '#1976d2 !important',
      //           color: '#ffffff !important',
      //           fontWeight: 'bold !important',
      //         },
      //         '& .MuiTableCell-head': {
      //           backgroundColor: '#1976d2 !important',
      //           color: '#ffffff !important',
      //           fontWeight: 'bold !important',
      //         },
      //       },
      //     },
      //   },
      //   MuiTableCell: {
      //     styleOverrides: {
      //       head: {
      //         backgroundColor: '#1976d2 !important',
      //         color: '#ffffff !important',
      //         fontWeight: 'bold !important',
      //       },
      //       root: {
      //         '&.MuiTableCell-head': {
      //           backgroundColor: '#1976d2 !important',
      //           color: '#ffffff !important',
      //           fontWeight: 'bold !important',
      //         },
      //       },
      //     },
      //   },
      //   MuiTableRow: {
      //     styleOverrides: {
      //       root: {
      //         '&.MuiTableRow-head': {
      //           backgroundColor: '#1976d2 !important',
      //         },
      //       },
      //     },
      //   },
      // },
      ApexCharts: {
        theme: {
          palette: 'palette1'
        }
      }
    }), [isLight, themeDirection, setColor]
  );
  // Apex.theme.palette: isLight
  // const chartTheme = createTheme({
  //   ApexCharts.theme.palette:'palette10'

  // })

  const theme = createTheme(themeOptions);
  theme.components = {
    ...componentsOverride(theme),
    ...theme.components,
    MuiCssBaseline: {
      styleOverrides: {
        '*': {

          // 'scrollbar-width': 'thin',
          // 'scrollbar-color': `${isLight ? '#888' : '#636e72'} ${isLight ? '#f1f1f1' : '#2f3542'}`,
          '&::-webkit-scrollbar': {
            width: '7.5px',
            height: '6.25px',
            borderRadius: 5,
            cursor: 'pointer'
          },

          '&::-webkit-scrollbar-track': {
            backgroundColor: '#dcdde1',
            borderRadius: 1,
            height: '101%'
          },

          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#95a5a6',
            borderRadius: 5,
            cursor: 'pointer',
            // transition: 'height 0.2s ease-in-out'
          },


          // '&::-webkit-scrollbar-button': {
          //   display: 'none', // Hide extra buttons
          // },
          // '&::-webkit-scrollbar-button:single-button': {
          //   display: 'block',
          //   width: '32px', // XL Buttons
          //   height: '32px',
          //   backgroundSize: 'cover',
          //   backgroundRepeat: 'no-repeat',
          //   backgroundPosition: 'center',
          // },

          // /* Left Button */
          // '&::-webkit-scrollbar-button:horizontal:decrement': {
          //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><polygon points="48,12 20,32 48,52" fill="%2395a5a6"/></svg>')`,
          // },

          // /* Right Button */
          // '&::-webkit-scrollbar-button:horizontal:increment': {
          //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><polygon points="16,12 44,32 16,52" fill="%2395a5a6"/></svg>')`,
          // },

          // /* Top Button */
          // '&::-webkit-scrollbar-button:vertical:decrement': {
          //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><polygon points="12,48 32,20 52,48" fill="%2395a5a6"/></svg>')`,
          // },

        },
      },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
