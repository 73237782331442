import React, { useState, useCallback } from 'react';
// REACT ROUTER IMPORTS
import { useSearchParams, useNavigate } from 'react-router-dom';
// MATERIAL IMPORTS 
import {
    Box, Button, CircularProgress, Chip, InputAdornment, Grid, Paper, TableHead, TableRow,
    Table, TableBody, TableCell, tableCellClasses, TableContainer, Stack, Tooltip, TablePagination,
    Typography, OutlinedInput, Tab, IconButton, Pagination
} from '@material-ui/core';
import { makeStyles } from '@mui/styles';
// import TableHead from '@mui/material/TableHead';
// import  from '@mui/material/TableRow';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Warning, Flag, Group, Star, MilitaryTech, CheckBox, RemoveCircleOutline, ArrowForward, ArrowDownward, ArrowUpward, Visibility } from '@material-ui/icons';
// NPM IMPORTS 

import { useResizeDetector } from 'react-resize-detector';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import _ from "lodash";
import { styled } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';
import moment from 'moment';
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
import { SettingsContext } from '../../contexts/SettingsContext';
// OTHER IMPORTS
import EmpDatePicker from "./datepicker";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_EMP } from '../../routes/paths';
import { setButtonBg, setButtonText } from '../../utils/filterUtilities';
// API IMPORTS
import { empScoreListApi, empScoreDetailsApi, myscorelistScorePageApi } from "../../_apis_/authApi.js/empapi";
import groupIcon from '../../assets/emp_dashboard_icons/groupIcon.svg';
import teamIcon from '../../assets/emp_dashboard_icons/teamIcon.svg';
import flagIcon from '../../assets/emp_dashboard_icons/flagIcon.svg';
import kudosIcon from '../../assets/emp_dashboard_icons/kudosIcon.svg';

const useStyles = makeStyles({
    pagination: {
        '& .MuiTablePagination-actions > button:first-of-type': {
            display: 'none', // Hide the "Previous" button
        },
        '& .MuiTablePagination-actions > button:last-of-type': {
            display: 'none', // Hide the "Next" button
        },
        '& .MuiTablePagination-toolbar': {
            paddingLeft: 1,
        },
    },
    paginationText: {
        color: '#212668', // Change this to the color you want,
        fontWeight: '600 !important'
    }
});

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {


        width: 210,
        height: 45,


        '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },


        '& .MuiOutlinedInput-input::placeholder': {
            color: theme.palette.text.buttonText, // Change this color to your desired placeholder color,
            fontFamily: 'Nunito, sans-serif'
        }
    },
    [theme.breakpoints.down('sm')]: {


        width: 150,
        height: 35,


        '&.Mui-focused': { width: 150, boxShadow: theme.customShadows.z8 },

        '& .MuiOutlinedInput-input::placeholder': {
            fontSize: '12px',
            color: theme.palette.text.buttonText, // Change this color to your desired placeholder color,
            fontFamily: 'Nunito, sans-serif'
        }
    },
    color: '#5F6368 !important',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
        border: 'none !important',
        color: '#5F6368 !important', fontWeight: '100 !important'
    },
}));


const myScoreHeader = [
    { name: 'ID' },
    { name: 'Group' },
    // { name: 'Created Date' },
    { name: 'Event Date' },
    { name: 'Category' },
    { name: 'Evaluator' },
    { name: 'Score' },
    { name: 'Status' },
    {
        id: 'feedaback',
        numeric: false,
        disablePadding: false,
        name: 'Feedback Date'
    },
    {
        id: 'reviewdby',
        numeric: false,
        disablePadding: false,
        name: 'Reviewed By'
    },
    {
        id: 'Action',
        numeric: false,
        disablePadding: false,
        name: 'Action'
    },
]




export default function EmpScore() {
    const classes = useStyles();
    const { width, ref } = useResizeDetector();

    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));

    // REACT ROUTER STATES
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // CONTEXT STATES
    const { datesData, IdEmp } = React.useContext(EmpContext);
    const { setColor, themeMode } = React.useContext(SettingsContext);
    // console.log('IdEmp', IdEmp, datesData)
    // REACT STATES
    const [value, setValue] = React.useState(searchParams.get('tab') || '1');
    const [scoreDetail, setScoreDetail] = React.useState({});
    const [scoreList, setScoreList] = React.useState([]);
    const [labelX, setLabelX] = React.useState([]);
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const [isGraphLoading, setIsGraphLoading] = React.useState(false);
    const [scoreCardId, setScoreCardId] = React.useState();
    const [myScoreData, setMyScoreData] = React.useState([]);
    const [mySectionData, setMySectionData] = React.useState([]);
    const [myScoreLoader, setMyScoreLoader] = React.useState(false);
    const [myScoreCount, setMyScoreCount] = React.useState(0);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(1);
    const [myScorePageRange, setMyScorePageRange] = useState(0)
    const [scorecardName, setScorecardName] = useState();
    const [scrDetailTableOrder, setScrDetailTableOrder] = useState(false);
    const [scrDetailShowIcon, setScrDetailShowIcon] = useState(false)
    const [secDetailTableOrder, setSecDetailTableOrder] = useState(false);
    const [secDetailShowIcon, setSecDetailShowIcon] = useState(false)



    // METHODS / FUNCTIONS

    const handleTableOrderScrDetails = () => {
        setScrDetailTableOrder(!scrDetailTableOrder);
        setMyScoreData(myScoreData.reverse())

    }
    const handleTableOrderSecDetails = () => {
        setSecDetailTableOrder(!secDetailTableOrder);
        setMySectionData(mySectionData.reverse())

    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchParams({ tab: newValue })
    };
    const loadingTimeOut = () => {
        setTimeout(() => {
            if (isDataLoading) {
                setIsDataLoading(false);
            }
        }, 60000);

    }

    React.useEffect(() => {
        if (scoreCardId) {
            fetchMyScoreTableData(datesData?.date1, datesData?.date2, scoreCardId);
        }
    }, [scoreCardId])


    const fetchMyScoreTableData = async (date1, date2, id, query, pgNo) => {
        setMyScoreLoader(true);
        setMyScoreData([]);
        try {
            const { data } = await myscorelistScorePageApi(date1, date2, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, id, pgNo || 1, query || "", pgNo || 1);
            setMyScoreData(data?.results);
            setMyScoreCount(data?.count);
            setMyScorePageRange(data?.page_range)
            setMyScoreLoader(false);
        } catch {
            setMyScoreData([]);
            setMyScoreCount(0);
            setMyScorePageRange(0)
            setMyScoreLoader(false);
        }
    }

    React.useEffect(() => {

        async function getList() {

            setScoreList([]);
            setIsDataLoading(true);
            loadingTimeOut();
            setValue(searchParams.get('tab') || '1');
            const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
            setScoreDetail({})
            const { data } = await empScoreListApi(searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, datesData?.date1, datesData?.date2);
            setScoreList(data.data);
            if (data?.data?.length < Number(searchParams.get('tab'))) {
                setValue('1');
                setScoreCardId(data?.data[0]?.score_card);
                setScorecardName(data?.data[0]?.score_card__name);
                setIsDataLoading(false);
            }
            if (data?.data?.length >= Number(searchParams.get('tab'))) {
                setScoreCardId(data?.data[value - 1]?.score_card);
                setScorecardName(data?.data[value - 1]?.score_card__name);
                setIsDataLoading(false);
            }


            if (data?.data.length > 0 && data?.data?.length < Number(searchParams.get('tab'))) {

                const res = await empScoreDetailsApi(data?.data[0]?.score_card, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, datesData?.date1, datesData?.date2);
                setScoreDetail(res.data)
                setMySectionData(res?.data?.section_details)

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__month) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `${moment(obj?.created_at__month, 'MM').format('MMMM')}`))
                }

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__week) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `Week - ${obj.created_at__week}`))
                }
            }
            if (data?.data.length > 0 && data?.data?.length >= Number(searchParams.get('tab'))) {

                const res = await empScoreDetailsApi(data?.data[value - 1]?.score_card, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id || IdEmp?.id, datesData?.date1, datesData?.date2);
                setScoreDetail(res.data)
                setMySectionData(res?.data?.section_details)

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__month) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `${moment(obj?.created_at__month, 'MM').format('MMMM')}`))
                }

                if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__week) {
                    setLabelX(res?.data?.score_graph_data.map(obj => `Week - ${obj.created_at__week}`))
                }
            }
        }
        if (datesData?.date1) {
            getList();
        }
    }, [datesData])


    const tabButtons = (props) => {
        // console.log('props.direction', props)
        if (props.direction === 'left' && !props.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => props.onClick()}
                    icon="icon-park-solid:left-c"
                    style={{ color: '#fb5d2e', fontSize: '25px', cursor: 'pointer' }}
                />
            </Stack>)
        }
        if (props.direction === 'right' && !props.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => props.onClick()}
                        icon="icon-park-solid:right-c"
                        style={{ color: '#fb5d2e', fontSize: '25px', cursor: 'pointer' }}
                    />
                    {/* <Box component='span'>13/{groupLength}</Box> */}

                </Stack>
            )
        }
        return null
    }


    const option = {
        series: [{
            name: "Overall Score",
            data: scoreDetail?.score_graph_data?.length > 0 ? scoreDetail?.score_graph_data.map(obj => { if (!Number?.isNaN(((obj.total_score / (obj?.total_sum - obj?.na_sum)) * 100))) { return ((obj.total_score / (obj?.total_sum - obj?.na_sum)) * 100)?.toFixed(2) } return 0 }) : []
        }],
        options: {
            colors: ['#788BD9'],

            chart: {
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        const stffEmpId = searchParams.get("stff_emp_id") ? `&stff_emp_id=${searchParams.get("stff_emp_id")}` : "";
                        const globalDash = searchParams.get("global_dash") ? `&global_dash=${searchParams.get("global_dash")}` : "";
                        // alert(labelX[config.dataPointIndex])
                        // alert(config.w.config.series[0].data[config.dataPointIndex]);
                        if (labelX[config.dataPointIndex].includes("Week")) {
                            navigate(`/employee/myscore?date1=${datesData?.date1}&date2=${datesData?.date2}&range=week&date_number=${labelX[config.dataPointIndex].slice(7, 9)}&staff=${searchParams.get("stff_emp_id") || loggedUserData?.staff?.id}&scorecard=${scoreCardId}${stffEmpId}${globalDash}`)
                        }
                        else {
                            navigate(`/employee/myscore?date1=${datesData?.date1}&date2=${datesData?.date2}&range=month&date_number=${moment().month(labelX[config.dataPointIndex]).format("M")}&staff=${searchParams.get("stff_emp_id") || loggedUserData?.staff?.id}&scorecard=${scoreCardId}${stffEmpId}${globalDash}`)


                        }
                        // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                    }
                },
                type: 'line',
                dropShadow: {
                    enabled: true,
                    top: -5,
                    left: 0,
                    blur: 2,
                    opacity: 0.2,
                    color: ['#788BD9']
                },
                toolbar: {
                    show: false
                },
            },
            dataLabels: {
                enabled: false,
            },
            grid: {

                show: true, borderColor: '#c7f3fc',
            },

            stroke: {
                curve: 'straight',
                width: 3,
            },
            xaxis: {
                categories: labelX?.length > 0 ? labelX : [0],
                labels: {
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },
                }
            },
            yaxis: {
                max: 100,
                show: scoreDetail?.score_graph_data?.length > 0,
                tickAmount: 4,
                labels: {
                    formatter: (val) =>
                        val?.toFixed(2),
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },

                },
                title: {
                    text: 'Score',
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 500,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            noData: {
                text: "No data found for the data range selected.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '18px',
                    fontWeight: "bold",
                    color: themeMode === 'light' ? '#666666' : '#ffffff'
                }
            },
            legend: {
                onItemClick: {
                    toggleDataSeries: false
                },
                labels: {
                    colors: themeMode === 'light' ? ['#666666'] : ['#ffffff'],
                    useSeriesColors: false,
                }, showForSingleSeries: true, fontSize: '12px', position: "top", paddinBottom: '12px', horizontalAlign: "right", height: 30
            },
            markers: {
                size: 10,
                strokeColor: ['#788BD9'],
                strokeWidth: 4,
                strokeOpacity: 0.9,
                // strokeDashArray: 0,
                colors: '#fff',
                fillOpacity: 0.9,
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0
            },
            title: {
                text: 'Score',
                align: 'left',
                // marginTop: '10px',
                offsetX: 10,
                offsetY: 15,
                floating: false,
                style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#000000',
                    // color: themeMode === 'light' ? '#666666' : '#ffffff'
                },
            },


        },
    }


    async function getScoreDetails(id) {

        const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
        setIsGraphLoading(true);
        const res = await empScoreDetailsApi(id, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, datesData?.date1, datesData?.date2);
        setScoreDetail(res?.data)
        setMySectionData(res?.data?.section_details)
        setScoreCardId(id);
        setIsGraphLoading(false);
        if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__month) {
            setLabelX(res?.data?.score_graph_data.map(obj => `${moment(obj?.created_at__month, 'MM').format('MMMM')}`))
        }

        if (res?.data?.score_graph_data?.length > 0 && res?.data?.score_graph_data[0]?.created_at__week) {
            setLabelX(res?.data?.score_graph_data.map(obj => `Week - ${obj.created_at__week}`))
        }
        // console.log(data)
    }


    // MY SCORE FUNCTIONS

    const sendQuery = (date1, date2, scorecardID, query, pgNo) => {
        // console.log(query, query?.length)
        // console.log(date1, date2)
        if (query?.length > 0) {

            fetchMyScoreTableData(date1, date2, scorecardID, query?.trim(), pgNo);
        }
        if (query?.length === 0) {

            fetchMyScoreTableData(date1, date2, scorecardID, query?.trim(), pgNo)
        }
    }

    const delayedQuery = useCallback(_.debounce((date1, date2, scorecardID, query, pgNo) => sendQuery(date1, date2, scorecardID, query, pgNo), 1000), []);

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
        delayedQuery(datesData?.date1, datesData?.date2, scoreCardId, event.target.value, 1);
        // getCalibrateData(event.target.value, 1);

    };


    const handleChangePage = (event, newPage) => {
        // getCalibrateData(filterName, newPage + 1);
        fetchMyScoreTableData(datesData?.date1, datesData?.date2, scoreCardId, filterName, newPage);
        return setPage(newPage);

    };



    return (
        <>
            <Box component={Paper} elevation={5} sx={{ mb: 2, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0, backgroundColor: theme => theme.palette.background.paper }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignContent="center" alignItems={{ sm: "center", xs: 'flex-start' }} sx={{ backgroundColor: theme => theme.palette.background.paper, px: 2, py: 1, borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <Box>
                        <HeaderBreadcrumbs
                            sx={{ p: 0, m: 0, color: '#666666', fontWeight: 600 }}
                            links={[{ name: 'Home', href: searchParams.get('stff_emp_id') ? `${PATH_EMP.dashboard}?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : PATH_EMP.dashboard }, { name: 'Scores', href: PATH_EMP.scores }]}

                        />
                        <Typography variant={{ sm: 'h4', xs: 'caption' }} sx={{ mt: 0, fontWeight: '400', color: '#000000' }}>
                            Score
                        </Typography>



                    </Box>
                    <EmpDatePicker />
                </Stack>
                <Box sx={{ py: 1 }}>
                    <TabContext value={value}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }} sx={{ mx: { xs: 1, sm: 3 } }} >
                            <TabList TabIndicatorProps={{ style: { display: 'none' } }} ScrollButtonComponent={(props) => tabButtons(props)} variant="scrollable"
                                sx={{
                                    width: { xs: '100%', sm: scoreList?.length === 0 ? '100%' : '85%' }, display: "flex", flexDirection: "row",



                                    borderBottom: (theme) => {
                                        return {
                                            lg: `1px solid ${scoreList?.length === 0 ? 'transparent' : '#fb5d2e'}`,
                                            xl: `2px solid ${scoreList?.length === 0 ? 'transparent' : '#fb5d2e'}`
                                        }
                                    },
                                    backgroundColor: (theme) => theme.palette.background.paper,
                                    '&.MuiTabList-indicator': {
                                        color: 'white'
                                    }
                                }}
                                onChange={handleChange}>
                                {scoreList?.map((tab, index) => (
                                    <Tab key={index} label={tab.score_card__name} onClick={() => { setScorecardName(tab.score_card__name); getScoreDetails(tab.score_card) }} value={String(index + 1)}

                                        sx={{
                                            fontWeight: 200,
                                            "&.Mui-selected": {
                                                color: 'white',
                                                // color: (theme) => theme.palette.text.buttonText, 
                                                fontWeight: 700,
                                                background: '#fb5d2e',
                                                padding: '0 25px',
                                                borderBottomLeftRadius: '4px',
                                                borderBottomRightRadius: '4px',
                                                borderTopLeftRadius: '4px',
                                                borderTopRightRadius: '4px',
                                            }
                                        }}
                                    />
                                ))}
                            </TabList>
                            {scoreList?.length > 0 && <Box sx={{
                                width: { xs: '100%', sm: '15%' }, borderBottom: (theme) => {
                                    return {
                                        lg: `1px solid #fb5d2e`,
                                        xl: `2px solid #fb5d2e`
                                    }
                                },
                            }}>
                                <Stack direction="row" alignItems="center" justifyContent={{ xs: 'flex-start', sm: 'flex-end' }} sx={{ mt: 1, py: 1 }}>
                                    <Stack direction="row" alignContent="center" alignItems="center" sx={{ mx: 1 }}>
                                        {scoreDetail?.failures_count?.fail_all_count || 0} <Tooltip title="Fail-All"><Warning sx={{ mx: 1 }} /></Tooltip>
                                    </Stack>
                                    <Stack direction="row" alignContent="center" alignItems="center" sx={{ mx: 1 }}>
                                        {scoreDetail?.failures_count?.fail_section_count || 0}  <Tooltip title="Fail"><Flag sx={{ mx: 1 }} /></Tooltip>
                                    </Stack>
                                    {/* <EmpDatePicker scoreTab={setValue} sx={{ mx: 1 }} /> */}
                                </Stack>

                            </Box>}
                        </Stack>

                        <Box
                            sx={{
                                py: 1,
                                mt: 1,
                                height: 'auto',
                                width: '100%',
                                borderRadius: 1
                            }}
                        >
                            {scoreList?.length > 0 && scoreList?.map((panel, index) => (
                                <TabPanel sx={{ px: { xs: 2, sm: 4 } }} key={index} value={String(index + 1)}>
                                    {Object?.keys(scoreDetail)?.length > 0 &&
                                        <>
                                            <Box>


                                                {!isGraphLoading && <>
                                                    <Box sx={{ py: 1, height: { lg: '190px', xl: '250px' } }} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" >

                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"

                                                            component={Paper}
                                                            elevation={5}
                                                            sx={{
                                                                flexGrow: 1,
                                                                borderRadius: { xs: 1, sm: 3 },
                                                                width: '100%',
                                                                mr: { lg: 2, xl: 3 },
                                                                ml: { lg: 1, xl: 2 },
                                                                px: 2,
                                                                py: { xs: 2, sm: 0 },
                                                                my: { sm: 0, xs: 1 },
                                                                boxShadow: "0 1px 6px 1px #c8cfc9",
                                                                // border: '2px solid #E9EBF3',
                                                                height: { lg: '58%', xl: '65%' },
                                                                // borderRadius: 2,
                                                                // backgroundColor: "#EFF1F7"


                                                                //  height: '385px', width: '385px'
                                                            }}>
                                                            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', height: '60px', width: '60px', backgroundColor: '#788BD9' }} >
                                                                <img src={groupIcon} alt='new' />
                                                            </Box>
                                                            <Box sx={{ ml: 4 }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "normal", fontSize: { lg: '15px', xl: '14px' },
                                                                        color: '#95a5a6'
                                                                    }} >
                                                                    Team OverAll Score
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: { lg: '20px', xl: '35px' },
                                                                        color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600
                                                                    }}>
                                                                    {`${((scoreDetail?.team_overall_score?.total_sum !== 0) && Number(scoreDetail?.team_overall_score?.total_sum)) ? ((scoreDetail?.team_overall_score?.total_score / (scoreDetail?.team_overall_score?.total_sum - scoreDetail?.team_overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}
                                                                </Typography>
                                                            </Box>

                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"

                                                            component={Paper}
                                                            elevation={5}
                                                            sx={{
                                                                flexGrow: 1,
                                                                borderRadius: { xs: 1, sm: 3 },
                                                                width: '100%',
                                                                mr: { lg: 2, xl: 3 },
                                                                ml: { lg: 1, xl: 2 },
                                                                px: 2,
                                                                py: { xs: 2, sm: 0 },
                                                                my: { sm: 0, xs: 1 },
                                                                boxShadow: "0 1px 6px 1px #c8cfc9",
                                                                // border: '2px solid #E9EBF3',
                                                                height: { lg: '58%', xl: '65%' },
                                                            }}>
                                                            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', height: '60px', width: '60px', backgroundColor: '#fa808a' }} >
                                                                <img src={teamIcon} alt='new' />
                                                            </Box>
                                                            <Box sx={{ ml: 4 }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "normal", fontSize: { lg: '15px', xl: '14px' },
                                                                        color: '#95a5a6'
                                                                    }} >
                                                                    My OverAll Score
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: { lg: '20px', xl: '35px' },
                                                                        color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600
                                                                    }}>
                                                                    {`${(scoreDetail?.staff_overall_score?.total_sum !== 0 && Number(scoreDetail?.staff_overall_score?.total_sum)) ? ((scoreDetail?.staff_overall_score?.total_score / (scoreDetail?.staff_overall_score?.total_sum - scoreDetail?.staff_overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}
                                                                </Typography>
                                                            </Box>

                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            alignItems="center"
                                                            component={Paper}
                                                            elevation={5}
                                                            sx={{
                                                                flexGrow: 1,
                                                                borderRadius: { xs: 1, sm: 3 },
                                                                width: '100%',
                                                                mr: { lg: 2, xl: 3 },
                                                                ml: { lg: 1, xl: 2 },
                                                                px: 2,
                                                                py: { xs: 2, sm: 0 },
                                                                my: { sm: 0, xs: 1 },
                                                                boxShadow: "0 1px 6px 1px #c8cfc9",
                                                                // border: '2px solid #E9EBF3',
                                                                height: { lg: '58%', xl: '65%' },
                                                            }}>
                                                            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', height: '60px', width: '60px', backgroundColor: '#7dd1b3' }} >
                                                                <img src={kudosIcon} alt='new' />
                                                            </Box>
                                                            <Box sx={{ ml: 4 }}>
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight: "normal", fontSize: { lg: '15px', xl: '14px' },
                                                                        color: '#95a5a6'
                                                                    }} >
                                                                    Kudos
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: { lg: '20px', xl: '35px' },
                                                                        color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600
                                                                    }}>
                                                                    {scoreDetail?.failures_count?.kudos_count}
                                                                </Typography>
                                                            </Box>

                                                        </Box>

                                                    </Box>

                                                </>}
                                                {isGraphLoading && <Stack sx={{ width: '100%', height: "150px", }} direction="row" alignItems="center" justifyContent="center">
                                                    <CircularProgress /> &nbsp;
                                                    <Typography variant="body" sx={{ my: 1 }}>
                                                        Loading Data...
                                                    </Typography>
                                                </Stack>}



                                            </Box>


                                        </>
                                    }

                                    {
                                        Object?.keys(scoreDetail)?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                            <MilitaryTech sx={{ fontSize: "100px" }} /><br />
                                            <Typography variant="body" sx={{ my: 1 }}>
                                                No Scores Found For Given Date Range.
                                            </Typography>
                                        </Box>
                                    }
                                </TabPanel>
                            ))}
                            {scoreList?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                <MilitaryTech sx={{ fontSize: "100px" }} /><br />
                                {isDataLoading ? <Stack direction="row" alignItems="center" justifyContent="center">
                                    <CircularProgress /> &nbsp;
                                    <Typography variant="body" sx={{ margin: '10%' }}>
                                        Loading Data...
                                    </Typography>
                                </Stack> :
                                    <Typography variant="body" sx={{ margin: '10%' }}>
                                        No Scores Found For Given Date Range.
                                    </Typography>}
                            </Box>
                            }
                        </Box>
                    </TabContext>
                </Box>
            </Box>


            <Box component={Paper} elevation={5} sx={{ height: '520px', mb: 2, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0, backgroundColor: theme => theme.palette.background.paper }}>
                <Box sx={{ height: "100%", width: "100%", py: 1, px: 2 }}>
                    {!isGraphLoading && <Chart options={option.options} series={option.series} type="line" width={width} height={500} />}
                    {/* test */}
                    {isGraphLoading && <Stack sx={{ width: '100%', height: '100%' }} direction="row" alignItems="center" justifyContent="center">
                        <CircularProgress /> &nbsp;
                        <Typography variant="body" sx={{ my: 1 }}>
                            Loading Data...
                        </Typography>
                    </Stack>}
                </Box>
            </Box>



            <>
                <Box component={Paper} elevation={5} sx={{ px: 2, mb: 2, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0, backgroundColor: theme => theme.palette.background.paper }}>
                    <Stack sx={{ width: "100%", px: 2, py: 2 }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                        {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                        <Typography variant='h6'>{`Score Details (${scorecardName || ``})`}</Typography>

                        <Box>
                            <SearchStyle
                                value={filterName}
                                onChange={handleFilterByName}
                                placeholder="Search..."
                                startAdornment={
                                    <>
                                        <InputAdornment position="start">
                                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                        </InputAdornment>
                                    </>
                                }
                            />
                        </Box>


                        {/* <Stack direction="row" alignItems="center" alignContent="center">

                            <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                            <TablePagination
                                sx={{ border: "1px solid transparent" }}
                                rowsPerPageOptions={[100]}
                                component="div"
                                count={myScoreCount || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Stack> */}
                    </Stack>
                    {/* <Divider /> */}
                    <Box>
                        <TableContainer sx={{ '&::-webkit-scrollbar': { borderRadius: 5, width: '8px' }, '&::-webkit-scrollbar-track': { borderRadius: 5, backgroundColor: '#dcdde1', overflow: 'hidden' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#95a5a6', borderRadius: 5 } }}>
                            <Table size='small' sx={{ width: '100%' }} aria-label='score_table'>
                                <TableHead>
                                    <TableRow sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            py: 2
                                        },
                                    }}>
                                        {myScoreHeader?.map((value, index) => <TableCell align='center' key={index} >
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }} >{value?.name}</Typography>
                                        </TableCell>)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {myScoreData?.length > 0 && myScoreData?.map((row, index) =>
                                        <TableRow sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                py: 1,
                                                borderBottom: (theme) => `1px solid ${((index !== myScoreData?.length - 1) && myScoreData?.length > 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                            },
                                        }} key={index}>
                                            <TableCell align='center'>{row?.id}</TableCell>
                                            <TableCell align='center'>{row?.groups}</TableCell>
                                            {/* <TableCell>{moment().format('YYYY-MM-DD')}</TableCell> */}
                                            <TableCell align='center'>{moment(row?.event_date).format('YYYY-MM-DD')}</TableCell>
                                            <TableCell align='center'>{row?.category}</TableCell>
                                            <TableCell align='center'>{row?.evaluator}</TableCell>
                                            <TableCell align='center'>
                                                <Chip label={`${row?.percentage}%`} sx={{ minHeight: '30px', maxHeight: '30px', minWidth: '78px', maxWidth: '78px', backgroundColor: setButtonBg(row?.score_result), color: setButtonText(row?.score_result) }} />
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" justifyContent="center">
                                                    {row?.is_score_reviewed && <CheckBox />}
                                                    {!row?.is_score_reviewed && <RemoveCircleOutline />}
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    {row?.is_score_reviewed && <Typography sx={{ fontSize: "14px" }}>{moment(row?.score_reviewed_on).format("LL")}</Typography>}
                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box display="flex" justifyContent="center">
                                                    <Typography sx={{ fontSize: "14px" }}>{row?.score_reviewed_by}</Typography>
                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <IconButton onClick={() => { navigate(searchParams.get("stff_emp_id") ? `/reports/reviewPerformance?score_card_Id=${row?.score_card}&score_Id=${row?.id}&review=True&sup_view=True` : `/reports/reviewPerformance?score_card_Id=${row?.score_card}&score_Id=${row?.id}&review=True`) }} size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }} >
                                                    <Visibility size='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                                </IconButton>



                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {myScoreData?.length === 0 && (
                                        <TableRow

                                        >

                                            {myScoreLoader ? <TableCell colSpan={12}>
                                                <Box display="flex" justifyContent="center" alignItems="center" sx={{ padding: '10%' }}>
                                                    <CircularProgress />&nbsp;Loading Data...
                                                </Box>
                                            </TableCell> : <TableCell colSpan={12}>
                                                <Typography gutterBottom align="center" variant="subtitle1" sx={{ padding: '10%', fontSize: "14px" }}>
                                                    No Data Found
                                                </Typography>
                                            </TableCell>}

                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ display: 'flex', my: 1, alignItems: 'center' }}>
                            <>
                                <Typography variant="body2" sx={{ fontSize: '16px', paddingLeft: '35px' }}>
                                    Showing
                                </Typography>
                                <TablePagination
                                    classes={{ root: classes.pagination, displayedRows: classes.paginationText, }}
                                    sx={{ border: "1px solid transparent" }}
                                    rowsPerPageOptions={[100]}
                                    component="div"
                                    count={myScoreCount || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={(page - 1)}
                                // onPageChange={handleChangePage}
                                // onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                            <Pagination sx={{ ml: 'auto' }} count={myScorePageRange} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                        </Box>

                    </Box>
                </Box>
            </>
            <>
                <Box component={Paper} elevation={5} sx={{ px: 2, mb: 2, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0, backgroundColor: theme => theme.palette.background.paper }}>
                    <Stack sx={{ width: "100%", px: 2, py: 2 }} direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                        {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                        <Typography variant='h6'>{`Section Details (${scorecardName || ``})`}</Typography>

                        {/* <Stack direction="row" alignItems="center" alignContent="center">

                            <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                            <TablePagination
                                sx={{ border: "1px solid transparent" }}
                                rowsPerPageOptions={[100]}
                                component="div"
                                count={myScoreCount || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Stack> */}
                    </Stack>
                    <Box component={Paper} elevation={1} sx={{ border: theme => theme.palette.mode !== 'light' ? "1px solid #dcdde1" : 'transparent', my: 1, borderRadius: '5px' }}>
                        <TableContainer component={Paper} elevation={0} sx={{ pb: 2, pt: 1, px: 0, borderRadius: '5px' }}>
                            <Table size='small' sx={{ width: "100%" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            py: 2
                                        },
                                    }}>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }} onMouseEnter={() => setSecDetailShowIcon(true)} onMouseLeave={() => setSecDetailShowIcon(false)}>
                                                <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                    Section
                                                </Typography>&nbsp;
                                                {secDetailShowIcon && <Tooltip title='Sort'>
                                                    <IconButton onClick={() => handleTableOrderSecDetails()}>
                                                        {secDetailTableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}
                                                    </IconButton>
                                                </Tooltip>}
                                            </Box>

                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                Pass
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                Fail
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                N/A
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                Pass Rate
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                Fail Rate
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                Comments
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { lg: '13px', xl: '14px' }, color: (theme) => theme.palette.text?.primary }}>
                                                Percentage
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mySectionData?.length > 0 && mySectionData?.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: (theme) => `1px solid ${(index !== scoreDetail?.section_details?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                },
                                            }}
                                        >
                                            <TableCell>
                                                <Typography sx={{ fontWeight: "bold", fontSize: '14px' }}>
                                                    {row?.section_name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {row?.count}
                                            </TableCell>
                                            <TableCell>{row?.fail_count}</TableCell>
                                            <TableCell>{row?.na}</TableCell>
                                            <TableCell> - </TableCell>
                                            <TableCell> - </TableCell>
                                            <TableCell> - </TableCell>
                                            <TableCell>
                                                <Typography sx={{ fontWeight: "bold", color: "gray", fontSize: '14px' }}>
                                                    {row?.max_val !== row?.na_total && <>{`${((row?.total / (row?.max_val - row?.na_total)) * 100).toFixed(2)}%`}</>}
                                                    {row?.max_val === row?.na_total && <>N/A</>}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                            {scoreDetail?.section_details?.length === 0 && <Box sx={{ width: "90%", minHeight: "80px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                {/* <List sx={{ fontSize: "60px" }} /><br /> */}
                                <Typography variant="body" sx={{ my: 1, fontWeight: "bold" }}>
                                    No section details found for the data range selected.
                                </Typography>
                            </Box>}
                        </TableContainer>
                    </Box>
                </Box>


            </>
        </>
    )
}