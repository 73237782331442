import React from 'react';
// REACT ROUTER IMPORTS
import { useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import { Stack, Box, Paper, IconButton, ToggleButton, ToggleButtonGroup, Typography, Tab, Button, Divider, TableContainer, TableCell, TableBody, Table, TableRow, TableHead, tableCellClasses } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { MilitaryTech, Close } from '@material-ui/icons';
// NPM IMPORTS
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import { makeStyles } from '@material-ui/styles';
import { Interweave } from 'interweave';
import moment from 'moment';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
// CONTEXT IMPORTS
import { SettingsContext } from '../../../contexts/SettingsContext';
import { EmpContext } from '../../../contexts/employeecontext';
// API IMPORTS
import { delGoalApi, getTeamGoalApi, getGroupGoalApi } from '../../../_apis_/authApi.js/staffApi';
import { prsnlGoalApi, empGoalApi } from '../../../_apis_/authApi.js/empapi';
// COMPONENTS IMPORTS
import { MIconButton } from '../../../components/@material-extend';
import DialogGoal from "../goaldialog";
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_EMP } from '../../../routes/paths';


const headers = [
    { name: 'Title', id: 'title' },
    { name: 'Comment', id: 'comment' },
    { name: 'Created At', id: 'created' },
]

// STYLED COMPONENT
const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        padding: "0 0 5px 0",
        width: "100%",
        // backgroundColor: "red",
        margin: "5px 0 10px 0",
        "&::-webkit-scrollbar": {
            marginTop: "10px",
            width: 15,
            height: 4,
            cursor: "pointer",
        },
        "&::-webkit-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "darkgrey",
            outline: `1px solid slategrey`,
            borderRadius: 4,
            cursor: "pointer",
        },
    },

}));

EmpGoals.propTypes = {
    type: PropTypes.string
}

export default function EmpGoals({ type }) {
    // console.log("type of ", typeof type)
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // CONTEXT STATES
    const { setColor, themeMode } = React.useContext(SettingsContext);
    const { datesData, supGoal, setSupGoal, IdEmp } = React.useContext(EmpContext);
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // SNACKBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // STYLED STATES
    const classes = useStyles();
    // CONFIRM STATE
    const confirm = useConfirm();
    // REACT STATES
    const [value, setValue] = React.useState('1');
    const [btnValue, setBtnValue] = React.useState(0);
    const [goalArray, setGoalArray] = React.useState([]);
    const [label, setLabel] = React.useState("Personal Goals");
    const [openDialog, setOpenDialog] = React.useState(false);
    const user = JSON.parse(window.localStorage.getItem('userData'))
    const [grpIndex, setGrpIndex] = React.useState(0);
    const [teamIndex, setTeamIndex] = React.useState(0);
    const [goalId, setGoalId] = React.useState();
    const [desc, setDesc] = React.useState("");


    // METHOD/ FUNCTIONS

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleData = (index) => {
        setBtnValue(index);

    }

    const tabButtons = (propsBtn) => {
        // console.log(propsBtn.direction, propsBtn)
        if (propsBtn.direction === 'left' && !propsBtn.disabled) {
            return (<Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                <Icon onClick={() => propsBtn.onClick()} icon="icon-park-solid:left-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />
            </Stack>)
        }
        if (propsBtn.direction === 'right' && !propsBtn.disabled) {
            return (
                <Stack direction='row' alignItems='center' sx={{ height: 'inherit' }}>
                    <Icon onClick={() => propsBtn.onClick()} icon="icon-park-solid:right-c" style={{ color: '#fb5d2e', fontSize: '18px', cursor: 'pointer' }} />

                </Stack>
            )
        }
        return null

    }

    const SIMPLE_TAB = []

    if (loggedUserData?.staff?.isPersonalGoals && !searchParams.get("stff_emp_id")) {
        SIMPLE_TAB.push({ value: '1', label: 'Personal Goals', disabled: false },
            { value: '2', label: 'Employee Goals', disabled: false },
            { value: '3', label: 'Team Goals', disabled: true },
            { value: '4', label: 'Group Goals', disabled: true })
    }
    else {
        SIMPLE_TAB.push(
            { value: '1', label: 'Employee Goals', disabled: false },
            { value: '2', label: 'Team Goals', disabled: true },
            { value: '3', label: 'Group Goals', disabled: true })
    }

    async function delGoal(goal) {
        await confirm({ description: `This action will permanently delete goal.` });
        const { status } = await delGoalApi(goal);
        if (status === 204) {
            const user = JSON.parse(window.localStorage.getItem('userData'));

            const { data } = await prsnlGoalApi(user?.id);
            setGoalArray(data.results);
            enqueueSnackbar('Deleted Personal Goal Successfully', {
                variant: 'success',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
            // setGoalArray(data.results)
            // return console.log(data.results)
        }
    }

    async function getPersonalGoals() {
        const { data } = await prsnlGoalApi(user?.id);
        return setGoalArray(data.results)
    }

    // console.log(loggedUserData?.isPersonalGoals)

    // USEEFFECT STATE
    React.useEffect(() => {

        const user = JSON.parse(window.localStorage.getItem('userData'))
        async function getGoals() {
            if (label === "Personal Goals" && user?.staff?.staffType === 10) {

                const { data } = await prsnlGoalApi(IdEmp?.user?.id || user?.id);
                return setGoalArray(data.results)
                // return console.log(data.results)
            }
            if (label === "Employee Goals") {
                const { data } = await empGoalApi(IdEmp?.id || user.staff?.id);
                return setGoalArray(data.results)
                // return console.log(data)
            }
            if (label === "Team Goals") {
                const { data } = await getTeamGoalApi(teamIndex || (IdEmp?.user?.teams[0]?.id || user?.teams[0]?.id));
                // console.log(teamIndex)

                return setGoalArray(data.results)
            }
            if (label === "Group Goals") {
                const { data } = await getGroupGoalApi(grpIndex || (IdEmp?.user?.groups[0]?.id || user?.groups[0]?.id));
                return setGoalArray(data.results)
                // return console.log(data)
            }
        }
        if (datesData?.date1 && ((searchParams.get('stff_emp_id') && IdEmp) || !searchParams.get('stff_emp_id'))) {
            getGoals()
        }
    }, [datesData, label, grpIndex, teamIndex, IdEmp])

    // async function updateGoal(id){

    // }

    return (
        <Box sx={{ px: (supGoal === false && type === undefined) ? 1 : 0 }}>
            {(supGoal === false && type === undefined) && <Box>
                <HeaderBreadcrumbs
                    sx={{ p: 0, m: 0, color: '#666666', fontWeight: 600 }}
                    links={[{ name: 'Home', href: searchParams.get('stff_emp_id') ? `${PATH_EMP.dashboard}?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : PATH_EMP.dashboard }, { name: 'Goals', href: PATH_EMP.goals }]}

                />
                <Typography variant='h4' sx={{ mt: 0, fontWeight: '400', color: '#000000' }}>
                    Goals
                </Typography>


            </Box>
                // <HeaderBreadcrumbs
                //     sx={{ pl: 2, ml: 3, pt: 1, pr: 3 }}
                //     links={[{ name: 'Home', href: PATH_EMP.dashboard }, { name: 'Goals', href: PATH_EMP.goals }]}
                // />
            }


            {type && <>
                <Box sx={{ px: 5, py: 2 }} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">Goals</Typography>
                    <IconButton onClick={() => { setSupGoal(false) }}><Close /></IconButton>
                </Box>
                <Divider />
            </>}
            <Box sx={{ px: 0, pt: 1, pb: 2, borderRadius: '5px' }}>
                <TabContext value={value}>
                    <Box sx={{ backgroundColor: '#EAEBF8', py: 1, }}>
                        <TabList TabIndicatorProps={{
                            style: {
                                // backgroundColor: themeMode === 'light' ? setColor?.main : setColor?.dark,
                                height: '5px',
                                borderRadius: '12px 12px 0px 0px'
                            }
                        }} ScrollButtonComponent={(props) => tabButtons(props)} variant="scrollable" sx={{ width: '100%', display: "flex", flexDirection: "row", px: 3 }} onChange={handleChange}>
                            {SIMPLE_TAB.map((tab, index) => (
                                <Tab sx={{ fontWeight: 200, "&.Mui-selected": { color: (theme) => theme.palette.text.buttonText, fontWeight: 700 } }} key={tab.value} label={tab.label} onClick={() => { setLabel(tab.label); setBtnValue(0); setTeamIndex(null); setGoalArray([]) }} value={String(index + 1)} />
                            ))}
                        </TabList>
                    </Box>



                    {label === "Group Goals" && <Box sx={{ px: 2 }} className={classes.root}>
                        <ToggleButtonGroup
                            size="small"
                            value={btnValue}
                            sx={{ mt: 1 }}
                        >
                            {!IdEmp && user?.groups?.map((row, index) =>
                                <ToggleButton key={index} sx={{ color: "black" }} value={index} onClick={() => { setGrpIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                            {IdEmp && IdEmp?.user?.groups?.map((row, index) =>
                                <ToggleButton key={index} sx={{ color: "black" }} value={index} onClick={() => { setGrpIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Box>}
                    {label === "Team Goals" && <Box sx={{ px: 2 }} className={classes.root}>
                        <ToggleButtonGroup
                            size="small"
                            value={btnValue}
                            sx={{ mt: 1 }}
                        >
                            {!IdEmp && user?.teams?.map((row, index) =>
                                <ToggleButton sx={{ color: "black" }} key={index} value={index} onClick={() => { setTeamIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                            {IdEmp && IdEmp?.user?.teams?.map((row, index) =>
                                <ToggleButton sx={{ color: "black" }} key={index} value={index} onClick={() => { setTeamIndex(row?.id); handleData(index) }} aria-label="left aligned">
                                    {row.name}
                                </ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </Box>}
                    <Box
                        sx={{
                            py: 1,
                            mt: 1,
                            height: 'auto',
                            width: '100%',
                            borderRadius: 1
                        }}
                    >
                        {SIMPLE_TAB.map((panel, index) => (
                            <Box component={Paper} elevation={5} sx={{
                                px: 2, mb: 2, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0, backgroundColor: theme => theme.palette.background.paper
                            }}>
                                <TabPanel sx={{ py: 2 }} key={panel.value} value={String(index + 1)}>
                                    {(label === "Personal Goals" && goalArray?.length > 0 && loggedUserData?.staff?.isPersonalGoals && !searchParams.get("stff_emp_id")) &&
                                        <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
                                            <Button onClick={() => { setOpenDialog(true); setDesc(null); setGoalId(null) }} variant="text" sx={{ m: 1 }}>Add Personal Goal</Button>
                                        </Stack>
                                    }

                                    {/* {goalArray?.length > 0 && goalArray?.map((row, index) => <Box key={index} sx={{ p: 2, width: "95%", margin: "0 auto" }}>
                                        <Stack sx={{ pr: 1 }} direction="row" alignItem="center" justifyContent="flex-start">
                                            <Typography sx={{ fontSize: "14px", my: 1, ml: 2 }}>
                                                {`${panel.label} #${index + 1}`}
                                            </Typography>
                                            <Typography sx={{ fontSize: "12px", my: 1, mx: 2, fontWeight: "bold" }}>
                                                {`${moment(row?.created_at).format("LL, LT")}`}
                                            </Typography>

                                        </Stack>
                                        <Box sx={{ p: 2, my: 1, minHeight: "120px", border: "2px dotted #dcdde1", backgroundColor: themeMode === 'light' ? "#dff9fb" : '#636e72', borderRadius: 1, width: "100%", margin: "0 auto" }}>
                                            <Interweave content={row?.description} />                                        </Box>


                                        {(label === "Personal Goals" && loggedUserData?.staff?.isPersonalGoals && !searchParams.get("stff_emp_id")) &&
                                            <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
                                                <Button onClick={() => { setOpenDialog(true); setDesc(row?.description); setGoalId(row?.id) }} variant="outlined" sx={{ mx: 1, border: "1px solid gray", color: "gray" }}>Edit</Button>
                                                <Button variant="outlined" sx={{ mx: 1, border: "1px solid gray", color: "gray" }} onClick={() => { delGoal(row.id) }}>Delete</Button>
                                            </Stack>
                                        }
                                    </Box>)} */}
                                    {/* {
                                        goalArray?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                                            <MilitaryTech sx={{ fontSize: "100px" }} /><br />
                                            <Typography variant="body" sx={{ my: 1 }}>
                                                No Goals Found.
                                            </Typography>
                                            {label === "Personal Goals" && loggedUserData?.staff?.isPersonalGoals && !searchParams.get("stff_emp_id") &&
                                                <Button variant="text" onClick={() => { setOpenDialog(true); setDesc(null); setGoalId(null) }}>Add New Goal+</Button>
                                            }
                                        </Box>
                                    } */}


                                    <TableContainer sx={{ my: 2, '&::-webkit-scrollbar': { borderRadius: 5, width: '8px' }, '&::-webkit-scrollbar-track': { borderRadius: 5, backgroundColor: '#dcdde1', overflow: 'hidden' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#95a5a6', borderRadius: 5 } }}>
                                        <Table stickyHeader size='small' sx={{ width: "100%" }} aria-label="simple table">
                                            <TableHead >
                                                <TableRow sx={{

                                                    [`& .${tableCellClasses.root}`]: {
                                                        py: 2
                                                    },
                                                }}>
                                                    {headers?.map(obj => <TableCell align='center' >
                                                        {obj?.name}
                                                    </TableCell>)}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    goalArray?.length > 0 && goalArray?.map((row, index) => <TableRow
                                                        key={index}
                                                        sx={{
                                                            [`& .${tableCellClasses.root}`]: {
                                                                py: 2,
                                                                borderBottom: (theme) => `1px solid ${(index !== goalArray?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                            },
                                                        }}
                                                    >
                                                        <TableCell align="center">


                                                            <Typography sx={{ fontSize: "14px" }}>
                                                                {`${panel.label} #${index + 1}`}
                                                            </Typography>



                                                        </TableCell>
                                                        <TableCell align="center">


                                                            <Typography sx={{ fontSize: "14px" }}>
                                                                <Interweave content={row?.description} />
                                                            </Typography>



                                                        </TableCell>
                                                        <TableCell align="center" >



                                                            <Typography sx={{ fontSize: "14px" }}>
                                                                {`${moment(row?.created_at).format("LL, LT")}`}
                                                            </Typography>




                                                        </TableCell>

                                                    </TableRow>)
                                                }
                                            </TableBody>

                                        </Table>
                                        {goalArray?.length === 0 && <Box sx={{ p: 2, width: "100%", minHeight: "150px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>

                                            <Typography variant="body2" sx={{ my: 1 }}>
                                                No Comments found for the data range selected.
                                            </Typography>
                                        </Box>}
                                    </TableContainer>

                                </TabPanel>
                            </Box>
                        ))}
                    </Box>
                </TabContext>
            </Box>

            < DialogGoal isOpen={openDialog} setOpen={setOpenDialog} goalfn={getPersonalGoals} id={{ gId: goalId, setGId: setGoalId }} desc={{ data: desc, setData: setDesc }} userid={IdEmp?.user?.id || user?.id} />
        </Box>
    )
}