import React from 'react';
import { Table, TableHead, TableBody, TableCell, TableContainer, TableRow, tableCellClasses } from '@material-ui/core';


const tableHeaders = [{ name: 'Date', id: 'dt' }, { name: 'Uploaded call', id: 'uc' }, { name: 'Time of interaction', id: 'toi' }, { name: 'Calls processed today', id: 'cpt' }, { name: 'Calls processed on another day', id: 'cpad' }, { name: 'Failed calls', id: 'fc' }]

export default function InboxCallLogTable({ data }) {
    return (
        <>
            <TableContainer sx={{ height: { lg: '150px', xl: '350px' } }}>
                <Table sx={{
                    [`& .${tableCellClasses.root}`]: {
                        border: '1px solid #DEE2E6'
                    },
                    "& .MuiTableCell-root:first-of-type": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        boxShadow: 'none',
                    },
                    "& .MuiTableCell-root:last-of-type": {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        boxShadow: 'none',
                    },
                }}>
                    <TableHead>
                        <TableRow>
                            {tableHeaders?.map(obj => <TableCell align='center' >{obj?.name}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((obj, i) => <TableRow key={i} sx={{
                            [`& .${tableCellClasses.root}`]: {
                                py: 2
                            },
                        }} >
                            <TableCell align='center'>{obj?.date}</TableCell>
                            <TableCell align='center'>{obj?.uploaded_calls}</TableCell>
                            <TableCell align='center'>{obj?.time_of_interaction}</TableCell>
                            <TableCell align='center'>{obj?.calls_processed_today}</TableCell>
                            <TableCell align='center'>{obj?.calls_processed_another_day}</TableCell>
                            <TableCell align='center'>{obj?.failed_calls}</TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}