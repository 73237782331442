import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from "moment";

// material
import {
    Box,
    Paper,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Avatar,
    TableContainer,
    Stack,
    Typography,
    Divider,
    OutlinedInput,

} from '@material-ui/core';
import { Interweave } from 'interweave';

import { CheckBox } from "@material-ui/icons";
import { useTheme, styled } from '@material-ui/core/styles';


// components../
import Page from '../../../../../components/Page';
import { MainBlockV2 } from '../../../MainBlockV2';
import Scrollbar from '../../../../../components/Scrollbar';
import { getAllGroups, } from '../../../../../redux/action/staffAction';
import { PATH_DASHBOARD } from "../../../../../routes/paths";
import { getSingleInbox } from "../../../../../_apis_/authApi.js/globalDashboardApi";
//
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import InboxCallLogTable from './inbox_call_log';



// ----------------------------------------------------------------------





// ----------------------------------------------------------------------


const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 45,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));


export default function MessageInboxTable() {
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [toUser, setToUser] = useState("");
    const [seenBy, setSeenBy] = useState("");

    // get Groups
    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllGroups(1))
        async function getValue() {
            const { data } = await getSingleInbox(searchParams.get("id"));
            // console.log(data);
            const newArr = []; const newArr2 = [];
            data?.to_user?.map(obj => newArr.push(`${obj?.first_name} ${obj?.last_name}`));
            data?.seen_by?.map(obj => newArr2.push(`${obj?.first_name} ${obj?.last_name}`));
            setSeenBy(newArr2?.join(","));
            setToUser(newArr?.join(","));
            setData(data);
        }
        getValue();

    }, [])


    // chck error connection

    const chckTimeOut = () => {

        if (isLoading && data?.length === 0) {
            setIsLoading(false);
        }

    }

    setTimeout(() => {
        chckTimeOut();
    }, 60000);




    // updating DATA STATE

    useEffect(() => {

        if (data) {
            setIsLoading(false);
        }
    }, [data]);
    // console.log(group)
    const dispatch = useDispatch();
    const [dense, setDense] = useState(false);

















    let homeLink = PATH_DASHBOARD.root;

    if (loggedUserData?.staff?.staffType === 10) {
        homeLink = '/employee/dashboard'
    }
    if (loggedUserData?.staff?.staffType === 20) {
        homeLink = "/supervisor/dashboard"
    }

    return (
        <>
            <Page sx={{ px: 1 }} title="Inbox | BeyondQA">

                <Box
                    component={Paper}
                    elevation={5}
                    sx={{ backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px', mb: 2 }}
                >

                    <MainBlockV2 title='Inbox' MBLinks={[{ name: 'Home', href: homeLink }, { name: "Notifications", href: "/notification" }, { name: "Inbox" }]}>






                        <Scrollbar sx={{ mt: 3 }}>

                            <Box sx={{ ml: 3 }}>
                                <Typography fontWeight='bold' sx={{ mt: 3, ml: 2 }} >Subject:&nbsp;{data?.subject}</Typography>



                                <Box sx={{ px: 2, pt: 1, pb: 3 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: "300px" }}>
                                        <Avatar alt="img" src="/static/illustrations/beyondQA-suspense.png" />
                                        <Box sx={{ ml: 2 }}>
                                            {/* {allUsers?.results?.length} */}
                                            <Typography variant="body2" sx={{ fontWeight: "light", my: 1 }}>From:&nbsp;{data?.from_user}</Typography>
                                            <Typography variant="body2" sx={{ fontWeight: "light", my: 1 }}>To:&nbsp;{toUser}</Typography>
                                            <Typography variant="body2" sx={{ fontWeight: "light", my: 1 }}>{moment(data?.created_at).format("LL, LT")}</Typography>
                                        </Box >

                                    </Box >
                                    <Box sx={{ my: 1 }}>
                                        {(data?.subject !== 'Daily call logs' && !data?.subject.includes('Call processing report')) && <Box sx={{ mb: 3, height: { lg: '150px', xl: '350px' } }}>
                                            <Interweave content={data?.message} />

                                        </Box>}
                                        {(data?.subject === 'Daily call logs' || data?.subject.includes('Call processing report')) && <Box sx={{ mb: 3 }}>
                                            <InboxCallLogTable data={[JSON.parse(data?.message)]} />

                                        </Box>}



                                        {(data?.is_seen && data?.to_user?.length > 1 && data?.to_user?.length !== data?.seen_by?.length) && <Typography fontStyle='italic' variant="caption" sx={{ mt: 5, mb: 1, fontWeight: 'bold' }}><Stack direction="row" alignItems="center"><CheckBox sx={{ fontSize: "16px", color: 'green' }} /> Seen by {seenBy}</Stack></Typography>}
                                        {(data?.is_seen && data?.to_user?.length > 1 && data?.to_user?.length === data?.seen_by?.length) && <Typography fontStyle='italic' variant="caption" sx={{ mt: 5, mb: 1, fontWeight: 'bold' }}><Stack direction="row" alignItems="center"><CheckBox sx={{ fontSize: "16px", color: 'green' }} /> Seen by everyone</Stack></Typography>}
                                        {(data?.is_seen && data?.to_user?.length === 1) && <Typography variant="caption" fontStyle='italic' sx={{ mt: 5, mb: 1, fontWeight: 'bold' }}><Stack direction="row" alignItems="center"><CheckBox sx={{ fontSize: "16px", color: 'green' }} /> Seen at {moment(data?.last_seen).format("LL")},&nbsp; {moment(data?.last_seen).format("LT")}</Stack></Typography>}

                                    </Box>
                                </Box>
                            </Box>
                        </Scrollbar>


                    </MainBlockV2>
                </Box>
            </Page>
        </>
    );
}
